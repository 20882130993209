import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// import { authenticationService } from '../../services';

const RoutePublic = ({ layout: Layout, component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(currentUser);
    // let pathName = '';
    if (currentUser) {
      const { token } = currentUser;
      console.log(token);
      if (token) {
        switch (token.role) {
          case 0:
          case 1:
            return (<Redirect exact from="/" to={{ pathname: '/banner', state: { from: props.location } }} />);
          case 2:
            return (<Redirect exact from="/" to={{ pathname: '/store', state: { from: props.location } }} />);
          case 3:
            return (<Redirect exact from="/" to={{ pathname: '/schedule-studio', state: { from: props.location } }} />);
          default:
            return (<Redirect exact from="/" to={{ pathname: '/sign-in', state: { from: props.location } }} />);
        }
      }
    }
    // else {
    //   return (<Redirect exact from="/" to={{ pathname: '/sign-in', state: { from: props.location } }} />);
    // }

    // authorised so return component
    return (
      <Layout>
        <Component {...props} />
      </Layout>
    )

  }
  } />
)

export default RoutePublic;