import React, { useState, useEffect } from 'react';
import {
  Grid, Card, TextField, Slide, FormControl, InputLabel, OutlinedInput,
  InputAdornment, IconButton, Button, Snackbar
} from '@material-ui/core';
import useStyles from './styles';
import { useTheme } from '@material-ui/core/styles';
import { API } from '../../constants';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const Profile = () => {
  const classes = useStyles();
  const theme = useTheme();

  // state region
  const [token, setToken] = useState(null);
  const [authData, setAuthData] = useState(null);
  const [messageSnackbar, setMessageSnackbar] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [userStore, setUserStore] = useState(null);
  const [userStudio, setUserStudio] = useState(null);
  const [form, setForm] = useState({
    old_password: '',
    new_password: '',
    conf_new_password: ''
  });
  const [passwordVisibility, setPasswordVisibility] = useState({
    old_password: false,
    new_password: false,
    conf_new_password: false
  });

  useEffect(() => {
    const tempToken = JSON.parse(localStorage.getItem('currentUser'));
    if (tempToken) {
      setAuthData(tempToken.token);
      setToken(tempToken.token.token);
    } else {
      handleShowSnackbar(true, "Sesi anda sudah berakhir, harap login kembali!");
      localStorage.clear();
      window.location.href = "/sign-in";
    }
  }, []);

  useEffect(() => {
    if (authData) {
      if (authData.store) {
        getStoreById(authData.store);
      }
      if (authData.studio) {
        getStudioById(authData.studio);
      }
    }
  }, [authData]);

  const handleChangeForm = type => e => {
    setForm({
      ...form, [type]: e.target.value
    })
  }

  const toggleShowHidePassword = type => e => {
    console.log(passwordVisibility[type]);
    setPasswordVisibility({
      ...passwordVisibility,
      [type]: !passwordVisibility[type]
    })
  }

  const handleMouseDownPassword = e => e.preventDefault();

  const handleChangePassword = async () => {
    if (!form.old_password || !form.new_password || !form.conf_new_password) {
      handleShowSnackbar(true, "Harap isi semua field");
      return;
    }

    if (form.new_password !== form.conf_new_password) {
      handleShowSnackbar(true, "Konfirmasi password tidak sama");
      return;
    }

    const api = await fetch(`${API}/admin/change_password`, {
      method: 'PUT',
      body: JSON.stringify(form),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }

    const res = await api.json();

    if (res.response_code === '00') {
      handleShowSnackbar(true, "Berhasil update password");
      setForm({
        old_password: '',
        new_password: '',
        conf_new_password: ''
      });
    } else {
      handleShowSnackbar(true, res.message);
    }
  }

  const getStoreById = async id => {
    const api = await fetch(`${API}/store?id=${id}`, {
      method: 'GET'
    });

    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }

    const res = await api.json();
    if (res.response_code === '00') {
      setUserStore(res.data);
    }
  }

  const getStudioById = async id => {
    const api = await fetch(`${API}/store/studio?id=${id}`, {
      method: 'GET'
    });

    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }

    const res = await api.json();
    if (res.response_code === '00') {
      setUserStudio(res.data);
    }
  }

  const handleShowSnackbar = (status, message) => {
    setMessageSnackbar(message);
    setShowSnackbar(status);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>Profile</h4>
            <hr style={{ marginBottom: '30px' }} />
            {
              authData ?
                <div>
                  <Grid container style={{ width: '100%' }}>
                    <Grid item xs={6} className="pl-3">
                      <Grid item xs={12}>
                        <h5>Informasi Data User</h5>
                        <table className="table" style={{ backgroundColor: '#f7f7f7', marginTop: '24px' }}>
                          <tbody>
                            <tr>
                              <td>Nama:</td>
                              <td className={classes.detail}>{authData.name}</td>
                            </tr>
                            <tr>
                              <td>Username:</td>
                              <td className={classes.detail}>{authData.username}</td>
                            </tr>
                            {
                              userStore ?
                                <tr>
                                  <td>Store:</td>
                                  <td className={classes.detail}>{userStore.name}</td>
                                </tr> :
                                <tr>
                                  <td>Store:</td>
                                  <td className={classes.detail}>-</td>
                                </tr>
                            }
                            {
                              userStudio ?
                                <tr>
                                  <td>Studio:</td>
                                  <td className={classes.detail}>{userStudio.username}</td>
                                </tr> :
                                <tr>
                                  <td>Studio:</td>
                                  <td className={classes.detail}>-</td>
                                </tr>
                            }
                            {/* <tr>
                          <td>Email:</td>
                          <td className={classes.detail}>{authData.email}</td>
                        </tr> */}
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} className="pl-3">
                    <Grid item xs={12}>
                      <div className="d-flex flex-column">
                        <h5>Ubah Password</h5>
                        <FormControl variant="outlined"
                          margin="normal"
                          className="pl-1"
                        >
                          <InputLabel htmlFor="user-old-password">Password Lama</InputLabel>
                          <OutlinedInput
                            id="user-old-password"
                            type={passwordVisibility.old_password ? 'text' : 'password'}
                            value={form.old_password}
                            placeholder="Password"
                            onChange={handleChangeForm('old_password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={toggleShowHidePassword('old_password')}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {passwordVisibility.old_password ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                        </FormControl>
                        <FormControl variant="outlined"
                          margin="normal"
                          className="pl-1"
                        >
                          <InputLabel htmlFor="user-old-password">Password Baru</InputLabel>
                          <OutlinedInput
                            id="user-old-password"
                            type={passwordVisibility.new_password ? 'text' : 'password'}
                            value={form.new_password}
                            placeholder="Password"
                            onChange={handleChangeForm('new_password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={toggleShowHidePassword('new_password')}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {passwordVisibility.new_password ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                        </FormControl>
                        <FormControl variant="outlined"
                          margin="normal"
                          className="pl-1"
                        >
                          <InputLabel htmlFor="user-conf-password">Konfirmasi Password Baru</InputLabel>
                          <OutlinedInput
                            id="user-conf-password"
                            type={passwordVisibility.conf_new_password ? 'text' : 'password'}
                            value={form.conf_new_password}
                            placeholder="Password"
                            onChange={handleChangeForm('conf_new_password')}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={toggleShowHidePassword('conf_new_password')}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {passwordVisibility.conf_new_password ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            }
                            labelWidth={70}
                          />
                        </FormControl>
                        <Button onClick={handleChangePassword}
                          color="primary"
                          variant="contained"
                          style={{ marginTop: '25px' }}
                        >
                          Ubah Password
                    </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div> :
                null
            }
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={3000}
        message={<span id="message-id">{messageSnackbar}</span>}
      />
    </div>
  )
}

export default Profile;