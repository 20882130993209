import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
    formWidth: {
        width: '100%',
    }
}));

function MySelect(props) {
    const classes = useStyles();
    const { name, placeholder, options, value, onChange, isMulti, inputRef, isDisabled } = props;
    return (
        <Select
            isMulti={isMulti}
            name={name}
            placeholder={placeholder}
            className={classes.formWidth}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            options={options}
            value={value}
            isDisabled={isDisabled}
            onChange={onChange}
            ref={inputRef}
        />
    )
}

MySelect.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

MySelect.defaultProps = {
    isMulti: false,
    isDisabled: false
}

export default MySelect;