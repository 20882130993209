import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { RoutePublic } from './components';

import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Banner as BannerView,
  Cities as CitiesView,
  Categories as CategoriesView,
  SubCategories as SubCategoriesView,
  BannerCategory as BannerCategoriesView,
  ConfirmPayment as ConfirmPaymentView,
  PhotoGallery as PhotoGalleryView,
  Product as ProductView,
  Package as PackageView,
  Reservation as ReservationView,
  Schedule as ScheduleView,
  ScheduleStudio as ScheduleStudioView,
  Setting as SettingView,
  Group as GroupView,
  Store as StoreView,
  Studio as StudioView,
  Transactions as TransactionsView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  User as UserView,
  Profile as ProfileView,
  Voucher as VoucherView,
  Tnc as TncView,
  Staircase as StaircaseView,
  Ranged as RangedView,
  Quotation as QuotationView,
  Template as TemplateView,
} from './views';
import { Document, PDFDownloadLink, PDFViewer, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { blue, green, red } from '@material-ui/core/colors';
import { Receipt } from 'views/Receipt';

const Routes = () => {
  return (
    <Switch>
      {/* <Redirect
        exact
        from="/"
        to="/dashboard"
      /> */}
      {/* <RouteWithLayout
        component={DashboardView}
        exact
        from="/"
        to="/dashboard"
      /> */}
      {/* <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      /> */}
      <RouteWithLayout
        component={BannerView}
        exact
        layout={MainLayout}
        path="/banner"
      />
      <RouteWithLayout
        component={CitiesView}
        exact
        layout={MainLayout}
        path="/cities"
      />
      <RouteWithLayout
        component={CategoriesView}
        exact
        layout={MainLayout}
        path="/categories"
      />
      <RouteWithLayout
        component={SubCategoriesView}
        exact
        layout={MainLayout}
        path="/sub-categories"
      />
      <RouteWithLayout
        component={BannerCategoriesView}
        exact
        layout={MainLayout}
        path="/categories/banner"
      />
      <RouteWithLayout
        component={ConfirmPaymentView}
        exact
        layout={MainLayout}
        path="/confirm-payment"
      />
      <RouteWithLayout
        component={StoreView}
        exact
        layout={MainLayout}
        path="/store"
      />
      <RouteWithLayout
        component={GroupView}
        exact
        layout={MainLayout}
        path="/store/group"
      />
      <RouteWithLayout
        component={StudioView}
        exact
        layout={MainLayout}
        path="/store/studio"
      />
      <RouteWithLayout
        component={ScheduleView}
        exact
        layout={MainLayout}
        path="/store/schedule"
      />
      <RouteWithLayout
        component={ScheduleView}
        exact
        layout={MainLayout}
        path="/schedule-studio"
      />
      <RouteWithLayout
        component={PhotoGalleryView}
        exact
        layout={MainLayout}
        path="/store/photo_gallery"
      />
      <RouteWithLayout
        component={ProductView}
        exact
        layout={MainLayout}
        path="/product"
      />
      <RouteWithLayout
        component={PackageView}
        exact
        layout={MainLayout}
        path="/package"
      />
      <RouteWithLayout
        component={ReservationView}
        exact
        layout={MainLayout}
        path="/reservation"
      />
      <RouteWithLayout
        component={SettingView}
        exact
        layout={MainLayout}
        path="/setting"
      />
      <RouteWithLayout
        component={TransactionsView}
        exact
        layout={MainLayout}
        path="/transactions"
      />
      <RoutePublic
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ProfileView}
        exact
        layout={MainLayout}
        path="/profile"
      />
      <RouteWithLayout
        component={VoucherView}
        exact
        layout={MainLayout}
        path="/voucher-discount"
      />
      <RouteWithLayout
        component={StaircaseView}
        exact
        layout={MainLayout}
        path="/staircase-voucher"
      />
      <RouteWithLayout
        component={TncView}
        exact
        layout={MainLayout}
        path="/tnc"
      />
      <RouteWithLayout
        component={RangedView}
        exact
        layout={MainLayout}
        path="/ranged"
      />
      <RouteWithLayout
        component={QuotationView}
        exact
        layout={MainLayout}
        path="/quota"
      />
      <RouteWithLayout
        component={TemplateView}
        exact
        layout={MainLayout}
        path="/template"
      />
      {/* <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      /> */}
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={Receipt}
        exact
        layout={MainLayout}
        path="/receipt"
      />
      <Redirect to="/sign-in" />
    </Switch>
  );
};

export default Routes;