import React, { useEffect, useState } from 'react';
import MyTable from '../../components/MyTable';
import MySelect from '../../components/Select';

import axios from '../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Button,
    Card,
    Drawer,
    Divider,
    FormControl,
    Grid,
    IconButton,
    TextField,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    TableHead,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Widget from '../../components/Widget';

import { Settings, EmailOutlined } from '@material-ui/icons';

const drawerWidth = '50%';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    content: {
        flexShrink: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        zIndex: '999'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start'
    },
    buttonAdd: {
        margin: theme.spacing(3)
    }
}));

const fields = ['Nama', 'Deskripsi'];
const fieldData = ['name', 'description'];

const Staircase = () => {

    const classes = useStyles();
    const theme = useTheme();

    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        id: null,
        name: "",
        description: ""
    });

    const [packages, setPackages] = useState([]);
    const [selectedMultiPackage, setSelectedMultiPackage] = useState(null);

    useEffect(() => {
        getStaircase();
    }, []);

    const getStaircase = () => {
        axios({
            method: 'GET',
            url: `/staircase/voucher`,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            if (response.status === 200) {
                setData(response.data.data);
            }
        }).catch(error => {
            alert(error);
        })
    }

    const getPackages = (id) => {
        axios({
            method: 'GET',
            url: `/staircase/packages?staircasevoucher=${id}`,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            const selected = [...response.data.data.selected];
            const available = [...response.data.data.available];
            const pkg = available.map(v => ({ label: v.title, value: v.id }))
            const pkgSelected = available.filter(f => selected.includes(f.id))
                .map(v => ({ label: v.title, value: v.id }));

            setPackages(pkg);
            setSelectedMultiPackage(pkgSelected);
        }).catch(error => {
            alert(error);
        });
    };

    const getSetup = (id) => {
        axios({
            method: 'GET',
            url: `/staircase/setup?staircase=${id}`,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            setSetup(response.data.data);
        }).catch(error => {
            alert(error);
        });
    };

    const AddVoucher = () => {
        const param = form
        delete param.id;
        axios({
            method: 'POST',
            url: `/staircase/voucher`,
            data: {
                ...form,
                packages: selectedMultiPackage.map(v => v.value),
            },
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            if (response.status === 200) {
                getStaircase()
                handleDrawerClose();
            }
        }).catch(error => {
            alert(error);
        })
    }

    const UpdateVoucher = () => {
        axios({
            method: 'PUT',
            url: `/staircase/voucher`,
            data: {
                ...form,
                packages: selectedMultiPackage.map(v => v.value),
            },
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            if (response.status === 200) {
                getStaircase()
                handleDrawerClose();
            }
        }).catch(error => {
            alert(error);
        })
    }

    const handleDrawerOpen = (id) => {
        setOpen(true);
        if (id == null) {
            getPackages(0);
        }
    }

    const handleDrawerOpenForEdit = (id) => {
        const staircase = data.filter(f => f.id == id)[0];
        setForm({
            id: staircase.id,
            name: staircase.name,
            description: staircase.description,
        })
        getSetup(id);
        getPackages(id);
        handleDrawerOpen(id);
    }

    const handleChangeForm = name => e => {
        setForm({
            ...form,
            [name]: e.target.value
        });
    };

    const handleOnDelete = (id) => {
        axios({
            method: 'DELETE',
            url: `/staircase/voucher`,
            data: { "id": Number(id) },
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            if (response.status === 200) {
                getStaircase()
            }
        }).catch(error => {
            alert(error);
        })
    }

    const handleDrawerClose = () => {
        setForm({
            id: null,
            name: "",
            description: "",
        })
        setOpen(false);
        setPackages([]);
        setSetup([]);
        setSelectedMultiPackage(null);
    }

    const handleOnNextPage = () => setPage(page + 1);
    const handleOnBackPage = () => setPage(page - 1);

    const [setup, setSetup] = useState([]);
    const [formSetupOpen, setFormSetupOpen] = useState(false);
    const [willDeleteId, setWillDeleteId] = useState(null);
    const [alertDelete, setAlertDelete] = useState(false);
    const [formSetup, setFormSetup] = useState({
        id: null,
        type: "pose",
        staircase_voucher: null,
        multiplier: 0,
        value: 0
    });

    const AddSetup = () => {
        axios({
            method: 'POST',
            url: `/staircase/setup`,
            data: {
                type: "pose",
                staircase_voucher: Number(form.id),
                multiplier: Number(formSetup.multiplier),
                value: Number(formSetup.value),
            },
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            getSetup(form.id);
            handleSetupFormClose();
        }).catch(error => {
            alert(error);
        })
    }

    const EditSetup = () => {
        axios({
            method: 'PUT',
            url: `/staircase/setup`,
            data: {
                id: Number(formSetup.id),
                type: "pose",
                staircase_voucher: Number(form.id),
                multiplier: Number(formSetup.multiplier),
                value: Number(formSetup.value),
            },
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            getSetup(form.id);
            handleSetupFormClose();
        }).catch(error => {
            alert(error);
        })
    }

    const DeleteSetup = () => {
        axios({
            method: 'DELETE',
            url: `/staircase/setup`,
            data: {
                id: Number(willDeleteId),
            },
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            getSetup(form.id);
            setAlertDelete(false);
        }).catch(error => {
            alert(error);
        })
    }

    const handleSetupFormOpen = (data) => {
        if (data != null) {
            setFormSetup({
                ...formSetup,
                id: data.id,
                multiplier: data.multiplier,
                value: data.value,
            });
        }
        setFormSetupOpen(true);
    }

    const handleSetupFormClose = () => {
        setFormSetup({
            id: null,
            type: "pose",
            staircase_voucher: null,
            multiplier: 0,
            value: 0
        });
        setFormSetupOpen(false);
    }

    const handleChangeFormSetup = name => e => {
        setFormSetup({
            ...formSetup,
            [name]: e.target.value
        });
    };

    const handleDeleteSetup = (item) => {
        setWillDeleteId(item.id);
        setAlertDelete(true);
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Card className={classes.root} variant="outlined">
                        <h4>Voucher Berjenjang</h4>
                        <hr style={{ marginBottom: '30px' }} />
                        <MyTable
                            id="id"
                            data={data}
                            fields={fields}
                            fieldData={fieldData}
                            onAdd={() => handleDrawerOpen(null)}
                            onEdit={handleDrawerOpenForEdit}
                            onUpdate={() => { }}
                            onDelete={handleOnDelete}
                            titleAdd="Tambah Voucher Berjenjang"
                            page={page}
                            onNextPageClicked={handleOnNextPage}
                            onBackPageClicked={handleOnBackPage} />
                    </Card>
                </Grid>
            </Grid>

            <Drawer
                className={classes.drawer}
                anchor="right"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => {
                        handleDrawerClose()
                    }}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    {form.id == null ? "Simpan" : "Ubah"} Informasi Voucher
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ position: 'absolute', right: '20px', top: '-10px' }}
                        className={classes.buttonAdd}
                        onClick={() => form.id != null ? UpdateVoucher() : AddVoucher()}>
                        {form.id == null ? "Simpan" : "Ubah"}
                    </Button>
                </div>
                <Divider />
                <Grid container style={{ padding: theme.spacing(3) }}>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            label="Nama Voucher"
                            value={form.name}
                            onChange={handleChangeForm('name')}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                        />
                        <TextField
                            id="description"
                            label="Deskripsi Voucher"
                            value={form.description}
                            onChange={handleChangeForm('description')}
                            margin="normal"
                            fullWidth
                            multiline
                            rows='4'
                            variant="outlined"
                        />
                        <div style={{ padding: theme.spacing(1) }}></div>
                        <MySelect
                            isMulti
                            name="Package"
                            placeholder="Pilih Package"
                            options={packages}
                            value={selectedMultiPackage}
                            onChange={e => (setSelectedMultiPackage(e))}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Divider />
                {
                    form.id != null &&
                    <div>
                        <Grid container style={{ padding: theme.spacing(3) }}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ float: "right" }}
                                    onClick={() => handleSetupFormOpen(null)}>
                                    Tambah Setup
                                </Button>
                                <br />
                                <br />
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>Multiplier</TableCell>
                                            <TableCell align='center'>Value</TableCell>
                                            <TableCell align='center'>Aksi</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            setup.map(item => (
                                                <TableRow>
                                                    <TableCell align='center'>
                                                        {item.multiplier}
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {item.value}%
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <EditIcon color='action' onClick={() => handleSetupFormOpen(item)} />
                                                        <DeleteIcon color='error' onClick={() => handleDeleteSetup(item)} />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </div>
                }
            </Drawer>

            <Dialog open={formSetupOpen} onClose={handleSetupFormClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Setup Voucher Berjenjang</DialogTitle>
                <DialogContent>
                    <Grid container style={{ width: '530px' }}>
                        <TextField
                            id="multiplier"
                            label="Multiplier"
                            InputProps={{ inputProps: { min: 0 } }}
                            value={formSetup.multiplier}
                            onChange={handleChangeFormSetup('multiplier')}
                            type="number"
                            margin="normal"
                            fullWidth
                            variant="outlined"
                        />
                        <TextField
                            id="value"
                            label="Value"
                            InputProps={{ inputProps: { min: 0 } }}
                            value={formSetup.value}
                            onChange={handleChangeFormSetup('value')}
                            type="number"
                            margin="normal"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSetupFormClose} color="primary">
                        Tidak
                    </Button>
                    <Button onClick={formSetup.id === null ? () => AddSetup() : () => EditSetup()} variant="contained" color="primary">
                        Simpan
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={alertDelete} onClose={() => { setAlertDelete(false) }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
                <DialogContent>
                    Anda yakin ingin menghapus data ini?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAlertDelete(false) }} color="primary">
                        Tutup
                    </Button>
                    <Button onClick={() => {
                        DeleteSetup();
                    }} color="primary" variant="contained">
                        Hapus
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Staircase;