import { API } from 'constants/api';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Drawer,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Snackbar
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MyTable from '../../../components/MyTable';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ReactQuill from 'react-quill';

const fields = ['Title', 'Type'];
const fieldData = ['title', 'type_name'];
const drawerWidth = '70%';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  buttonAdd: {
    margin: theme.spacing(3)
  }
}));

const EmailSettingsView = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [settingsData, setSettingsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [page, setPage] = useState(1);
  const [form, setForm] = useState({
    id: null,
    title: '',
    content: '',
    type: '1'
  });

  useEffect(() => {
    getAllContentSettings();
  }, []);

  const getAllContentSettings = async () => {
    const api = await fetch(`${API}/email/settings`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const res = await api.json();

    if (res.response_code === '00') {
      if (res.data.length === 0) {
        // Handle empty voucher
      } else {
        const datas = res.data;
        datas.map(v => {
          switch (v.type) {
            case 1:
              v.type_name = 'Invoice';
              break;
            case 2:
              v.type_name = 'JOM';
              break;
            case 3:
              v.type_name = 'Voucher';
              break;
            default:
              break;
          }
        });

        setSettingsData(datas);
      }
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const addOrEditContent = async () => {
    // validate form
    if (form.title == null || form.title.length == 0) {
      handleShowSnackbar('Semua field harus diisi');
      return;
    }
    if (form.content == null || form.content.length == 0) {
      handleShowSnackbar('Semua field harus diisi');
      return;
    }
    let url = `${API}/email/setting`;
    if (form.id) {
      url = `${API}/email/setting?id=${form.id}`;
    }

    const body = JSON.stringify({
      type: form.type,
      title: form.title,
      content: form.content
    });

    const api = await fetch(url, {
      method: form.id ? 'PUT' : 'POST',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const res = await api.json();

    if (res.response_code === '00') {
      handleDrawerClose();

      let msg = 'Setting berhasil dibuat';
      if (form.id) {
        msg = 'Setting berhasil diubah';
      }
      handleShowSnackbar(msg);
      getAllContentSettings();
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const handleShowSnackbar = message => {
    setShowSnackbar(true);
    setMessage(message);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenForEdit = id => {
    const selectedSetting = settingsData.find(v => v.id === id);

    setForm({
      ...form,
      id,
      title: selectedSetting.title,
      content: selectedSetting.content,
      type: selectedSetting.type
    });
    handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      content: '',
      title: '',
      type: '1'
    });
  };

  const handleChangeForm = name => e => {
    console.log(e.target.value);
    setForm({
      ...form,
      [e.name]: e.target.value
    });
  };

  const handleChangeContent = value => {
    setForm({
      ...form,
      content: value
    });
  };

  const handleOnNextPage = () => setPage(page + 1);

  const handleOnBackPage = () => setPage(page - 1);

  return (
    <Grid item md={12} xs={12}>
      <Card className={classes.root} variant="outlined">
        <h4>Email Content Settings</h4>
        <hr style={{ marginBottom: '30px' }} />

        <MyTable
          id="id"
          data={settingsData}
          fields={fields}
          fieldData={fieldData}
          onAdd={handleDrawerOpen}
          onEdit={handleDrawerOpenForEdit}
          onUpdate={() => {}}
          isAdd={true}
          page={page}
          onNextPageClicked={handleOnNextPage}
          onBackPageClicked={handleOnBackPage}
          titleAdd={'Tambah'}
        />
      </Card>

      {/* ALERT FOR ERROR */}
      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        autoHideDuration={3000}
        message={<span id="message-id">{message}</span>}
      />

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={() => {
              handleDrawerClose();
            }}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          {form.id == null ? 'Simpan' : 'Ubah'} Setting Email
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', right: '20px', top: '-10px' }}
            className={classes.buttonAdd}
            onClick={addOrEditContent}>
            {form.id == null ? 'Simpan' : 'Ubah'}
          </Button>
        </div>
        <Divider />

        <Grid container spacing={4} className="p-3">
          <Grid item xs={6}>
            <TextField
              id="title"
              label="Judul"
              value={form.title}
              onChange={e => setForm({ ...form, title: e.target.value })}
              margin="normal"
              name="title"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
              style={{ margin: '15px 15px 0 0' }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Tipe
              </InputLabel>
              <Select
                label="demo-simple-select-label"
                id="satuan"
                value={form.type}
                onChange={e => setForm({ ...form, type: e.target.value })}>
                <MenuItem value="1">Invoice</MenuItem>
                <MenuItem value="2">JOM</MenuItem>
                <MenuItem value="3">Voucher</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <ReactQuill onChange={handleChangeContent} value={form.content} />
          </Grid>
        </Grid>
        <Divider />
      </Drawer>
    </Grid>
  );
};

export default EmailSettingsView;
