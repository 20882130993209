import React, { useEffect,useState } from 'react';
import { NavLink } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import ButtonExportExcel from '../../../components/ButtonExportExcel';
import Select from '../../../components/Select';
import axios from '../../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  Grid, 
  Card, 
  Drawer,
  Divider,
  Button,
  IconButton,
  InputLabel
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';

const drawerWidth = '70%';
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    },
    content: {
      flexShrink: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: '999'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    buttonAdd: {
      margin: theme.spacing(3)
    }
  })
);

const fields = ['Nama','No telp','Paket','Jumlah orang','Tambah Baju','Tambah Pose','Waktu','Status'];
const fieldData = ['Nama','Nomor_Telepon','Paket','Jumlah_Orang','Addon_Cloth','Addon_Pose','Waktu','Status']; 

const Schedule = () => {
  const classes = useStyles();
  const theme = useTheme();

  // Role
  const currUser = JSON.parse(localStorage.getItem('currentUser'));
  const [accountData, setAccountData] = useState(null);

  //state
  const [data, setData] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [dataStudio, setDataStudio] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStudio, setSelectedStudio] = useState(null);
  const [selectedDate, handleDateChange] = useState(new Date());

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  // FILTER DATE
  const startDate = new Date();
  function disablePrevDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    }
  }

  // state for export excel
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    if (currUser) {
      const { token } = currUser;
      if (token) {
        setAccountData(token);
      }
    }
  }, []);

  useEffect(() => {
    if (accountData) {
      if (accountData.role === 2) {
        getStore(accountData.store);
      }else if (accountData.role === 3) {
        getStore(accountData.store);
        getStudio(accountData.studio);
        getSchedules(accountData.store,accountData.studio,selectedDate);
      }
      else {
        getStores();
      }
    }
  }, [accountData]);

  // API
  // ROLE = 0 || ROLE = 1
  const getStores = () => {
    axios({
      method: 'GET',
      url: `stores`,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) { parsingStore(response.data.data); }
      else { parsingStore([]); }
    }).catch(error => { alert(error); })
  };

  // ROLE = 2
  const getStore = (store) => {
    axios({
      method: 'GET',
      url: `store?id=`+store,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) { 
        setSelectedStore(response.data.data);
        if (accountData.role === 2) { 
          getStudios(response.data.data);
          getSchedules(accountData.store,response.data.data.studios[0].id,selectedDate);
        }
      }
      else { setSelectedStore([]) }
    }).catch(error => { alert(error); })
  };

  // ROLE = 0~2
  function getStudios(store){
    axios({
      method: 'GET',
      url: `store/studios?store=`+store.id,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) { parsingStudio(response.data.data); }
      else { parsingStudio([]); }
    }).catch(error => { alert(error); })
  };

  // ROLE = 3
  function getStudio(studio){
    axios({
      method: 'GET',
      url: `store/studio?id=`+studio,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if(response.status === 200 && response.data.data != null){ 
        setSelectedStudio(response.data.data);
      }
      else { setSelectedStudio([]); }
    }).catch(error => { alert(error); })
  };

  function getSchedules(store,studio,date){
    if (accountData) {
      date = moment(date).format("YYYY-MM-DD");
      axios({
        method: 'GET',
        // url: `store/studio/schedules?store=`+store+`&studio=`+studio+`&date_from=`+date+`&date_to=`+date,
        url: `store/studio/schedules?store=`+store+`&studio=`+studio+`&date=`+date,
        headers: { "Content-Type": "application/json" },
      }).then(response => {
        if (response.status === 200&& response.data.data != null) { 
          parsingSchedules(response.data.data[0].studio_schedule,response.data.data[0].store);
        }
      }).catch(error => { console.log(error); })
    }
  };
  //

  // parsing
  const parsingStore = data => {
    if (data != null) {
      const newData = data.map(v => {
        return{
          ...v,
          label: v.name,
          value: v.id
        }
      })
     
     setDataStore(newData);
     setSelectedStore(newData[0]);
     
     getStudios(newData[0]);

     getSchedules(newData[0].id,newData[0].studios[0].id,selectedDate);
    }
  };

  const parsingStudio = (data) => {
    if (data != null) {
      const newData = data.map(v => {
      return{
        ...v,
        label: v.name,
        value: v.id
        }
      })

    setDataStudio(newData);
    setSelectedStudio(newData[0]);
    }
  };

  const parsingSchedules = (data,data_store) => {

    data.map(v => {
      // v.data.map(val => {
        const newData = v.schedule.map((s,i) => {
            return{
              // ...data,
              No:i+1,
              Store: data_store.name,
              Studio: v.studio.name,
              Nama: s.name,
              Nomor_Telepon: s.phone_number,
              Paket: s.package_title,
              Jumlah_Orang: s.person + " orang",
              Waktu: s.time_from + " - " + s.time_to,
              Status: s.status,
              Addon_Cloth : s.add_on_cloth,
              Addon_Pose : s.add_on_pose
            }
        })
        setData(newData);
        setExcelData(newData);
      // })
    })
  };
  // 

  // handle
  const handleDrawerClose = () => { setOpen(false) };

  const handleSelectedStore = store => {
    setSelectedStore(store);
    getStudios(store);
  };

  const handleSelectedStudio = studio => { setSelectedStudio(studio); };

  const handleFilterSchedule = () => { getSchedules(selectedStore.id,selectedStudio.id,selectedDate); };
  //

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  const renderFilterStore = () => {
    if (accountData) {
      if (accountData.role === 2 || accountData.role === 3) {
        return(
        <h5 style={{marginBottom:'15px'}}>Store : <b>{selectedStore && selectedStore.name}</b></h5>
        )
      }else{
        return(
          <Grid item xs={3} style={{float:'left',display: 'block',minWidth: '200px',marginRight:'20px'}}>
            <InputLabel htmlFor="store"><b>Filter Store</b></InputLabel>
            <Select
              name="Store"
              label="store"
              placeholder="Filter Store"
              options={dataStore}
              value={selectedStore}
              onChange={handleSelectedStore}
            />
          </Grid>
        )
      }
    }
  };

  const renderFilterStudio = () => {
    if (accountData) {
      if (accountData.role === 3) {
        return(
          <h5 style={{marginBottom:'15px'}}>Studio : <b>{selectedStudio && selectedStudio.name}</b></h5>
        )
      } else {
        return(
        <Grid item xs={3} style={{float:'left',display: 'block',minWidth: '200px',marginRight:'20px'}}>
          <InputLabel htmlFor="Studio"><b>Filter Studio</b></InputLabel>
          <Select
            name="Studio"
            label="Studio"
            placeholder="Filter Studio"
            options={dataStudio}
            value={selectedStudio}
            onChange={handleSelectedStudio}
          />
        </Grid>
        )
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            {
            accountData && accountData.role === 3
            ?
            <></>
            :
            <NavLink to='/store' style={{ color:'#3c3835',marginLeft:'-10px' }}>
              <ArrowBackIcon />
            </NavLink>
            }

            <h4>Schedule Studio</h4>
            <hr style={{ marginBottom:'30px' }}/>

            <Grid item xs={12} style={{ margin:'15px 0 25px -10px' }}>
              { renderFilterStore() }
              { renderFilterStudio() }
              <Grid item xs={3} style={{ float:'left',display: 'block',minWidth: '200px',marginRight:'20px',marginTop:'5px' }}>
                <InputLabel htmlFor="Studio"><b>Filter Tanggal Booking</b></InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    value={selectedDate}
                    onChange={date => handleDateChange(date)}
                    minDate={new Date()}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilterSchedule}
                style={{ marginTop:'25px',marginRight:'15px' }}
              >
                Filter
              </Button>
              <ButtonExportExcel 
                csvData={excelData} 
                fileName={`Laporan Jadwal Booking Jonas ${moment(selectedDate).format("D MMM YYYY")}`}
                style={{padding:'20px'}}
                />
            </Grid>
            
            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onDetail={() => {}}
              page={page}
              isReport={true}
              isAction={false}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage}/>
          </Card>
        </Grid>
      </Grid>
      
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ 
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          Detail Schedule
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
        </Grid>
        <Divider />

      </Drawer> 
    </div>
  );
};

export default Schedule;
