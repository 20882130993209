import React, { useEffect, useState } from 'react';
import {
  Grid, Card, Drawer, Divider, Button, IconButton, TextField, Typography, Slide, Snackbar,
  OutlinedInput, InputAdornment, FormControl, InputLabel
} from '@material-ui/core';
import MySelect from '../../components/Select';
import { useTheme } from '@material-ui/core/styles';
import { API } from '../../constants';
import MyTable from "../../components/MyTable";
import useStyles from './styles';

// Icons
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Visibility, VisibilityOff
} from '@material-ui/icons';

const User = () => {
  const classes = useStyles();
  const theme = useTheme();

  // table data
  const fields = ['Username', 'Nama', 'Role'];
  const fieldData = ['username', 'name', 'role'];

  // state region
  const [authData, setAuthData] = useState(null);
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [roles, setRoles] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [studioData, setStudioData] = useState([]);
  const [canFetchStore, setCanFetchStore] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isStoreDisabled, setIsStoreDisabled] = useState(false);
  const [form, setForm] = useState({
    id: null,
    username: '',
    password: '',
    name: '',
    role: null,
    store: null,
    studio: null
  });

  useEffect(() => {
    const tempToken = JSON.parse(localStorage.getItem('currentUser'));
    if (tempToken) {
      setAuthData(tempToken.token);
      setToken(tempToken.token.token);
      fetchStores();
    } else {
      handleShowSnackbar(true, "Sesi anda sudah berakhir, harap login kembali!");
      localStorage.clear();
      window.location.href = "/sign-in";
    }
  }, []);

  const conditionByRole = x => {
    const { role } = authData;
    switch (role) {
      case 0:
        return x.role !== 0;
      case 1:
        return x.role !== 0 && x.role !== 1;
      case 2:
        setIsStoreDisabled(true);
        setCanFetchStore(false);
        return x.role !== 0 && x.role !== 1 && x.role !== 2;
      default:
        return x.role !== 0 && x.role !== 1 && x.role !== 2;
    }
  }

  const getAllUser = async (userToken) => {
    const api = await fetch(`${API}/admin/get_all`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });
    if (!api.ok) {
      // if (api.status === 401) {
      //   handleShowSnackbar(true, "Sesi anda sudah berakhir, harap login kembali!");
      //   localStorage.clear();
      //   window.location.href = "/sign-in";
      //   return;
      // }
      handleShowSnackbar(true, api.status);
      return;
    }
    const res = await api.json();
    if (res && res.response_code && res.response_code === '00') {
      // populate user list
      const { data } = res;
      let tempUser = [];
      data.map(x => {
        // hide superadmin :p
        if (conditionByRole(x)) {
          x.role_id = x.role;
          x.role = roles.find(v => v.id === x.role).role;
          tempUser.push(x);
        }
      });
      setUserData(tempUser);
    } else {
      handleShowSnackbar(true, res.message ? res.message : "Unknown error. Please contact developer!");
    }
  }

  const fetchRoles = async (userToken) => {
    const api = await fetch(`${API}/admin/roles`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    });
    if (!api.ok) {
      // if (api.status === 401) {
      //   handleShowSnackbar(true, "Sesi anda sudah berakhir, harap login kembali!");
      //   localStorage.clear();
      //   window.location.href = "/sign-in";
      //   return;
      // }
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res && res.response_code && res.response_code === '00') {
      setRoles(res.data);
      // populate roles
      // remove superadmin
      let tempRoles = [];
      res.data.map(v => {
        if (v.id !== 0) {
          v.label = v.role;
          v.value = v.id;
          tempRoles.push(v);
        }
      })
      setRoleData(tempRoles);
    } else {
      handleShowSnackbar(true, res.message ? res.message : "Unknown error. Please contact developer!");
    }
  }

  const fetchStores = async () => {
    const api = await fetch(`${API}/stores`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      res.data.map(v => {
        v.label = v.name;
        v.value = v.id;
      });
      setStoreData(res.data);
    } else {
      handleShowSnackbar(res.message);
    }
  }

  const fetchStudio = async (storeId) => {
    const api = await fetch(`${API}/store/studios?store=${storeId}`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      res.data.map(v => {
        v.label = v.name;
        v.value = v.id;
      });
      setStudioData(res.data);
    } else {
      handleShowSnackbar(true, res.message);
    }
  }

  useEffect(() => {
    if (token) {
      fetchRoles(token);
    }
    // if (token) {
    //   getAllUser(token);
    // }
  }, [token]);

  useEffect(() => {
    if (token) {
      getAllUser(token);
    }
  }, [roles]);

  const handleDrawerOpen = () => {
    setIsStoreDisabled(false);
    setCanFetchStore(true);
    setOpen(true);

    fetchStores();
  };

  const [selectedUser, setSelectedUser] = useState(null);

  const handleDrawerOpenForEdit = (id) => {
    const selectedUser = userData.find(x => x.id === id);
    setSelectedUser(selectedUser);

    const role = roleData.find(x => x.id === selectedUser.role_id);
    let store = null;
    if (selectedUser.store === null) {
      setCanFetchStore(true);
      setIsStoreDisabled(false);
    } else {
      store = storeData.find(x => x.id === selectedUser.store);
      if (store) {
        store.label = store.name;
        fetchStudio(store.id);
      }
    }
    setForm({
      id: selectedUser.id,
      username: selectedUser.username,
      name: selectedUser.name,
      role: role,
      store: store,
      studio: selectedUser.studio
    });
    setOpen(true);
  }

  useEffect(() => {
    if (selectedUser && selectedUser.studio) {
      let studio = studioData.find(x => x.id === selectedUser.studio);
      if (studio) {
        studio.label = studio.name;
        setForm({
          ...form, studio
        });
      }
    }
  }, [studioData]);

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      username: '',
      password: '',
      name: '',
      role: null,
      store: null,
      studio: null
    });
  };

  const handleChangeSelect = type => value => {
    if (type === 'store') {
      setStudioData([]);
      fetchStudio(value.id);

      setForm({
        ...form, [type]: value, studio: type === 'store' ? null : form.studio
      });
    }
    if (type !== 'store') {
      setForm({
        ...form, [type]: value
      });
    }
  }

  const handleChangeForm = type => e => {
    setForm({
      ...form, [type]: e.target.value
    })
  }

  const toggleShowHidePassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = e => e.preventDefault();

  const validateForm = () => form.username && form.username.length > 0 &&
    form.name && form.name.length > 0 &&
    form.password && form.password.length > 0 &&
    form.role;

  const validateFormEdit = () => form.username && form.username.length > 0 &&
    form.name && form.name.length > 0 &&
    form.role;

  const deleteUser = async id => {
    const api = await fetch(`${API}/admin?id=${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      handleShowSnackbar(true, 'Berhasil hapus user');
      getAllUser(token);
    } else {
      handleShowSnackbar(true, res.message);
    }
  }

  const createOrEditUser = () => {
    if (form.id) {
      // create new user
      if (!validateFormEdit()) {
        handleShowSnackbar(true, "Mohon isi semua field!");
        console.log(form);
        return;
      }

      updateUser();
    } else {
      // create new user
      if (!validateForm()) {
        handleShowSnackbar(true, "Mohon isi semua field!");
        return;
      }

      createUser();
    }
  }

  const createUser = async () => {
    const body = {
      username: form.username,
      password: form.password,
      name: form.name,
      role: form.role.id,
      store: form.store && form.store.id ? form.store.id : null,
      studio: form.studio && form.studio.id ? form.studio.id : null,
    }

    const api = await fetch(`${API}/admin/add`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!api.ok) {
      // if (api.status === 401) {
      //   handleShowSnackbar(true, "Sesi anda sudah berakhir, harap login kembali!");
      //   localStorage.clear();
      //   window.location.href = "/sign-in";
      //   return;
      // }
      handleShowSnackbar(true, api.statusText);
      return;
    }

    const res = await api.json();
    if (res.response_code === '00') {
      // Success create user
      handleDrawerClose();
      getAllUser(token);
    }
  }

  const updateUser = async () => {
    const body = {
      username: form.username,
      name: form.name,
      role: form.role.id,
      store: form.store && form.store.id ? form.store.id : null,
      studio: form.studio && form.studio.id ? form.studio.id : null,
    }

    const api = await fetch(`${API}/admin/customize`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!api.ok) {
      // if (api.status === 401) {
      //   handleShowSnackbar(true, "Sesi anda sudah berakhir, harap login kembali!");
      //   localStorage.clear();
      //   window.location.href = "/sign-in";
      //   return;
      // }
      handleShowSnackbar(true, api.statusText);
      return;
    }

    const res = await api.json();
    if (res.response_code === '00') {
      // Success create user
      handleDrawerClose();
      getAllUser(token);
    }
  }

  const handleShowSnackbar = (status, message) => {
    setMessageSnackbar(message);
    setShowSnackbar(status);
  }

  const handleOnNextPage = () => setPage(page + 1);

  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>User</h4>
            <hr style={{ marginBottom: '30px' }} />
            {
              userData ?
                <MyTable
                  id="id"
                  data={userData}
                  fields={fields}
                  fieldData={fieldData}
                  onAdd={handleDrawerOpen}
                  onDelete={deleteUser}
                  onEdit={handleDrawerOpenForEdit}
                  onUpdate={() => { }}
                  titleAdd="Tambah User"
                  page={page}
                  onNextPageClicked={handleOnNextPage}
                  onBackPageClicked={handleOnBackPage}
                /> :
                null
            }
          </Card>
        </Grid>
      </Grid>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography variant="h5">{form.id == null ? "Simpan" : "Ubah"} Informasi User</Typography>
          <Button variant="contained"
            color="primary"
            className={classes.buttonAdd}
            onClick={createOrEditUser}
          >
            {form.id == null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            <TextField
              id="username"
              label="Username"
              value={form.username}
              onChange={handleChangeForm('username')}
              margin="normal"
              className={form && form.id ? "w-100" : "w-50 pr-1"}
              variant="outlined"
            />
            {
              form && form.id ?
                null :
                <FormControl variant="outlined"
                  margin="normal"
                  className="w-50 pl-1"
                >
                  <InputLabel htmlFor="user-password">Password</InputLabel>
                  <OutlinedInput
                    id="user-password"
                    type={showPassword ? 'text' : 'password'}
                    value={form.password}
                    placeholder="Password"
                    onChange={handleChangeForm('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowHidePassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
            }
            <TextField
              id="name"
              label="Nama"
              value={form.name}
              onChange={handleChangeForm('name')}
              margin="normal"
              fullWidth
              className="mt-0"
              variant="outlined"
            />
            <div className="MuiFormControl-marginNormal mt-0">
              <MySelect
                name="Role"
                placeholder="Pilih Role"
                options={roleData}
                value={form.role}
                onChange={handleChangeSelect('role')}
              />
            </div>
            <div className="MuiFormControl-marginNormal mt-0">
              <MySelect
                name="Store"
                placeholder="Pilih Store"
                options={storeData}
                isDisabled={isStoreDisabled}
                value={form.store}
                onChange={handleChangeSelect('store')}
              />
            </div>
            <div className="MuiFormControl-marginNormal mt-0">
              <MySelect
                name="Studio"
                placeholder="Pilih Studio"
                options={studioData}
                value={form.studio}
                onChange={handleChangeSelect('studio')}
              />
            </div>
          </Grid>
        </Grid>
      </Drawer>
      {/* Snackbar Component */}
      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={3000}
        message={<span id="message-id">{messageSnackbar}</span>}
      />
    </div >
  )
}

export default User;