import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Snackbar,
  Slide,
} from '@material-ui/core';
import { API } from '../../constants';
import logo_dark from '../../assets/logo/logo-jonas-dark.png';

import fetch from 'isomorphic-unfetch';

// import { authenticationService } from '../../services'

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px',
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    }
  },
  form: {
    flexBasis: 500,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    backgroundColor: '#fff',
    padding: '50px',
    borderRadius: '15px',
    boxShadow: '5px 10px 18px #c1c1c1',
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { history } = props;

  const classes = useStyles();

  const currentUser = JSON.parse(localStorage.getItem('currentUser'));

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  // SHOWING SNACKBAR ERROR
  const [alertt, setAlert] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');

  useEffect(() => {

    // if (authenticationService.currentUserValue) {
    //   // history.push('/dashboard');
    //   console.log('aa')
    // }
    // const errors = validate(formState.values, schema);

    // setFormState(formState => ({
    //   ...formState,
    //   isValid: errors ? false : true,
    //   errors: errors || {}
    // }));
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const doLogin = async (e) => {
    e.preventDefault();
    try {
      const api = await fetch(`${API}/admin/login`, {
        method: 'GET',
        headers: { 'Authorization': `Basic ${btoa(formState.values.email + ":" + formState.values.password)}` }
      });
      const res = await api.json();
      
      // if api error
      if (!api.ok) throw new Error('Email atau password salah');
      console.clear();

      // if api success
      if (res.response_code === '00') {
        const data = {
          token: res.data,
          username: formState.values.email
        };
        localStorage.setItem('token', res.data);
        localStorage.setItem('currentUser', JSON.stringify(data));
  
        const { role } = res.data;
        switch (role) {
          case 0:
          case 1:
            history.push('/banner');
            return;
          case 2:
            history.push('/store');
            return;
          case 3:
            history.push('/schedule-studio');
            return;
          default:
            history.push('/sign-in');
            return;
        }
      }
    }
    catch (err) {
      handleShowSnackbar(true, String(err));
    }
  }

  const handleShowSnackbar = (status, err) => {
    setMessageSnackbar(err);
    setAlert(status);
  }

  // const handleSignIn = event => {
  //   event.preventDefault();
  //   // history.push('/');

  //   authenticationService.login(formState.values.email, formState.values.password)
  //     .then(
  //       user => {
  //         // const { from } = window.location.state || { from: { pathname: "/cities" } };
  //         // history.push(from);
  //         // history.push('/cities')
  //         // currentUser == null ? history.push('/sign-in') : history.push('/banner');
  //         if (currentUser) {
  //           const { token } = currentUser;
  //           if (token) {
  //             switch (token.role) {
  //               case 0:
  //               case 1:
  //                 history.push('/banner');
  //                 return;
  //               case 2:
  //                 history.push('/store');
  //                 return;
  //               case 3:
  //                 history.push('/store');
  //                 return;
  //               default:
  //                 history.push('/sign-in');
  //                 return;
  //             }
  //           }
  //         } else {
  //           history.push('/sign-in');
  //         }
  //       },
  //       error => {
  //         // setSubmitting(false);
  //         // setStatus(error);
  //         alert(error)
  //       }
  //     );

  // };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content} style={{ margin: '5% 0 0 30%' }}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={doLogin}
              >
                <img src={logo_dark} style={{ width: '200px', margin: '0 auto', display: 'block' }} />
                <Typography
                  className={classes.title}
                  variant="h3"
                  style={{ textAlign: 'center' }}
                >
                  Dashboard Jonas Reservation
                </Typography>
                <TextField
                  className={classes.textField}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Username"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Masuk
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* ALERT FOR ERROR */}
      <Snackbar
        open={alertt}
        onClose={() => setAlert(false)}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={3000}
        message={<span id="message-id">{messageSnackbar}</span>}
      />
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
