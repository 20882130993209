import React, { Component } from 'react';
import { Router, Redirect, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { SignIn as SignInView } from './views';
import 'react-quill/dist/quill.snow.css';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null
    };
  }

  render() {
    const { currentUser } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          {currentUser && <Routes />}

          <Routes>
            <Route exact path="/sign-in"></Route>
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }
}
