import React, { useEffect,useState } from "react";
import MyTable from "../../components/MyTable";
import MySelect from "../../components/Select";
// import axios from "axios";
import axios from "../../util/Service";
import {API} from "../../constants"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { 
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import moment from "moment";  

const drawerWidth = "40%";
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    },
    content: {
      flexShrink: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: "999"
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    buttonAdd: {
      margin: theme.spacing(3)
    },
    loaderBungkus: {
      margin: 10,
      width: 100,
      height: 100,
      background: "rgba(0,0,0,0.4)",
      position: "absolute",
      top: "0",
      borderRadius: "50%",
      zIndex: "99"
    },
    progress: {
      margin: "auto",
      position: "absolute",
      top: "50%",
      left: 0,
      right: 0,
      transform: "translateY(-50%)"
    },
    fileUploadhide: {
      left: "25px",
      float: "left",
      opacity: "0",
      zIndex: "999",
      position: "absolute",
      width: "21%",
      height: "11%",
      marginTop:"20px"
    },
    bigAvatar: {
      width: "100px",
      height: "100px",
      borderRadius: "0",
      backgroundColor: "#f7f7f7",
      marginTop: "25px"
    }
  })
);

const fields = ["Judul Banner", "Redirect URL", "Valid Until", "Jenis Banner"];
const fieldData = ["title", "redirect_url", "valid_until", "type_banner"];

const Banner = () => {
  const classes = useStyles();
  const theme = useTheme();

  //state
  const [data, setData] = useState([]);
  const [form,setForm] = useState({
    id: null,
    title: "",
    is_promo_banner:false,
    redirect_url: "",
    valid_until_date: moment().format("YYYY-MM-DD"),
    valid_until_time:"",
    valid_until_format:"WIB",
    image_url:""
  });
  const [uploadImage, setUploadImage] = useState({
    load_on_upload : 0,
    thumbnail:"",
    image:""
  });
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([])

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getBanners();
    getCities();
  }, []);

  // API
  const getBanners = () => {
    axios({
      method: "GET",
      // url: `${API}/banners`,
      url: "banners",
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200) {
        const newData = response.data.data.map( v => ({
          ...v,
          type_banner: v.is_promo_banner ? "Promo" : "Banner Home"
        }));
        setData(newData);
      }
    }).catch(error => { alert(error); })
  };

  const getCities = () => {
    axios({
      method: "GET",
      url: "admin/cities",
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if(response.status === 200){
        const newData = response.data.data.map( v => ({
          ...v,
          value:v.id,
          label:v.name
        }));
        setCities(newData);
      }
    }).catch(error => { alert(error); })
  };

  async function AddPhoto() {
    if(uploadImage.thumbnail  !== ""){
      const url = `upload`
      let bodyData = new FormData();
          bodyData.append("category", "thumbnail");
          bodyData.append("file", uploadImage.image);
          bodyData.append("type", uploadImage.image["type"]);
      const result = await axios.post(
        url, bodyData
      );
      
      let gambar = result.data.data;
      form.id == null ? AddBanner(gambar) : UpdateBanner(gambar);
    }else{
      form.id == null ? AddBanner(form.image_url) : UpdateBanner(form.image_url);
    }
  };

  async function AddBanner(url_gambar) {

    let valid_until = moment(form.valid_until_date).format("YYYY-MM-DD")+" "+form.valid_until_time+":00 "+form.valid_until_format;
    // let valid_until = ""

    const data_banner = {
      title: form.title,
      image_url:url_gambar,
      is_promo_banner: form.is_promo_banner,
      redirect_url: form.is_promo_banner ? form.redirect_url : "http://reservation.jonasphoto.co.id/",
      valid_until: valid_until
    };

    axios({
      method: "POST",
      url: "banner",
      headers: { "Content-Type": "application/json" },
      data: data_banner
    }).then(response => {

      const dataBanner = response.data.data;
      if(form.is_promo_banner){
        const cities = {
          banner: dataBanner.id,
          data: selectedCities.map( v => ({ city: v.id }))
        }

        axios({
          method: "POST",
          url: "banner/city",
          headers: { "Content-Type": "application/json" },
          data: cities
        }).then(response => { }).catch(error => { alert(error); })
      }

      getBanners();
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  async function UpdateBanner(url_gambar){

    if(form.is_promo_banner){
      const cities = {
        banner:form.id,
        data:selectedCities.map( v => ({ city: v.id }))
      };

      axios({
        method: "PUT",
        url: "banner/city",
        headers: { "Content-Type": "application/json" },
        data: cities
      }).then(response => { }).catch(error => { alert(error); })
    }

    let valid_until = moment(form.valid_until_date).format("YYYY-MM-DD")+" "+form.valid_until_time+" "+form.valid_until_format;

    const data_banner = {
      id: form.id,
      title: form.title,
      image_url:url_gambar,
      is_promo_banner: form.is_promo_banner,
      redirect_url: form.is_promo_banner ? form.redirect_url : "http://reservation.jonasphoto.co.id/",
      redirect_url: form.redirect_url,
      valid_until: valid_until
    };

    axios({
      method: "PUT",
      url: "banner",
      data: data_banner,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      getBanners();
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  const deleteBanner = id => {
    axios({
      method: "DELETE",
      url: "banner",
      headers: { "Content-Type": "application/json" },
      params: { id: id }
    }).then(response => {
      getBanners();
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };
  //

  // handle
  const handleDrawerOpen = () => { setOpen(true) };

  const handleDrawerOpenForEdit = id => { 
    axios({
      method: "GET",
      url: "banner?id="+id,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200) {
        const selectBanner = response.data.data;

        const valid_until = selectBanner.valid_until.split(" ");
  
        setForm({
          ...form,
          id: selectBanner.id,
          title: selectBanner.title,
          is_promo_banner: selectBanner.is_promo_banner,
          redirect_url: selectBanner.redirect_url,
          valid_until_date: valid_until[0],
          valid_until_time: valid_until[1],
          valid_until_format: valid_until[2],
          image_url:selectBanner.image_url
        });
        const selectedCitiess = selectBanner.cities.map( v => ({...v,
          value: v.id,
          label: v.name
        }));
        setSelectedCities(selectedCitiess)
        handleDrawerOpen();

      }else{
        alert("BANNER TIDAK DITEMUKAN");
      }
    }).catch(error => { alert(error); })
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      title: "",
      redirect_url: "",
      valid_until_date: Date(),
      valid_until_time:"",
      valid_until_format:"WIB",
      image_url:"",
      image:""
    });
    setSelectedCities([]);
  };

  const handleChangeForm = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleSelectedCities = cities => {
    setSelectedCities(cities);
  };

  const handleChangeValidUntil = e => {
    setForm({
      ...form,
      valid_until_format: e.target.value
    });
  };

  const onChangeGambar = (value) => {
    let gambar = value.target.files;
    if (gambar.length !== 0) {
      setUploadImage({
        thumbnail: URL.createObjectURL(gambar[0]),
        image: gambar[0]
      });
    }
  };
  //

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">

            <h4>Banner</h4>
            <hr style={{marginBottom:"30px"}}/>
            
            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deleteBanner}
              onEdit={handleDrawerOpenForEdit}
              onUpdate={() => {}}
              titleAdd="Tambah Banner"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage}
              />
          </Card>
        </Grid>
      </Grid>
      
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ 
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {form.id == null ? "Simpan" : "Ubah"} Informasi Banner
          <Button variant="contained" color="primary" style={{position:"absolute",right:"20px",top:"-10px"}} className={classes.buttonAdd} onClick={() => AddPhoto()}>
            {form.id == null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
          <Grid container style={{ padding: theme.spacing(3) }}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Judul Banner"
                value={form.title}
                onChange={handleChangeForm("title")}
                margin="normal"
                fullWidth
                variant="outlined"
              />
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.formControl} style={{margin:"15px 0"}}>
                  <InputLabel id="demo-simple-select-outlined-label">Format</InputLabel>
                  <Select
                    label="demo-simple-select-label"
                    id="satuan"
                    value={form.is_promo_banner}
                    onChange={handleChangeForm("is_promo_banner")}
                    >
                    <MenuItem value={false}>Banner Home</MenuItem>
                    <MenuItem value={true}>Promo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
                {
                form.is_promo_banner
                ?
                <>
                <MySelect
                  isMulti
                  name="Kota"
                  label="Kota"
                  placeholder="Kota"
                  options={cities}
                  value={selectedCities}
                  onChange={handleSelectedCities}
                />
                <TextField
                    id="redirect_url"
                    label="Redirect URL Banner"
                    value={form.redirect_url}
                    onChange={handleChangeForm("redirect_url")}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    style={{marginTop:'20px'}}
                />
                </>
                :
                <></>
                }
                <TextField
                  id="date"
                  label="Valid hingga"
                  format="dd/mm/yyyy"
                  type="date"
                  value={form.valid_until_date}
                  onChange={handleChangeForm("valid_until_date")}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{marginTop:"20px"}}
                />
                
                <TextField
                  id="valid_until"
                  label="Waktu"
                  value={form.valid_until_time}
                  onChange={handleChangeForm("valid_until_time")}
                  margin="normal"
                  variant="outlined"
                  type="time"
                  style={{marginLeft:"15px"}}
                />
                {/* <FormControl variant="outlined" className={classes.formControl} style={{margin:"15px"}}>
                    <InputLabel id="demo-simple-select-outlined-label">Format</InputLabel>
                    <Select
                      label="demo-simple-select-label"
                      id="satuan"
                      value={form.valid_until_format}
                      onChange={handleChangeValidUntil}
                      >
                        <MenuItem value="WIB">WIB</MenuItem>
                        <MenuItem value="WITA">WITA</MenuItem>
                        <MenuItem value="WIT">WIT</MenuItem>
                  </Select>
                </FormControl> */}
                <Grid item xs={6}>
                  <div className={classes.bungkusGambar}>
                    {uploadImage.thumbnail !== "" ?
                      <div>
                        {uploadImage.load_on_upload === 1 ? <div className={classes.loaderBungkus}>
                          <CircularProgress color="secondary" style={{ top: "40%" }} className={classes.progress} />
                        </div> : uploadImage.load_on_upload === 2 ? <div className={classes.loaderBungkus}>
                          <CheckIcon color="primary" fontSize="large" className={classes.progress} />
                        </div> : ""}
                        <Avatar alt="Image" style={{width:"100px"}} src={uploadImage.thumbnail} className={classes.bigAvatar} value={form.image_url} />
                      </div>
                      :
                      <Avatar alt="Image" className={classes.bigAvatar} src={form.image_url}>
                        <InsertPhotoIcon fontSize="large" />
                      </Avatar>
                    }
                  </div>
                  <input className={classes.fileUploadhide + " mt-2"} type="file" onChange={onChangeGambar} />
                  <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:"20px"}}>
                    Ubah Foto
                  </Button>
                </Grid>
            </Grid>
          </Grid>
        <Divider />
      </Drawer> 
    </div>
  );
};

export default Banner;
