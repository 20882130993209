const uploadFileTypes = {
  SET_UPLOAD_FILE: 'SET_UPLOAD_FILE',
  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
  SUCCESS_UPLOAD_FILE: 'SUCCESS_UPLOAD_FILE',
  FAILURE_UPLOAD_FILE: 'FAILURE_UPLOAD_FILE',
  DISPATCH_IMAGE_URL: 'DISPATCH_IMAGE_URL',
  DONE_UPLOAD: 'DONE_UPLOAD',
  RESET_IMAGE: 'RESET_IMAGE'
}

export default uploadFileTypes