import React, { useEffect, useState } from 'react';
import MyTable from '../../components/MyTable';
import axios from '../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Drawer,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Widget from '../../components/Widget';

import { Settings, EmailOutlined } from '@material-ui/icons';

import EmailSettingsView from './Email';

const drawerWidth = '50%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  buttonAdd: {
    margin: theme.spacing(3)
  }
}));

const fields = ['Label', 'Deskripsi', 'Parameter', 'Value'];
const fieldData = ['label', 'description', 'param', 'value'];

const Setting = () => {
  const classes = useStyles();
  const theme = useTheme();

  // product
  const [data, setData] = useState([]);
  const [dataForm, setDataForm] = useState([]);
  const [form, setForm] = useState({
    id: null,
    label: '',
    description: '',
    param: 'INQ_DURATION',
    value: 0
  });

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const [selectedMenu, setSelectedMenu] = useState(0);

  useEffect(() => {
    getSettings();
  }, []);

  // API

  const getSettings = () => {
    axios({
      method: 'GET',
      url: `settings`,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          setDataForm(response.data.data);

          const newData = response.data.data.map(v => ({
            ...v,
            value:
              v.param === 'VOUCHER_UNTIL_DURATION'
                ? v.value + ' hari'
                : v.param === 'DEFAULT_FREE_PRICE_PER_CHAR'
                  ? v.value + ' rupiah'
                  : v.param === 'PREFIX_VOUCHER' ||
                    v.param === 'POSTFIX_VOUCHER' ||
                    v.param === 'TOGGLE_BCA' ||
                    v.param === 'WHATSAPP_NUMBER' ||
                    v.param === 'WHATSAPP_PRODUCT_MESSAGE_TEMPLATE' ||
                    v.param === 'DEFAULT_FREE_TEXT_LIMIT' ||
                    v.param === 'MESSAGE_STUDIO_LIMIT'
                    ? v.value
                    : v.value + ' menit'
          }));
          setData(newData);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const AddSetting = () => {
    let data_setting = {
      label: form.label,
      description: form.description,
      param: form.param,
      value: form.value
    };

    axios({
      method: 'POST',
      url: `setting`,
      headers: { 'Content-Type': 'application/json' },
      data: data_setting
    })
      .then(response => {
        getSettings();
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const UpdateSetting = () => {
    axios({
      method: 'PUT',
      url: `setting`,
      data: form,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        getSettings();
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const deleteSetting = id => {
    axios({
      method: 'DELETE',
      url: `setting`,
      headers: { 'Content-Type': 'application/json' },
      params: { id: id }
    })
      .then(response => {
        getSettings();
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  };
  //

  // handle
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenForEdit = id => {
    const selectSetting = dataForm.find(v => v.id === id);

    setForm({
      ...form,
      id: selectSetting.id,
      label: selectSetting.label,
      description: selectSetting.description,
      param: selectSetting.param,
      value: selectSetting.value
    });
    handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      label: '',
      description: '',
      param: 0,
      value: 0
    });
  };

  const handleChangeForm = name => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleClickMenu = index => setSelectedMenu(index);

  //
  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid
          item
          sm={6}
          xs={12}
          style={{
            color: selectedMenu === 0 ? '#3c3835' : '#bcbcbc',
            cursor: 'pointer'
          }}
          onClick={e => handleClickMenu(0)}>
          <Widget styleName="p-3 py-4">
            <div className="media align-items-center flex-nowrap my-auto mx-auto">
              <div className="mr-lg-2 mr-1">{<Settings />}</div>
              <div className="media-body">
                <h2 className="jr-font-weight-bold mb-0">{`General`}</h2>
              </div>
            </div>
          </Widget>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          style={{
            color: selectedMenu === 1 ? '#3c3835' : '#bcbcbc',
            cursor: 'pointer'
          }}
          onClick={e => handleClickMenu(1)}>
          <Widget styleName="p-3 py-4">
            <div className="media align-items-center flex-nowrap my-auto mx-auto">
              <div className="mr-lg-2 mr-1">{<EmailOutlined />}</div>
              <div className="media-body">
                <h2 className="jr-font-weight-bold mb-0">{`Email`}</h2>
              </div>
            </div>
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {selectedMenu == 0 ? (
          <Grid item md={12} xs={12}>
            <Card className={classes.root} variant="outlined">
              <h4>Settings</h4>
              <hr style={{ marginBottom: '30px' }} />

              <MyTable
                id="id"
                data={data}
                fields={fields}
                fieldData={fieldData}
                onAdd={handleDrawerOpen}
                onDelete={deleteSetting}
                onEdit={handleDrawerOpenForEdit}
                onUpdate={() => { }}
                isAdd={false}
                page={page}
                onNextPageClicked={handleOnNextPage}
                onBackPageClicked={handleOnBackPage}
              />
            </Card>
          </Grid>
        ) : (
          <EmailSettingsView />
        )}
      </Grid>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={() => {
              handleDrawerClose();
            }}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          {form.id == null ? 'Simpan' : 'Ubah'} Setting
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', right: '20px', top: '-10px' }}
            className={classes.buttonAdd}
            onClick={
              form.id === null ? () => AddSetting() : () => UpdateSetting()
            }>
            {form.id == null ? 'Simpan' : 'Ubah'}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <TextField
            id="label"
            label="Label Setting"
            value={form.label}
            onChange={handleChangeForm('label')}
            margin="normal"
            fullWidth
            variant="outlined"
          />
          <TextField
            id="description"
            label="Deskripsi Setting"
            value={form.description}
            onChange={handleChangeForm('description')}
            margin="normal"
            fullWidth
            multiline
            rows="4"
            variant="outlined"
          />
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ margin: '15px 15px 0 0' }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Format
            </InputLabel>
            <Select
              label="demo-simple-select-label"
              id="satuan"
              value={form.param}
              onChange={handleChangeForm('param')}>
              <MenuItem value="INQ_DURATION">INQ_DURATION</MenuItem>
              <MenuItem value="POS_DURATION">POS_DURATION</MenuItem>
              <MenuItem value="POS_DURATION_BCA_MANUAL">POS_DURATION_BCA_MANUAL</MenuItem>
              <MenuItem value="PREFIX_VOUCHER">PREFIX_VOUCHER</MenuItem>
              <MenuItem value="POSTFIX_VOUCHER">POSTFIX_VOUCHER</MenuItem>
              <MenuItem value="WHATSAPP_NUMBER">WHATSAPP_NUMBER</MenuItem>
              <MenuItem value="WHATSAPP_PRODUCT_MESSAGE_TEMPLATE">WHATSAPP_PRODUCT_MESSAGE_TEMPLATE</MenuItem>
              <MenuItem value="VOUCHER_UNTIL_DURATION">VOUCHER_UNTIL_DURATION</MenuItem>
              <MenuItem value="TOGGLE_BCA">TOGGLE_BCA</MenuItem>
              <MenuItem value="CONTENT_WHATS_DIFFERENCE">CONTENT_WHATS_DIFFERENCE</MenuItem>
              <MenuItem value="DEFAULT_FREE_TEXT_LIMIT">DEFAULT_FREE_TEXT_LIMIT</MenuItem>
              <MenuItem value="DEFAULT_FREE_PRICE_PER_CHAR">DEFAULT_FREE_PRICE_PER_CHAR</MenuItem>
              <MenuItem value="MESSAGE_STUDIO_LIMIT">MESSAGE_STUDIO_LIMIT</MenuItem>
            </Select>
          </FormControl>
          {form.param == "TOGGLE_BCA" ?
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ margin: '15px 15px 0 0' }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Format
              </InputLabel>
              <Select
                label="demo-simple-select-label"
                id="label"
                value={form.value}
                onChange={handleChangeForm('value')}
              >
                <MenuItem value="1">Buka</MenuItem>
                <MenuItem value="0">Tutup</MenuItem>
              </Select>
            </FormControl>
            :
            <TextField
              id="label"
              label="Value"
              value={form.value}
              onChange={handleChangeForm('value')}
              margin="normal"
              variant="outlined"
            />
          }
        </Grid>
        <Divider />
      </Drawer>
    </div>
  );
};

export default Setting;
