import { Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, FormControlLabel, Grid, IconButton, InputLabel, Slide, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import MyTable from "../../components/MyTable";
import { connect } from "react-redux";
import { useEffect } from 'react';
import { useState } from 'react';
import { API } from "../../constants";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Select from '../../components/Select';
import moment from 'moment';
import { blue, red } from '@material-ui/core/colors';

const drawerWidth = '80%';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    typography: {
        fontFamily: [
            'Roboto',
        ].join(','),
    },
    content: {
        flexShrink: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: '999',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    buttonAdd: {
        margin: theme.spacing(3),
    },
    //upload image
    fileUploadThumbnail: {
        left: '25px',
    },
    fileUploadhide: {
        float: 'left',
        opacity: '0',
        zIndex: '999',
        position: 'absolute',
        width: '21%',
        height: '11%',
        marginTop: '20px',
    },
    bigAvatar: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    bigAvatarBanner: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    disableClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    enableClick: {
        pointerEvents: 'auto',
        cursor: 'default',
    }
}));

const fields = ['Nama Grup'];
const fieldData = ['name'];
const daysData = ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"];
const statusData = [
    { label: "Aktif", value: 1 },
    { label: "Tidak Aktif", value: 0 }
];

const Quotation = props => {
    const classes = useStyles();
    const theme = useTheme();

    const getPackageQuota = async () => {
        const api = await fetch(`${API}/quotation/quotations`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setData(res.data);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getDetailQuotation = async (id) => {
        const api = await fetch(`${API}/quotation/quotation?id=${id}`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setSelectedPackages(dataPackages.filter(f => res.data.packages.includes(f.value)))
            setForm({
                id: res.data.id,
                name: res.data.name,
                packageIds: res.data.packages,
            });
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getDetailStudios = async (id) => {
        const api = await fetch(`${API}/quotation/studio?quotation=${id}`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setDataQuotationStudios(res.data);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getPackages = async () => {
        const api = await fetch(`${API}/packages?page=1&limit=1000000`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setDataPackages(res.data.map((v) => ({ label: v.title, value: v.id, time: v.interval_time })));
            setSelectedPackages([]);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getStudios = async () => {
        const api = await fetch(`${API}/timebox/studios`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            function compare(a, b) {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            }

            setDataStudios(res.data.filter(f => f.data_store.publish).map((v) => ({ label: `${v.data_store.name} - ${v.name}`, value: v.id })).sort(compare));
            setSelectedStudios([]);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const sessionExpired = () => {
        handleShowSnackbar('Sesi anda sudah berakhir. Harap login kembali');
        localStorage.clear();
        window.location.href = "/sign-in";
    };

    const addQuotation = async (form) => {
        const api = await fetch(`${API}/quotation/quotation`, {
            body: JSON.stringify(form),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            console.log(res.data.id)
            handleCloseDialog(res.data.id);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const updateQuotation = async (form) => {
        const api = await fetch(`${API}/quotation/quotation`, {
            body: JSON.stringify(form),
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleCloseDialog(form.id);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const addQuotationStudio = async (body) => {
        const api = await fetch(`${API}/quotation/studio`, {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleCloseDialog(form.id);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const updateQuotationStudio = async (body) => {
        const api = await fetch(`${API}/quotation/studio`, {
            body: JSON.stringify(body),
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleCloseDialog(form.id);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const deleteQuotation = async (id) => {
        const body = {
            id: id
        };
        const api = await fetch(`${API}/quotation/quotation`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!api.ok) {
            handleShowSnackbar(true, api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleShowSnackbar(true, 'Quotation berhasil di hapus');
            getPackageQuota();
        } else {
            handleShowSnackbar(true, res.message);
        }
    }

    const deleteQuotationStudio = async (body) => {
        const api = await fetch(`${API}/quotation/studio`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!api.ok) {
            handleShowSnackbar(true, api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleCloseDialog(form.id)
        } else {
            handleShowSnackbar(true, res.message);
        }
    }

    useEffect(() => {
        const currUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currUser) {
            const { token } = currUser;
            if (token) {
                setAccountData(token);
                getPackageQuota();
                getStudios();
                getPackages();
            } else {
                sessionExpired();
            }
        } else {
            sessionExpired();
        }
    }, []);

    const [accountData, setAccountData] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [data, setData] = useState([]);
    const [disableDuringFetch, setDisableDuringFetch] = useState(false);
    const [open, setOpen] = useState(false);
    const [dataQuotationStudios, setDataQuotationStudios] = useState([]);
    const [detailStudios, setDetailStudios] = useState(null);
    const [dataStudios, setDataStudios] = useState([]);
    const [selectedStudios, setSelectedStudios] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [dataPackages, setDataPackages] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState(null);
    const [modalQuotation, setModalQuotation] = useState(null);
    const [alertDelete, setAlertDelete] = useState(null);
    const [form, setForm] = useState({
        id: null,
        name: null,
        packageIds: null,
    })
    const [formStudio, setFormStudio] = useState({
        id: null,
        quotation: null,
        studio: null,
        quota: null,
        type: null,
        days: [],
    })

    const handleDrawerOpen = () => {
        setForm({
            id: null,
            name: null,
            packageIds: null,
        });
        setSelectedStudios(null);
        setDetailStudios(null);
        setOpen(true);
    }

    const handleDrawerOpenForEdit = (id) => {
        getDetailQuotation(id);
        getDetailStudios(id);
        setSelectedStudios(null);
        setAlertDelete(null);
        setOpen(true);
    }

    const handleShowSnackbar = message => {
        setShowSnackbar(true);
        setMessageSnackbar(message);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setSelectedPackages(null);
        setSelectedStudios(null);
        setModalQuotation(null);
        setAlertDelete(null);
        getPackageQuota();
    }

    const handleCloseDialog = (id = null) => {
        setModalQuotation(null);
        setSelectedStatus(null);
        setSelectedStudios(null);
        setFormStudio({
            id: null,
            quotation: null,
            studio: null,
            quota: null,
            type: null,
            days: [],
        });
        if (id != null) {
            handleDrawerOpenForEdit(id);
        }
    }

    const handleSubmit = () => {
        const body = {
            name: form.name,
            packages: selectedPackages.map(it => it.value)
        }
        if (form.id == null) {
            addQuotation(body);
        } else {
            updateQuotation({
                ...body,
                id: form.id,
            })
        }
    }

    const handleSubmitStudio = () => {
        const body = {
            quotation: form.id,
            studio: selectedStudios.value,
            quota: Number(formStudio.quota),
            type: formStudio.quota == -1 ? "unlimited" : "limited",
            days: formStudio.days.join(),
            status: selectedStatus.value == 1
        }
        if (formStudio.id == null) {
            addQuotationStudio(body);
        } else {
            updateQuotationStudio({
                ...body,
                id: formStudio.id,
            })
        }
    }


    const handleDeleteQuotation = async id => {
        deleteQuotation(id);
    };


    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Card className={classes.root} variant="outlined">

                        <h4>Package Quota</h4>
                        <hr style={{ marginBottom: '30px' }} />

                        <MyTable
                            id="id"
                            data={data}
                            fields={fields}
                            fieldData={fieldData}
                            onAdd={handleDrawerOpen}
                            onEdit={handleDrawerOpenForEdit}
                            onDelete={handleDeleteQuotation}
                            onUpdate={() => { }}
                            titleAdd="Tambah Kuota" />
                    </Card>
                </Grid>
            </Grid>

            <Drawer
                className={`${classes.drawer} ${disableDuringFetch ? classes.disableClick : classes.enableClick}`}
                anchor="right"
                open={open}
                classes={{ paper: classes.drawerPaper, }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => {
                        handleDrawerClose()
                    }}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Typography variant="h5">{form.id == null ? "Simpan" : "Ubah"} Informasi Kuota</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ position: 'absolute', right: '20px', top: '-10px' }}
                        className={classes.buttonAdd} onClick={() => handleSubmit()}>
                        {form.id == null ? "Simpan" : "Ubah"}
                    </Button>
                </div>
                <Divider />
                <Grid container style={{ padding: theme.spacing(3) }}>
                    <Grid item xs={12}>
                        <Select
                            name="package"
                            placeholder="Pilih Package"
                            options={dataPackages}
                            value={selectedPackages}
                            onChange={e => { setSelectedPackages(e) }}
                            fullWidth
                            isMulti
                        />
                        <br />
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label="Nama Grup"
                            value={form.name || ""}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    name: e.target.value,
                                })
                            }}
                            margin="normal"
                            variant="outlined"
                        />
                        {
                            form.id != null && <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    float: 'right',
                                }}
                                size='small'
                                className={classes.buttonAdd} onClick={() => {
                                    setModalQuotation({});
                                }}>
                                Tambah Kuota Studio
                            </Button>
                        }
                        {
                            form.id != null && <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Store</TableCell>
                                        <TableCell>Studio</TableCell>
                                        <TableCell>Kuota</TableCell>
                                        <TableCell>Tipe</TableCell>
                                        <TableCell>Days</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataQuotationStudios.map(item => {
                                            return (
                                                <TableRow key={item.id}>
                                                    <TableCell>{item.studio_data.data_store.name}</TableCell>
                                                    <TableCell>{item.studio_data.name}</TableCell>
                                                    <TableCell>{item.quota}</TableCell>
                                                    <TableCell>{item.type}</TableCell>
                                                    <TableCell>{daysData.filter((v, i) => item.days.split(",").map(item => Number(item)).includes(i)).join()}</TableCell>
                                                    <TableCell>{item.status == 1 ? "Aktif" : "Tidak Aktif"}</TableCell>
                                                    <TableCell>
                                                        <a onClick={() => {
                                                            setModalQuotation(form);
                                                            setSelectedStudios(dataStudios.find(f => f.value == item.studio))
                                                            setSelectedStatus(statusData.find(f => f.value == item.status))
                                                            setFormStudio({
                                                                id: item.id,
                                                                quota: item.quota,
                                                                quotation: form.id,
                                                                studio: item.studio,
                                                                type: item.type,
                                                                days: item.days.split(",").map(item => Number(item)),
                                                            })
                                                        }}>
                                                            <EditIcon style={{ color: blue[500] }} className="pointer mr-2" />
                                                        </a>
                                                        <a onClick={() => {
                                                            setAlertDelete({ id: item.id });
                                                        }}>
                                                            <DeleteIcon style={{ color: red[500] }} className="pointer mr-2" />
                                                        </a>
                                                    </TableCell>
                                                </TableRow >
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        }
                    </Grid>
                </Grid>
            </Drawer>

            <Dialog open={modalQuotation} onClose={() => handleCloseDialog(form.id)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Quotation</DialogTitle>
                <DialogContent>
                    <Grid container style={{ width: '530px' }}>
                        <Grid item xs={12} style={{ marginRight: '20px' }}>
                            <Select
                                name="modal_studio"
                                placeholder="Pilih Studio"
                                options={dataStudios}
                                value={selectedStudios}
                                onChange={e => { setSelectedStudios(e) }}
                                fullWidth
                            />
                            <TextField
                                id="quota"
                                name="quota"
                                label="Kuota"
                                style={{ width: '100%' }}
                                value={formStudio.quota}
                                onChange={(e) => {
                                    setFormStudio({
                                        ...formStudio,
                                        quota: e.target.value,
                                    })
                                }}
                                margin="normal"
                                variant="outlined"
                            />
                            <Select
                                name="modal_aktif"
                                placeholder="Status Kuota"
                                options={statusData}
                                value={selectedStatus}
                                onChange={e => { setSelectedStatus(e) }}
                                fullWidth
                            />
                            <Grid container>
                                {
                                    daysData.map((item, index) => {
                                        return <Grid>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formStudio.days.includes(index)}
                                                        color="primary"
                                                        name="policy"
                                                        onChange={(e) => {
                                                            const newData = (e.target.checked)
                                                                ? [...formStudio.days, index]
                                                                : formStudio.days.filter(v => v != index)
                                                            console.log(newData);
                                                            setFormStudio({
                                                                ...formStudio,
                                                                days: newData,
                                                            });
                                                        }}
                                                    />
                                                }
                                                label={item}
                                            />
                                        </Grid>
                                    })
                                }
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    float: 'right',
                                }}
                                size='small'
                                className={classes.buttonAdd} onClick={handleSubmitStudio}>
                                {formStudio.id != null ? "Ubah" : "Tambah"} Kuota Studio
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog open={alertDelete != null} onClose={() => { setAlertDelete(null) }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
                <DialogContent>
                    Anda yakin ingin menghapus data ini?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAlertDelete(null) }} color="primary">
                        Tutup
                    </Button>
                    <Button onClick={() => {
                        deleteQuotationStudio(alertDelete);
                    }} color="primary" variant="contained">
                        Hapus
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                TransitionComponent={Slide}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                autoHideDuration={3000}
                message={<span id="message-id">{messageSnackbar}</span>}
            />
        </div >
    )
}

export default connect()(Quotation);
