import React from 'react'

const Index = props => {
  const { file, progress } = props.file

  return (
    <div>
      {
        file ?
          <div className="wrapper-item">
            <div className="left-side">
              <div className="progress-bar">
                <div style={{ width: `${progress}%` }} />
              </div>
              <label>{file.name}</label>
            </div>
            <span className="percentage">{`${progress}%`}</span>
          </div> :
          null
      }
      <style jsx>
        {
          `
            .wrapper-item {
              display: grid;
              grid-template-columns: calc(100% - 50px) 50px;
              padding: 16px;
              box-shadow: 0px 1px 2px lightgrey;
            }
            .left-side {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-right: 8px;
            }
            .progress-bar {
              width: 100%;
              height: 15px;
              background-color: lightgray;
              margin-bottom: 8px;
              border-radius: 20px;
            }
            .progress-bar > div {
              height: 15px;
              background-color: lightgreen;
              border-radius: 20px;
            }
            .left-side > label {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
            }
            .percentage {
              margin-left: 12px;
            }
          `
        }
      </style>
    </div>
  )
}

export default Index