import React, { useEffect, useState } from 'react';
import MyTable from "../../components/MyTable";
import axios from '../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const drawerWidth = '40%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: '999',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  buttonAdd: {
    margin: theme.spacing(3)
  },
  //upload image
  loaderBungkus: {
    margin: 10,
    width: 100,
    height: 100,
    background: 'rgba(0,0,0,0.4)',
    position: 'absolute',
    top: '0',
    borderRadius: '50%',
    zIndex: '99',
  },
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
  },
  fileUploadhide: {
    left: '25px',
    float: 'left',
    opacity: '0',
    zIndex: '999',
    position: 'absolute',
    width: '21%',
    height: '11%',
    marginTop: '20px',
  },
  bigAvatar: {
    width: '100px',
    height: '100px',
    borderRadius: '0',
    backgroundColor: '#f7f7f7',
    marginTop: '25px',
  }
})
);

const fields = ['Nama Kota', 'Deskripsi'];
const fieldData = ['name', 'description'];

const Cities = () => {
  const classes = useStyles();
  const theme = useTheme();

  //state
  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    id: null,
    name: '',
    description: '',
    thumbnail_image_url: '',
  })
  const [uploadImage, setUploadImage] = useState({
    load_on_upload: 0,
    thumbnail: '',
    image: '',
  })

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getCities();
  }, []);

  // API
  const getCities = () => {
    axios({
      method: 'GET',
      url: `admin/cities`,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200) {
        setData(response.data.data);
      }
    }).catch(error => {
      alert(error);
    })
  };

  async function AddPhoto() {
    if (uploadImage.thumbnail !== '') {
      const url = `upload`
      let bodyData = new FormData();
      bodyData.append("category", "thumbnail");
      bodyData.append("file", uploadImage.image);
      bodyData.append("type", uploadImage.image['type']);
      const result = await axios.post(
        url, bodyData
      );

      let gambar = result.data.data;
      form.id === null ? AddCity(gambar) : UpdateCity(gambar);
    } else {
      form.id === null ? AddCity(form.thumbnail_image_url) : UpdateCity(form.thumbnail_image_url);
    }
  };

  async function AddCity(url_gambar) {

    const data_city = {
      name: form.name,
      description: form.description,
      thumbnail_image_url: url_gambar,
    };

    axios({
      method: 'POST',
      url: `city`,
      headers: { "Content-Type": "application/json" },
      data: data_city
    }).then(() => {
      getCities()
      handleDrawerClose()
    }).catch(error => {
      alert(error);
    })
  };

  async function UpdateCity(url_gambar) {

    const data_city = {
      id: form.id,
      name: form.name,
      description: form.description,
      thumbnail_image_url: url_gambar,
    };

    axios({
      method: 'PUT',
      url: `city`,
      data: data_city,
      headers: { "Content-Type": "application/json" },
    }).then(() => {
      getCities();
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  const deleteCity = id => {
    axios({
      method: 'DELETE',
      url: `city`,
      headers: { "Content-Type": "application/json" },
      params: { id: id }
    }).then(() => {
      getCities();
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };
  //

  // handle
  const handleDrawerOpen = () => { setOpen(true); };

  const handleDrawerOpenForEdit = id => {
    const selectCity = data.find(v => v.id === id)
    setForm({
      ...form,
      id: selectCity.id,
      name: selectCity.name,
      description: selectCity.description,
      thumbnail_image_url: selectCity.thumbnail_image_url
    })
    handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      name: '',
      description: '',
      thumbnail_image_url: '',
      image: ''
    });
  };

  const handleChangeForm = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const onChangeGambar = (value) => {
    let gambar = value.target.files;
    if (gambar.length !== 0) {
      setUploadImage({
        thumbnail: URL.createObjectURL(gambar[0]),
        image: gambar[0]
      });
    }
  };
  //

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">

            <h4>Kota</h4>
            <hr style={{ marginBottom: '30px' }} />

            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onEdit={handleDrawerOpenForEdit}
              onUpdate={() => {}}
              titleAdd="Tambah Kota"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage} />
          </Card>
        </Grid>
      </Grid>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {form.id == null ? "Simpan" : "Ubah"} Informasi Kota
          <Button variant="contained" color="primary" style={{ position: 'absolute', right: '20px', top: '-10px' }} className={classes.buttonAdd} onClick={() => AddPhoto()}>
            {form.id == null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Nama Kota"
              value={form.name}
              onChange={handleChangeForm('name')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="description"
              label="Deskripsi Kota"
              value={form.description}
              onChange={handleChangeForm('description')}
              margin="normal"
              fullWidth
              multiline
              rows='4'
              variant="outlined"
            />
            <Grid item xs={6}>
              <div className={classes.bungkusGambar}>
                {uploadImage.thumbnail !== '' ?
                  <div>
                    {uploadImage.load_on_upload === 1 ? <div className={classes.loaderBungkus}>
                      <CircularProgress color='secondary' style={{ top: '40%' }} className={classes.progress} />
                    </div> : uploadImage.load_on_upload === 2 ? <div className={classes.loaderBungkus}>
                      <CheckIcon color='primary' fontSize='large' className={classes.progress} />
                    </div> : ''}
                    < Avatar alt="Image" style={{ width: '100px' }} src={uploadImage.thumbnail} className={classes.bigAvatar} value={form.thumbnail_image_url} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatar} src={form.thumbnail_image_url}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
                }
              </div>
              <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChangeGambar} />
              <Button variant="contained" color="secondary" className={classes.button} style={{ marginTop: '20px' }}>
                Ubah Foto
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </Drawer>
    </div>
  );
};

export default Cities;
