import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// import { authenticationService } from '../../services';

const RouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (currentUser == null) {
      // if (currentUser != null) {
      // not logged in so redirect to login page with the return url
      return (<Redirect exact from="/" to={{ pathname: '/sign-in', state: { from: props.location } }} />)
    }

    // authorised so return component
    return (<Layout>
      <Component {...props} />
    </Layout>)

  }} />
)








// const RouteWithLayout = props => {
//   const { layout: Layout, component: Component, ...rest } = props;

// //   <Route {...rest} render={props => {
// //     const currentUser = authenticationService.currentUserValue;
// //     if (!currentUser) {
// //         // not logged in so redirect to login page with the return url
// //         return <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />;
// //     }

// //     // authorised so return component
// //     return <Layout><Component {...props} /></Layout>;
// // }} />
//   const currentUser = authenticationService.currentUserValue;
//   if (!currentUser) {
//       // not logged in so redirect to login page with the return url
//       // return <Redirect to={{ pathname: '/sign-in', state:{ from: props.location} }} />
//       // return <Route exact path='/sign-in' />

//             // not logged in so redirect to login page with the return url
//     return <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
//   }
//     return (
//       <Route 
//       {...rest} 
//       render={props => (
//         <Layout>
//           <Component {...props} />
//         </Layout>

//         // authorised so return component
//         // <Component {...props} />
//     )} />
//     // <Route
//     //   {...rest}
//     //   render={matchProps => (
//     //     <Layout>
//     //       <Component {...matchProps} />
//     //     </Layout>
//     //   )}
//     // />
//   );

// };

// RouteWithLayout.propTypes = {
//   component: PropTypes.any.isRequired,
//   layout: PropTypes.any.isRequired,
//   path: PropTypes.string
// };

export default RouteWithLayout;