import React, { useEffect,useState } from 'react';
import { NavLink } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import axios from '../../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  Grid, 
  Card, 
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Select from '../../../components/Select';

const drawerWidth = '40%';
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    },
    content: {
      flexShrink: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: '999'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    buttonAdd: {
      margin: theme.spacing(3)
    },
    //upload image
    loaderBungkus: {
      margin: 10,
      width: 100,
      height: 100,
      background: 'rgba(0,0,0,0.4)',
      position: 'absolute',
      top: '0',
      borderRadius: '50%',
      zIndex: '99'
    },
    progress: {
      margin: 'auto',
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      transform: 'translateY(-50%)'
    },
    fileUploadhide: {
      left: '25px',
      float: 'left',
      opacity: '0',
      zIndex: '999',
      position: 'absolute',
      width: '21%',
      height: '11%',
      marginTop:'20px'
    },
    bigAvatar: {
      width: '100px',
      height: '100px',
      borderRadius: '0',
      backgroundColor: '#f7f7f7',
      marginTop: '25px'
    }

  })
);

const fields = ['Label'];
const fieldData = ['label'];

const PhotoGallery = () => {
  const classes = useStyles();
  const theme = useTheme();

  // Role
  const currUser = JSON.parse(localStorage.getItem('currentUser'));
  const [accountData, setAccountData] = useState(null);

  //state
  const [data, setData] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  // form
  const [selectedStoreForm, setSelectedStoreForm] = useState(null);
  const [form,setForm] = useState({
    id: null,
    label: '',
    image_url:''
  })
  const [uploadImage, setUploadImage] = useState({
    load_on_upload : 0,
    thumbnail:'',
    image:''
  })

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {

    if (currUser) {
      const { token } = currUser;
      if (token) {
        setAccountData(token);
      }
    }
  }, []);

  useEffect(() => {
    if(accountData){
      if(accountData.role === 2){
        getStore(accountData.store);
      }else{
        getStores();
      }
    }
  },[accountData]);

  // API
  // ROLE = 0 || ROLE = 1
  const getStores = () => {
    axios({
      method: 'GET',
      url: `stores`,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if(response.status === 200 && response.data.data != null){ parsingStore(response.data.data) }
      else{ parsingStore([]) }
    }).catch(error => { alert(error); })
  };

  // ROLE = 2
  const getStore = (store) => {
    axios({
      method: 'GET',
      url: `store?id=`+store,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if(response.status === 200 && response.data.data != null){ 
        setSelectedStore(response.data.data);
        setSelectedStoreForm(response.data.data);
        getPhotoGallery(response.data.data);
      }
      else{ setSelectedStore([]) }
    }).catch(error => { alert(error); })
  };

  const getPhotoGallery = (store) => {
    axios({
      method: 'GET',
      url: `store/photo_galleries?store=`+store.id,
      headers: { "Content-Type": "application/json" },
    }).then(response => { if(response.status === 200&& response.data.data != null){ setData(response.data.data) }
    }).catch(error => { alert(error); })
  };

  async function AddPhoto(){
    if(uploadImage.thumbnail  !== ''){
      const url = `upload`
      let bodyData = new FormData();
          bodyData.append("category", "thumbnail");
          bodyData.append("file", uploadImage.image);
          bodyData.append("type", uploadImage.image['type']);
      const result = await axios.post(
        url, bodyData
      );
      
      let gambar = result.data.data;
      form.id === null ? AddPhotoGallery(gambar) : UpdatePhotoGallery(gambar);
    }else{
      form.id === null ? AddPhotoGallery(form.image_url) : UpdatePhotoGallery(form.image_url);
    }
  };

  async function AddPhotoGallery(url_gambar){

    let data_photo_gallery = {
      store: selectedStoreForm.id,
      photo_galleries: [
        {
          label: form.label,
          image_url: url_gambar
        }
      ]
    };

    axios({
      method: 'POST',
      url: `store/photo_gallery`,
      headers: { "Content-Type": "application/json" },
      data: data_photo_gallery
    }).then(response => {
      getPhotoGallery(selectedStore)
      handleDrawerClose()
    }).catch(error => { alert(error); })
  };

  async function UpdatePhotoGallery (url_gambar) {
    
    let data_photo_gallery = {
      store: selectedStoreForm.id,
      photo_galleries: [
        {
          id:form.id,
          label: form.label,
          image_url: url_gambar
        }
      ]
    };

    axios({
      method: 'PUT',
      url: `store/photo_gallery`,
      data: data_photo_gallery,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      getPhotoGallery(selectedStore);
      handleDrawerClose();
    }).catch(error => { alert(error); })
  };

  const deletePhotoGallery = id => {
    axios({
      method: 'DELETE',
      url: `store/photo_gallery`,
      headers: { "Content-Type": "application/json" },
      params: { id: id }
    }).then(response => {
      getPhotoGallery(selectedStore);
      handleDrawerClose();
    }).catch(error => { alert(error); })
  };
  //

  // parsing
  const parsingStore = data => {
    const newData = data.map(v => {
      return{
        ...v,
        label: v.name,
        value: v.id
      }
    })
     
    setDataStore(newData);
    setSelectedStore(newData[0]);
    setSelectedStoreForm(newData[0]);
    
    getPhotoGallery(newData[0]);
  };
  // 

  // handle
  const handleDrawerOpen = () => { setOpen(true) };

  const handleDrawerOpenForEdit = id => { 
    
    const selectPhotoGallery = data.find(v => v.id === id)
    
    setForm({
        ...form,
        id: selectPhotoGallery.id,
        label: selectPhotoGallery.label,
        image_url:selectPhotoGallery.image_url
    })

    setSelectedStoreForm(dataStore.find(store => store.id === selectedStore.id))
    handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      label: '',
      image_url:''
    })
  };

  const handleChangeForm = (name) => e => {
    setForm({
        ...form,
        [name]: e.target.value
    })
  };

  const handleSelectedStore = store => {
    setSelectedStore(store);
    getPhotoGallery(store);
  };

  const handleSelectedStoreForm = store => { setSelectedStoreForm(store); };

  const onChangeGambar = (value) => {
    let gambar = value.target.files;
    if (gambar.length !== 0) {
      setUploadImage({
        thumbnail: URL.createObjectURL(gambar[0]),
        image: gambar[0]
      })
    };
  };
  //

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  const renderFilter = () => {
    if (accountData) {
      if (accountData.role === 2) {
        return( <h5>Store : <b>{selectedStore && selectedStore.name}</b></h5> )
      } else {
        return(
          <Grid item xs={4} style={{marginTop:'15px',marginLeft:'-10px'}}>
            <Select
              name="Store"
              placeholder="Filter Store"
              options={dataStore}
              value={selectedStore}
              onChange={handleSelectedStore}
            />
          </Grid>
        )
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <NavLink to='/store' style={{color:'#3c3835',marginLeft:'-10px'}}>
              <ArrowBackIcon />
            </NavLink>

            <h4>Photo Gallery Store</h4>
            <hr style={{marginBottom:'30px'}}/>

            { renderFilter() }

            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deletePhotoGallery}
              onEdit={handleDrawerOpenForEdit}
              onUpdate={() => {}}
              titleAdd="Tambah Photo Gallery"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage}/>
          </Card>
        </Grid>
      </Grid>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ 
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {form.id == null ? "Simpan" : "Ubah"} Informasi Photo Gallery
          <Button variant="contained" color="primary" style={{position:'absolute',right:'20px',top:'-10px'}} className={classes.buttonAdd} onClick={() => AddPhoto()}>
            {form.id == null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            { accountData && (accountData.role === 0 || accountData.role === 1)
            ?
            <Select
              name="Store"
              placeholder="Pilih Store"
              options={dataStore}
              value={selectedStoreForm}
              onChange={handleSelectedStoreForm}
              fullWidth
            />
            :
            <h5>Store : {selectedStore && selectedStore.name}</h5>}
            <TextField
              id="label"
              label="Label Photo Gallery"
              value={form.label}
              onChange={handleChangeForm('label')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <Grid item xs={6}>
              <div>
                {uploadImage.thumbnail !== '' ?
                  <div>
                    {uploadImage.load_on_upload === 1 ? <div className={classes.loaderBungkus}>
                      <CircularProgress color='secondary' style={{ top: '40%' }} className={classes.progress} />
                    </div> : uploadImage.load_on_upload === 2 ? <div className={classes.loaderBungkus}>
                      <CheckIcon color='primary' fontSize='large' className={classes.progress} />
                    </div> : ''}
                    < Avatar alt="Image" style={{width:'100px'}} src={uploadImage.thumbnail} className={classes.bigAvatar} value={form.image_url} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatar} src={form.image_url}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
                }
              </div>
              <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChangeGambar} />
              <Button variant="contained" color="secondary" className={classes.button} style={{marginTop:'20px'}}>
                Ubah Foto
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </Drawer> 
    </div>
  );
};

export default PhotoGallery;
