import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Snackbar,
  Slide,
  Drawer,
  Typography,
  Divider,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,

} from '@material-ui/core';
import Select from '../../components/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker
} from '@material-ui/pickers';
import { API } from 'constants/api';
import moment from 'moment';
import MyTable from '../../components/MyTable';
import MySelect from '../../components/Select';
import { ChevronLeft, ChevronRight, ExpandMore, SettingsBackupRestore } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  detail: {
    fontWeight: '600',
    textAlign: 'right'
  },
  drawer: {
    width: '50%',
    flexShrink: 0
  },
  drawerPaper: {
    width: '70%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  buttonAdd: {
    margin: theme.spacing(3)
  }
}));

const voucherType = [
  {
    label: 'Percent',
    value: 'percent'
  },
  {
    label: 'Fixed',
    value: 'fixed'
  }
];

const valueFromTypeOption = [
  {
    label: 'Utama',
    value: 'primary'
  },
  {
    label: 'Total',
    value: 'total'
  }
];

const VoucherView = () => {
  const classes = useStyles();
  const theme = useTheme();

  const fields = [
    'Kode Voucher',
    'Judul',
    'Jumlah',
    'Tipe',
    'Nilai',
    'Berlaku Sampai'
  ];

  const fieldData = [
    'code',
    'title',
    'quantity',
    'type',
    'value',
    'valid_until'
  ];

  const [setIsVoucher] = useState(false);
  const [setSelectedMenu] = useState(0);
  const [voucherList, setVoucherList] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedVoucherType, setSelectedVoucherType] = useState(null);
  const [valueFromType, setValueFromType] = useState(valueFromTypeOption[0]);
  const [selectedDateVoucher, setSelectedDateVoucher] = useState(new Date());
  const [selectedTimeVoucher, setSelectedTimeVoucher] = useState(new Date());
  const [messageSnackbar, setMessageSnackbar] = React.useState('');
  const [alertt, setAlert] = React.useState(false);
  const [onBooking] = useState(false);
  const [cities, setCities] = useState([]);
  const [stores, setStores] = useState([]);
  const [packages, setPackages] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedMultiPackage, setSelectedMultiPackage] = useState(null);

  const [formVoucher, setFormVoucher] = useState({
    id: null,
    code: '',
    title: '',
    type: '',
    value: '0',
    quantity: '0',
    valid_until: null,
    limit: '0',
    value_from: 'primary'
  });

  useEffect(() => {
    getVoucherList();

    /**
     * Because get stores need a city, we get all city first
     * Inline function, avoid warnings
     */
    const getCities = async () => {
      const api = await fetch(`${API}/admin/cities`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      const res = await api.json();

      if (res.response_code === '00') {
        const tempArrCities = [];
        if (res.data.length > 0) {
          res.data.map(v => {
            tempArrCities.push({ label: v.name, value: v.id });
            return v;
          });

          setCities(tempArrCities);
        }
      }
    };

    getCities();


    /**
     * GET ALL STORE BY CITY
     */

    const getStores = async () => {
      const api = await fetch(`${API}/admin/stores`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      const res = await api.json();

      if (res.response_code === '00') {
        const arrStore = [];

        if (res.data.length > 0) {
          res.data.map(v => {
            arrStore.push({ label: v.name, value: v.id, ref: v.city.id });
          });

          setStores(arrStore);
          setSelectedStore(arrStore.map(item => ({ ...item, selected: false })));
        }
      }
    };
    getStores();

    /**
     * Set limit to 999999, because now backend not supported to get all packages
     * Inline function, avoid warnings
     */
    const getPackages = async () => {
      const api = await fetch(`${API}/packages?page=1&limit=999999`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      const res = await api.json();

      if (res.response_code === '00') {
        const arrPackages = [];

        if (res.data.length > 0) {
          res.data.map(v => {
            arrPackages.push({ label: v.title, value: v.id });
            return v;
          });

          setPackages(arrPackages);
        }
      }
    };

    getPackages();
  }, []);

  /***
   *
   * GET VOUCHER LIST
   *
   * */

  const getVoucherList = async () => {
    setVoucherList([]);
    const api = await fetch(`${API}/vouchers`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      if (res.data.length === 0) {
        // Handle empty voucher
      } else {
        // synchronous process get store and package
        const slicedVouchers = res.data.slice(0);

        // check voucher length
        if (slicedVouchers.length > 0) {
          // for table purpose, we pass a new attribute to an object
          Array.from(slicedVouchers).map(async v => {
            if (v.store_data != null) {
              v.store_name = v.store_data.name;
            }
            if (v.package_data != null) {
              v.package_name = v.package_data.title;
            }
            return v;
          });
          setVoucherList(slicedVouchers);
        } else {
          setVoucherList([]);
        }
      }
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  /***
   *
   * DELETE VOUCHER
   *
   * */

  const deleteVoucher = async id => {
    const api = await fetch(`${API}/voucher?id=${id}`, {
      method: 'DELETE'
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      handleShowSnackbar(true, 'Berhasil hapus voucher');
      getVoucherList();
      handleDrawerClose();
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const updateVoucher = async () => {
    const body = {
      id: formVoucher.id,
      code: formVoucher.code,
      title: formVoucher.title,
      type: selectedVoucherType.value,
      value: parseInt(formVoucher.value),
      stores: selectedStore.filter(f => f.selected).map(item => item.value),
      limit: parseInt(formVoucher.limit),
      from: valueFromType.value,
      packages: selectedMultiPackage.map(item => item.value),
      quantity:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? null
          : parseInt(formVoucher.quantity),
      valid_until:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? formVoucher.valid_until
          : null
    };

    const api = await fetch(`${API}/voucher`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });

    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }

    const res = await api.json();
    if (res.response_code === '00') {
      handleShowSnackbar(true, 'Berhasil update voucher');
      getVoucherList();
      handleDrawerClose();
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  /***
   *
   * GET VOUCHER BY ID
   *
   * */

  const getVoucherById = async id => {
    const api = await fetch(`${API}/voucher?id=${id}`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setFormVoucher({
        ...formVoucher,
        ...res.data,
        quantity: res.data.quantity ? `${res.data.quantity}` : '0',
        value: `${res.data.value}`
      });
      if (res.data.valid_until) {
        let date = res.data.valid_until.split(' ')[0];
        let year = date.split('-')[0];
        let month = date.split('-')[1];
        let day = date.split('-')[2];
        let time = res.data.valid_until.split(' ')[1];
        let hour = time.split(':')[0];
        let minute = time.split(':')[1];
        let second = time.split(':')[2];

        let newDate = new Date(year, month, day, hour, minute, second, 0);
        setSelectedDateVoucher(newDate);
        setSelectedTimeVoucher(newDate);
      }
      voucherType.map(v => {
        if (v.value === res.data.type) {
          setSelectedVoucherType(v);
        }
      });
      valueFromTypeOption.map(v => {
        if (v.value == res.data.from) {
          setValueFromType(v);
        }
      });
      const resStores = res.data.stores;
      setSelectedStore(selectedStore.map(item => ({
        ...item,
        selected: resStores.includes(item.value)
      })));
      const resPackages = res.data.packages
      setSelectedMultiPackage(
        packages.filter(item => resPackages.includes(item.value))
      )
      // if (res.data.store) {
      //   const store = await getStoreById(res.data.store);

      //   if (store) {
      // setSelectedStore({ label: store.name, value: store.id });

      //     const tempSelectedCity = cities.filter(
      //       v => v.value === store.city.id
      //     )[0];
      //     setSelectedCity(tempSelectedCity);

      // await getStores(tempSelectedCity.value);
      //   }
      // }
      // if (res.data.package) {
      //   const pkg = packages.filter(v => v.value === res.data.package)[0];
      // setSelectedPackages(pkg);
      // }
    }
  };

  const getStoreById = async storeId => {
    const api = await fetch(`${API}/store?id=${storeId}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });

    const res = await api.json();

    if (res.response_code === '00') {
      const { data } = res;
      return data;
    } else {
      return null;
    }
  };

  /**
   *
   * CREATE NEW VOUCHER
   *
   */

  const createNewVoucher = async () => {
    const body = {
      code: formVoucher.code,
      title: formVoucher.title,
      type: selectedVoucherType.value,
      value: parseInt(formVoucher.value),
      stores: selectedStore.filter(f => f.selected).map(item => item.value),
      packages: selectedMultiPackage.map(item => item.value),
      limit: parseInt(formVoucher.limit),
      from: valueFromType.value,
      quantity:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? null
          : parseInt(formVoucher.quantity),
      valid_until:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? formVoucher.valid_until
          : null
    };
    const api = await fetch(`${API}/voucher`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      getVoucherList();
      handleDrawerClose();
    }
  };

  /***
   *
   * STATE HANDLER
   *
   * */

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenForEdit = id => {
    getVoucherById(id);
    setOpen(true);
  };

  const handleChangeFormVoucher = name => e => {
    setFormVoucher({
      ...formVoucher,
      [name]: e.target.value
    });
  };

  const handleSelectedVoucherType = value => {
    setSelectedVoucherType(value);
  };

  const handleValueFromType = value => {
    setValueFromType(value);
  };

  const handleSelectedCity = value => {
    setSelectedCity(value);
    // getStores(value.value);
  };

  const handleChangeDateTime = datetime => e => {
    if (datetime === 'date') {
      if (e) {
        setSelectedDateVoucher(e);
        setFormVoucher({
          ...formVoucher,
          valid_until: `${moment(e).format('YYYY-MM-DD')} ${moment(
            selectedTimeVoucher
          ).format('HH:mm:ss')} WIB`
        });
      } else {
        setSelectedDateVoucher(new Date());
        setSelectedTimeVoucher(new Date());
        setFormVoucher({
          ...formVoucher,
          valid_until: null
        });
      }
    } else {
      if (e) {
        setSelectedTimeVoucher(e);
        setFormVoucher({
          ...formVoucher,
          valid_until: `${moment(selectedDateVoucher).format(
            'YYYY-MM-DD'
          )} ${moment(e).format('HH:mm:ss')} WIB`
        });
      } else {
        setSelectedDateVoucher(new Date());
        setSelectedTimeVoucher(new Date());
        setFormVoucher({
          ...formVoucher,
          valid_until: null
        });
      }
    }
  };

  const validateVoucherField = () => {
    let quantityOrValidUntil = false;
    if (formVoucher.quantity !== '0' && formVoucher.quantity.length > 0) {
      quantityOrValidUntil =
        formVoucher.quantity !== '0' && formVoucher.quantity.length > 0;
    } else {
      quantityOrValidUntil =
        formVoucher.valid_until && formVoucher.valid_until !== null;
    }
    return (
      formVoucher.code &&
      formVoucher.code.length > 0 &&
      formVoucher.title &&
      formVoucher.title.length > 0 &&
      selectedVoucherType &&
      quantityOrValidUntil
    );
  };

  const manageVoucher = async () => {
    if (validateVoucherField()) {
      if (formVoucher.id) {
        // Edit
        updateVoucher();
      } else {
        // Create new voucher
        createNewVoucher();
      }
    } else {
      handleShowSnackbar(true, 'Harap isi field yang diperlukan');
      return;
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);

    setFormVoucher({
      id: null,
      code: '',
      title: '',
      type: '',
      value: '0',
      quantity: '0',
      valid_until: null
    });

    setSelectedDateVoucher(new Date());
    setSelectedTimeVoucher(new Date());
    setSelectedVoucherType(null);
    setSelectedCity(null);
    setSelectedStore(selectedStore.map(item => ({ ...item, selected: false })))
    setSelectedMultiPackage(null)
  };

  const handleClickMenu = pos => {
    setIsVoucher(false);
    setSelectedMenu(pos);
  };

  const handleShowSnackbar = (status, message) => {
    setMessageSnackbar(message);
    setAlert(status);
  };

  const handleResetLimit = e => {
    setFormVoucher({
      ...formVoucher,
      quantity: '0',
      valid_until: null
    });
  };

  const handleOnNextPage = () => setPage(page + 1);

  const handleOnBackPage = () => setPage(page - 1);

  /***
   *
   * UI SECTION
   *
   * */

  const renderScreen = () => {
    return (
      <Grid item md={12} xs={12}>
        <Card className={classes.root} variant="outlined">
          <h4>Voucher Discount</h4>
          <hr style={{ marginBottom: '30px' }} />

          <MyTable
            id="id"
            data={voucherList}
            fields={fields}
            fieldData={fieldData}
            onAdd={handleDrawerOpen}
            onDelete={deleteVoucher}
            onEdit={handleDrawerOpenForEdit}
            titleAdd="Tambah Voucher"
            page={page}
            onNextPageClicked={handleOnNextPage}
            onBackPageClicked={handleOnBackPage}
          />
        </Card>
      </Grid>
    );
  };

  return (
    <div
      className={classes.root}
      style={{ pointerEvents: onBooking ? 'none' : 'inherit' }}>
      <Grid container spacing={4}>
        {renderScreen()}
      </Grid>

      {/* Drawer Voucher */}
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={() => {
              handleDrawerClose();
            }}>
            {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
          <Typography variant="h5">
            {formVoucher.id == null ? 'Simpan' : 'Ubah'} Informasi Voucher
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', right: '20px', top: '-10px' }}
            className={classes.buttonAdd}
            onClick={() => manageVoucher()}>
            {formVoucher.id == null ? 'Simpan' : 'Ubah'}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={4}>
            <TextField
              id="code"
              label="Kode Voucher"
              value={formVoucher.code}
              onChange={handleChangeFormVoucher('code')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="title"
              label="Judul"
              value={formVoucher.title}
              onChange={handleChangeFormVoucher('title')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <MySelect
              isMulti={false}
              name="type"
              placeholder="Pilih Tipe Voucher"
              options={voucherType}
              value={selectedVoucherType}
              onChange={handleSelectedVoucherType}
              fullWidth
            />
            <TextField
              id="value"
              label="Nilai"
              type="number"
              value={formVoucher.value}
              onChange={handleChangeFormVoucher('value')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="limit"
              label="Limit Persentase"
              type="number"
              value={formVoucher.limit}
              onChange={handleChangeFormVoucher('limit')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <MySelect
              isMulti={false}
              name="value_from"
              placeholder="Pilih Sumber Nilai Voucher"
              options={valueFromTypeOption}
              value={valueFromType}
              onChange={handleValueFromType}
              fullWidth
            />
            <TextField
              id="quantity"
              label="Jumlah"
              type="number"
              disabled={formVoucher.valid_until}
              value={formVoucher.quantity}
              onChange={handleChangeFormVoucher('quantity')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <div className="mt-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  inputVariant="outlined"
                  clearable
                  label="Berlaku Hingga"
                  value={selectedDateVoucher}
                  disabled={
                    formVoucher.quantity !== '0' &&
                    formVoucher.quantity.length > 0
                  }
                  minDate={new Date()}
                  format="dd MMMM yyyy"
                  className="w-50 pr-1"
                  onChange={handleChangeDateTime('date')}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  ampm={false}
                  openTo="hours"
                  clearable
                  views={['hours', 'minutes', 'seconds']}
                  format="HH:mm:ss"
                  disabled={
                    formVoucher.quantity !== '0' &&
                    formVoucher.quantity.length > 0
                  }
                  label="Jam"
                  className="w-50 pl-1"
                  inputVariant="outlined"
                  value={selectedTimeVoucher}
                  onChange={handleChangeDateTime('time')}
                />
              </MuiPickersUtilsProvider>
            </div>
            {/* <div className="mt-3">
              <MySelect
                isMulti={false}
                placeholder="Pilih Kota"
                options={cities}
                value={selectedCity != null ? selectedCity : ''}
                onChange={handleSelectedCity}
                fullWidth
              />
            </div>
            {stores && stores.length > 0 ? (
              <div className="mt-3">
                <MySelect
                  isMulti={false}
                  placeholder="Pilih Toko"
                  options={stores}
                  value={selectedStore != null ? selectedStore : ''}
                  onChange={handleSelectedStore}
                  fullWidth
                />
              </div>
            ) : null}
            <div className="mt-3">
              <MySelect
                isMulti={false}
                placeholder="Pilih Package"
                options={packages}
                value={selectedPackages != null ? selectedPackages : ''}
                onChange={handleSelectedPackage}
                fullWidth
              />
            </div> */}
            <Button onClick={handleResetLimit} color="primary" className="mt-3">
              Reset Batas
            </Button>
            <Typography>
              *Batas ditentukan berdasarkan jumlah/tanggal tertentu.
            </Typography>
          </Grid>
          <Grid item xs={8} style={{ padding: theme.spacing(3) }}>

            <Select
              isMulti
              name="Package"
              placeholder="Pilih Package"
              options={packages}
              value={selectedMultiPackage}
              onChange={e => {
                setSelectedMultiPackage(e)
              }}
              fullWidth
            />
            <div className="mt-3"></div>
            <Table>
              <TableBody>
                {
                  cities.map(item => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5">{item.label}</Typography>
                        {
                          stores
                            .filter(f => f.ref == item.value)
                            .map(sitem => {
                              const index = selectedStore.findIndex(f => f.value == sitem.value);
                              const checked = index >= 0 ? selectedStore[index].selected : false;
                              return <FormControlLabel
                                control={<Checkbox
                                  checked={checked}
                                  onChange={(event) => {
                                    const value = event.target.checked;
                                    let newData = selectedStore;
                                    newData[index].selected = value;
                                    setSelectedStore([...newData]);
                                  }} />}
                                label={sitem.label} />
                            })
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Drawer>

      {/* ALERT FOR ERROR */}
      <Snackbar
        open={alertt}
        onClose={() => setAlert(false)}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        autoHideDuration={3000}
        message={<span id="message-id">{messageSnackbar}</span>}
      />
    </div>
  );
};

export default VoucherView;
