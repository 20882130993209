import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    Card,
    Button,
    CircularProgress,
} from '@material-ui/core';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect } from 'react-redux';
import axios from '../../util/Service';
import {
    setUploadFile,
    checkStore,
    setDoneUpload,
    resetImage
} from '../../redux/uploadFile/uploadFile.actions';
import { setToken } from '../../redux/auth/auth.actions';
import { setTransaction } from '../../redux/transaction/transaction.actions';
import { makeStyles } from '@material-ui/styles';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';
import ImageBlot from 'views/Categories/ImageBlot';
Quill.register('modules/imageResize', ImageResize);
// Quill.register(ImageBlot);

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
}));

const Tnc = props => {
    const classes = useStyles();
    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['clean'],
        ['link', 'image'],                                         // remove formatting button
    ];
    const [loading, setLoading] = useState(false)
    const { quill, quillRef } = useQuill({
        modules: {
            toolbar: toolbarOptions,
            imageResize: {
                displaySize: true,
                modules: ['Resize', 'DisplaySize'],
            },
        },
        formats: [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image',
            'alt', 'width', 'height'
        ]
    });

    // API
    const getContent = async () => {
        await axios({
            method: 'GET',
            url: `tnc/tnc`,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            const content = response.data.data.content;
            quill.clipboard.dangerouslyPasteHTML(content);
        }).catch(error => {
            alert(error);
        })
    }

    const updateContent = async (content) => {
        setLoading(true)
        await axios({
            method: 'PUT',
            url: `tnc/tnc`,
            headers: { "Content-Type": "application/json" },
            data: { id: 1, content: content }
        })
        setLoading(false)
    }


    useEffect(() => {
        if (quill) {
            getContent()
        }
    }, [quill]);


    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Card className={classes.root} variant="outlined">
                        <h4>Terms and Condition</h4>
                        <hr style={{ marginBottom: '30px' }} />
                        <div ref={quillRef} style={{ height: "500px" }} />
                        <hr style={{ marginBottom: '30px' }} />
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ float: "right" }}
                            onClick={async () => {
                                await updateContent(quill.root.innerHTML)
                            }}>
                            Simpan
                        </Button>
                        {loading && <CircularProgress style={{ width: "25px", float: "right", marginRight: "25px" }} />}
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

const mapStateToProps = state => ({
    url_image: state.UploadFile.url_image,
    files: state.UploadFile.fileProgress,
    done_upload: state.UploadFile.done_upload
});

const mapDispatchToProps = dispatch => ({
    setUploadFile: files => dispatch(setUploadFile(files)),
    setToken: token => dispatch(setToken(token)),
    setTransaction: trx => dispatch(setTransaction(trx)),
    checkStore: () => dispatch(checkStore()),
    setDoneUpload: data => dispatch(setDoneUpload(data)),
    resetImage: () => dispatch(resetImage())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tnc);
