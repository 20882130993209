import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { size, toArray } from 'lodash';
import { uploadFile } from '../../redux/uploadFile/uploadFile.actions';

import UploadItem from '../UploadItem';

const Index = (props) => {
  const { fileProgress, uploadFile } = props;
  const uploadedFileAmount = size(fileProgress);

  useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(
      (file) => file.progress === 0
    );
    uploadFile(fileToUpload);
  }, [uploadedFileAmount]);

  return uploadedFileAmount > 0 ? (
    <div>
      <div className="wrapper">
        <h4>Uploading File</h4>
        {size(fileProgress)
          ? toArray(fileProgress).map((file, position) => (
            <UploadItem
              file={file}
              key={file.id}
            />
          ))
          : null}
      </div>
      <style jsx>
        {`
          .wrapper {
            background-color: white;
            width: 100%;
            overflow: scroll;
            max-height: 400px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
        `}
      </style>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({
  fileProgress: state.UploadFile.fileProgress
});

const mapDispatchToProps = (dispatch) => ({
  uploadFile: (files) => dispatch(uploadFile(files))
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
