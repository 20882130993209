
import { size } from 'lodash'

export const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {}
  if (files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      const id = size(existingFiles) + i + 1
      fileToUpload = {
        ...fileToUpload,
        [id]: {
          id,
          file: files[i],
          progress: 0,
        },
      }
    }
  }

  return fileToUpload
}
