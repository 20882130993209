import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import PencilIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Dialog from 'components/Dialog';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { red, blue } from '@material-ui/core/colors';
import {
  IconButton,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PublishIcon from '@material-ui/icons/Publish';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubCategoryIcon from '@material-ui/icons/CategoryOutlined';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  title: {
    flexGrow: 1
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    zIndex: '999'
  },
  button: {
    marginBottom: theme.spacing(1)
  },
  margin: {
    marginRight: theme.spacing(1)
  }
}));

const drawerWidth = 500;

export default function CustomerTable(props) {
  const classes = useStyles();

  const theme = useTheme();
  const [alertDelete, setAlertDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [alertOff, setAlertOff] = useState(false);
  const [alertShowHide, setAlertShowHide] = useState(false);

  const handleAlertDeleteOpen = (id) => {
    props.onDelete(selectedId);
    setAlertDelete(false);
  };

  const handleAlertShowHideOpen = (id) => {
    props.onShowHide(selectedId);
    setAlertShowHide(false);
  };

  function handleAlertDeleteClose() {
    setAlertDelete(false);
  };

  const shouldRenderAdd = () => {
    if (props.titleAdd === '') {
      return null;
    } else {
      return (
        <Grid
          container
          justify="flex-end"
        >
          <Grid item>
            <Button
              className={classes.button}
              color="secondary"
              onClick={props.onAdd}
              variant="contained"
            >
              {props.titleAdd}
            </Button>
          </Grid>
        </Grid>
      );
    }
  };

  const shouldRenderAction = () => {
    if (props.isAction) {
      return (
        <TableCell
          align="center"
          style={{
            paddingRight: 30,
            color: '#fff'
          }}
        >
          Action
        </TableCell>
      );
    } else {
      return null;
    }
  };

  const { page, rowsPerPage, paper } = props;

  const renderContentTextShowHide = () => {
    const selectStore = props.data.find(v => v.id === selectedId);
    if (selectStore) {
      if (selectStore.publish) {
        return "Anda yakin ingin menyembunyikan data ini?"
      }
      return "Anda yakin ingin menampilkan data ini?"
    }
    return "";
  };

  const renderButtonTextShowHide = () => {
    const selectStore = props.data.find(v => v.id === selectedId);
    if (selectStore) {
      if (selectStore.publish) {
        return "Sembunyikan"
      }
      return "Tampilkan"
    }
    return "";
  };

  return (
    <div>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            className="p-0 m-0"
            item
            xs={12}
          >
            {/* <Paper className="p-3" elevation={paper ? 1 : 0}> */}
            {props.isAdd ? shouldRenderAdd() : null}
            <Grid
              item
              xs={12}
            >
              <Table>
                <TableHead style={{ backgroundColor: '#3C3835' }}>
                  <TableRow>
                    {props.fields.map((row) => (
                      <TableCell
                        key={row}
                        style={{ color: '#fff' }}
                      >
                        {row}
                      </TableCell>
                    ))}
                    {shouldRenderAction()}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.isLoading && (
                      <StyledTableRow>
                        <TableCell
                          align="center"
                          colSpan={props.fieldData.length + 1}
                        >
                          <CircularProgress />
                        </TableCell>
                      </StyledTableRow>
                    )}
                  {(props.data.length == 0 && !props.isLoading) && (
                    <StyledTableRow>
                      <TableCell
                        align="center"
                        colSpan={props.fieldData.length + 1}
                      >
                        Tidak ada data
                      </TableCell>
                    </StyledTableRow>
                  )}
                  {(props.data.length && !props.isLoading) >= 0 && (
                    props.data
                      .map((row, position) => (
                        <StyledTableRow key={row[props.id]}>
                          {props.fieldData.map((data, index) => (
                            <TableCell
                              className={props.styles[index]}
                              key={row[data]}
                              style={{ whiteSpace: 'pre-line', opacity: props.onShowHide != 'undefined' ? row[data] && row[data].publish ? '50%' : '100%' : '100%' }}
                            >
                              <div
                                style={{
                                  color: props.onShowHide ? row.publish ? '#000000' : '#b8b8b8' : '#000000'
                                }}
                              >
                                {row[data]}
                              </div>
                            </TableCell>
                          ))}

                          {props.isAction ? (
                            props.isReport == false ? (
                              <TableCell align="right">
                                <a
                                  onClick={() => {
                                    setSelectedId(row[props.id]);
                                    props.onEdit(row[props.id]);
                                  }}
                                >
                                  {props.isEdit == true ? (
                                    // <Fab size="small" color="primary" aria-label="add" className={classes.margin}>
                                    //     <PencilIcon />
                                    // </Fab>
                                    <PencilIcon
                                      className="pointer mr-2"
                                      style={{ color: blue[500] }}
                                    />
                                  ) : (
                                    <div />
                                  )}
                                </a>
                                {typeof props.onDelete != 'undefined' ? (
                                  <a
                                    onClick={() => {
                                      setSelectedId(row[props.id]);
                                      setAlertDelete(true);
                                    }}
                                  >
                                    {/* <Fab size="small" aria-label="add" className={classes.margin} style={{ color: red[50], backgroundColor: red[500] }}>
                                                                  <DeleteIcon/>
                                                              </Fab> */}
                                    <DeleteIcon
                                      className="pointer mr-2"
                                      style={{ color: red[500] }}
                                    />
                                  </a>
                                ) : (
                                  <></>
                                )}
                                {
                                  typeof props.onShowHide != 'undefined' ?
                                    (
                                      <a
                                        onClick={() => {
                                          setSelectedId(row[props.id]);
                                          setAlertShowHide(true);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        title={row.publish ? 'Hide Store' : 'Show Store'}
                                      >
                                        {
                                          !row.publish ?
                                            <VisibilityOffIcon
                                              className="pointer mr-2"
                                              style={{
                                                opacity: '50%'
                                              }}
                                            /> :
                                            <VisibilityIcon
                                              className="pointer mr-2"
                                            />
                                        }
                                      </a>
                                    ) : null
                                }
                                {
                                  typeof props.onSub != 'undefined' ?
                                    (
                                      <a
                                        onClick={() => {
                                          setSelectedId(row[props.id]);
                                          props.onSub(row);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                        title='Sub Category'
                                      >
                                        <SubCategoryIcon
                                          className="pointer mr-2"
                                        />
                                      </a>
                                    ) : null
                                }
                              </TableCell>
                            ) : (
                              <TableCell align="right">
                                <div className="d-flex">
                                  <a
                                    onClick={() => {
                                      setSelectedId(row[props.id]);
                                      props.onDetail(row[props.id]);
                                    }}
                                  >
                                    <VisibilityIcon
                                      className="pointer mr-2"
                                      color="secondary"
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </a>
                                  {
                                    <a
                                      onClick={() => {
                                        // setSelectedId(row[props.id]);
                                        props.onShowingSN(row);
                                      }}
                                    >
                                      <ConfirmationNumberIcon
                                        className="pointer mr-2"
                                        color="primary"
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </a>
                                  }
                                  {row &&
                                    row.status_transaction &&
                                    row.status_transaction === 'paid' ? (
                                    <a
                                      onClick={() => {
                                        props.onClickUpload(position);
                                      }}
                                    >
                                      <span title="Upload Preview Photos">
                                        <PublishIcon
                                          className="mr-2"
                                          color="primary"
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </span>
                                    </a>
                                  ) : null}
                                </div>
                              </TableCell>
                            )
                          ) : null}
                        </StyledTableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Grid
                alignItems="center"
                container
                justify="flex-end"
              >
                <Grid item>
                  {Math.ceil(props.pageCount.total) > 0 ? (
                    <Typography>
                      Halaman {page} dari{' '}
                      {Math.ceil(props.pageCount.total_pages)}
                      {` (${props.pageCount.total} data)`}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="previous page"
                    disabled={props.page === 1}
                    onClick={() => props.onBackPageClicked()}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                  </IconButton>
                  <IconButton
                    aria-label="next page"
                    disabled={
                      page >= Math.ceil(props.pageCount.total_pages)
                    }
                    onClick={() => props.onNextPageClicked()}
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          content="Anda yakin ingin menghapus data ini?"
          negative="Tutup"
          onNegative={handleAlertDeleteClose}
          onPositive={() => handleAlertDeleteOpen(selectedId)}
          open={alertDelete}
          positive="Hapus"
          title="Peringatan!"
        />

        <Dialog
          content="Anda yakin ingin memaksa logout user ini?"
          negative="Tutup"
          onNegative={() => setAlertOff(false)}
          onPositive={() => {
            setAlertOff(false);
            props.onOff(selectedId.guid_pengguna);
          }}
          open={alertOff}
          positive="Logout"
          title="Peringatan!"
        />

        <Dialog
          content={renderContentTextShowHide()}
          negative="Batal"
          onNegative={() => setAlertShowHide(false)}
          onPositive={() => handleAlertShowHideOpen(selectedId)}
          open={alertShowHide}
          positive={renderButtonTextShowHide()}
          title="Peringatan!"
        />
      </div>
    </div>
  );
}

CustomerTable.defaultProps = {
  data: [],
  pageCount: 1,
  page: 1,
  rowsPerPage: 10,
  isEdit: true,
  isAction: true,
  titleAdd: '',
  onNextPageClicked: null,
  onBackPageClicked: null,
  paper: true,
  styles: [],
  isAdd: true,
  isReport: false,
  isLoading: true
};
