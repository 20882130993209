import React, { useEffect, useState } from 'react';
import MyTable from '../../components/MyTablePagination';
import ButtonExportExcel from '../../components/ButtonExportExcel';
import MySelect from '../../components/Select';
import axios from '../../util/Service';
import { API } from '../../constants';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TextField,
  MenuItem,
  Select,
  Button,
  InputLabel,
  FormControl,
  Snackbar,
  Slide
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import {
  setUploadFile,
  checkStore,
  setDoneUpload,
  resetImage
} from '../../redux/uploadFile/uploadFile.actions';
import { setToken } from '../../redux/auth/auth.actions';
import { setTransaction } from '../../redux/transaction/transaction.actions';
import UploadProgress from '../../components/UploadProgress';
import ButtonExportPdf from 'components/ButtonExportPdf';

const drawerWidth = '90%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  buttonAdd: {
    margin: theme.spacing(3)
  },
  detail: {
    fontWeight: '600',
    textAlign: 'right'
  }
}));

const Transactions = props => {
  const classes = useStyles();
  const theme = useTheme();

  const fields = [
    'ID Transaksi',
    'Tanggal Transaksi',
    'Nama',
    'Store',
    'Jumlah',
    'Tanggal Booking',
    'Status',
    'JOM Serial Number'
  ];
  const fieldData = [
    'ID_Transaksi',
    'Waktu_Transaksi',
    'Nama',
    'Store',
    'Jumlah_Harga',
    'Tanggal_Booking',
    'Status_Transaksi',
    'jom_sn'
  ];

  //state
  const [data, setData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setLoading] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [serialNumber, setSerialNumber] = useState('');
  const [selectedData, setSelectedData] = useState(null);

  // Role
  const currUser = JSON.parse(localStorage.getItem('currentUser'));
  const [accountData, setAccountData] = useState(null);
  const [selectedStore, setSelectedStore] = useState([]);
  const [dataStore, setDataStore] = useState([]);

  // state filter
  const [formFilterStatus, setFormFilterStatus] = useState({
    transactionDate: moment().format('YYYY-MM-DD'),
    status: 'all'
  });
  const defaultFormFilter = {
    startDate: moment().format('MM/DD/YYYY'),
    endDate: moment().format('MM/DD/YYYY'),
    showDate: `${moment().format('MM/DD/YYYY')} ~ ${moment().format(
      'MM/DD/YYYY'
    )}`
  };
  const [formFilter, setFormFilter] = useState(defaultFormFilter);

  // state for export excel
  const [excelData, setExcelData] = useState([]);

  // SHOWING SNACKBAR ERROR
  const [alertt, setAlert] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [alertValidation, setAlertValidation] = React.useState(false);

  useEffect(() => {
    const currUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currUser) {
      const { token } = currUser;
      if (token) {
        setAccountData(token);
        props.setToken(token.token);
      }
    }
  }, []);

  useEffect(() => {
    if (accountData) {
      if (accountData.role === 2) {
        getStore(accountData.store);
      } else {
        getStores();
      }
    }
  }, [accountData]);

  // API
  // ROLE = 0 || ROLE = 1
  const getStores = () => {
    axios({
      method: 'GET',
      url: 'stores',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          parsingStore(response.data.data);
        } else {
          parsingStore([]);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  // ROLE = 2
  const getStore = store => {
    axios({
      method: 'GET',
      url: 'store?id=' + store,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          setSelectedStore(response.data.data);
          if (accountData.role === 2) {
            getTransactions(
              formFilter.startDate,
              formFilter.endDate,
              formFilterStatus.status,
              accountData.store,
              page
            );
          }
        } else {
          setSelectedStore([]);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const getTransactions = (startDate, endDate, status, store, page) => {
    status = status === 'all' ? '' : status;

    axios({
      method: 'GET',
      url:
        'transaction/list?store=' +
        store +
        '&transaction_from=' +
        moment(startDate).format('YYYY-MM-DD') +
        '&transaction_to=' +
        moment(endDate).format('YYYY-MM-DD') +
        '&status=' +
        status +
        '&limit=10' +
        '&page=' +
        page,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200) {
          setLoading(false);
          parseData(response.data.data);
          setPageCount(response.data.pagination);
        }
      })
      .catch(error => {
        alert(error);
      });

    axios({
      method: 'GET',
      url:
        'transaction/list?store=' +
        store +
        '&transaction_from=' +
        moment(startDate).format('YYYY-MM-DD') +
        '&transaction_to=' +
        moment(endDate).format('YYYY-MM-DD') +
        '&status=' +
        status +
        '&limit=100000' +
        '&page=1',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      if (response.status === 200) {
        setPdfData(response.data.data.map(it => it.id));
      }
    })
  };

  // Parse
  const parsingStore = data => {
    if (data != null) {
      const newData = data.map(v => {
        return {
          ...v,
          label: v.name,
          value: v.id
        };
      });
      newData.unshift({ id: '', value: '', label: 'All store' });
      setDataStore(newData);
      setSelectedStore(newData[0]);

      getTransactions(
        formFilter.startDate,
        formFilter.endDate,
        formFilterStatus.status,
        '',
        page
      );
    }
  };

  const parseData = data => {
    if (data != null) {
      // data for export excel(without id)
      let newData = data.map(v => ({
        ...v,
        date: v.transaction_time.split(' ')
      }));
      newData = newData.map((v, i) => ({
        No: i + 1,
        ID_Transaksi: v.transaction_id,
        Store: v.store,
        Waktu_Transaksi:
          moment(v.date[0]).format('DD-MM-YYYY') + ' ' + v.date[1],
        Nama: v.name,
        Email: v.email,
        Nomor_Kontak: v.msisdn,
        Jumlah_Harga:
          'Rp ' +
          v.total_price.toLocaleString(navigator.language, {
            minimumFractionDigits: 2
          }),
        Status_Transaksi: v.status_transaction,
        Tanggal_Booking:
          v.booking_date === ''
            ? ''
            : moment(v.booking_date).format('DD-MM-YYYY'),
        Jumlah_orang: v.person,
        Tipe_Transaksi: v.type_transaction,
        JOM_Serial_Number: v.jom_sn
      }));
      setExcelData(newData);

      // data for Table(plus id)
      let newTableData = data.map(v => ({
        ...v,
        date: v.transaction_time.split(' ')
      }));
      newTableData = newTableData.map(v => ({
        ...v,
        id: v.id,
        ID_Transaksi: v.transaction_id,
        Waktu_Transaksi:
          moment(v.date[0]).format('DD-MM-YYYY') + ' ' + v.date[1],
        Nama: v.name,
        Store: v.store,
        Jumlah_Harga:
          'Rp ' +
          v.total_price.toLocaleString(navigator.language, {
            minimumFractionDigits: 2
          }),
        Status_Transaksi: v.status_transaction,
        Tanggal_Booking:
          v.booking_date === ''
            ? ''
            : moment(v.booking_date).format('DD-MM-YYYY')
      }));
      setData(newTableData);
    }
  };

  const insertSN = () => {
    axios({
      method: 'POST',
      url: 'transaction/jom-sn?id=' + selectedData.id,
      headers: { 'Content-Type': 'application/json' },
      data: { jom_sn: serialNumber }
    })
      .then(() => {
        setAlertValidation(false);
        getTransactions(
          formFilter.startDate,
          formFilter.endDate,
          formFilterStatus.status,
          selectedStore.id,
          page
        );
        setOpenDialog(false);
        handleShowSnackbar(true, 'Berhasil submit Serial Number');
      })
      .catch(() => {
        handleShowSnackbar(
          true,
          'Gagal submit Serial Number, Transaksi belum lengkap'
        );
        setAlertValidation(false);
      });
  };

  // handle
  const handleSelectedStore = store => {
    setSelectedStore(store);
  };

  const handleShowSnackbar = (status, err) => {
    setMessageSnackbar(err);
    setAlert(status);
  };

  const handleDrawerDetail = id => {
    axios({
      method: 'GET',
      url: 'transaction/detail/' + id,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200) {
          setDataDetail(response.data.data);
          setOpen(true);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleOnNextPage = () => {
    setPage(page + 1);
    setData([]);
    setLoading(true);
    getTransactions(
      formFilter.startDate,
      formFilter.endDate,
      formFilterStatus.status,
      selectedStore.id,
      page + 1
    );
  };

  const handleOnBackPage = () => {
    setPage(page - 1);
    setData([]);
    setLoading(true);
    getTransactions(
      formFilter.startDate,
      formFilter.endDate,
      formFilterStatus.status,
      selectedStore.id,
      page - 1
    );
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setDataDetail([]);
  };

  const handleFilterDate = (event, picker) => {
    const startDate = moment(picker.startDate).format('MM/DD/YYYY');
    const endDate = moment(picker.endDate).format('MM/DD/YYYY');
    setFormFilter({
      ...formFilter,
      startDate: startDate,
      endDate: endDate,
      showDate: `${startDate} ~ ${endDate}`
    });
  };

  const handleFilterData = () => {
    getTransactions(
      formFilter.startDate,
      formFilter.endDate,
      formFilterStatus.status,
      selectedStore.id,
      1
    );
    setPage(1);
    setData([]);
    setLoading(true);
  };

  const handleChangeFormFilter = name => e => {
    setFormFilterStatus({
      ...formFilterStatus,
      [name]: e.target.value
    });
  };

  const renderFilterStore = () => {
    if (accountData) {
      if (accountData.role === 2) {
        return (
          <h5 style={{ marginBottom: '20px' }}>
            Store : <b>{selectedStore && selectedStore.name}</b>
          </h5>
        );
      } else {
        return (
          <Grid
            item
            style={{
              float: 'left',
              display: 'block',
              minWidth: '200px',
              marginRight: '15px'
            }}
            xs={3}>
            <InputLabel htmlFor="store">
              <b>Filter Store</b>
            </InputLabel>
            <MySelect
              label="store"
              name="Store"
              onChange={handleSelectedStore}
              options={dataStore}
              placeholder="Filter Store"
              value={selectedStore}
            />
          </Grid>
        );
      }
    }
  };

  // Upload Photos Section
  const [showDialogUploadPhotos, setShowDialogUploadPhotos] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [doUpload, setDoUpload] = useState(false);
  const [previewList, setPreviewList] = useState([]);
  const [showDialogImage, setShowDialogImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [showDialogDeleteImage, setShowDialogDeleteImage] = useState(false);

  const handleClickUpload = position => {
    getPhotoList(data[position].id);
    setSelectedTransaction(data[position]);
    props.setTransaction(data[position]);
  };

  const handleOnClickPrint = position => {
    setSelectedTransaction(data[position]);
    props.setTransaction(data[position]);
    alert("Print " + selectedTransaction["Nama"])
  };

  const handleChangeUploadImage = files => {
    setSelectedPhotos(files);
    props.setUploadFile(files);
    props.checkStore();
  };

  const getPhotoList = async transactionId => {
    const api = await fetch(
      `${API}/transaction/preview?page=1&limit=100&transaction_id=${transactionId}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${accountData.token}` }
      }
    );
    if (!api.ok) {
      if (api.status === 401) {
        handleShowSnackbar(
          true,
          'Sesi anda sudah berakhir, harap login kembali'
        );
        localStorage.clear();
        window.location.href = '/sign-in';
        return;
      }
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setPreviewList(res.data);

      setShowDialogUploadPhotos(true);
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const handleUploadImage = async e => {
    setDoUpload(true);
  };

  const onClickDeleteImagePreview = data => e => {
    setShowDialogDeleteImage(true);
    setSelectedImage(data);
  };

  const onClickImagePreview = image_url => {
    setSelectedImage(image_url);
    setShowDialogImage(true);
  };

  const handleDeleteImage = async e => {
    const deleteBody = new FormData();
    deleteBody.append('url', selectedImage.url_photo);
    await fetch(
      'http://ec2-18-138-82-207.ap-southeast-1.compute.amazonaws.com:6969/api/v1/delete',
      {
        method: 'POST',
        body: deleteBody,
        headers: {
          Authorization: `Bearer ${accountData.token}`
        }
      }
    );
    const body = {
      id: selectedImage.id
    };
    const api = await fetch(`${API}/transaction/preview-asset`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accountData.token}`
      }
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      handleShowSnackbar(true, 'Berhasil hapus foto');
      setSelectedImage('');
      setShowDialogDeleteImage(false);
      getPhotoList(selectedTransaction.id);
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  useEffect(() => {
    // if (props.UploadFile) {
    //   if (props.UploadFile.fileProgress && Object.keys(props.UploadFile.fileProgress).length > 0) {
    //     let hasComplete = true
    //     for (var property in props.UploadFile.fileProgress) {
    //       if (props.UploadFile.fileProgress.hasOwnProperty(property)) {
    //         let item = props.UploadFile.fileProgress[property];
    //         if (item.status !== 1) {
    //           hasComplete = false;
    //         }
    //       }
    //     }
    //     if (hasComplete) {
    //       setShowDialogUploadPhotos(false);
    //     }
    //   }
    // }
    if (props.done_upload) {
      setShowDialogUploadPhotos(false);
      setSelectedTransaction(null);
      props.setDoneUpload(false);
      props.setUploadFile([]);
      setDoUpload(false);
      handleShowSnackbar(true, 'Berhasil Upload Foto');
    }
  }, [props]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>Transaksi</h4>
            <hr style={{ marginBottom: '30px' }} />

            <Grid item style={{ marginBottom: '25px' }} xs={12}>
              {renderFilterStore()}
              <Grid
                item
                style={{ float: 'left', width: '225px', marginRight: '15px' }}
                xs={4}>
                <InputLabel htmlFor="store">
                  <b>Filter Range Tanggal Transaksi</b>
                </InputLabel>
                <DateRangePicker
                  containerStyles={{ width: '100%' }}
                  endDate={formFilter.endDate}
                  onEvent={handleFilterDate}
                  startDate={formFilter.startDate}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="showDate"
                    readOnly
                    style={{ marginTop: '0px' }}
                    value={formFilter.showDate}
                    variant="outlined"
                  />
                </DateRangePicker>
              </Grid>
              <Grid item style={{ float: 'left' }} xs={3}>
                <InputLabel htmlFor="store">
                  <b>Filter Status</b>
                </InputLabel>
                <FormControl
                  className={classes.formControl}
                  style={{ margin: '0 15px 0 0', width: '215px' }}
                  variant="outlined">
                  <Select
                    id="satuan"
                    onChange={handleChangeFormFilter('status')}
                    style={{ height: '40px' }}
                    value={formFilterStatus.status}
                    defaultValue={'all'}>
                    <MenuItem value="all">All status</MenuItem>
                    <MenuItem value="cancel">Cancel</MenuItem>
                    <MenuItem value="expired">Expired</MenuItem>
                    <MenuItem value="failed">Failed</MenuItem>
                    <MenuItem value="paid">Paid</MenuItem>
                    <MenuItem value="waiting_confirmation">
                      Waiting Confirmation
                    </MenuItem>
                    <MenuItem value="waiting_for_payment">
                      Waiting For Payment
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Button
                color="primary"
                onClick={handleFilterData}
                style={{ marginTop: '25px', marginRight: '15px' }}
                variant="contained">
                Filter
              </Button>

              <ButtonExportExcel
                csvData={excelData}
                fileName={`Laporan Reservasi ${selectedStore.id === ''
                  ? 'Jonas ' + selectedStore.label
                  : selectedStore.name
                  }`}
                typeData={'transaksi'}
                style={{ padding: '20px', marginRight: '15px' }}
              />

              <ButtonExportPdf
                data={pdfData}
                style={{ padding: '20px' }}
              />
            </Grid>

            <Grid item style={{ overflowX: 'scroll' }} xs={12}>
              <MyTable
                data={data}
                pageCount={pageCount}
                isLoading={isLoading}
                fieldData={fieldData}
                fields={fields}
                id="id"
                isReport
                onBackPageClicked={handleOnBackPage}
                onClickUpload={handleClickUpload}
                onDetail={handleDrawerDetail}
                onNextPageClicked={handleOnNextPage}
                onClickPrint={handleOnClickPrint}
                onShowingSN={e => {
                  setSelectedData(e);
                  setSerialNumber(e.jom_sn);
                  setOpenDialog(true);
                }}
                page={page}
              />
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerPaper }}
        className={classes.drawer}
        open={open}>
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={() => {
              handleDrawerClose();
            }}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          Detail Transaksi
        </div>
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item style={{ marginRight: '60px' }} xs={4}>
            <h5>Informasi</h5>
            <table className="table" style={{ backgroundColor: '#f7f7f7' }}>
              <tbody>
                <tr>
                  <td>ID Transaksi:</td>
                  <td className={classes.detail}>
                    {dataDetail.transaction_id}
                  </td>
                </tr>
                <tr>
                  <td>Nama:</td>
                  <td className={classes.detail}>{dataDetail.name}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td className={classes.detail}>{dataDetail.email}</td>
                </tr>
                <tr>
                  <td>No Telepon:</td>
                  <td className={classes.detail}>{dataDetail.msisdn}</td>
                </tr>
                <tr>
                  <td>Jumlah:</td>
                  <td className={classes.detail}>{dataDetail.person} orang</td>
                </tr>
                <tr>
                  <td>Tanggal Booking:</td>
                  <td className={classes.detail}>{dataDetail.booking_date}</td>
                </tr>
                {
                  dataDetail.detail && <tr>
                    <td>Image Text:</td>
                    <td className={classes.detail}>{dataDetail.detail.image_text}</td>
                  </tr>
                }
              </tbody>
            </table>
          </Grid>
          <Grid item style={{ marginRight: '60px' }} xs={3}>
            <h5>Jadwal</h5>
            <table className="table" style={{ backgroundColor: '#f7f7f7' }}>
              <tbody>
                <tr>
                  <td>Store:</td>
                  <td className={classes.detail}>
                    {dataDetail.store && dataDetail.store.name}
                  </td>
                </tr>
                <tr>
                  <td>Studio:</td>
                  <td className={classes.detail}>
                    {dataDetail.schedule && dataDetail.schedule.studio}
                  </td>
                </tr>
                <tr>
                  <td>Tanggal:</td>
                  <td className={classes.detail}>
                    {dataDetail.schedule && dataDetail.schedule.date}
                  </td>
                </tr>
                <tr>
                  <td>Waktu Mulai:</td>
                  <td className={classes.detail}>
                    {dataDetail.schedule && dataDetail.schedule.time_from}
                  </td>
                </tr>
                <tr>
                  <td>Waktu Selesai:</td>
                  <td className={classes.detail}>
                    {dataDetail.schedule && dataDetail.schedule.time_to}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
          <Grid item xs={3}>
            <h5>Tipe Transaksi</h5>
            <table className="table" style={{ backgroundColor: '#f7f7f7' }}>
              <tbody>
                <tr>
                  <td>Tipe:</td>
                  <td className={classes.detail}>
                    {dataDetail.type_transaction}
                  </td>
                </tr>
                <tr>
                  <td>Status Pembayaran:</td>
                  <td className={classes.detail}>{dataDetail.status_fraud}</td>
                </tr>
                <tr>
                  <td>Status Transaksi:</td>
                  <td className={classes.detail}>
                    {dataDetail.status_transaction}
                  </td>
                </tr>
                <tr>
                  <td>Valid hingga:</td>
                  <td className={classes.detail}>
                    {dataDetail.valid_until === ''
                      ? '-'
                      : dataDetail.valid_until}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item style={{ marginRight: '60px', marginTop: '30px' }} xs={5}>
            <h5>Detail Package</h5>
            <Grid item xs={10}>
              <table className="table" style={{ backgroundColor: '#f7f7f7' }}>
                <tbody>
                  <tr>
                    <td>Nama Package:</td>
                    <td className={classes.detail}>
                      {dataDetail.detail && dataDetail.detail.package_title}
                    </td>
                  </tr>
                  {
                    dataDetail?.detail?.variant_data?.name != "" &&
                    <tr>
                      <td>Varian:</td>
                      <td className={classes.detail}>
                        Kode: {dataDetail?.detail?.variant_data?.code}
                        <br />
                        {dataDetail?.detail?.variant_data?.name}
                      </td>
                    </tr>
                  }
                  {
                    dataDetail?.detail?.category != '' &&
                    <tr>
                      <td>Kategori:</td>
                      <td className={classes.detail}>
                        {dataDetail.detail && dataDetail.detail.category}
                      </td>
                    </tr>
                  }
                  {
                    dataDetail?.detail?.subcategory != '' &&
                    <tr>
                      <td>Sub Kategori:</td>
                      <td className={classes.detail}>
                        {dataDetail.detail && dataDetail.detail.subcategory}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td>Deskripsi:</td>
                    <td className={classes.detail}>
                      {dataDetail.detail && dataDetail.detail.description}
                    </td>
                  </tr>
                  <tr>
                    <td>Harga Package:</td>
                    <td className={classes.detail}>
                      Rp{' '}
                      {dataDetail.detail &&
                        dataDetail.detail.base_price.toLocaleString(
                          navigator.language,
                          { minimumFractionDigits: 2 }
                        )}
                    </td>
                  </tr>
                  {
                    dataDetail.detail && dataDetail.detail.template != 0 && <tr>
                      <td>Data Template:</td>
                      <td className={classes.detail}>
                        {dataDetail.detail &&
                          dataDetail.detail.template_data.name}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </Grid>
            <Grid item style={{ marginTop: '30px' }} xs={12}>
              <h5>Package Includes</h5>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Label</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>Height</TableCell>
                    <TableCell>Jumlah</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataDetail.detail &&
                    dataDetail.detail.includes &&
                    dataDetail.detail.includes.map(v => (
                      <TableRow key={v.id}>
                        <TableCell>{v.label}</TableCell>
                        <TableCell>{v.name}</TableCell>
                        <TableCell>{v.weight} cm</TableCell>
                        <TableCell>{v.height} cm</TableCell>
                        <TableCell>{v.quantity}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
            <Grid item style={{ marginTop: '30px' }} xs={12}>
              <h5>Addons</h5>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Harga</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataDetail.addons &&
                    dataDetail.addons.filter(f => f.price != 0).map(v => (
                      <TableRow key={v.id}>
                        <TableCell>{v.name}</TableCell>
                        <TableCell>
                          {
                            v.name === 'Add on person'
                              ? v.value + ' orang'
                              : v.name === 'Photo Image Text'
                                ? v.value + ' karakter'
                                : v.value + ' menit'
                          }
                        </TableCell>
                        <TableCell>
                          Rp{' '}
                          {v.price.toLocaleString(navigator.language, {
                            minimumFractionDigits: 2
                          })}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: '30px' }} xs={6}>
            <h5>Total</h5>
            <table className="table" style={{ backgroundColor: '#f7f7f7' }}>
              <tbody>
                <tr>
                  <td>Payment Code:</td>
                  <td className={classes.detail}>
                    {dataDetail.payment && dataDetail.payment.code}
                  </td>
                </tr>
                <tr>
                  <td>Harga:</td>
                  <td className={classes.detail}>
                    Rp{' '}
                    {dataDetail.billing &&
                      dataDetail.billing.price.toLocaleString(
                        navigator.language,
                        { minimumFractionDigits: 2 }
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Addons:</td>
                  <td className={classes.detail}>
                    Rp{' '}
                    {dataDetail.billing &&
                      dataDetail.billing.add_on.toLocaleString(
                        navigator.language,
                        { minimumFractionDigits: 2 }
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Pajak:</td>
                  <td className={classes.detail}>
                    Rp{' '}
                    {dataDetail.billing &&
                      dataDetail.billing.tax.toLocaleString(
                        navigator.language,
                        { minimumFractionDigits: 2 }
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Service Fee:</td>
                  <td className={classes.detail}>
                    Rp{' '}
                    {dataDetail.billing &&
                      dataDetail.billing.service.toLocaleString(
                        navigator.language,
                        { minimumFractionDigits: 2 }
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Voucher Amount:</td>
                  <td className={classes.detail}>
                    Rp{' '}
                    {dataDetail.billing &&
                      dataDetail.billing.voucher_amount.toLocaleString(
                        navigator.language,
                        { minimumFractionDigits: 2 }
                      )}
                  </td>
                </tr>
                <tr>
                  <td>Total Pembayaran:</td>
                  <td className={classes.detail} style={{ fontWeight: '700' }}>
                    Rp{' '}
                    {dataDetail.billing &&
                      (dataDetail.billing.total_price).toLocaleString(
                        navigator.language,
                        { minimumFractionDigits: 2 }
                      )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Drawer>
      {/* DIALOG FOR CREATE SERIAL NUMBER */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setOpenDialog(false);
        }}
        open={openDialog}>
        <DialogTitle id="form-dialog-title">Masukkan Serial Number</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                disabled={selectedData && selectedData.jom_sn != null}
                id="open_at"
                label="Serial Number"
                margin="normal"
                name="open"
                onChange={v => setSerialNumber(v.target.value)}
                placeholder="Masukkan serial number"
                value={serialNumber}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={selectedData && selectedData.jom_sn != null}
            onClick={() => {
              setAlertValidation(true);
            }}
            variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* ALERT FOR ERROR */}
      <Snackbar
        autoHideDuration={3000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{messageSnackbar}</span>}
        onClose={() => setAlert(false)}
        open={alertt}
        TransitionComponent={Slide}
      />

      {/* DIALOG FOR VALIDATION SETUP */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setAlertValidation(false);
        }}
        open={alertValidation}>
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Pastikan Serial Number Benar (saat klik submit user langsung menerima
          email)
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setAlertValidation(false);
            }}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={() => {
              insertSN();
            }}
            variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR UPLOAD PHOTOS */}
      <Dialog maxWidth="lg" open={showDialogUploadPhotos}>
        <DialogContent>
          <Grid container style={{ width: '1000px' }}>
            <Grid className="w-100" item>
              <div className="d-flex flex-column pt-3">
                <h5 className="pb-3">Upload Photo Preview</h5>
                <div className="image-list-wrapper mb-3">
                  {previewList && previewList.length > 0
                    ? previewList.map(v => {
                      return (
                        <div className="image-item">
                          <img
                            className="image"
                            onClick={e => onClickImagePreview(v.url_photo)}
                            src={v.url_photo}
                          />
                          <div
                            className="delete-wrapper p-2"
                            onClick={onClickDeleteImagePreview(v)}>
                            <DeleteIcon />
                          </div>
                        </div>
                      );
                    })
                    : null}
                </div>
                {!doUpload ? (
                  <DropzoneArea
                    acceptedFiles={['image/*']}
                    dropzoneText="Drag and Drop Image here"
                    filesLimit={20}
                    onChange={handleChangeUploadImage}
                    showPreviewsInDropzone
                  />
                ) : null}
                {doUpload ? (
                  <UploadProgress
                    token={accountData.token}
                    transaction={selectedTransaction}
                  />
                ) : null}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={doUpload}
            onClick={e => {
              props.resetImage();
              setShowDialogUploadPhotos(false);
              setSelectedPhotos([]);
            }}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={doUpload}
            onClick={handleUploadImage}
            variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showDialogImage}>
        <div className="image-wrap">
          <img className="dialog-image" src={selectedImage} />
          <div className="close-wrap" onClick={e => setShowDialogImage(false)}>
            <CloseIcon />
          </div>
        </div>
      </Dialog>
      <Dialog open={showDialogDeleteImage}>
        <DialogTitle>Hapus Image</DialogTitle>
        <DialogContent>Yakin untuk menghapus foto ini?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={e => setShowDialogDeleteImage(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleDeleteImage}
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <style jsx>
        {`
          .image-list-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .image-item {
            position: relative;
          }
          .image {
            width: 200px;
            height: 100px;
            object-fit: cover;
          }
          .image:hover {
            opacity: 0.5;
          }
          .delete-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            background-color: white;
            border-radius: 50%;
            padding: 0.1rem;
            margin: 8px;
          }
          .dialog-image {
            object-fit: contain;
          }
          .image-wrap {
            width: 100%;
            height: max-content;
            display: flex;
            position: relative;
            cursor: pointer;
          }
          .close-wrap {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.25rem;
            background-color: white;
            border-radius: 50%;
            margin-top: 8px;
            margin-right: 8px;
          }
        `}
      </style>
    </div>
  );
};

const mapStateToProps = state => ({
  url_image: state.UploadFile.url_image,
  files: state.UploadFile.fileProgress,
  done_upload: state.UploadFile.done_upload
});

const mapDispatchToProps = dispatch => ({
  setUploadFile: files => dispatch(setUploadFile(files)),
  setToken: token => dispatch(setToken(token)),
  setTransaction: trx => dispatch(setTransaction(trx)),
  checkStore: () => dispatch(checkStore()),
  setDoneUpload: data => dispatch(setDoneUpload(data)),
  resetImage: () => dispatch(resetImage())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transactions);
