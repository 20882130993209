import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from '../../util/Service';
import Select from '../../components/Select';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Snackbar,
  Slide,
  Drawer,
  Typography,
  Divider
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker
} from '@material-ui/pickers';
import { API } from 'constants/api';
import moment from 'moment';
import Widget from '../../components/Widget';
import MyTable from '../../components/MyTable';
import MySelect from '../../components/Select';
import {
  ConfirmationNumberOutlined,
  Event,
  ChevronLeft,
  ChevronRight
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  detail: {
    fontWeight: '600',
    textAlign: 'right'
  },
  drawer: {
    width: '50%',
    flexShrink: 0
  },
  drawerPaper: {
    width: '50%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  buttonAdd: {
    margin: theme.spacing(3)
  }
}));

const Reservation = props => {
  const classes = useStyles();
  const theme = useTheme();

  const fields = [
    'Kode Voucher',
    'Judul',
    'Jumlah',
    'Tipe',
    'Nilai',
    'Berlaku Sampai'
  ];
  const fieldData = [
    'code',
    'title',
    'quantity',
    'type',
    'value',
    'valid_until'
  ];

  // SHOWING SNACKBAR ERROR
  const [alertt, setAlert] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState('');

  // state
  const [form, setForm] = useState({
    voucher: ''
  });
  const [dataVoucher, setDataVoucher] = useState(null);
  const [isVoucher, setIsVoucher] = useState(false);
  const [dataStudio, setDataStudio] = useState([]);
  const [selectedStudio, setSelectedStudio] = useState(null);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [token, setToken] = useState(null);
  const [bookingDuration, setBookingDuration] = useState(0);
  const [schedule, setSchedule] = useState(null);
  const [isCanClearSelection, setIsCanClearSelection] = useState(false);
  const [dialogConfirmOrder, setDialogConfirmOrder] = useState(false);
  const [showingSchedulesTime, setShowingSchedulesTime] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [voucherList, setVoucherList] = useState(null);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedVoucherType, setSelectedVoucherType] = useState(null);

  const [formVoucher, setFormVoucher] = useState({
    id: null,
    code: '',
    title: '',
    type: '',
    value: '0',
    quantity: '0',
    valid_until: null
  });

  // COMPONENTDIDMOUNT
  useEffect(() => {
    axios({
      method: 'GET',
      url: `transaction/check-voucher?voucher=`
    })
      .then(response => {
        handleShowSnackbar(true, 'Silahkan isi form voucher');
      })
      .catch(error => {
        handleShowSnackbar(true, 'Silahkan isi form voucher');
      });

    const tempToken = JSON.parse(localStorage.getItem('currentUser'));
    if (tempToken) {
      setToken(tempToken.token.token);
    } else {
      alert('Sesi anda sudah berakhir, harap login kembali!');
      localStorage.removeItem('token');
      localStorage.removeItem('currentUser');
      window.location.href = '/sign-in';
    }
    getVoucherList();
  }, []);

  useEffect(() => {
    if (schedule) {
      const tempSchedule = schedule.slice(0, schedule.length);
      let isSelected = false;
      tempSchedule.map(v => {
        if (v.is_checked && v.is_checked === true) {
          isSelected = true;
        }
      });
      setIsCanClearSelection(isSelected);
    }
  }, [schedule]);

  // HANDLE
  // CHECK VOUCHER AVAILABLE
  const handleGetVoucher = async () => {
    setIsVoucher(false);
    setBookingDuration(0);
    setSchedule(null);
    if (form.voucher.length == 0) {
      handleShowSnackbar(true, 'Form tidak boleh kosong!');
      return;
    }

    const voucherApi = await fetch(
      `${API}/transaction/check-voucher?voucher=${form.voucher}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    const res = await voucherApi.json();

    if (res && res.response_code === '00') {
      const { data } = res;
      setBookingDuration(data.detail.duration);
      setDataVoucher(data);
      const studioApi = await fetch(
        `${API}/transaction/reservation/studio?voucher=${form.voucher}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      const studioRes = await studioApi.json();

      if (studioRes && studioRes.response_code === '00') {
        parsingStudio(studioRes.data);
        setIsVoucher(true);
      } else {
        handleShowSnackbar(
          true,
          'Voucher tidak ditemukan di studio manapun. Silahkan isi kode voucher yang lain'
        );
      }
    } else {
      if (res.response_code === '05') {
        handleShowSnackbar(true, res.message);
        return;
      }
      handleShowSnackbar(
        true,
        'Voucher tidak ditemukan. Silahkan isi kode voucher yang lain'
      );
    }
  };

  // GET SHCEDULE
  const handleGetSchedules = async () => {
    const checkScheduleApi = await fetch(
      `${API}/transaction/reservation/studio/schedule?studio=${
        selectedStudio.id
      }&date=${moment(selectedDate).format('YYYY-MM-DD')}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    const checkScheduleRes = await checkScheduleApi.json();

    if (checkScheduleRes.response_code === '00') {
      if (
        checkScheduleRes &&
        checkScheduleRes.data &&
        checkScheduleRes.data.schedule
      ) {
        if (checkScheduleRes.data.schedule.length > 0) {
          setSchedule(checkScheduleRes.data.schedule[0].times);
        }
      } else {
        handleShowSnackbar(true, 'Jadwal kosong');
      }
    } else {
      handleShowSnackbar(true, 'Gagal mendapatkan jadwal. Coba lagi.');
    }
  };

  // GET VOUCHER LIST
  const getVoucherList = async () => {
    setVoucherList([]);
    const api = await fetch(`${API}/vouchers`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      if (res.data.length === 0) {
        // Handle empty voucher
      } else {
        setVoucherList(res.data);
      }
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const handleChangeForm = name => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleSelectedStudio = studio => {
    setSelectedStudio(studio);
    setSchedule(null);
  };

  const handleShowSnackbar = (status, message) => {
    setMessageSnackbar(message);
    setAlert(status);
  };

  // PARSING
  const parsingStudio = data => {
    if (data != null) {
      const newData = data.map(v => {
        return {
          ...v,
          label: v.name,
          value: v.id
        };
      });

      setDataStudio(newData);
      setSelectedStudio(newData[0]);
    }
  };

  const clearScheduleSelection = () => {
    const tempSchedule = schedule.slice(0, schedule.length);
    tempSchedule.map(v => {
      v.is_checked = false;
    });
    setSchedule(tempSchedule);
  };

  const handleScheduleClicked = position => {
    const bookingCount = bookingDuration / 5 + 1;
    let isCanUpdateState = true;

    let tempSchedule = schedule.slice(0, schedule.length);
    tempSchedule.map((v, index) => {
      v.is_checked = false;
    });

    if (position < schedule.length - (bookingCount - 1)) {
      for (let i = 0; i < bookingCount; i++) {
        let tempIndex = position + i;
        tempSchedule.map((v, index) => {
          if (index === tempIndex) {
            if (v.status === 'available') {
              v.is_checked = true;
            } else {
              isCanUpdateState = false;
            }
          }
        });
      }
      if (isCanUpdateState) {
        setSchedule(tempSchedule);
      } else {
        tempSchedule.map(v => {
          v.is_checked = false;
        });
        setIsCanClearSelection(false);
        handleShowSnackbar(
          true,
          'Jadwal tidak bisa di booking. Mohon pilih jadwal lain'
        );
      }
    } else {
      tempSchedule.map(v => {
        v.is_checked = false;
      });
      setIsCanClearSelection(false);
      handleShowSnackbar(
        true,
        'Jadwal tidak bisa di booking. Mohon pilih jadwal lain'
      );
    }
  };

  const populateScheduleData = () => {
    let result = [];
    if (schedule && schedule.length > 0) {
      schedule.map(v => {
        if (v.is_checked) {
          const item = v.time;
          result = [...result, item];
        }
      });
    }
    return result;
  };

  const [onBooking, setOnBooking] = useState(false);

  const handleBookSchedule = async () => {
    if (form.voucher === null || form.voucher.length === 0) {
      handleShowSnackbar('Form tidak boleh kosong');
      return;
    }
    setOnBooking(true);
    const bookScheduleBody = {
      voucher: form.voucher,
      studio: selectedStudio.id,
      date: moment(selectedDate).format('YYYY-MM-DD'),
      schedules: populateScheduleData()
    };

    setShowingSchedulesTime(populateScheduleData());

    let url = '/transaction/reservation/studio/book';

    if (isReschedule) {
      url = '/transaction/reservation/studio/reschedule';
    }

    const bookScheduleApi = await fetch(`${API}${url}`, {
      method: isReschedule ? 'PUT' : 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bookScheduleBody)
    });

    const bookScheduleRes = await bookScheduleApi.json();

    if (bookScheduleRes.response_code === '00') {
      if (isReschedule) {
        handleShowSnackbar(true, 'Berhasil reschedule jadwal');
        setForm({ ...form, ['voucher']: '' });
        setSchedule(null);
        setIsVoucher(false);
        setBookingDuration(0);
        setOnBooking(false);
        return;
      }
      // Success Booking a Schedule
      handleShowSnackbar(true, 'Berhasil booking');
      // setForm({ ...form, ['voucher']: '' });
      // setSchedule(null);
      // setIsVoucher(false);
      // setBookingDuration(0);
      // setOnBooking(false);
      setDialogConfirmOrder(true);
    } else {
      handleShowSnackbar(true, bookScheduleRes.message);
      setOnBooking(false);
    }
  };

  // HANDLE CANCEL BOOK
  const handleCancelBookSchedule = async () => {
    const bookScheduleBody = {
      voucher: form.voucher,
      studio: selectedStudio.id,
      date: moment(selectedDate).format('YYYY-MM-DD'),
      schedules: populateScheduleData()
    };

    const bookScheduleApi = await fetch(
      `${API}/transaction/reservation/studio/book/cancel`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookScheduleBody)
      }
    );

    const cancelScheduleRes = await bookScheduleApi.json();

    if (cancelScheduleRes.response_code === '00') {
      // Success Cancel Booking a Schedule
      handleShowSnackbar(true, 'Berhasil Cancel Booking');
      setForm({ ...form, ['voucher']: '' });
      setSchedule(null);
      setIsVoucher(false);
      setBookingDuration(0);
      setOnBooking(false);
      setDialogConfirmOrder(false);
    } else {
      handleShowSnackbar(true, cancelScheduleRes.message);
      setOnBooking(false);
    }
  };

  const handleConfirmBookSchedule = async () => {
    const bookScheduleBody = {
      voucher: form.voucher,
      studio: selectedStudio.id,
      date: moment(selectedDate).format('YYYY-MM-DD'),
      schedules: populateScheduleData()
    };

    const bookScheduleApi = await fetch(`${API}/transaction/reservation`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bookScheduleBody)
    });

    const confirmScheduleRes = await bookScheduleApi.json();

    if (confirmScheduleRes.response_code === '00') {
      // Success Confirm Booking a Schedule
      handleShowSnackbar(true, 'Berhasil Konfirmas Booking Order');
      setForm({ ...form, ['voucher']: '' });
      setSchedule(null);
      setIsVoucher(false);
      setBookingDuration(0);
      setOnBooking(false);
      setDialogConfirmOrder(false);
    } else {
      handleShowSnackbar(true, confirmScheduleRes.message);
      setOnBooking(false);
    }
  };

  // COMPONENTS
  const getVoucher = () => {
    return (
      <Grid
        item
        xs={12}
        style={{ float: 'left', marginRight: '15px', width: '100%' }}>
        <Grid item xs={3} style={{ float: 'left', marginRight: '15px' }}>
          <InputLabel htmlFor="store">
            <b>Masukkan Voucher</b>
          </InputLabel>
          <TextField
            variant="outlined"
            margin="dense"
            value={form.voucher}
            onChange={handleChangeForm('voucher')}
            fullWidth
            style={{ marginTop: '0px' }}
          />
        </Grid>
        <Button
          onClick={handleGetVoucher}
          color="primary"
          variant="contained"
          style={{ marginTop: '25px' }}>
          Pilih
        </Button>
      </Grid>
    );
  };

  const getFormStudioDate = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          float: 'left',
          marginRight: '15px',
          marginTop: '20px',
          width: '100%'
        }}>
        <Grid
          item
          xs={3}
          style={{ float: 'left', marginRight: '15px', width: '200px' }}>
          <InputLabel htmlFor="store">
            <b>Pilih Studio</b>
          </InputLabel>
          <Select
            name="Studio"
            label="Studio"
            placeholder="Pilih Studio"
            options={dataStudio}
            value={selectedStudio}
            onChange={handleSelectedStudio}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{ float: 'left', marginRight: '15px', width: '150px' }}>
          <InputLabel htmlFor="store">
            <b>Pilih Tanggal</b>
          </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={selectedDate}
              onChange={date => {
                setSchedule(null);
                handleDateChange(date);
              }}
              minDate={new Date()}
              format="dd/MM/yyyy"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Button
          onClick={handleGetSchedules}
          color="primary"
          variant="contained"
          style={{ marginTop: '25px' }}>
          Submit
        </Button>
      </Grid>
    );
  };

  const renderSchedule = () => {
    if (schedule) {
      if (schedule.length > 0) {
        return (
          <Grid
            item
            xs={12}
            style={{
              float: 'left',
              marginRight: '15px',
              marginTop: '20px',
              width: '100%'
            }}>
            <Grid
              item
              xs={12}
              style={{ float: 'left', marginRight: '15px', width: '100%' }}>
              <div className="d-flex align-items-center justify-content-between">
                <span style={{ fontSize: '14px', verticalAlign: 'middle' }}>
                  <b>Pilih Jadwal</b>
                </span>
                <Button
                  variant="contained"
                  disabled={!isCanClearSelection}
                  onClick={e => clearScheduleSelection()}>
                  Hapus Jadwal Terpilih
                </Button>
              </div>
              <Grid
                container
                style={{ border: '1px solid #d8d8d8' }}
                className="mt-3 pb-3">
                {schedule.map((v, index) => (
                  <Grid
                    item
                    xs={6}
                    md={2}
                    sm={3}
                    key={index}
                    style={{
                      position: 'relative',
                      width: '100%',
                      minHeight: '1px',
                      paddingRight: '15px',
                      paddingLeft: '15px',
                      flexBasis: 'auto'
                    }}>
                    <Button
                      variant={
                        v.status !== 'available' ? 'contained' : 'outlined'
                      }
                      style={{
                        width: '100%',
                        marginTop: '1rem',
                        backgroundColor: v.is_checked ? '#5A622D' : '',
                        color: v.is_checked ? 'white' : 'black',
                        cursor:
                          v.status !== 'available'
                            ? 'not-allowed'
                            : onBooking
                            ? 'not-allowed'
                            : 'pointer'
                      }}
                      disabled={v.status !== 'available'}
                      onClick={e => handleScheduleClicked(index)}>
                      {v.time}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className="mt-3"
              style={{ float: 'left', marginRight: '15px', width: '100%' }}>
              <Button
                variant="contained"
                disabled={!isCanClearSelection}
                onClick={e => handleBookSchedule()}>
                {isReschedule ? 'Reschedule Jadwal' : 'Booking Jadwal'}
              </Button>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid
            item
            xs={12}
            style={{
              float: 'left',
              marginRight: '15px',
              marginTop: '20px',
              width: '100%'
            }}>
            <Grid
              container
              style={{ border: '1px solid #d8d8d8' }}
              className="mt-3 d-flex align-content-center justify-content-center p-3">
              <span>Jadwal Kosong</span>
            </Grid>
          </Grid>
        );
      }
    } else {
      return null;
    }
  };

  const handleClickMenu = pos => {
    setIsReschedule(pos === 2 ? true : false);
    setIsVoucher(false);
    setSelectedMenu(pos);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenForEdit = id => {
    getVoucherById(id);
    setOpen(true);
  };

  const deleteVoucher = async id => {
    const api = await fetch(`${API}/voucher?id=${id}`, {
      method: 'DELETE'
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      handleShowSnackbar(true, 'Berhasil hapus voucher');
      getVoucherList();
      handleDrawerClose();
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);

    setFormVoucher({
      id: null,
      code: '',
      title: '',
      type: '',
      value: '0',
      quantity: '0',
      valid_until: null
    });

    setSelectedDateVoucher(new Date());
    setSelectedTimeVoucher(new Date());
    setSelectedVoucherType(null);
  };

  const manageVoucher = async () => {
    if (validateVoucherField()) {
      if (formVoucher.id) {
        // Edit
        updateVoucher();
      } else {
        // Create new voucher
        createNewVoucher();
      }
    } else {
      handleShowSnackbar(true, 'Harap isi field yang diperlukan');
      return;
    }
  };

  const createNewVoucher = async () => {
    const body = {
      code: formVoucher.code,
      title: formVoucher.title,
      type: selectedVoucherType.value,
      value: parseInt(formVoucher.value),
      quantity:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? null
          : parseInt(formVoucher.quantity),
      valid_until:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? formVoucher.valid_until
          : null
    };
    const api = await fetch(`${API}/voucher`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      getVoucherList();
      handleDrawerClose();
    }
  };

  const getVoucherById = async id => {
    const api = await fetch(`${API}/voucher?id=${id}`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setFormVoucher({
        ...formVoucher,
        ...res.data,
        quantity: res.data.quantity ? `${res.data.quantity}` : '0',
        value: `${res.data.value}`
      });
      if (res.data.valid_until) {
        let date = res.data.valid_until.split(' ')[0];
        let year = date.split('-')[0];
        let month = date.split('-')[1];
        let day = date.split('-')[2];
        let time = res.data.valid_until.split(' ')[1];
        let hour = time.split(':')[0];
        let minute = time.split(':')[1];
        let second = time.split(':')[2];

        let newDate = new Date(year, month, day, hour, minute, second, 0);
        setSelectedDateVoucher(newDate);
        setSelectedTimeVoucher(newDate);
      }
      voucherType.map(v => {
        if (v.value === res.data.type) {
          setSelectedVoucherType(v);
        }
      });
    }
  };

  const updateVoucher = async () => {
    const body = {
      id: formVoucher.id,
      code: formVoucher.code,
      title: formVoucher.title,
      type: selectedVoucherType.value,
      value: parseInt(formVoucher.value),
      quantity:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? null
          : parseInt(formVoucher.quantity),
      valid_until:
        formVoucher.quantity === '0' || formVoucher.quantity.length === 0
          ? formVoucher.valid_until
          : null
    };

    const api = await fetch(`${API}/voucher`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });

    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }

    const res = await api.json();
    if (res.response_code === '00') {
      handleShowSnackbar(true, 'Berhasil update voucher');
      getVoucherList();
      handleDrawerClose();
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const handleSelectedVoucherType = value => {
    setSelectedVoucherType(value);
  };

  const handleChangeFormVoucher = name => e => {
    setFormVoucher({
      ...formVoucher,
      [name]: e.target.value
    });
  };

  const handleOnNextPage = () => setPage(page + 1);

  const handleOnBackPage = () => setPage(page - 1);

  const voucherType = [
    {
      label: 'Percent',
      value: 'percent'
    },
    {
      label: 'Fixed',
      value: 'fixed'
    }
  ];

  const [selectedDateVoucher, setSelectedDateVoucher] = useState(new Date());
  const [selectedTimeVoucher, setSelectedTimeVoucher] = useState(new Date());

  const handleChangeDateTime = datetime => e => {
    if (datetime === 'date') {
      if (e) {
        setSelectedDateVoucher(e);
        setFormVoucher({
          ...formVoucher,
          valid_until: `${moment(e).format('YYYY-MM-DD')} ${moment(
            selectedTimeVoucher
          ).format('HH:mm:ss')} WIB`
        });
      } else {
        setSelectedDateVoucher(new Date());
        setSelectedTimeVoucher(new Date());
        setFormVoucher({
          ...formVoucher,
          valid_until: null
        });
      }
    } else {
      if (e) {
        setSelectedTimeVoucher(e);
        setFormVoucher({
          ...formVoucher,
          valid_until: `${moment(selectedDateVoucher).format(
            'YYYY-MM-DD'
          )} ${moment(e).format('HH:mm:ss')} WIB`
        });
      } else {
        setSelectedDateVoucher(new Date());
        setSelectedTimeVoucher(new Date());
        setFormVoucher({
          ...formVoucher,
          valid_until: null
        });
      }
    }
  };

  const validateVoucherField = () => {
    let quantityOrValidUntil = false;
    if (formVoucher.quantity !== '0' && formVoucher.quantity.length > 0) {
      quantityOrValidUntil =
        formVoucher.quantity !== '0' && formVoucher.quantity.length > 0;
    } else {
      quantityOrValidUntil =
        formVoucher.valid_until && formVoucher.valid_until !== null;
    }
    return (
      formVoucher.code &&
      formVoucher.code.length > 0 &&
      formVoucher.title &&
      formVoucher.title.length > 0 &&
      selectedVoucherType &&
      quantityOrValidUntil
    );
  };

  const handleResetLimit = e => {
    setFormVoucher({
      ...formVoucher,
      quantity: '0',
      valid_until: null
    });
  };

  // reschedule
  const getVoucherReschedule = () => {
    return (
      <Grid
        item
        xs={12}
        style={{ float: 'left', marginRight: '15px', width: '100%' }}>
        <Grid item xs={3} style={{ float: 'left', marginRight: '15px' }}>
          <InputLabel htmlFor="store">
            <b>Masukkan Voucher</b>
          </InputLabel>
          <TextField
            variant="outlined"
            margin="dense"
            value={form.voucher}
            onChange={handleChangeForm('voucher')}
            fullWidth
            style={{ marginTop: '0px' }}
          />
        </Grid>
        <Button
          onClick={handleGetVoucher}
          color="primary"
          variant="contained"
          style={{ marginTop: '25px' }}>
          Cek
        </Button>
      </Grid>
    );
  };

  const checkStudioForVoucher = async () => {
    const studioApi = await fetch(
      `${API}/transaction/reservation/studio?voucher=${form.voucher}`,
      {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    const studioRes = await studioApi.json();

    if (studioRes && studioRes.response_code === '00') {
      parsingStudio(studioRes.data);
      setIsVoucher(true);
    } else {
      handleShowSnackbar(
        true,
        'Voucher tidak ditemukan di studio manapun. Silahkan isi kode voucher yang lain'
      );
    }
  };

  const renderRescheduleDateAndStudio = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          float: 'left',
          marginRight: '15px',
          marginTop: '20px',
          width: '100%'
        }}>
        <Grid
          item
          xs={3}
          style={{ float: 'left', marginRight: '15px', width: '200px' }}>
          <InputLabel htmlFor="store">
            <b>Pilih Studio</b>
          </InputLabel>
          <Select
            name="Studio"
            label="Studio"
            placeholder="Pilih Studio"
            options={dataStudio}
            value={selectedStudio}
            onChange={handleSelectedStudio}
          />
        </Grid>
        <Grid
          item
          xs={3}
          style={{ float: 'left', marginRight: '15px', width: '150px' }}>
          <InputLabel htmlFor="store">
            <b>Pilih Tanggal</b>
          </InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={selectedDate}
              onChange={date => {
                setSchedule(null);
                handleDateChange(date);
              }}
              minDate={new Date()}
              format="dd/MM/yyyy"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Button
          onClick={checkStudioAvailability}
          color="primary"
          variant="contained"
          style={{ marginTop: '25px' }}>
          Submit
        </Button>
      </Grid>
    );
  };

  const checkStudioAvailability = () => {
    if (selectedStudio) {
      handleGetSchedules();
    } else {
      handleShowSnackbar(true, 'Pilih studio terlebih dahulu');
      return;
    }
  };

  const [isReschedule, setIsReschedule] = useState(false);

  const renderScreen = () => {
    if (selectedMenu === 2) {
      return (
        <Grid item xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>Reschedule</h4>
            <hr style={{ marginBottom: '30px' }} />

            {getVoucherReschedule()}
            {isVoucher && renderRescheduleDateAndStudio()}
            {renderSchedule()}
          </Card>
        </Grid>
      );
    }
    if (selectedMenu === 0) {
      return (
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>Reservasi</h4>
            <hr style={{ marginBottom: '30px' }} />

            {getVoucher()}
            {isVoucher && getFormStudioDate()}
            {renderSchedule()}
          </Card>
        </Grid>
      );
    }
    if (selectedMenu === 1) {
      return (
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>Voucher</h4>
            <hr style={{ marginBottom: '30px' }} />

            <MyTable
              id="id"
              data={voucherList}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deleteVoucher}
              onEdit={handleDrawerOpenForEdit}
              titleAdd="Tambah Voucher"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage}
            />
          </Card>
        </Grid>
      );
    }
  };

  return (
    <div
      className={classes.root}
      style={{ pointerEvents: onBooking ? 'none' : 'inherit' }}>
      <Grid container spacing={4}>
        <Grid
          item
          sm={6}
          xs={12}
          style={{
            color: selectedMenu === 0 ? '#3c3835' : '#bcbcbc',
            cursor: 'pointer'
          }}
          onClick={e => handleClickMenu(0)}>
          <Widget styleName="p-3 py-4">
            <div className="media align-items-center flex-nowrap my-auto mx-auto">
              <div className="mr-lg-2 mr-1">
                {<ConfirmationNumberOutlined />}
              </div>
              <div className="media-body">
                <h2 className="jr-font-weight-bold mb-0">{`Reservation`}</h2>
              </div>
            </div>
          </Widget>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          style={{
            color: selectedMenu === 2 ? '#3c3835' : '#bcbcbc',
            cursor: 'pointer'
          }}
          onClick={e => handleClickMenu(2)}>
          <Widget styleName="p-3 py-4">
            <div className="media align-items-center flex-nowrap my-auto mx-auto">
              <div className="mr-lg-2 mr-1">
                {<ConfirmationNumberOutlined />}
              </div>
              <div className="media-body">
                <h2 className="jr-font-weight-bold mb-0">{`Reschedule`}</h2>
              </div>
            </div>
          </Widget>
        </Grid>
        {/* <Grid
          item
          sm={4}
          xs={12}
          style={{
            color: selectedMenu === 1 ? '#3c3835' : '#bcbcbc',
            cursor: 'pointer'
          }}
          onClick={e => handleClickMenu(1)}>
          <Widget styleName="p-3 py-4">
            <div className="media align-items-center flex-nowrap my-auto mx-auto">
              <div className="mr-lg-2 mr-1">{<Event />}</div>
              <div className="media-body">
                <h2 className="jr-font-weight-bold mb-0">{`Voucher`}</h2>
              </div>
            </div>
          </Widget>
        </Grid> */}
      </Grid>
      <Grid container spacing={4}>
        {renderScreen()}
      </Grid>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}>
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={() => {
              handleDrawerClose();
            }}>
            {theme.direction === 'rtl' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
          <Typography variant="h5">
            {formVoucher.id == null ? 'Simpan' : 'Ubah'} Informasi Voucher
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', right: '20px', top: '-10px' }}
            className={classes.buttonAdd}
            onClick={() => manageVoucher()}>
            {formVoucher.id == null ? 'Simpan' : 'Ubah'}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            <TextField
              id="code"
              label="Kode Voucher"
              value={formVoucher.code}
              onChange={handleChangeFormVoucher('code')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="title"
              label="Judul"
              value={formVoucher.title}
              onChange={handleChangeFormVoucher('title')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <MySelect
              isMulti={false}
              name="type"
              placeholder="Pilih Tipe Voucher"
              options={voucherType}
              value={selectedVoucherType}
              onChange={handleSelectedVoucherType}
              fullWidth
            />
            <TextField
              id="value"
              label="Nilai"
              type="number"
              value={formVoucher.value}
              onChange={handleChangeFormVoucher('value')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="quantity"
              label="Jumlah"
              type="number"
              disabled={formVoucher.valid_until}
              value={formVoucher.quantity}
              onChange={handleChangeFormVoucher('quantity')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <div className="mt-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  inputVariant="outlined"
                  clearable
                  label="Berlaku Hingga"
                  value={selectedDateVoucher}
                  disabled={
                    formVoucher.quantity !== '0' &&
                    formVoucher.quantity.length > 0
                  }
                  minDate={new Date()}
                  format="dd MMMM yyyy"
                  className="w-50 pr-1"
                  onChange={handleChangeDateTime('date')}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  ampm={false}
                  openTo="hours"
                  clearable
                  views={['hours', 'minutes', 'seconds']}
                  format="HH:mm:ss"
                  disabled={
                    formVoucher.quantity !== '0' &&
                    formVoucher.quantity.length > 0
                  }
                  label="Jam"
                  className="w-50 pl-1"
                  inputVariant="outlined"
                  value={selectedTimeVoucher}
                  onChange={handleChangeDateTime('time')}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button onClick={handleResetLimit} color="primary" className="mt-3">
              Reset Batas
            </Button>
            <Typography>
              *Batas ditentukan berdasarkan jumlah/tanggal tertentu.
            </Typography>
          </Grid>
        </Grid>
      </Drawer>

      {/* ALERT FOR ERROR */}
      <Snackbar
        open={alertt}
        onClose={() => setAlert(false)}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        autoHideDuration={3000}
        message={<span id="message-id">{messageSnackbar}</span>}
      />

      {/* MODAL FOR SHOWING DATA USER CONFIRM ORDER*/}
      {dataVoucher != null && (
        <Dialog
          open={dialogConfirmOrder}
          maxWidth="lg"
          onClose={() => {
            setDialogConfirmOrder(false);
          }}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Detail Reservasi</DialogTitle>
          <DialogContent>
            <h5>Voucher : {form.voucher}</h5>
            <Grid container style={{ width: '1000px', marginTop: '20px' }}>
              <Grid item xs={6} style={{ paddingRight: '20px' }}>
                <Grid item xs={12}>
                  <h5>Informasi Data User</h5>
                  <table
                    className="table"
                    style={{ backgroundColor: '#f7f7f7' }}>
                    <tbody>
                      <tr>
                        <td>Nama:</td>
                        <td className={classes.detail}>{dataVoucher.name}</td>
                      </tr>
                      <tr>
                        <td>No. Telepon:</td>
                        <td className={classes.detail}>{dataVoucher.msisdn}</td>
                      </tr>
                      <tr>
                        <td>Email:</td>
                        <td className={classes.detail}>{dataVoucher.email}</td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid item xs={12}>
                  <h5>Informasi Penerima Voucher</h5>
                  <table
                    className="table"
                    style={{ backgroundColor: '#f7f7f7' }}>
                    <tbody>
                      <tr>
                        <td>Nama:</td>
                        <td className={classes.detail}>
                          {dataVoucher.voucher &&
                            dataVoucher.voucher.receiver_name}
                        </td>
                      </tr>
                      <tr>
                        <td>No. Telepon:</td>
                        <td className={classes.detail}>
                          {dataVoucher.voucher &&
                            dataVoucher.voucher.receiver_phone}
                        </td>
                      </tr>
                      <tr>
                        <td>Valid hingga:</td>
                        <td className={classes.detail}>
                          {dataVoucher.voucher &&
                            dataVoucher.voucher.valid_until}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '20px' }}>
                <h5>Detail Reservasi</h5>
                <table className="table" style={{ backgroundColor: '#f7f7f7' }}>
                  <tbody>
                    <tr>
                      <td>Nama Produk</td>
                      <td className={classes.detail}>
                        {dataVoucher.detail && dataVoucher.detail.package_title}
                      </td>
                    </tr>
                    <tr>
                      <td>Jumlah Orang</td>
                      <td className={classes.detail}>
                        {dataVoucher.person} orang
                      </td>
                    </tr>
                    <tr>
                      <td>Store:</td>
                      <td className={classes.detail}>
                        {dataVoucher.store && dataVoucher.store.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Studio:</td>
                      <td className={classes.detail}>
                        {selectedStudio && selectedStudio.name}
                      </td>
                    </tr>
                    <tr>
                      <td>Tanggal Booking:</td>
                      <td className={classes.detail}>
                        {selectedDate &&
                          moment(selectedDate).format('DD MMMM YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <td>Waktu:</td>
                      <td className={classes.detail}>
                        {showingSchedulesTime &&
                          `${showingSchedulesTime[0]} - ${
                            showingSchedulesTime[
                              showingSchedulesTime.length - 1
                            ]
                          }`}
                      </td>
                    </tr>
                    <tr style={{ borderTop: '2px solid #adadad' }}>
                      <td>Sub Total:</td>
                      <td className={classes.detail}>
                        Rp{' '}
                        {dataVoucher.billing &&
                          dataVoucher.billing.price.toLocaleString(
                            navigator.language,
                            { minimumFractionDigits: 2 }
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Add on:</td>
                      <td className={classes.detail}>
                        Rp{' '}
                        {dataVoucher.billing &&
                          dataVoucher.billing.add_on.toLocaleString(
                            navigator.language,
                            { minimumFractionDigits: 2 }
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Pajak:</td>
                      <td className={classes.detail}>
                        Rp{' '}
                        {dataVoucher.billing &&
                          dataVoucher.billing.tax.toLocaleString(
                            navigator.language,
                            { minimumFractionDigits: 2 }
                          )}
                      </td>
                    </tr>
                    <tr style={{ borderTop: '2px solid #adadad' }}>
                      <td>
                        <b>Total:</b>
                      </td>
                      <td
                        className={classes.detail}
                        style={{ fontWeight: '700' }}>
                        Rp{' '}
                        {dataVoucher.billing &&
                          dataVoucher.billing.total_price.toLocaleString(
                            navigator.language,
                            { minimumFractionDigits: 2 }
                          )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelBookSchedule} color="primary">
              Cancel Order
            </Button>
            <Button
              onClick={handleConfirmBookSchedule}
              color="primary"
              variant="contained">
              Konfirmasi Order
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Reservation;
