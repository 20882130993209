import React, { useEffect, useState } from 'react';
import axios from '../../util/Service';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
  root: { color: '#fff', }
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: { padding: theme.spacing(4) },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  buttonAdd: { margin: theme.spacing(3) },
  detail: {
    fontWeight: '600',
    textAlign: 'right',
  }
})
);

const ConfirmPayment = () => {
  const classes = useStyles();
  const theme = useTheme();

  // const fields = ['ID Transaksi', 'Tanggal Transaksi', 'Nama', 'Email', 'Jumlah', 'Tanggal Booking', 'Status'];
  // const fieldData = ['ID_Transaksi', 'Waktu_Transaksi', 'Nama', 'Email', 'Jumlah_Harga', 'Tanggal_Booking', 'Status_Transaksi'];

  //state
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [token, setToken] = useState(null);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  // state filter
  const [formFilterStatus, setFormFilterStatus] = useState({
    transactionDate: moment().format("YYYY-MM-DD"),
    status: 'all'
  });
  const defaultFormFilter = {
    startDate: moment().format('MM/DD/YYYY'),
    endDate: moment().format('MM/DD/YYYY'),
    showDate: `${moment().format('MM/DD/YYYY')} ~ ${moment().format('MM/DD/YYYY')}`,
  };
  const [formFilter, setFormFilter] = useState(defaultFormFilter);

  // state for export excel
  const [excelData, setExcelData] = useState([]);

  // state dialog action
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);
  const [selectedID, setSelectedID] = useState(false);

  useEffect(() => {
    const tempToken = JSON.parse(localStorage.getItem('currentUser'));
    if (tempToken) {
      setToken(tempToken.token.token);
    } else {
      alert("Sesi anda sudah berakhir, harap login kembali!");
      localStorage.removeItem("token");
      localStorage.removeItem("currentUser");
      window.location.href = "/sign-in";
    }
    getListConfirmPayment();
  }, []);

  // API
  const getListConfirmPayment = () => {
    axios({
      method: 'GET',
      url: `transaction/payment-receipts`,
      headers: { "Content-Type": "application/json" },
    }).then(response => { if (response.status === 200) { setData(response.data.data); }
    }).catch(error => { alert(error); })
  };

  const ApprovePayment = id => {
    axios({
      method: 'POST',
      url: `transaction/approve-payment?id=` + id,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    }).then(response => {
      if (response.status === 200) {
        getListConfirmPayment();
        setOpenConfirm(false);
      }
    }).catch(error => { alert(error); })
  };

  const DeclinePayment = id => {
    axios({
      method: 'POST',
      url: `transaction/decline-payment?id=` + id,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200) {
        getListConfirmPayment();
        setOpenDecline(false);
      }
    }).catch(error => { alert(error); })
  };

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">

            <h4>Konfirmasi Pembayaran</h4>
            <hr style={{ marginBottom: '30px' }} />

            {/* <Grid item xs={12} style={{marginBottom:'20px'}}>
              <Grid item xs={4} style={{float:'left',width:'225px'}}>
                <DateRangePicker startDate={formFilter.startDate} endDate={formFilter.endDate} onEvent={handleFilterDate} containerStyles={{ width: '100%' }}>
                    <TextField
                        variant="outlined"
                        name="showDate"
                        margin="dense"
                        label="Filter Range Tanggal Transaksi"
                        value={formFilter.showDate}
                        fullWidth
                        readOnly
                    />
                </DateRangePicker>
              </Grid> 
              <FormControl variant="outlined" className={classes.formControl} style={{margin:'15px',marginTop:'8px',width:'215px'}}>
                  <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                  <Select
                    label="demo-simple-select-label"
                    id="satuan"
                    value={formFilterStatus.status}
                    onChange={handleChangeFormFilter('status')}
                    style={{height:'40px'}}
                    >
                      <MenuItem value='all'>All status</MenuItem>
                      <MenuItem value='success'>Success</MenuItem>
                      <MenuItem value='waiting_for_payment'>Menunggu Pembayaran</MenuItem>
                      <MenuItem value='expired'>Expired</MenuItem>
                      <MenuItem value='cancel'>Cancel</MenuItem>
                      <MenuItem value='failed'>Failed</MenuItem>
                </Select>
              </FormControl>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilterData}
                  style={{marginTop:"10px",marginRight:'15px'}}
              >
                  Filter
              </Button>

              <ButtonExportExcel 
                csvData={excelData} 
                fileName={'Laporan Transaksi Reservasi Jonas'}
                style={{padding:'20px'}}
                />
            </Grid> */}

            <div className="wrapTable" style={{ width: '100%', overflow: 'scroll', overflowY: 'hidden' }}>
              <Table>
                <TableHead style={{ backgroundColor: '#3c3835' }}>
                  <TableRow>
                    <StyledTableCell>No</StyledTableCell>
                    <StyledTableCell>ID Transaksi</StyledTableCell>
                    <StyledTableCell style={{ minWidth: '105px' }}>Tanggal Transaksi</StyledTableCell>
                    <StyledTableCell style={{ minWidth: '125px' }}>Nama</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell style={{ minWidth: '125px' }}>Jumlah</StyledTableCell>
                    <StyledTableCell style={{ minWidth: '110px' }}>Tanggal Booking</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Resi Pembayaran</StyledTableCell>
                    <StyledTableCell style={{ minWidth: '100px' }}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.slice((page - 1) * 10, page * 10).map((v, index) => {
                    return (
                      <StyledTableRow key={v.transaction_id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{v.transaction_id}</TableCell>
                        <TableCell>{v.transaction_time}</TableCell>
                        <TableCell>{v.name}</TableCell>
                        <TableCell>{v.email}</TableCell>
                        <TableCell>{'Rp ' + v.total_price.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                        <TableCell>{v.booking_date === '' ? '' : moment(v.booking_date).format('DD MMMM YYYY')}</TableCell>
                        <TableCell><Chip color={v.status === 'waiting_confirmation' ? '' : v.status === 'decline' ? 'secondary' : 'primary'} label={v.status === 'waiting_confirmation' ? 'Menunggu Konfirmasi' : v.status === 'decline' ? 'Ditolak' : 'Dikonfirmasi'} /></TableCell>
                        <TableCell align="center"><a href={v.payment_receipt_url} target="_blank"><img src={v.payment_receipt_url} style={{ width: '50px', height: '80px', objectFit: 'contain' }} /></a></TableCell>
                        <TableCell>
                          {v.status === 'waiting_confirmation'
                            ?
                            <>
                              <DoneIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => {
                                setOpenConfirm(true);
                                setSelectedID(v.id);
                              }} />
                              <ClearIcon color="secondary" style={{ cursor: 'pointer' }} onClick={() => {
                                setOpenDecline(true);
                                setSelectedID(v.id);
                              }} />
                            </>
                            :
                            '-'}
                        </TableCell>
                      </StyledTableRow>
                    )
                  })
                  }
                </TableBody>
              </Table>
            </div>
            {<Grid item xs={12}>
              <Grid container justify="flex-end" alignItems="center">
                <Grid item>
                  {
                  Math.ceil(data.length / 10) > 0
                    ?
                    <Typography>
                      Halaman {page} dari {Math.ceil(data.length / 10)}
                    </Typography>
                    : null
                  }
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleOnBackPage}
                    disabled={page === 1}
                    aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  </IconButton>
                  <IconButton
                    onClick={handleOnNextPage}
                    disabled={page >= Math.ceil(data.length / 10)}
                    aria-label="next page"
                  >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>}
          </Card>
        </Grid>
      </Grid>

      {/* DIALOG FOR APPROVE */}
      <Dialog open={openConfirm} onClose={() => { setOpenConfirm(false); }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Anda yakin ingin mengkonfirmasi pembayaran ini?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenConfirm(false); }} color="primary">
            Tutup
        </Button>
          <Button onClick={() => {
            ApprovePayment(selectedID);
          }} color="primary" variant="contained">
            Ya
        </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR DECLINE */}
      <Dialog open={openDecline} onClose={() => { setOpenDecline(false); }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Anda yakin ingin Menolak pembayaran ini?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenDecline(false); }} color="secondary">
            Tutup
        </Button>
          <Button onClick={() => {
            DeclinePayment(selectedID);
          }} color="secondary" variant="contained">
            Ya
        </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmPayment;
