import React, { useEffect,useState } from 'react';
import { NavLink } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import axios from '../../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  Grid, 
  Card, 
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const drawerWidth = '80%';
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    },
    content: {
      flexShrink: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: '999'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    buttonAdd: {
      margin: theme.spacing(3)
    },
  })
);

const fields = ['Nama'];
const fieldData = ['name'];

const Group = () => {
  const classes = useStyles();
  const theme = useTheme();

  // state handle drawer/dialog
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  // state group
  const [data, setData] = useState([]);
  const [form,setForm] = useState({
    id: null,
    name: '',
  });

  useEffect(() => {
    getGroups();
  }, []);

  // API
  const getGroups = () => {
    axios({
      method: 'GET',
      url: `groups`,
      headers: { "Content-Type": "application/json" },
    }).then(response => { if (response.status === 200&& response.data.data != null) { setData(response.data.data); }
    }).catch(error => { alert(error); })
  };

  const AddGroup = () => {
    axios({
      method: 'POST',
      url: `group`,
      headers: { "Content-Type": "application/json" },
      data: { "name": form.name }
    }).then(response => {
      getGroups();
      handleDrawerClose();
    }).catch(error => { alert(error); })
  };

  const UpdateGroup = () => {
    axios({
      method: 'PUT',
      url: `group`,
      data: form,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      getGroups();
      handleDrawerClose();
    }).catch(error => { alert(error); })
  };

  const deleteGroup = id => {
    axios({
      method: 'DELETE',
      url: `group`,
      headers: { "Content-Type": "application/json" },
      params: { id: id }
    }).then(response => {
      getGroups();
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      name: '',
    });
  };

  // handle
  const handleDrawerOpen = () => { setOpen(true); };

  const handleDrawerOpenForEdit = id => {
    const selectedGroup = data.find(v => v.id === id);
    setForm({
      ...form,
      id: selectedGroup.id,
      name: selectedGroup.name,
    });
    handleDrawerOpen();
  };

  const handleChangeForm = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <NavLink to='/store' style={{ color:'#3c3835',marginLeft:'-10px' }}>
              <ArrowBackIcon />
            </NavLink>

            <h4>Group</h4>
            <hr style={{ marginBottom:'30px' }}/>
            
            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deleteGroup}
              onEdit={handleDrawerOpenForEdit}
              titleAdd="Tambah Group"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage}/>
          </Card>
        </Grid>
      </Grid>
      
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper, }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}> {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />} </IconButton>
          {form.id == null ? "Simpan" : "Ubah"} Informasi Group
          <Button variant="contained" color="primary" style={{ position:'absolute',right:'20px',top:'-10px' }} className={classes.buttonAdd} onClick={form.id === null ? () => AddGroup() : () => UpdateGroup()}>{form.id == null ? "Simpan" : "Ubah"}</Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={3}>
            <h5>Informasi Group</h5>
            <Grid item xs={11}>
              <TextField
                id="name"
                label="Nama Group"
                value={form.name}
                onChange={handleChangeForm('name')}
                margin="normal"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          {/* <Grid item xs={7}>
            <h5>Group Store</h5>
            <Grid item xs={12} style={{marginBottom:'25px'}}>
            </Grid>
          </Grid> */}
        </Grid>
      </Drawer>
    </div>
  );
}

export default Group;