import React from 'react';
import PropTypes from 'prop-types';
import ThousandFormat from 'react-number-format';

function ThousandFormatCustom(props) {
    const { inputRef, onChange, suffix, ...other } = props;

    // const handleSuffix = (type) => {
    //   if(typeof type === 'undefined' || type === 'currency') {
    //     return " IDR";
    //   } 
    //   return " %";
    // }
  
    return (
      <ThousandFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        // suffix={handleSuffix(suffix)}
      />
    );
}
  
ThousandFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    suffix: PropTypes.string
};

export default ThousandFormatCustom;