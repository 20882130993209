import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import ButtonExportExcel from '../../../components/ButtonExportExcel';
import Select from '../../../components/Select';
import axios from '../../../util/Service';
import { API } from '../../../constants'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  InputLabel
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';

const drawerWidth = '70%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  buttonAdd: {
    margin: theme.spacing(3)
  }
})
);

const fields = ['Nama', 'Email', 'No telp', 'Sesi', 'Waktu mulai', 'Waktu akhir', 'Status'];
const fieldData = ['Nama', 'Email', 'Nomor_Telepon', 'Sesi', 'Waktu_Mulai', 'Waktu_Akhir', 'Status'];

const Schedule = () => {
  const classes = useStyles();
  const theme = useTheme();

  //state
  const [data, setData] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [dataStudio, setDataStudio] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStudio, setSelectedStudio] = useState(null);
  const [selectedDate, handleDateChange] = useState(new Date());

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  // FILTER DATE
  const startDate = new Date();
  function disablePrevDates(startDate) {
    const startSeconds = Date.parse(startDate);
    return (date) => {
      return Date.parse(date) < startSeconds;
    }
  }

  // state for export excel
  const [excelData, setExcelData] = useState([]);

  useEffect(() => {
    getStore()
  }, [])

  // API
  function getStore() {
    axios({
      method: 'GET',
      url: `stores`,
      headers: {
        "Content-Type": "application/json"
      },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) {
        parsingStore(response.data.data)
      } else {
        parsingStore([])
      }
    }).catch(error => {
      alert(error);
    })
  }

  function getStudio(store) {
    axios({
      method: 'GET',
      url: `store/studios?store=` + store.value,
      headers: {
        "Content-Type": "application/json"
      },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) {
        parsingStudio(response.data.data)
      } else {
        parsingStudio([])
      }
    }).catch(error => {
      alert(error);
    })
  }

  function getSchedules(store, studio, date) {
    // console.log(store.id)
    date = moment(date).format("YYYY-MM-DD")
    axios({
      method: 'GET',
      url: `store/studio/schedules?store=` + store.id + `&studio=` + studio.id + `&date_from=` + date + `&date_to=` + date,
      headers: {
        "Content-Type": "application/json"
      },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) {
        // console.log(response.data.data)
        parsingSchedules(response.data.data[0].studio_schedule, response.data.data[0].store)
      }
    }).catch(error => {
      alert(error);
    })
  }
  //

  // parsing
  const parsingStore = data => {
    if (data != null) {
      const newData = data.map(v => {
        return {
          ...v,
          label: v.name,
          value: v.id
        }
      })

      setDataStore(newData)
      setSelectedStore(newData[0])

      getStudio(newData[0])

      getSchedules(newData[0], newData[0].studios[0], selectedDate)
    }
  }

  const parsingStudio = (data) => {

    if (data != null) {
      const newData = data.map(v => {
        return {
          ...v,
          label: v.name,
          value: v.id
        }
      })

      setDataStudio(newData)
      setSelectedStudio(newData[0])
    }
  }

  const parsingSchedules = (data, data_store) => {

    data.map(v => {
      v.data.map(val => {
        const newData = val.schedule.map((s, i) => {
          return {
            // ...data,
            No: i + 1,
            Store: data_store.name,
            Studio: v.studio.name,
            // id: s.user.id,
            Nama: s.user.name,
            // user_id: s.user.user_id,
            Email: s.user.email,
            Nomor_Telepon: s.user.phone,
            Sesi: s.session,
            Waktu_Mulai: s.times.time_from,
            Waktu_Akhir: s.times.time_to,
            Status: s.times.status
          }
        })
        setData(newData)
        setExcelData(newData)
      })
    })
  }
  // 

  // handle
  const handleDrawerOpen = () => {
    setOpen(true)
  };

  const handleDrawerClose = () => {
    setOpen(false)
  };

  const handleDrawerDetail = id => {

  }

  const handleSelectedStore = store => {
    setSelectedStore(store);
    getStudio(store)
  }

  const handleSelectedStudio = studio => {
    setSelectedStudio(studio);
  }

  const handleFilterSchedule = () => {
    getSchedules(selectedStore, selectedStudio, selectedDate)
  }
  //


  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">

            <h4>Schedule Studio</h4>
            <hr style={{ marginBottom: '30px' }} />

            <Grid item xs={12} style={{ margin: '15px 0 50px -10px' }}>
              <Grid item xs={3} style={{ float: 'left', display: 'block', minWidth: '200px', marginRight: '20px' }}>
                <InputLabel htmlFor="store"><b>Filter Store</b></InputLabel>
                <Select
                  name="Store"
                  label="store"
                  placeholder="Filter Store"
                  options={dataStore}
                  value={selectedStore}
                  onChange={handleSelectedStore}
                />
              </Grid>
              <Grid item xs={3} style={{ float: 'left', display: 'block', minWidth: '200px', marginRight: '20px' }}>
                <InputLabel htmlFor="Studio"><b>Filter Studio</b></InputLabel>
                <Select
                  name="Studio"
                  label="Studio"
                  placeholder="Filter Studio"
                  options={dataStudio}
                  value={selectedStudio}
                  onChange={handleSelectedStudio}
                />
              </Grid>
              <Grid item xs={3} style={{ float: 'left', display: 'block', minWidth: '200px', marginRight: '20px', marginTop: '5px' }}>
                <InputLabel htmlFor="Studio"><b>Filter Tanggal Booking</b></InputLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    value={selectedDate}
                    // placeholder="10/10/2018"
                    onChange={date => handleDateChange(date)}
                    minDate={new Date()}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilterSchedule}
                style={{ marginTop: '10px', marginRight: '15px' }}
              >
                Filter
              </Button>
              <ButtonExportExcel
                csvData={excelData}
                fileName={`Laporan Jadwal Booking Jonas ${moment(selectedDate).format("D MMM YYYY")}`}
                style={{ padding: '20px' }}
              />
            </Grid>

            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onDetail={handleDrawerDetail}
              page={page}
              isReport={true}
              isAction={false}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage} />
          </Card>
        </Grid>
      </Grid>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
            Detail Schedule
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
        </Grid>
        <Divider />

      </Drawer>
    </div>
  );
};

export default Schedule;
