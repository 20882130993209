import React, { useEffect, useState } from 'react';
import MyTable from "../../components/MyTable";
import { API } from '../../constants';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  Typography,
  Snackbar,
  Slide
} from '@material-ui/core';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EducationEditor from './EducationEditor';

const drawerWidth = '90%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  typography: {
    fontFamily: [
      'Roboto',
    ].join(','),
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: '999',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  buttonAdd: {
    margin: theme.spacing(3),
  },
  //upload image
  fileUploadThumbnail: {
    left: '25px',
  },
  fileUploadhide: {
    float: 'left',
    opacity: '0',
    zIndex: '999',
    position: 'absolute',
    width: '21%',
    height: '11%',
    marginTop: '20px',
  },
  bigAvatar: {
    width: '100px',
    height: '100px',
    borderRadius: '0',
    backgroundColor: '#f7f7f7',
    marginTop: '25px',
  },
  bigAvatarBanner: {
    width: '100px',
    height: '100px',
    borderRadius: '0',
    backgroundColor: '#f7f7f7',
    marginTop: '25px',
  },
  disableClick: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  enableClick: {
    pointerEvents: 'auto',
    cursor: 'default',
  }
}));

const fields = ['Nama Kategori', 'Deskripsi'];
const fieldData = ['name', 'description'];

const Category = () => {
  const classes = useStyles();
  const theme = useTheme();

  //state
  const [accountData, setAccountData] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState('');
  const [data, setData] = useState([]);
  const [disableDuringFetch, setDisableDuringFetch] = useState(false);
  const [form, setForm] = useState({
    id: null,
    name: '',
    description: '',
    banner_image_url: '',
    thumbnail_image_url: '',
    thumbnail_recommend: '',
    education_content: '',
    education_status: false,
  });
  const [uploadImage, setUploadImage] = useState({
    load_on_upload: 0,
    thumbnail: '',
    image: '',
  });
  const [uploadBanner, setUploadBanner] = useState({
    load_on_upload_banner: 0,
    banner: '',
    image_banner: '',
  });
  const [uploadRecommend, setUploadRecommend] = useState({
    load_on_upload_banner: 0,
    recommend: '',
    image_recommend: '',
  });

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const currUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currUser) {
      const { token } = currUser;
      if (token) {
        setAccountData(token);
        getCategories()
      } else {
        sessionExpired();
      }
    } else {
      sessionExpired();
    }
  }, []);

  const sessionExpired = () => {
    handleShowSnackbar('Sesi anda sudah berakhir. Harap login kembali');
    localStorage.clear();
    window.location.href = "/sign-in";
  };

  // API
  const getCategories = async () => {
    const api = await fetch(`${API}/categories`);
    if (!api.ok) {
      handleShowSnackbar(api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setData(res.data);
    } else {
      handleShowSnackbar(res.message);
    }
  };

  const addPhoto = async () => {
    setDisableDuringFetch(true);
    let thumbnail = '';
    let banner = '';
    let thumbnail_recommend = '';

    // Upload thumbnail
    if (uploadImage.thumbnail !== '') {
      let body = new FormData();
      body.append("category", "thumbnail");
      body.append("file", uploadImage.image);
      body.append("type", uploadImage.image['type']);
      const api = await fetch(`${API}/upload`, {
        method: 'POST', body
      });
      if (!api.ok) {
        handleShowSnackbar(api.statusText);
        setDisableDuringFetch(false);
        return;
      }
      const res = await api.json();
      if (res.response_code === '00') {
        thumbnail = res.data;
      } else {
        setDisableDuringFetch(false);
        handleShowSnackbar(res.message);
        return;
      }
    } else {
      thumbnail = form.thumbnail_image_url;
    }

    // Upload banner
    if (uploadBanner.banner !== '') {
      let body = new FormData();
      body.append("category", "thumbnail");
      body.append("file", uploadBanner.image_banner);
      body.append("type", uploadBanner.image_banner['type']);
      const api = await fetch(`${API}/upload`, {
        method: 'POST', body
      });
      if (!api.ok) {
        setDisableDuringFetch(false);
        handleShowSnackbar(api.statusText);
        return;
      }
      const res = await api.json();
      if (res.response_code === '00') {
        banner = res.data;
      } else {
        setDisableDuringFetch(false);
        handleShowSnackbar(res.message);
        return;
      }
    } else {
      banner = form.banner_image_url;
    }

    // Upload thumbnail recommend
    if (uploadRecommend.recommend !== '') {
      let body = new FormData();
      body.append("category", "thumbnail");
      body.append("file", uploadRecommend.image_recommend);
      body.append("type", uploadRecommend.image_recommend['type']);
      const api = await fetch(`${API}/upload`, {
        method: 'POST', body
      });
      if (!api.ok) {
        setDisableDuringFetch(false);
        handleShowSnackbar(api.statusText);
        return;
      }
      const res = await api.json();
      if (res.response_code === '00') {
        thumbnail_recommend = res.data;
      } else {
        setDisableDuringFetch(false);
        handleShowSnackbar(res.message);
        return;
      }
    }

    if (form.id) {
      updateCategory(thumbnail, banner, thumbnail_recommend, form.education_content, form.education_status);
    } else {
      addCategory(thumbnail, banner, thumbnail_recommend);
    }
  };

  const addCategory = async (url_gambar, url_banner, url_recommend) => {
    const body = {
      name: form.name,
      description: form.description,
      banner_image_url: url_banner,
      thumbnail_image_url: url_gambar,
      recomend_thumbnail_image_url: url_recommend,
      education_content: form.education_content,
      education_status: form.education_status,
    };
    const api = await fetch(`${API}/category`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });
    if (!api.ok) {
      setDisableDuringFetch(false);
      handleShowSnackbar(api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setDisableDuringFetch(false);
      handleShowSnackbar('Berhasil menambahkan kategori');
      getCategories();
      handleDrawerClose();
    } else {
      setDisableDuringFetch(false);
      handleShowSnackbar(res.message);
    }
  };

  const updateCategory = async (
    url_gambar,
    url_banner,
    url_recommend,
    education_content,
    education_status,
  ) => {
    const body = {
      id: form.id,
      name: form.name,
      description: form.description,
      banner_image_url: url_banner,
      thumbnail_image_url: url_gambar,
      recomend_thumbnail_image_url: url_recommend,
      education_content: education_content,
      education_status: education_status,
    }
    const api = await fetch(`${API}/category`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    });
    if (!api.ok) {
      setDisableDuringFetch(false);
      handleShowSnackbar(api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setDisableDuringFetch(false);
      handleShowSnackbar('Berhasil mengubah kategori');
      getCategories();
      handleDrawerClose();
    } else {
      setDisableDuringFetch(false);
      handleShowSnackbar(res.message);
    }
  };

  const deleteCategory = async id => {
    const api = await fetch(`${API}/category?id=${id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    });
    if (!api.ok) {
      handleShowSnackbar(api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      handleShowSnackbar('Berhasil menghapus kategori');
      getCategories();
      handleDrawerClose();
    } else {
      handleShowSnackbar(res.message);
    }
  };

  // handle
  const handleDrawerOpen = () => {
    setOpen(true)
  };

  const handleDrawerOpenForEdit = id => {
    const selectCity = data.find(v => v.id === id);
    setForm({
      ...form,
      id: selectCity.id,
      name: selectCity.name,
      description: selectCity.description,
      banner_image_url: selectCity.banner_image_url,
      thumbnail_image_url: selectCity.thumbnail_image_url,
      thumbnail_recommend: selectCity.recomend_thumbnail_image_url,
      education_content: selectCity.education_content,
      education_status: selectCity.education_status,
    });
    handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      name: '',
      description: '',
      banner_image_url: '',
      thumbnail_image_url: '',
      education_content: '',
      education_status: '',
    });
    setUploadImage({
      thumbnail: '',
      image: '',
    });
    setUploadBanner({
      banner: '',
      image_banner: '',
    });
    setUploadRecommend({
      recommend: '',
      image_recommend: '',
    });
  };

  const handleChangeForm = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const onChangeGambar = (value) => {
    let gambar = value.target.files;
    if (gambar.length !== 0) {
      setUploadImage({
        thumbnail: URL.createObjectURL(gambar[0]),
        image: gambar[0],
      });
    }
  };

  const onChangeBanner = (valueBanner) => {
    let gambar_banner = valueBanner.target.files;
    if (gambar_banner.length !== 0) {
      setUploadBanner({
        banner: URL.createObjectURL(gambar_banner[0]),
        image_banner: gambar_banner[0]
      });
    }
  };

  const onChangeRecommend = (e) => {
    let gambar_recomend = e.target.files;
    if (gambar_recomend.length !== 0) {
      setUploadRecommend({
        recommend: URL.createObjectURL(gambar_recomend[0]),
        image_recommend: gambar_recomend[0]
      });
    }
  };

  const handleShowSnackbar = message => {
    setShowSnackbar(true);
    setMessageSnackbar(message);
  };

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">

            <h4>Kategori</h4>
            <hr style={{ marginBottom: '30px' }} />

            <Button color="primary" href="/categories/banner" style={{ margin: '0 0 15px -12px' }} variant="contained">Banner Kategori</Button>

            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deleteCategory}
              onEdit={handleDrawerOpenForEdit}
              onUpdate={() => { }}
              titleAdd="Tambah Kategori"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage} />
          </Card>
        </Grid>
      </Grid>

      <Drawer
        className={`${classes.drawer} ${disableDuringFetch ? classes.disableClick : classes.enableClick}`}
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper, }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography variant="h5">{form.id == null ? "Simpan" : "Ubah"} Informasi Kategori</Typography>
          <Button variant="contained" color="primary" style={{ position: 'absolute', right: '20px', top: '-10px' }} className={classes.buttonAdd} onClick={() => addPhoto()}>
            {form.id == null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Nama Kategori"
              value={form.name}
              onChange={handleChangeForm('name')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="description"
              label="Deskripsi Kategori"
              value={form.description}
              onChange={handleChangeForm('description')}
              margin="normal"
              fullWidth
              multiline
              rows='4'
              variant="outlined"
              style={{ marginBottom: '30px' }}
            />
            <Grid item xs={6} style={{ float: 'left' }}>
              <Typography variant="h4">{form.thumbnail_image_url === '' ? 'Tambah Thumbnail' : 'Ubah Thumbnail'}</Typography>
              <div className={classes.bungkusGambar}>
                {uploadImage.thumbnail !== '' ?
                  <div>
                    <Avatar alt="Image" style={{ width: '100px' }} src={uploadImage.thumbnail} className={classes.bigAvatar} value={form.thumbnail_image_url} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatar} src={form.thumbnail_image_url}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
                }
              </div>
              <input className={classes.fileUploadhide + ' mt-2' + classes.fileUploadThumbnail} type='file' onChange={onChangeGambar} accept="image/*" />
              <Button variant="contained" color="secondary" className={classes.button} style={{ marginTop: '20px' }}>
                {form.thumbnail_image_url === '' ? 'Tambah Foto' : 'Ubah Foto'}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ float: 'left', marginLeft: '70px' }}>
              <Typography variant="h4">{form.banner_image_url === '' ? 'Tambah Image Banner' : 'Ubah Image Banner'}</Typography>
              <div className={classes.bungkusBanner}>
                {uploadBanner.banner !== '' ?
                  <div>
                    <Avatar alt="Image" style={{ width: '200px' }} src={uploadBanner.banner} className={classes.bigAvatarBanner} value={form.banner_image_url} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatarBanner} src={form.banner_image_url} style={{ width: '200px' }}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
                }
              </div>
              <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChangeBanner} accept="image/*" />
              <Button variant="contained" color="secondary" className={classes.button} style={{ marginTop: '20px' }}>
                {form.banner_image_url === '' ? 'Tambah Banner' : 'Ubah Banner'}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ float: 'left', marginLeft: '70px' }}>
              <Typography variant="h4">{form.banner_image_url === '' ? 'Tambah Thumbnail Recommend' : 'Ubah Thumbnail Recommend'}</Typography>
              <div className={classes.bungkusBanner}>
                {uploadRecommend.recommend !== '' ?
                  <div>
                    <Avatar alt="Image" style={{ width: '200px' }} src={uploadRecommend.recommend} className={classes.bigAvatarBanner} value={form.thumbnail_recommend} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatarBanner} src={form.thumbnail_recommend} style={{ width: '200px' }}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
                }
              </div>
              <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChangeRecommend} accept="image/*" />
              <Button variant="contained" color="secondary" className={classes.button} style={{ marginTop: '20px' }}>
                {form.thumbnail_recommend === '' ? 'Tambah Thumbnail Recommend' : 'Ubah Thumbnail Recommend'}
              </Button>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} style={{ marginTop: 20 }}>
            {
              form.id != null &&
              <EducationEditor
                initialContent={form}
                updateEducationContent={(content, status) => {
                  updateCategory(
                    form.thumbnail_image_url,
                    form.banner_image_url,
                    form.thumbnail_recommend,
                    content,
                    status
                  );
                }}
              />
            }
          </Grid>
        </Grid>
      </Drawer>
      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        TransitionComponent={Slide}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={3000}
        message={<span id="message-id">{messageSnackbar}</span>}
      />
    </div>
  );
};

export default Category;
