import uploadFileTypes from './uploadFile.types'
import { API } from '../../constants'
import axios from 'axios'

export const setUploadProgress = (id, progress) => ({
  type: uploadFileTypes.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
})

export const dispatchImageUrl = data => ({
  type: uploadFileTypes.DISPATCH_IMAGE_URL,
  payload: data
})

export const setUploadFile = data => ({
  type: uploadFileTypes.SET_UPLOAD_FILE,
  payload: data,
})

export const successUploadFile = id => ({
  type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
  payload: id,
})

export const failureUploadFile = id => ({
  type: uploadFileTypes.FAILURE_UPLOAD_FILE,
  payload: id,
})

export const checkStore = () => (dispatch, getState) => {
  console.log('entah huwiw', getState())
  return {}
}

export const resetImage = () => ({
  type: uploadFileTypes.RESET_IMAGE,
  payload: {}
})

export const uploadFile = (files) => (dispatch, getState) => {
  if (files.length) {
    let counter = 0;
    files.forEach(async file => {
      const formPayload = new FormData()
      formPayload.append('category', 'preview')
      formPayload.append('type', file.file.type.split('/')[1])
      formPayload.append('file', file.file)
      try {
        const api = await axios({
          baseURL: `${API}`,
          url: '/upload',
          method: 'post',
          data: formPayload,
          onUploadProgress: progress => {
            const { loaded, total } = progress
            const percentageProgress = Math.floor((loaded / total) * 100)
            dispatch(setUploadProgress(file.id, percentageProgress))
          },
        })
        const res = await api;
        dispatch(successUploadFile(file.id))

        if (res.data.response_code === '00') {
          const body = {
            transaction_id: getState().Transaction.transaction.id,
            url_photo: res.data.data
          }
          const apiInsertAsset = await fetch(`${API}/transaction/preview-asset`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Authorization': `Bearer ${getState().Auth.token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!apiInsertAsset.ok) {
            deletePhoto()
            return;
          }
          const resInsertAsset = await apiInsertAsset.json();
          if (resInsertAsset.response_code === '00') {
            counter = counter + 1

            if (counter === files.length) {
              dispatch(setDoneUpload(true))
            }
          } else {
            deletePhoto()
          }
        }
      } catch (error) {
        dispatch(failureUploadFile(file.id))
      }
    })
  }
}

export const deletePhoto = async (url_image) => {
  // Delete photos
  const deleteBody = new FormData();
  deleteBody.append('url', url_image);
  await fetch(`http://ec2-18-138-82-207.ap-southeast-1.compute.amazonaws.com:6969/api/v1/delete`, {
    method: 'POST',
    body: deleteBody
  });
}

export const setDoneUpload = data => ({
  type: uploadFileTypes.DONE_UPLOAD,
  payload: data
})