import React from 'react';
import { Document, PDFDownloadLink, PDFViewer, Page, Text, View, renderToFile } from "@react-pdf/renderer";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import axios from '../../util/Service';
import moment from 'moment';
import { Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@material-ui/core';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const Receipt = (props) => {

    const { search } = useLocation();
    const [pdfDatas, setPdfDatas] = useState([]);
    const [title, setTitle] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [progress, setProgress] = useState(0);

    let blobData = [];
    let products = [];
    let customerService = "";

    useEffect(async () => {
        setLoading(true);
        const currUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currUser) {
            customerService = currUser.token.name;
        } else {
            alert('Sesi anda sudah berakhir. Harap login kembali');
            localStorage.clear();
            window.location.href = "/sign-in";
        }

        const ids = search.replace("?ids=", "").split(",");
        let datas = [];
        let dataPrint = [];

        const responseProducts = await axios({
            method: 'GET',
            url: 'package/products?page=1&limit=1000',
            headers: { 'Content-Type': 'application/json' }
        });
        products = responseProducts.data.data;
        setTotal(ids.length);

        let currentProgress = 1;
        for (const id of ids) {
            setProgress(currentProgress);
            const response = await axios({
                method: 'GET',
                url: 'transaction/detail/' + id,
                headers: { 'Content-Type': 'application/json' }
            });
            const printResponse = await axios({
                method: 'PUT',
                data: { transaction: Number(id) },
                url: 'transaction/print',
                headers: { 'Content-Type': 'application/json' }
            });
            datas.push(response.data.data);
            dataPrint.push(printResponse.data.data.print);
            currentProgress += 1;
        }

        console.log(dataPrint);

        setTitle(datas.map(item => item?.transaction_id || ""));
        setPdfDatas(datas.map((item, index) => {
            let address = (item?.store?.address || "").split(",");
            address = (address.length > 0) ? address.slice(-1).toString().trim() : "";

            return <MyDocument
                data={
                    [
                        {
                            name: item?.detail?.package_title || "",
                            qty: 1,
                            value: item?.billing?.price || 0,
                            discount: item?.billing?.voucher_amount || 0,
                            include: true,
                        },
                        {
                            name: "Tax",
                            qty: 1,
                            value: item?.billing?.tax,
                            discount: 0,
                            include: true,
                        },
                        {
                            name: "Service",
                            qty: 1,
                            value: item?.billing?.service,
                            discount: 0,
                            include: true,
                        },
                        ...(item.addons || []).filter(f => f.price > 0).map(m => (
                            {
                                name: m.name,
                                qty: m.value,
                                value: m.price / m.value,
                                discount: 0,
                                include: true,
                            }
                        )),
                        // ...(item.detail.includes || []).map(m => (
                        //     {
                        //         name: m.name,
                        //         qty: m.quantity,
                        //         value: products.find(item => item.name == m.name)?.price || 0,
                        //         discount: 0,
                        //         include: false,
                        //     }
                        // )),
                    ]
                }
                store={item?.store?.name || ""}
                city={address}
                customer={toTitleCase(item?.name || "")}
                transactionNumber={item?.transaction_id || ""}
                printDate={moment().format("DD/MM/YYYY")}
                printCount={dataPrint[index]}
                customerService={customerService}
            />
        }));
        setLoading(false);
    }, []);

    if (loading)
        return <>
            <div style={{
                margin: "20px 0px 0px 20px",
            }}>
                Collecting Data {progress}/{total} Receipt
            </div>
        </>

    return <>
        <Grid container>
            <Grid xs={6}>
                <PDFViewer style={{
                    margin: "20px 0px 0px 20px",
                    width: "90%",
                    height: 550,
                }}>
                    <Document>{pdfDatas}</Document>
                </PDFViewer>
            </Grid>
            <Grid xs={6}>
                <div style={{
                    display: "flex",
                    marginTop: 20,
                    marginRight: 20,
                    alignItems: "flex-start",
                    flexDirection: "column"
                }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{
                                display: "table",
                                width: "100%",
                            }}>
                                <TableCell style={{
                                    width: 50,
                                    background: "rgb(60 56 53)",
                                    color: "white",
                                }}>No</TableCell>
                                <TableCell style={{
                                    background: "rgb(60 56 53)",
                                    color: "white",
                                }}>Nomor Transaksi</TableCell>
                                <TableCell style={{
                                    width: 150,
                                    background: "rgb(60 56 53)",
                                    color: "white",
                                }}>Aksi</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={{
                            maxHeight: 450,
                            overflowY: "auto",
                            overflowX: "hidden",
                            display: "block",
                            width: "100%",
                        }}>
                            {
                                pdfDatas.map((item, index) => {
                                    let address = (item?.store?.address || "").split(",");
                                    address = (address.length > 0) ? address.slice(-1).toString().trim() : "";
                                    return <>
                                        <TableRow style={{
                                            display: "table",
                                            width: "100%",
                                        }}>
                                            <TableCell style={{
                                                width: 50,
                                            }}>{index + 1}</TableCell>
                                            <TableCell>{title[index]}</TableCell>
                                            <TableCell style={{
                                                width: 150,
                                            }} align='center'>
                                                <PDFDownloadLink
                                                    fileName={`Receipt ${title[index]}`}
                                                    document={<Document>{item}</Document>}
                                                    style={{
                                                        backgroundColor: "rgb(60 56 53)",
                                                        borderRadius: 8,
                                                        padding: "5px 10px",
                                                        border: "1px solid #b1a7a0",
                                                        color: "white",
                                                        textDecoration: "none",
                                                    }}>
                                                    {({ blob, url, loading, error }) => {
                                                        if (blob)
                                                            blobData[index] = blob;
                                                        return loading
                                                            ? 'Generating Report...'
                                                            : `Download`
                                                    }}
                                                </PDFDownloadLink>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow style={{
                                display: "table",
                                width: "100%",
                            }}>
                                <TableCell style={{
                                    background: "rgb(60 56 53)",
                                    color: "white",
                                }} colSpan={2}>All Receipt</TableCell>
                                <TableCell style={{
                                    background: "rgb(60 56 53)",
                                    color: "white",
                                    width: 150,
                                }} align='center'>
                                    {
                                        <a
                                            onClick={async () => {
                                                const zip = new JSZip();

                                                blobData.forEach((b, i) => {
                                                    const file = new File([b], title[i] + ".pdf");
                                                    zip.file(title[i] + ".pdf", file);
                                                })

                                                zip.generateAsync({ type: "blob" }).then(function (content) {
                                                    saveAs(content, "All Receipt.zip");
                                                });
                                            }}
                                            style={{
                                                backgroundColor: "rgb(129 86 54)",
                                                borderRadius: 8,
                                                padding: "5px 10px",
                                                border: "1px solid #b1a7a0",
                                                color: "white",
                                                textDecoration: "none",
                                                cursor: "pointer",
                                            }}
                                        >All Receipt.pdf</a>
                                    }
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Grid>
        </Grid >
    </>
}

const MyDocument = ({
    data = [],
    store,
    city,
    customer,
    transactionNumber,
    printDate,
    printCount,
    customerService,
}) => {
    return (
        <Page size={{ width: 3.14961 * 72 }}
            style={{
                padding: 5,
                fontSize: "10pt"
            }}>
            <View>
                <Text>Jonas Ecommerce</Text>
                <Text>Kota Bandung, 40115</Text>
                <Text>&nbsp;</Text>
                <Text style={{
                    width: "100%",
                    textAlign: "center"
                }}>BUKTI PEMBAYARAN ORDER</Text>
                <Text>&nbsp;</Text>
            </View>
            <View>
                <Con>
                    <Field flex={1} >No</Field>
                    <Field flex={3}>: {transactionNumber}</Field>
                </Con>
                <Con>
                    <Field flex={1}>Nama</Field>
                    <Field flex={3}>: {customer}</Field>
                </Con>
                <Divider />
                <Con>
                    <Field flex={1}>Jumlah</Field>
                    <Con direction='column' alignItems='start'>
                        <Field flex={1}>Harga</Field>
                        <Field flex={1}>Satuan</Field>
                    </Con>
                    <Con direction='column' alignItems='start'>
                        <Field flex={1}>Disc</Field>
                        <Field flex={1}>Satuan</Field>
                    </Con>
                    <Field flex={1} align='right'>Subtotal</Field>
                </Con>
                <Divider />
                <Field flex={1}>&nbsp;</Field>
                {/* Content Here */}
                {
                    data.map(item => {
                        return (<>
                            <Field flex={1}>{item.name}</Field>
                            <Con>
                                <Field flex={1}>{item.qty}</Field>
                                <Field flex={1}>{item.value.toLocaleString().replace(/\,/g, ".")}</Field>
                                <Field flex={1}>{
                                    (item.discount > 1)
                                        ? item.discount.toLocaleString().replace(/\,/g, ".")
                                        : item.value * item.discount
                                }</Field>
                                <Field flex={1} align='right'>
                                    {(
                                        (item.value * item.qty) - (
                                            (item.discount > 1)
                                                ? (item.discount)
                                                : (item.value * item.qty * item.discount)
                                        )
                                    ).toLocaleString().replace(/\,/g, ".")}
                                </Field>
                            </Con>
                        </>);
                    })
                }
                {/* Content Here */}
                <Field flex={1}>&nbsp;</Field>
                <Divider />
                <Con>
                    <Field flex={1} align='right'>TOTAL</Field>
                    <Field flex={1} align='right'>{
                        data.filter(f => f.include).reduce((acc, cur) => {
                            return acc + (cur.value * cur.qty)
                        }, 0).toLocaleString().replace(/\,/g, ".")
                    }</Field>
                </Con>
                <Con>
                    <Field flex={1} align='right'>DISCOUNT</Field>
                    <Field flex={1} align='right'>{
                        data.filter(f => f.include).reduce((acc, cur) => {
                            return acc + (
                                (cur.discount > 1)
                                    ? (cur.discount)
                                    : (cur.value * cur.qty * cur.discount)
                            )
                        }, 0).toLocaleString().replace(/\,/g, ".")
                    }</Field>
                </Con>
                <Con>
                    <Field flex={1} align='right'>GRAND TOTAL</Field>
                    <Field flex={1} align='right'>{
                        data.filter(f => f.include).reduce((acc, cur) => {
                            return acc + (cur.value * cur.qty) - (
                                (cur.discount > 1)
                                    ? (cur.discount)
                                    : (cur.value * cur.qty * cur.discount)
                            )
                        }, 0).toLocaleString().replace(/\,/g, ".")
                    }</Field>
                </Con>
                <Field flex={1}>&nbsp;</Field>
                <Con>
                    <Field flex={1} align='right'>Transfer E-Commerce</Field>
                    <Field flex={1} align='right'>{
                        data.filter(f => f.include).reduce((acc, cur) => {
                            return acc + (cur.value * cur.qty) - (
                                (cur.discount > 1)
                                    ? (cur.discount)
                                    : (cur.value * cur.qty * cur.discount)
                            )
                        }, 0).toLocaleString().replace(/\,/g, ".")
                    }</Field>
                </Con>
                <Field flex={1}>&nbsp;</Field>
                <Field flex={1}>&nbsp;</Field>
                <Field flex={1}>&nbsp;</Field>
                <Field flex={1} align='justify'>
                    Dengan melakukan transaksi ini, konsumen berarti menyetujui dan bersedia mentaati syarat-syarat dan ketentuan yang berlaku.
                </Field>
                <Field flex={1}>&nbsp;</Field>
                <Field flex={1}>&nbsp;</Field>
                <Con>
                    <Field flex={1} >Pos Date</Field>
                    <Field flex={3}>: {printDate}</Field>
                </Con>
                <Field flex={1} align='right'>{customerService}</Field>
                <Con>
                    <Field flex={1}>Print Date</Field>
                    <Field flex={3}>: {printDate}</Field>
                </Con>
                <Field flex={1} align='right'>{customerService}</Field>
                <Con>
                    <Con flex={1} direction='column' alignItems='start'>
                        <Field flex={1}>Total Print</Field>
                        <Field flex={1}>Receipt</Field>
                    </Con>
                    <Field flex={3}>: {printCount}</Field>
                </Con>
            </View>
        </Page>
    );
}

const Divider = () => (
    <View>
        <Text>=====================================</Text>
    </View>
);

const Con = ({ flex = 1, direction = "row", alignItems = "center", children }) => (
    <View style={{
        flex: flex,
        alignItems: alignItems,
        flexDirection: direction,
    }}>{children}</View>
);

const Field = ({ flex, align = "left", children }) => (
    <Text style={{
        flex: flex,
        textAlign: align,
    }}>{children}</Text>
);

function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}