import React, { useEffect, useState } from 'react';
import { API } from '../../constants';
import { Button, Card, Divider, Drawer, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Slide, Snackbar, TextField } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MyTable from "../../components/MyTable";
import Typography from 'theme/typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const drawerWidth = '60%';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    typography: {
        fontFamily: [
            'Roboto',
        ].join(','),
    },
    content: {
        flexShrink: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: '999',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    buttonAdd: {
        margin: theme.spacing(3),
    },
    //upload image
    fileUploadThumbnail: {
        left: '25px',
    },
    fileUploadhide: {
        float: 'left',
        opacity: '0',
        zIndex: '999',
        position: 'absolute',
        width: '21%',
        height: '11%',
        marginTop: '20px',
    },
    bigAvatar: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    bigAvatarBanner: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    disableClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    enableClick: {
        pointerEvents: 'auto',
        cursor: 'default',
    }
}));

const fields = ['Nama Sub Kategori', 'Kategori', 'Deskripsi'];
const fieldData = ['name', 'parent_name', 'description'];

const SubCategory = () => {

    const classes = useStyles();
    const theme = useTheme();

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [disableDuringFetch, setDisableDuringFetch] = useState(false);

    const [categories, setCategories] = useState([]);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        id: null,
        category_id: "",
        name: "",
        description: "",
        image: ""
    });

    useEffect(() => {
        const currUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currUser) {
            const { token } = currUser;
            if (token) {
                getCategories();
                getSubCategories()
            } else {
                sessionExpired();
            }
        } else {
            sessionExpired();
        }
    }, []);

    const sessionExpired = () => {
        handleShowSnackbar('Sesi anda sudah berakhir. Harap login kembali');
        localStorage.clear();
        window.location.href = "/sign-in";
    };

    // API
    const getCategories = async () => {
        const api = await fetch(`${API}/categories`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setCategories(res.data);
        } else {
            handleShowSnackbar(res.message);
        }
    };

    const getSubCategories = async () => {
        const api = await fetch(`${API}/category/sub`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setData(res.data.map(e => ({ ...e, parent_name: e.category.name })));
        } else {
            handleShowSnackbar(res.message);
        }
    };

    const handleShowSnackbar = message => {
        setShowSnackbar(true);
        setMessageSnackbar(message);
    };

    const handleOnNextPage = () => setPage(page + 1);
    const handleOnBackPage = () => setPage(page - 1);

    const handleDrawerOpen = () => {
        setOpen(true)
    };

    const handleDrawerOpenForEdit = id => {
        const selectedCategory = data.find(v => v.id === id);
        setForm({
            ...form,
            id: selectedCategory.id,
            category_id: selectedCategory.category_id,
            name: selectedCategory.name,
            description: selectedCategory.description,
            image: selectedCategory.image,
        });
        handleDrawerOpen();
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setForm({});
    };

    const handleChangeForm = (name) => e => {
        setForm({
            ...form,
            [name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        try {
            if (form.id != null) {
                await fetch(`${API}/category/sub`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(form),
                });
            } else {
                await fetch(`${API}/category/sub`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(form),
                });
            }
            getSubCategories();
        } catch (e) {
            setMessageSnackbar(e);
            setShowSnackbar(true);
        } finally {
            handleDrawerClose();
        }
    }

    const deleteSubCategory = async id => {
        const api = await fetch(`${API}/category/sub?id=${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleShowSnackbar('Berhasil menghapus kategori');
            getSubCategories();
            handleDrawerClose();
        } else {
            handleShowSnackbar(res.message);
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Card className={classes.root} variant="outlined">
                        <h4>Sub Kategori</h4>
                        <hr style={{ marginBottom: '30px' }} />
                        <MyTable
                            id="id"
                            data={data}
                            fields={fields}
                            fieldData={fieldData}
                            onAdd={handleDrawerOpen}
                            onDelete={deleteSubCategory}
                            onEdit={handleDrawerOpenForEdit}
                            onUpdate={() => { }}
                            titleAdd="Tambah Sub Kategori"
                            page={page}
                            onNextPageClicked={handleOnNextPage}
                            onBackPageClicked={handleOnBackPage} />
                    </Card>
                </Grid>
            </Grid>
            <Drawer
                className={`${classes.drawer} ${disableDuringFetch ? classes.disableClick : classes.enableClick}`}
                anchor="right"
                open={open}
                classes={{ paper: classes.drawerPaper }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => {
                        handleDrawerClose()
                    }}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <h5>{form.id == null ? "Simpan" : "Ubah"} Informasi Sub Kategori</h5>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ position: 'absolute', right: '20px', top: '-10px' }}
                        className={classes.buttonAdd}
                        onClick={() => handleSubmit()}
                    >
                        {form.id == null ? "Simpan" : "Ubah"}
                    </Button>
                </div>
                <Divider />
                <Grid container style={{ padding: theme.spacing(3) }}>
                    <Grid item xs={12}>
                        <FormControl
                            className={classes.formControl}
                            style={{ margin: '15px 0' }}
                            variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">
                                Kategori
                            </InputLabel>
                            <Select
                                id="service_type"
                                label="demo-simple-select-label"
                                style={{ minWidth: 250 }}
                                onChange={handleChangeForm('category_id')}
                                value={form.category_id}
                            >
                                {
                                    categories.map(e => (
                                        <MenuItem selected={e.id == form.category_id} value={e.id}>{e.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            id="name"
                            label="Nama Kategori"
                            value={form.name}
                            onChange={handleChangeForm('name')}
                            margin="normal"
                            fullWidth
                            variant="outlined"
                        />
                        <TextField
                            id="description"
                            label="Deskripsi Kategori"
                            value={form.description}
                            onChange={handleChangeForm('description')}
                            margin="normal"
                            fullWidth
                            multiline
                            rows='4'
                            variant="outlined"
                            style={{ marginBottom: '30px' }}
                        />
                    </Grid>
                </Grid>
            </Drawer>
            <Snackbar
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                TransitionComponent={Slide}
                ContentProps={{ 'aria-describedby': 'message-id' }}
                autoHideDuration={3000}
                message={<span id="message-id">{messageSnackbar}</span>}
            />
        </div>
    );
}

export default SubCategory;