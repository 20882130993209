import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import Widget from '../../components/Widget/index';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import ScheduleIcon from '@material-ui/icons/Schedule';

const dataMetricsAdminJonas = [
  {
    title: 'Studio',
    imageIcon: <MeetingRoomIcon style={{ fontSize: '40px' }} />,
    link: '/store/studio'
  },
  {
    title: 'Group',
    imageIcon: <GroupWorkIcon style={{ fontSize: '40px' }} />,
    link: '/store/group'
  },
  {
    title: 'Schedule',
    imageIcon: <ScheduleIcon style={{ fontSize: '40px' }} />,
    link: '/store/schedule',
  },
  // {
  //   title: 'Photo Gallery',
  //   imageIcon: <PhotoAlbumIcon style={{ fontSize: '40px' }} />,
  //   link: '/store/photo_gallery',
  // }
];

const dataMetricsStore = [
  {
    title: 'Studio',
    imageIcon: <MeetingRoomIcon style={{ fontSize: '40px' }} />,
    link: '/store/studio'
  },
  {
    title: 'Schedule',
    imageIcon: <ScheduleIcon style={{ fontSize: '40px' }} />,
    link: '/store/schedule',
  },
  // {
  //   title: 'Photo Gallery',
  //   imageIcon: <PhotoAlbumIcon style={{ fontSize: '40px' }} />,
  //   link: '/store/photo_gallery',
  // }
];

const dataMetricsStudio = [
  {
    title: 'Schedule',
    imageIcon: <ScheduleIcon style={{ fontSize: '40px' }} />,
    link: '/store/schedule',
  }
];

const IconWithTextCard = ({ data }) => {
  const { imageIcon, title, link } = data;
  return (
    <NavLink
      style={{ color: '#3c3835' }}
      to={link}
    >
      <Widget styleName="p-2 py-3">
        <div className="media align-items-center flex-nowrap my-auto mx-auto">
          <div className="mr-lg-2 mr-1">
            {imageIcon}
          </div>
          <div className="media-body">
            <h2 className="jr-font-weight-bold mb-0">{title}</h2>
          </div>
        </div>
      </Widget>
    </NavLink>
  );
};

const StoreMenu = () => {

  const [account, setAccount] = useState({});

  useEffect(() => {
    const account = localStorage.getItem('currentUser');
    setAccount(JSON.parse(account));
  }, []);

  const renderMenu = () => {
    if (account) {
      const { token } = account;
      if (token) {
        switch (token.role) {
          case 0:
            return (
              <div className="row"
                style={{ width: '100%', marginLeft: '0' }}>
                {
                  dataMetricsAdminJonas.map((v, i) => {
                    return (
                      <div
                        className="col-lg-4 col-sm-4 col-12 text-center"
                        key={i}
                      >
                        <IconWithTextCard data={v} />
                      </div>
                    )
                  })
                }
              </div>
            );
          case 1:
            return (
              <div className="row"
                style={{ width: '100%', marginLeft: '0' }}>
                {
                  dataMetricsAdminJonas.map((v, i) => {
                    return (
                      <div
                        className="col-lg-4 col-sm-4 col-12 text-center"
                        key={i}
                      >
                        <IconWithTextCard data={v} />
                      </div>
                    )
                  })
                }
              </div>
            );
          case 2:
            return (
              <div className="row"
                style={{ width: '100%', marginLeft: '0' }}>
                {
                  dataMetricsStore.map((v, i) => {
                    return (
                      <div
                        className="col-lg-6 col-sm-6 col-12 text-center"
                        key={i}
                      >
                        <IconWithTextCard data={v} />
                      </div>
                    )
                  })
                }
              </div>
            );
          case 3:
            return (
              <div className="row"
                style={{ width: '100%', marginLeft: '0' }}>
                {
                  dataMetricsStudio.map((v, i) => {
                    return (
                      <div
                        className="col-lg-6 col-sm-6 col-12 text-center"
                        key={i}
                      >
                        <IconWithTextCard data={v} />
                      </div>
                    )
                  })
                }
              </div>
            );
        }
      }
    }
  }


  return (
    <div className="w-100">
      {
        account && renderMenu()
      }
    </div>
  )
}

export default StoreMenu;
