import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  TableBody,
} from '@material-ui/core';
import Select from '../../components/Select';
import { selectLocalImage } from 'helpers/upload';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize';
import ImageBlot from './ImageBlot';
Quill.register('modules/imageResize', ImageResize);
// Quill.register(ImageBlot);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const EducationEditor = ({
  initialContent,
  updateEducationContent,
}) => {
  const classes = useStyles();
  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
    ['clean'],                                         // remove formatting button
    ['link', 'image']
  ];

  const [formEducation, setFormEducation] = useState({
    status: {
      value: initialContent.education_status == true ? 1 : 0,
      label: initialContent.education_status == true ? "Active" : "Inactive",
    },
    content: initialContent.education_content
  });

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: toolbarOptions,
      imageResize: {
        // parchment: Quill.import('parchment'),
        displaySize: true,
        modules: ['Resize', 'DisplaySize'],
      },
    },
    formats: [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image',
      'alt', 'width', 'height'
    ]
  });

  const editorInsertImage = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  }

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(initialContent.education_content);
      quill.getModule('toolbar').addHandler('image', () => {
        selectLocalImage(editorInsertImage);
      });
    }
  }, [quill]);

  return (
    <div>
      <Card className={classes.root} variant="outlined" style={{ marginTop: 20 }}>
        <Grid container justify="flex-end">
          <Grid item xs={3}>
            <Select
              name="Store"
              placeholder="Pilih Store"
              options={[
                { value: 1, label: "Active" },
                { value: 0, label: "Inactive" },
              ]}
              value={formEducation.status}
              onChange={(value) => {
                setFormEducation({
                  ...formEducation,
                  status: value
                });
              }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                updateEducationContent(
                  formEducation.content,
                  (formEducation.status.value == 1) ? true : false,
                );
              }}
              style={{ marginLeft: 20 }}>
              Save Content
            </Button>
          </Grid>
        </Grid>
        <Table style={{ marginTop: 20 }}>
          <TableHead style={{ backgroundColor: '#3C3835' }}>
            <TableRow>
              <TableCell style={{ color: '#fff' }} width="90%" align='center'>Education Content</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ color: '#fff' }} width="90%" align='center'>
                <TextField
                  id="link"
                  label="Link"
                  value={formEducation.content}
                  onChange={e => setFormEducation({
                    ...formEducation,
                    content: e.target.value
                  })}
                  margin="normal"
                  fullWidth
                  variant="outlined"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {/* <div ref={quillRef} style={{ height: "500px" }} /> */}
      </Card>
    </div >
  );
}

export default EducationEditor;