import React, { useEffect, useState } from 'react';
// import MyTable from '../../components/MyTable';
import MyTable from '../../components/MyTablePagination';
import axios from 'axios';
import { API } from '../../constants';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  MenuItem,
  Select,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Slide,
  Checkbox,
  FormControlLabel,
  Paper,
  OutlinedInput,
  InputAdornment,
  FormHelperText
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MySelect from '../../components/Select';
import { red, blue } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NumberFormatCustom from '../../components/NumberFormatCustom';
import ThousandFormatCustom from '../../components/ThousandFormatCustom';
import VarianTable from './VariantTable';

const drawerWidth = '90%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  buttonAdd: {
    margin: theme.spacing(3)
  },
  //upload image
  bungkusGambar: {
    // margin: 'auto',
    // width: 'auto',
    // display: 'table',
  },
  loaderBungkus: {
    margin: 10,
    width: 100,
    height: 100,
    background: 'rgba(0,0,0,0.4)',
    position: 'absolute',
    top: '0',
    borderRadius: '50%',
    zIndex: '99'
  },
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)'
  },
  fileUploadhide: {
    left: '0',
    float: 'left',
    opacity: 0,
    zIndex: '999',
    position: 'absolute',
    width: '100%',
    height: '100%',
    marginTop: '0'
  },
  bigAvatar: {
    width: '100px',
    height: '100px',
    borderRadius: '0',
    zIndex: 1,
    '&:hover': {
      opacity: '.5'
    }
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    left: 0,
    top: 0,
    zIndex: 2,
    opacity: '0',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgb(255, 255, 255, .8)'
    }
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '95.6%',
    paddingLeft: '.3rem'
  },
  iconButton: {
    padding: 10
  }
}));

const fields = ['Judul Package', 'Interval Time', 'Harga'];
const fieldData = ['title', 'interval_time', 'price'];

const Package = () => {
  const classes = useStyles();
  const theme = useTheme();

  //state
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataTemplate, setDataTemplate] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [alertt, setAlert] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState(null);
  const [drawerVariant, setDrawerVariant] = useState(null);

  //form
  const [selectedCategoryForm, setSelectedCategoryForm] = useState(null);
  //state package
  const [form, setForm] = useState({
    id: null,
    category: '',
    title: '',
    alias: '',
    templates: '',
    interval_time: '',
    included_description: '',
    additional_information: '',
    online_reservation_description: '',
    buy_this_product_description: '',
    description: '',
    is_online_reservation: false,
    is_can_reschedule: true,
    is_buy_this_product: false,
    minimal_reservation: 1,
    minimal_reschedule: 1,
    minimal_reservation_time: '00:00',
    minimal_reschedule_time: '00:00',
    tolerant: 0,
    enable_free_text: false,
    free_text_limit: "",
    price_text_per_char: "",
  });
  // state price
  const [formPrice, setFormPrice] = useState({
    id: null,
    base: 0,
    min: 0,
    max: 0,
    tax_type: 'percent',
    tax: 0,
    service_type: 'percent',
    service: 0,
  });

  //state included
  const [formIncluded, setFormIncluded] = useState({
    id: null,
    product: null,
    product_name: '',
    label: '',
    quantity: 0
  });
  const [dataIncluded, setDataIncluded] = useState([]);
  const [dataIncludedProduct, setDataIncludedProduct] = useState([]);
  const [openIncluded, setOpenIncluded] = useState(false);
  const [alertDeleteIncluded, setAlertDeleteIncluded] = useState(false);
  const [selectedIDIncluded, setSelectedIDIncluded] = useState(null);

  // state person setup
  const [formPersonSetup, setFormPersonSetup] = useState({
    id: null,
    parameter: '',
    value: 0
  });
  const [formClothSetup, setFormClothSetup] = useState({
    id: null,
    parameter: '',
    value: 0
  });
  const [formPoseSetup, setFormPoseSetup] = useState({
    id: null,
    parameter: '',
    value: 0
  });
  const [dataPersonSetup, setDataPersonSetup] = useState([
    { parameter: 'default_person', value: 0 },
    { parameter: 'min_person', value: 0 },
    { parameter: 'max_person', value: 0 },
    { parameter: 'fee_person', value: 0 },
    { parameter: 'extra_time_person', value: 0 },
    { parameter: 'applied_person', value: 0 }
  ]);
  const [dataClothSetup, setDataClothSetup] = useState([
    { parameter: 'default_cloth', value: 0 },
    { parameter: 'min_cloth', value: 0 },
    { parameter: 'max_cloth', value: 0 },
    { parameter: 'fee_cloth', value: 0 },
    { parameter: 'extra_time_cloth', value: 0 },
    { parameter: 'applied_cloth', value: 0 },
    { parameter: 'extra_cloth_by_person_limit', value: 0 },
    { parameter: 'extra_cloth_by_person_fee', value: 0 },
    { parameter: 'extra_cloth_by_person_time', value: 0 },
  ]);
  const [dataPoseSetup, setDataPoseSetup] = useState([
    { parameter: 'default_pose', value: 0 },
    { parameter: 'min_pose', value: 0 },
    { parameter: 'max_pose', value: 0 },
    { parameter: 'fee_pose', value: 0 },
    { parameter: 'extra_time_pose', value: 0 },
    { parameter: 'applied_pose', value: 0 }
  ]);
  const [openPersonSetup, setOpenPersonSetup] = useState(false);
  const [alertDeletePersonSetup, setAlertDeletePersonSetup] = useState(false);
  const [selectedIDPersonSetup, setSelectedIDPersonSetup] = useState(null);

  const [openClothSetup, setOpenClothSetup] = useState(false);
  const [alertDeleteClothSetup, setAlertDeleteClothSetup] = useState(false);
  const [selectedIDClothSetup, setSelectedIDClothSetup] = useState(null);

  const [openPoseSetup, setOpenPoseSetup] = useState(false);
  const [alertDeletePoseSetup, setAlertDeletePoseSetup] = useState(false);
  const [selectedIDPoseSetup, setSelectedIDPoseSetup] = useState(null);

  //state thumbnail
  const [uploadImage, setUploadImage] = useState({
    id: null,
    label: '',
    load_on_upload: 0,
    image_url: '',
    thumbnail: '',
    image: ''
  });

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setLoading] = React.useState(true);
  const [settingDefaultFreeTextLimit, setsettingDefaultFreeTextLimit] = useState(0);
  const [defaultFreePrincePerChar, setDefaultFreePrincePerChar] = useState(0);

  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState(null);

  useEffect(() => {
    getSettings();
    getCategories();
    getSubCategories();
    getTemplates();
    getProducts();
    getCities();
  }, []);

  const getSettings = () => {
    axios({
      method: 'GET',
      url: `${API}/settings`,
      headers: { 'Content-Type': 'application/json' }
    }).then(res => {
      setsettingDefaultFreeTextLimit(res.data.data.find(toggle => toggle.param === "DEFAULT_FREE_TEXT_LIMIT").value || null);
      setDefaultFreePrincePerChar(res.data.data.find(toggle => toggle.param === "DEFAULT_FREE_PRICE_PER_CHAR").value || null);
    }).catch(error => {
      console.log(error)
    })
  }

  const getSubCategories = async () => {
    const api = await fetch(`${API}/category/sub`);
    if (!api.ok) {
      handleShowSnackbar(api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setSubCategories(res.data.map(e => ({ parent: e.category_id, label: e.name, value: e.id })));
    } else {
      handleShowSnackbar(res.message);
    }
  };

  // API
  const getCategories = () => {
    axios({
      method: 'GET',
      url: `${API}/categories`,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          parsingCategories(response.data.data);
        } else {
          parsingCategories([]);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const getTemplates = () => {
    axios({
      method: 'GET',
      url: `${API}/template/templates`,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          parsingTemplate(response.data.data);
        } else {
          parsingTemplate([]);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const getPackages = (category, page) => {
    let url = `${API}/packages?category=${category.id}&limit=10&page=${page}`;
    axios({
      method: 'GET',
      url: url,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          setLoading(false);
          setPageCount(response.data.pagination);
          parsingPackages(response.data.data);
        } else {
          parsingPackages([]);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const AddPhoto = async () => {
    if (uploadImage.thumbnail !== '') {
      const url = `${API}/upload`;
      let bodyData = new FormData();
      bodyData.append('category', 'thumbnail');
      bodyData.append('file', uploadImage.image);
      bodyData.append('type', uploadImage.image['type']);
      const result = await axios.post(url, bodyData);

      let gambar = result.data.data;
      form.id == null ? AddPackage(gambar) : UpdatePackage(gambar);
    } else {
      form.id == null
        ? AddPackage(uploadImage.image_url)
        : UpdatePackage(uploadImage.image_url);
    }
  };

  async function AddPackage(url_gambar) {
    if (selectedCategoryForm === null) {
      alert('Harap pilih kategori');
    } else {
      // PACKAGE
      const dataPackage = {
        title: form.title,
        alias: form.alias,
        templates: selectedTemplate != null ? selectedTemplate.map(i => i.value).join() : null,
        interval_time: Number(form.interval_time),
        included_description: form.included_description,
        additional_information: form.additional_information,
        online_reservation_description: form.online_reservation_description,
        buy_this_product_description: form.buy_this_product_description,
        description: form.description,
        reservation_day_limit: form.minimal_reservation,
        reschedule_day_limit: form.minimal_reschedule,
        reservation_clock_limit: form.minimal_reservation_time + ":00",
        reschedule_clock_limit: form.minimal_reschedule_time + ":00",
        tolerant: Number(form.tolerant),
        enable_free_text: form.enable_free_text,
        free_text_limit: Number(form.free_text_limit),
        price_text_per_char: Number(form.price_text_per_char),
        subcategories: (selectedSubCategories || []).map(e => e.value)
      };

      axios({
        method: 'POST',
        url: `${API}/package?category=` + selectedCategoryForm.id,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataPackage
      })
        .then(response => {
          const dataPackage = response.data.data;

          // add person setup
          AddPersonSetup(dataPackage.id);
          AddClothSetup(dataPackage.id);
          AddPoseSetup(dataPackage.id);

          // THUMBNAIL
          const dataThumbnail = {
            package: dataPackage.id,
            image_thumbnails: [
              {
                label: uploadImage.label,
                image_url: url_gambar
              }
            ]
          };

          axios({
            method: 'POST',
            url: `${API}/package/image_thumbnail`,
            headers: { 'Content-Type': 'application/json' },
            data: dataThumbnail
          })
            .then(response => {
              // PRICE
              const dataPrice = {
                package: dataPackage.id,
                base: Number(formPrice.base),
                min: Number(formPrice.min),
                max: Number(formPrice.max),
                tax_type: formPrice.tax_type,
                tax: Number(formPrice.tax),
                service_type: formPrice.service_type,
                service: Number(formPrice.service)
              };

              axios({
                method: 'POST',
                url: `${API}/package/price`,
                headers: { 'Content-Type': 'application/json' },
                data: dataPrice
              })
                .then(response => {
                  // getPackages(selectedCategory)
                  // handleDrawerClose()
                  handleDrawerOpenForEdit(dataPackage.id);
                  alert('package berhasil ditambahkan');
                })
                .catch(error => {
                  alert(error);
                });
            })
            .catch(error => {
              alert(error);
            });
        })
        .catch(error => {
          alert(error);
        });
    }
  }

  async function UpdatePackage(url_gambar) {
    const dataPackage = {
      id: form.id,
      title: form.title,
      alias: form.alias,
      templates: selectedTemplate != null ? selectedTemplate.map(i => i.value).join() : null,
      interval_time: Number(form.interval_time),
      included_description: form.included_description,
      additional_information: form.additional_information,
      online_reservation_description: form.online_reservation_description,
      buy_this_product_description: form.buy_this_product_description,
      description: form.description,
      reservation_day_limit: form.minimal_reservation,
      reschedule_day_limit: form.minimal_reschedule,
      reservation_clock_limit: form.minimal_reservation_time + ":00",
      reschedule_clock_limit: form.minimal_reschedule_time + ":00",
      tolerant: Number(form.tolerant),
      enable_free_text: form.enable_free_text,
      free_text_limit: Number(form.free_text_limit),
      price_text_per_char: Number(form.price_text_per_char),
    };

    await axios({
      method: 'PUT',
      url: `${API}/package?category=` + selectedCategoryForm.id,
      data: dataPackage,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        // getPackages(selectedCategory)
        // handleDrawerClose()
      })
      .catch(error => {
        alert(error);
      });

    // PERSON SETUP
    EditPersonSetup();
    EditClothSetup();
    EditPoseSetup();

    // THUMBNAIL
    const dataThumbnail = {
      id: uploadImage.id,
      label: uploadImage.label,
      image_url: url_gambar
    };

    await axios({
      method: 'PUT',
      url: `${API}/package/image_thumbnail?package=` + form.id,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataThumbnail
    })
      .then(response => {
        // getPackages(selectedCategory);
        // handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });

    // PRICE
    const dataPrice = {
      id: formPrice.id,
      package: form.id,
      base: Number(formPrice.base),
      min: Number(formPrice.min),
      max: Number(formPrice.max),
      tax_type: formPrice.tax_type,
      tax: Number(formPrice.tax),
      service_type: formPrice.service_type,
      service: Number(formPrice.service)
    };

    await axios({
      method: 'PUT',
      url: `${API}/package/price`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataPrice
    })
      .then(response => {
        getPackages(selectedCategory);
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  }

  const deletePackage = id => {
    axios({
      method: 'DELETE',
      url: `${API}/package`,
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        id: id
      }
    })
      .then(response => {
        getPackages(selectedCategory);
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  // CRUD Person Setup
  const AddPersonSetup = id => {
    const dataPerson = {
      package: id,
      package_person: dataPersonSetup.map(v => {
        return {
          ...v,
          value: Number(v.value),
          package: id,
        };
      })
      // [
      //   {
      //     parameter: formPersonSetup.parameter,
      //     value: Number(formPersonSetup.value)
      //   }
      // ]
    };

    axios({
      method: 'POST',
      url: `${API}/package/person`,
      headers: { 'Content-Type': 'application/json' },
      data: dataPerson
    })
      .then(response => {
        // handleDrawerOpenForEdit(form.id);
        // handlePersonSetupClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const AddClothSetup = id => {
    const dataCloth = {
      package: id,
      package_cloth: dataClothSetup.map(v => {
        return {
          ...v,
          value: Number(v.value)
        };
      })
      // [
      //   {
      //     parameter: formPersonSetup.parameter,
      //     value: Number(formPersonSetup.value)
      //   }
      // ]
    };

    axios({
      method: 'POST',
      url: `${API}/package/cloth`,
      headers: { 'Content-Type': 'application/json' },
      data: dataCloth
    })
      .then(response => {
        // handleDrawerOpenForEdit(form.id);
        // handlePersonSetupClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const AddPoseSetup = id => {
    const dataPose = {
      package: id,
      package_pose: dataPoseSetup.map(v => {
        return {
          ...v,
          value: Number(v.value),
          package: id,
        };
      })
      // [
      //   {
      //     parameter: formPersonSetup.parameter,
      //     value: Number(formPersonSetup.value)
      //   }
      // ]
    };

    axios({
      method: 'POST',
      url: `${API}/package/pose`,
      headers: { 'Content-Type': 'application/json' },
      data: dataPose
    })
      .then(response => {
        // handleDrawerOpenForEdit(form.id);
        // handlePersonSetupClose();
      })
      .catch(error => {
        alert(error);
      });
  };



  const EditPersonSetup = id => {
    let dataPerson = dataPersonSetup
      .filter(v => typeof v.value === 'string')
      .map(v => {
        return {
          ...v,
          value: Number(v.value),
          package: id,
        };
      });

    dataPerson.forEach(v => {
      axios({
        method: 'PUT',
        url: `${API}/package/person?package=` + id,
        headers: { 'Content-Type': 'application/json' },
        data: v
      })
        .then(response => { })
        .catch(error => {
          alert(error);
        });
    });
  };

  const EditClothSetup = id => {
    let dataCloth = dataClothSetup
      .filter(v => typeof v.value === 'string')
      .map(v => {
        return {
          ...v,
          value: Number(v.value)
        };
      });

    dataCloth.forEach(v => {
      if (v.id == undefined || v.id == "") {
        axios({
          method: 'POST',
          url: `${API}/package/cloth`,
          headers: { 'Content-Type': 'application/json' },
          data: {
            package: id,
            package_cloth: [v],
          }
        })
          .then(response => {
            // handleDrawerOpenForEdit(form.id);
            // handlePersonSetupClose();
          })
          .catch(error => {
            alert(error);
          });
      } else {
        axios({
          method: 'PUT',
          url: `${API}/package/cloth?package=` + id,
          headers: { 'Content-Type': 'application/json' },
          data: v
        })
          .then(response => { })
          .catch(error => {
            alert(error);
          });
      }
    });
  };

  const EditPoseSetup = id => {
    let dataPose = dataPoseSetup
      .filter(v => typeof v.value === 'string')
      .map(v => {
        return {
          ...v,
          value: Number(v.value),
          package: id,
        };
      });

    dataPose.forEach(v => {
      axios({
        method: 'PUT',
        url: `${API}/package/pose?package=` + id,
        headers: { 'Content-Type': 'application/json' },
        data: v
      })
        .then(response => { })
        .catch(error => {
          alert(error);
        });
    });
  };

  const DeletePersonSetup = id => {
    axios({
      method: 'DELETE',
      url: `${API}/package/person`,
      headers: { 'Content-Type': 'application/json' },
      params: { id: id }
    })
      .then(response => {
        handleDrawerOpenForEdit(form.id);
        setAlertDeletePersonSetup(false);
      })
      .catch(error => {
        alert(error);
      });
  };

  const DeleteClothSetup = id => {
    axios({
      method: 'DELETE',
      url: `${API}/package/cloth`,
      headers: { 'Content-Type': 'application/json' },
      params: { id: id }
    })
      .then(response => {
        handleDrawerOpenForEdit(form.id);
        setAlertDeleteClothSetup(false);
      })
      .catch(error => {
        alert(error);
      });
  };

  const DeletePoseSetup = id => {
    axios({
      method: 'DELETE',
      url: `${API}/package/pose`,
      headers: { 'Content-Type': 'application/json' },
      params: { id: id }
    })
      .then(response => {
        handleDrawerOpenForEdit(form.id);
        setAlertDeletePoseSetup(false);
      })
      .catch(error => {
        alert(error);
      });
  };
  ////////////////////

  //CRUD Included
  const getProducts = () => {
    axios({
      method: 'GET',
      url: `${API}/package/products`,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          setDataIncludedProduct(response.data.data);
        } else {
          setDataIncludedProduct([]);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const AddIncluded = () => {
    const dataIncluded = {
      package: form.id,
      package_include: [
        {
          product: formIncluded.product,
          label: formIncluded.label,
          quantity: Number(formIncluded.quantity)
        }
      ]
    };

    axios({
      method: 'POST',
      url: `${API}/package/include`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataIncluded
    })
      .then(response => {
        handleDrawerOpenForEdit(form.id);
        handleIncludedClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const EditIncluded = () => {
    const dataIncluded = {
      id: formIncluded.id,
      package: form.id,
      product: formIncluded.product,
      label: formIncluded.label,
      quantity: Number(formIncluded.quantity)
    };

    axios({
      method: 'PUT',
      url: `${API}/package/include`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataIncluded
    })
      .then(response => {
        handleDrawerOpenForEdit(form.id);
        handleIncludedClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const DeleteIncluded = id => {
    axios({
      method: 'DELETE',
      url: `${API}/package/include`,
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        id: id
      }
    })
      .then(response => {
        handleDrawerOpenForEdit(form.id);
        setAlertDeleteIncluded(false);
      })
      .catch(error => {
        alert(error);
      });
  };
  ///////////////

  //

  // parsing
  const parsingPackages = data => {
    // setAllData(data)
    const newData = data.map(v => {
      return {
        id: v.id,
        title: v.title,
        alias: v.alias,
        subcategory: v.subcategory || [],
        templates: v.templates,
        interval_time: v.interval_time + ' menit',
        price: `Rp ${v.price.base.toLocaleString(navigator.language, {
          minimumFractionDigits: 2
        })}`,
        included_description: v.included_description,
        additional_information: v.additional_information,
        online_reservation_description: v.online_reservation_description,
        buy_this_product_description: v.buy_this_product_description,
        description: v.description,
        publish: v.publish,
        variant_status: v.variant_status,
      };
    });
    setData(newData);
  };

  const parsingCategories = data => {
    const newData = data.map(v => {
      return {
        ...v,
        label: v.name,
        value: v.id
      };
    });

    newData.unshift({ id: '', value: '', label: 'All categories' });
    setDataCategories(newData);
    setSelectedCategory(newData[0]);
    // setSelectedCategoryForm(newData[0])
    getPackages(newData[0], page);
  };

  const parsingTemplate = data => {
    const newData = data.map(v => {
      return {
        ...v,
        label: v.name,
        value: v.id
      };
    });

    setDataTemplate(newData);
    // setSelectedTemplate(newData[0]);
    // setSelectedCategoryForm(newData[0])
    // getPackages(newData[0], page);
  };
  //

  // handle
  const handleDrawerOpen = (isEdit = false) => {
    if (isEdit) {
      setSelectedCategoryForm(null);
    }
    setIsManagePackageCity(false);
    setOpen(true);
    dataCategories.shift();
  };

  const handleDrawerOpenForEdit = id => {
    const fromDataTable = data.find(v => v.id === id)
    let selectPackage = {};

    getMarginPackage(id);

    axios({
      method: 'GET',
      url: `${API}/package?id=` + id,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          selectPackage = response.data.data;

          setForm({
            ...form,
            id: selectPackage.id,
            title: selectPackage.title,
            alias: selectPackage.alias,
            interval_time: selectPackage.interval_time,
            included_description: selectPackage.included_description,
            additional_information: selectPackage.additional_information,
            online_reservation_description:
              selectPackage.online_reservation_description,
            buy_this_product_description:
              selectPackage.buy_this_product_description,
            description: selectPackage.description,
            banner_image_url: selectPackage.banner_image_url,
            is_online_reservation: selectPackage.is_online_reservation,
            is_can_reschedule: selectPackage.is_can_reschedule,
            is_buy_this_product: selectPackage.is_buy_this_product,
            minimal_reservation: selectPackage.reservation_day_limit,
            minimal_reschedule: selectPackage.reschedule_day_limit,
            minimal_reservation_time: selectPackage.reservation_clock_limit,
            minimal_reschedule_time: selectPackage.reschedule_clock_limit,
            tolerant: selectPackage.tolerant,
            enable_free_text: selectPackage.enable_free_text,
            free_text_limit: selectPackage.free_text_limit || settingDefaultFreeTextLimit,
            price_text_per_char: selectPackage.price_text_per_char || defaultFreePrincePerChar,
          });

          setFormPrice({
            id: selectPackage.price.id,
            base: selectPackage.price.base,
            min: selectPackage.price.min,
            max: selectPackage.price.max,
            tax_type: selectPackage.price.tax_type,
            tax: selectPackage.price.tax,
            service_type: selectPackage.price.service_type,
            service: selectPackage.price.service,
          });
          //state thumbnail
          // setUploadImage({
          //   ...uploadImage,
          //   id: selectPackage.image_thumbnails[0].id,
          //   label: selectPackage.image_thumbnails[0].label,
          //   image_url: selectPackage.image_thumbnails[0].image_url,
          // })
          setUploadImage(selectPackage.image_thumbnails);
          // setPhotoBanner({
          //   image_url: selectPackage.banner_image_url,
          //   img: new File(selectPackage.banner_image_url),
          //   hasChange: false
          // })

          let clothData = selectPackage.cloth_datas;
          const withClothPerson = clothData.filter(f => f.parameter.includes("extra_cloth_by_person"));
          const withoutClothPerson = clothData.filter(f => !f.parameter.includes("extra_cloth_by_person"));
          clothData = [...withoutClothPerson, ...withClothPerson];

          setDataPersonSetup(selectPackage.person_datas);
          setDataClothSetup(clothData);
          setDataPoseSetup(selectPackage.pose_datas);
          setDataIncluded(selectPackage.included);

          // setSelectedCategoryForm(dataCategories.find(category => category.id === selectedCategory.id))
          let selectedCategoryFromResponse = [];
          selectPackage.category.map(v => {
            dataCategories.forEach(w => {
              if (v.id === w.id) {
                selectedCategoryFromResponse.push(w);
              }
            });
          });
          setSelectedCategoryForm(selectedCategoryFromResponse);
          setTempSelectedCategoryForEdit(selectedCategoryFromResponse);
          setSelectedTemplate(dataTemplate.filter(f => selectPackage.templates.split(",").map(m => Number(m)).includes(f.value)))

          console.log(fromDataTable.subcategory)
          if (fromDataTable.subcategory) {
            console.log("ADA")
            console.log(fromDataTable.subcategory.map(e => ({ parent: e.category_id, label: e.name, value: e.id })));
            setSelectedSubCategories(fromDataTable.subcategory.map(e => ({ parent: e.category_id, label: e.name, value: e.id })))
          }

          handleDrawerOpen();
        } else {
          alert('ada kesalahan saat load data, harap hubungi developer.');
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  useEffect(() => {
    let isExist = false;
    dataPersonSetup.map(v => {
      if (v.parameter === 'applied_person') {
        isExist = true;
      }
    });
    if (!isExist) {
      // hardcoded new one, because previously it doesn't exist from BE
      setDataPersonSetup([
        ...dataPersonSetup,
        { parameter: 'applied_person', value: 0 }
      ]);
    }
  }, [dataPersonSetup]);

  useEffect(() => {
    let isExist = false;
    let isClothPersonFeeExist = false;
    let isClothPersonLimit = false;
    let isClothPersonTime = false;
    dataClothSetup.map(v => {
      if (v.parameter === 'applied_cloth') { isExist = true; }
      if (v.parameter === 'extra_cloth_by_person_fee') { isClothPersonFeeExist = true; }
      if (v.parameter === 'extra_cloth_by_person_limit') { isClothPersonLimit = true; }
      if (v.parameter === 'extra_cloth_by_person_time') { isClothPersonTime = true; }
    });
    if (!isExist) {
      // hardcoded new one, because previously it doesn't exist from BE
      setDataClothSetup([
        ...dataClothSetup,
        { parameter: 'applied_cloth', value: 0 }
      ]);
    }

    if (!isClothPersonFeeExist) {
      // hardcoded new one, because previously it doesn't exist from BE
      setDataClothSetup([
        ...dataClothSetup,
        { parameter: 'extra_cloth_by_person_fee', value: 0 }
      ]);
    }

    if (!isClothPersonLimit) {
      // hardcoded new one, because previously it doesn't exist from BE
      setDataClothSetup([
        ...dataClothSetup,
        { parameter: 'extra_cloth_by_person_limit', value: 0 }
      ]);
    }

    if (!isClothPersonTime) {
      // hardcoded new one, because previously it doesn't exist from BE
      setDataClothSetup([
        ...dataClothSetup,
        { parameter: 'extra_cloth_by_person_time', value: 0 }
      ]);
    }
  }, [dataClothSetup]);

  useEffect(() => {
    let isExist = false;
    dataPoseSetup.map(v => {
      if (v.parameter === 'applied_pose') {
        isExist = true;
      }
    });
    if (!isExist) {
      // hardcoded new one, because previously it doesn't exist from BE
      setDataPoseSetup([
        ...dataPoseSetup,
        { parameter: 'applied_pose', value: 0 }
      ]);
    }
  }, [dataPoseSetup]);

  const [
    tempSelectedCategoryForEdit,
    setTempSelectedCategoryForEdit
  ] = useState(null);

  const handleDrawerClose = () => {
    dataCategories.unshift({ id: '', value: '', label: 'All categories' });
    setOpen(false);

    setSelectedTemplate(null);
    setSelectedCategoryForm(null);
    setSelectedSubCategories(null);

    setForm({
      id: null,
      category: '',
      title: '',
      alias: '',
      templates: '',
      interval_time: '',
      included_description: '',
      additional_information: '',
      online_reservation_description: '',
      buy_this_product_description: '',
      description: '',
      banner_image_url: null,
      is_buy_this_product: false,
      is_online_reservation: false,
      is_can_reschedule: true,
      tolerant: 0,
      enable_free_text: false,
      free_text_limit: "",
      price_text_per_char: "",
    });
    // state price
    setFormPrice({
      id: null,
      base: 0,
      min: 0,
      max: 0,
      tax_type: 'percent',
      tax: 0,
      service_type: 'percent',
      service: 0
    });

    //state thumbnail
    setUploadImage({
      id: null,
      label: '',
      image_url: '',
      load_on_upload: 0,
      thumbnail: '',
      image: ''
    });

    setDataIncluded([]);
    setDataPersonSetup([
      { parameter: 'default_person', value: 0 },
      { parameter: 'min_person', value: 0 },
      { parameter: 'max_person', value: 0 },
      { parameter: 'fee_person', value: 0 },
      { parameter: 'extra_time_person', value: 0 }
    ]);
    setDataClothSetup([
      { parameter: 'default_cloth', value: 0 },
      { parameter: 'min_cloth', value: 0 },
      { parameter: 'max_cloth', value: 0 },
      { parameter: 'fee_cloth', value: 0 },
      { parameter: 'extra_time_cloth', value: 0 },
      { parameter: 'extra_cloth_by_person_limit', value: 0 },
      { parameter: 'extra_cloth_by_person_fee', value: 0 },
      { parameter: 'extra_cloth_by_person_time', value: 0 },
    ]);
    setDataPoseSetup([
      { parameter: 'default_pose', value: 0 },
      { parameter: 'min_pose', value: 0 },
      { parameter: 'max_pose', value: 0 },
      { parameter: 'fee_pose', value: 0 },
      { parameter: 'extra_time_pose', value: 0 }
    ]);
    setPhoto([]);
    setPhotoBanner(null);
  };

  const handleChangeForm = name => e => {
    setForm({
      ...form,
      [name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };

  const handleChangeFormThumbnail = name => e => {
    setUploadImage({
      ...uploadImage,
      [name]: e.target.value
    });
  };

  const handleChangeFormPrice = name => e => {
    setFormPrice({
      ...formPrice,
      [name]: e.target.value
    });
  };

  // const handleChangeFormPersonSetup = (name) => (e) => {
  //   setFormPersonSetup({
  //     ...formPersonSetup,
  //     [name]: e.target.value
  //   });
  // };

  const handleChangeFormPersonSetup = (name, index) => e => {
    const { value } = e.target;
    setDataPersonSetup(
      dataPersonSetup.map((v, i) =>
        i !== index
          ? v
          : {
            ...v,
            [name]: value
          }
      )
    );
  };

  const handleChangeFormClothSetup = (name, index) => e => {
    const { value } = e.target;
    setDataClothSetup(
      dataClothSetup.map((v, i) =>
        i !== index
          ? v
          : {
            ...v,
            [name]: value
          }
      )
    );
  };

  const handleChangeFormPoseSetup = (name, index) => e => {
    const { value } = e.target;
    setDataPoseSetup(
      dataPoseSetup.map((v, i) =>
        i !== index
          ? v
          : {
            ...v,
            [name]: value
          }
      )
    );
  };

  const handleEditPersonSetup = id => {
    const selectPersonSetup = dataPersonSetup.find(v => v.id === id);
    setFormPersonSetup({
      id: selectPersonSetup.id,
      parameter: selectPersonSetup.parameter,
      value: selectPersonSetup.value
    });
    setOpenPersonSetup(true);
  };

  const handleEditClothSetup = id => {
    const selectClothSetup = dataClothSetup.find(v => v.id === id);
    setFormClothSetup({
      id: selectClothSetup.id,
      parameter: selectClothSetup.parameter,
      value: selectClothSetup.value
    });
    setOpenClothSetup(true);
  };

  const handleEditPoseSetup = id => {
    const selectPoseSetup = dataPoseSetup.find(v => v.id === id);
    setFormPoseSetup({
      id: selectPoseSetup.id,
      parameter: selectPoseSetup.parameter,
      value: selectPoseSetup.value
    });
    setOpenPoseSetup(true);
  };

  const handlePersonSetupClose = () => {
    setOpenPersonSetup(false);

    // state person setup
    setFormPersonSetup({
      id: null,
      parameter: '',
      value: 0
    });
  };

  const handleClothSetupClose = () => {
    setOpenClothSetup(false);

    // state cloth setup
    setFormClothSetup({
      id: null,
      parameter: '',
      value: 0
    });
  };

  const handlePoseSetupClose = () => {
    setOpenPoseSetup(false);

    // state Pose setup
    setFormPoseSetup({
      id: null,
      parameter: '',
      value: 0
    });
  };

  //handle Included
  const handleChangeFormIncluded = name => e => {
    setFormIncluded({
      ...formIncluded,
      [name]: e.target.value
    });
  };

  const handleEditIncluded = id => {
    const selectIncluded = dataIncluded.find(v => v.id === id);
    setFormIncluded({
      id: selectIncluded.id,
      product: selectIncluded.product.id,
      product_name: selectIncluded.product.name,
      label: selectIncluded.label,
      quantity: selectIncluded.quantity
    });
    setOpenIncluded(true);
  };

  const handleIncludedClose = () => {
    setOpenIncluded(false);

    //state included
    setFormIncluded({
      id: null,
      product: null,
      product_name: '',
      label: '',
      quantity: 0
    });
  };

  const handleSelectedCategoryForm = category => {
    setSelectedCategoryForm(category);
    const selectedParent = category.map(e => e.value);
    const newSelectedSub = selectedSubCategories?.filter(f => selectedParent.includes(f.parent)) || [];
    setSelectedSubCategories(newSelectedSub);
  };

  const handleSelectedSubCategoryForm = subcategories => {
    setSelectedSubCategories(subcategories);
  };

  const handleSelectedCategory = category => {
    setSelectedCategory(category);
    getPackages(category, 1);
  };

  const handleSelectedTemplate = template => {
    setSelectedTemplate(template);
  };

  const onChangeGambar = value => {
    let gambar = value.target.files;
    if (gambar.length !== 0) {
      setUploadImage({
        ...uploadImage,
        thumbnail: URL.createObjectURL(gambar[0]),
        image: gambar[0]
      });
    }
  };
  //

  const handleOnNextPage = () => {
    setPage(page + 1);
    setData([]);
    setLoading(true);
    getPackages(selectedCategory, page + 1);
  };

  const handleOnBackPage = () => {
    setPage(page - 1);
    setData([]);
    setLoading(true);
    getPackages(selectedCategory, page - 1);
  };

  const [photo, setPhoto] = useState([]);

  const onChooseImage = value => {
    const img = value.target.files;
    if (img.length !== 0) {
      const imgObj = {
        image_url: URL.createObjectURL(img[0]),
        label: '',
        img: img[0],
        error: false
      };
      if (photo.length > 0) {
        setPhoto([...photo, imgObj]);
      } else {
        setPhoto([imgObj]);
      }
    }
  };

  const [photoBanner, setPhotoBanner] = useState(null);

  const onChooseImageBanner = value => {
    const img = value.target.files;
    const imgObj = {
      image_url: URL.createObjectURL(img[0]),
      img: img[0],
      hasChange: true
    };
    setPhotoBanner(imgObj);
  };

  const onUpdateImage = index => value => {
    const img = value.target.files;
    if (img.length !== 0) {
      const imgObj = {
        image_url: URL.createObjectURL(img[0]),
        label: photo[index].label ? photo[index].label : '',
        img: img[0],
        error: false
      };
      if (photo.length > 0) {
        let tempPhotos = photo.slice(0, photo.length);
        tempPhotos[index] = imgObj;
        setPhoto(tempPhotos);
      } else {
        setPhoto([imgObj]);
      }
    }
  };

  const fileRef = React.createRef();
  const bannerRef = React.createRef();

  const onDeleteImage = index => {
    let tempPhoto = photo.slice(0, photo.length);
    tempPhoto.splice(index, 1);
    setPhoto([...tempPhoto]);
  };

  const handleLabelChange = (index, v) => e => {
    if (photo.length > 0) {
      let tempArrPhotos = photo.slice(0, photo.length);
      const imgObj = {
        image_url: v.image_url,
        label: e.target.value,
        img: v.img,
        error: false
      };
      tempArrPhotos[index] = imgObj;
      setPhoto(tempArrPhotos);
    }
  };

  const handleLabelCurrentImageChange = (index, v) => e => {
    if (uploadImage.length > 0) {
      let tempUploadImage = uploadImage.slice(0, uploadImage.length);
      const imgObj = {
        image_url: v.image_url,
        label: e.target.value,
        id: v.id,
        error: false
      };
      tempUploadImage[index] = imgObj;
      setUploadImage(tempUploadImage);
    }
  };

  const validateLabelCurrentPhotos = () => {
    let tempPhotos = uploadImage.slice(0, uploadImage.length);
    let isValid = true;
    tempPhotos.forEach(v => {
      if (v.label.length === 0) {
        isValid = false;
      }
    });
    return isValid;
  };

  const validateLabelNewPhotos = () => {
    let tempPhotos = photo.slice(0, photo.length);
    let isValid = true;
    tempPhotos.forEach(v => {
      if (v.label.length === 0) {
        isValid = false;
      }
    });
    return isValid;
  };

  const validateIntervalTime = () => {
    if (parseInt(form.interval_time) % 5 !== 0) {
      return false;
    }
    return true;
  };

  const uploadThumbs = async () => {
    if (!selectedCategoryForm) {
      handleShowSnackbar(true, 'Harap pilih kategori terlebih dahulu');
      return;
    }
    if (!validateIntervalTime()) {
      intervalTimeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      handleShowSnackbar(true, 'Durasi foto harus kelipatan 5');
      return;
    }

    let banner = '';
    if (photoBanner && photoBanner.hasChange && form.id) {
      // if (!photoBanner) {
      //   handleShowSnackbar(true, 'Banner tidak boleh kosong!');
      //   return;
      // }
      // EDIT: Upload with new Image
      const body = new FormData();
      body.append('category', 'thumbnail');
      body.append('file', photoBanner.img);
      body.append('type', photoBanner.img ? photoBanner.img['type'] : 'jpeg');
      const uploadBanner = await fetch(`${API}/upload`, {
        method: 'POST',
        body
      });
      const res = await uploadBanner.json();
      if (res && res.response_code === '00') {
        banner = res.data ? res.data : '';
      } else {
        handleShowSnackbar(true, 'Gagal unggah banner package!');
        return;
      }
    } else if (form.id) {
      // EDIT: Not upload, just pass the value
      if (form.banner_image_url) {
        banner = form.banner_image_url ? form.banner_image_url : '';
      }
      // else {
      //   handleShowSnackbar(true, 'Banner tidak boleh kosong!');
      //   return;
      // }
    } else if (photoBanner && photoBanner.hasChange) {
      // if (!photoBanner) {
      //   handleShowSnackbar(true, 'Banner tidak boleh kosong!');
      //   return;
      // }
      // NEW : Just upload
      const body = new FormData();
      body.append('category', 'thumbnail');
      body.append('file', photoBanner.img);
      body.append('type', photoBanner.img ? photoBanner.img['type'] : 'jpeg');
      const uploadBanner = await fetch(`${API}/upload`, {
        method: 'POST',
        body
      });
      const res = await uploadBanner.json();
      if (res && res.response_code === '00') {
        banner = res.data ? res.data : '';
      } else {
        handleShowSnackbar(true, 'Gagal unggah banner package!');
        return;
      }
    } else {
      banner = '';
      // handleShowSnackbar(true, 'Banner tidak boleh kosong!');
      // return;
    }

    if (uploadImage && uploadImage.length > 0 && form.id) {
      if (validateLabelCurrentPhotos()) {
        let updatedImage = [];
        uploadImage.map(async (v, i) => {
          const body = {
            id: v.id,
            label: v.label,
            image_url: v.image_url
          };
          const api = await fetch(
            `${API}/package/image_thumbnail?package=${form.id}`,
            {
              method: 'PUT',
              body: JSON.stringify(body),
              headers: {
                'Content-Type': 'application/json'
              }
            }
          );
          const res = await api.json();
          if (res.response_code === '00') {
            if (updatedImage.length > 0) {
              updatedImage = [...updatedImage, res.data];
            } else {
              updatedImage = [res.data];
            }
          }
          if (
            updatedImage.length === uploadImage.length &&
            photo.length === 0
          ) {
            updatePackage([], banner);
          }
        });
      } else {
        let tempPhotos = uploadImage.slice(0, uploadImage.length);
        tempPhotos.map(v => {
          if (v.label.length === 0) {
            v.error = true;
          }
        });
        setUploadImage(tempPhotos);
        labelRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    if (photo && photo.length > 0) {
      if (validateLabelNewPhotos()) {
        let uploadedPhotos = [];
        photo.map(async (v, i) => {
          // Validate the Label First
          if (v.label && v.label.length > 0) {
            setDisableWhenImageUpload(true);
            const body = new FormData();
            body.append('category', 'thumbnail');
            body.append('file', v.img);
            body.append('type', v.img ? v.img['type'] : 'jpeg');

            const api = await fetch(`${API}/upload`, {
              method: 'POST',
              body
            });
            const res = await api.json();

            if (res.response_code === '00') {
              const data = { label: v.label, image_url: res.data };
              if (uploadedPhotos.length > 0) {
                uploadedPhotos = [...uploadedPhotos, data];
              } else {
                uploadedPhotos = [data];
              }
            }
          } else {
            let tempPhotos = photo.slice(0, photo.length);
            tempPhotos.map(v => {
              if (v.label.length === 0) {
                v.error = true;
              }
            });
            setPhoto(tempPhotos);
            labelRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
            setDisableWhenImageUpload(false);
            return;
          }
          if (form.id) {
            // Update Package
            if (
              uploadedPhotos.length > 0 &&
              uploadedPhotos.length === photo.length
            ) {
              updatePackage(uploadedPhotos, banner);
            } else {
              console.log('error');
            }
          } else {
            if (
              uploadedPhotos.length > 0 &&
              uploadedPhotos.length === photo.length
            ) {
              createNewPackage(uploadedPhotos, banner);
            } else {
              console.log('error');
            }
          }
        });
      } else {
        setDisableWhenImageUpload(false);
        let tempPhotos = photo.slice(0, photo.length);
        tempPhotos.map(v => {
          if (v.label.length === 0) {
            v.error = true;
          }
        });
        setPhoto(tempPhotos);
        labelRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const handleShowSnackbar = (status, message) => {
    setMessageSnackbar(message);
    setAlert(status);
  };

  const createNewPackage = async (pictures, banner) => {
    const data = {
      title: form.title,
      alias: form.alias,
      templates: selectedTemplate != null ? selectedTemplate.map(i => i.value).join() : null,
      interval_time: Number(form.interval_time),
      included_description: form.included_description,
      additional_information: form.additional_information,
      online_reservation_description: form.online_reservation_description,
      buy_this_product_description: form.buy_this_product_description,
      description: form.description,
      banner_image_url: banner,
      is_online_reservation: form.is_online_reservation || false,
      is_can_reschedule: form.is_can_reschedule || false,
      is_buy_this_product: form.is_buy_this_product || false,
      tolerant: Number(form.tolerant),
      enable_free_text: form.enable_free_text,
      free_text_limit: Number(form.free_text_limit),
      price_text_per_char: Number(form.price_text_per_char),
      publish: true,
      subcategories: (selectedSubCategories || []).map(e => e.value)
    };

    const api = await fetch(`${API}/package`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const res = await api.json();

    if (res.response_code === '00') {
      const packData = res.data;

      selectedCategoryForm.map(async v => {
        const bodyCategoryPackage = {
          package: packData.id,
          category: v.id
        };

        const apiCategory = await fetch(`${API}/package/category`, {
          method: 'POST',
          body: JSON.stringify(bodyCategoryPackage),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const resCategory = await apiCategory.json();
      });

      let imgThumbnails = [];
      if (pictures && pictures.length > 0) {
        pictures.map((v, i) => {
          if (imgThumbnails.length > 0) {
            imgThumbnails = [...imgThumbnails, v];
          } else {
            imgThumbnails = [v];
          }
        });
      }

      if (imgThumbnails.length === 0) {
        console.log('error length 0', pictures);
        console.log('error length 0');
        return;
      }

      // Thumbs POST Section
      const thumbnailData = {
        package: packData.id,
        image_thumbnails: imgThumbnails
      };

      const thumbsApi = fetch(`${API}/package/image_thumbnail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(thumbnailData)
      });

      // person setup post section
      AddPersonSetup(packData.id);
      AddClothSetup(packData.id);
      AddPoseSetup(packData.id);

      // Price POST Section
      const priceData = {
        package: packData.id,
        base: Number(formPrice.base),
        min: Number(formPrice.min),
        max: Number(formPrice.max),
        tax_type: formPrice.tax_type,
        tax: Number(formPrice.tax),
        service_type: formPrice.service_type,
        service: Number(formPrice.service)
      };

      const priceApi = fetch(`${API}/package/price`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(priceData)
      });

      // Parallel Network Request
      Promise.all([thumbsApi, priceApi])
        .then(([_thumbs, _price]) => {
          setDisableWhenImageUpload(false);
          setPhoto([]);
          handleDrawerOpenForEdit(packData.id);
          getPackages(selectedCategory, 1);
        })
        .catch(err => console.log(err));
    }
  };

  const updatePackage = async (pics, banner) => {
    // Delete whole category
    tempSelectedCategoryForEdit.map(async v => {
      const deleteCategoryBody = {
        package: form.id,
        category: v.id
      };
      const apiDeleteCategory = await fetch(`${API}/package/category`, {
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(deleteCategoryBody),
        method: 'DELETE'
      });
      const resDeleteCategory = await apiDeleteCategory.json();
    });

    const data = {
      id: form.id,
      title: form.title,
      alias: form.alias,
      templates: selectedTemplate != null ? selectedTemplate.map(i => i.value).join() : null,
      interval_time: Number(form.interval_time),
      included_description: form.included_description,
      additional_information: form.additional_information,
      online_reservation_description: form.online_reservation_description,
      buy_this_product_description: form.buy_this_product_description,
      description: form.description,
      banner_image_url: banner,
      is_online_reservation: form.is_online_reservation || false,
      is_can_reschedule: form.is_can_reschedule || false,
      is_buy_this_product: form.is_buy_this_product || false,
      reservation_day_limit: Number(form.minimal_reservation),
      reschedule_day_limit: Number(form.minimal_reschedule),
      reservation_clock_limit: form.minimal_reservation_time,
      reschedule_clock_limit: form.minimal_reschedule_time,
      tolerant: Number(form.tolerant),
      enable_free_text: form.enable_free_text,
      free_text_limit: Number(form.free_text_limit),
      price_text_per_char: Number(form.price_text_per_char),
      publish: true,
      subcategories: (selectedSubCategories || []).map(e => e.value),
    };

    const api = await fetch(
      `${API}/package?category=${selectedCategoryForm.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    );

    const res = await api.json();

    if (res.response_code === '00') {
      const packData = res.data;

      selectedCategoryForm.map(async v => {
        const bodyCategoryPackage = {
          package: packData.id,
          category: v.id
        };

        const apiCategory = await fetch(`${API}/package/category`, {
          method: 'POST',
          body: JSON.stringify(bodyCategoryPackage),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const resCategory = await apiCategory.json();
      });

      let imgThumbnails = [];
      if (pics && pics.length > 0) {
        pics.map((v, i) => {
          if (imgThumbnails.length > 0) {
            imgThumbnails = [...imgThumbnails, v];
          } else {
            imgThumbnails = [v];
          }
        });
      }

      let thumbsApi = null;

      if (imgThumbnails.length > 0) {
        // Thumbs POST Section
        const thumbnailData = {
          package: packData.id,
          image_thumbnails: imgThumbnails
        };

        thumbsApi = await fetch(`${API}/package/image_thumbnail`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(thumbnailData)
        });
      }

      // Person setup Edit section
      EditPersonSetup(packData.id);
      EditClothSetup(packData.id);
      EditPoseSetup(packData.id);

      // Price POST Section
      const priceData = {
        id: formPrice.id,
        package: packData.id,
        base: Number(formPrice.base),
        min: Number(formPrice.min),
        max: Number(formPrice.max),
        tax_type: formPrice.tax_type,
        tax: Number(formPrice.tax),
        service_type: formPrice.service_type,
        service: Number(formPrice.service)
      };

      const priceApi = fetch(`${API}/package/price`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(priceData)
      });

      // Parallel Network Request
      if (thumbsApi) {
        Promise.all([thumbsApi, priceApi])
          .then(([_thumbs, _price]) => {
            setDisableWhenImageUpload(false);
            setPhoto([]);
            getPackages(selectedCategory, 1);
            handleDrawerClose();
          })
          .catch(err => console.log(err));
      } else {
        Promise.all([priceApi])
          .then(([_price]) => {
            setDisableWhenImageUpload(false);
            setPhoto([]);
            getPackages(selectedCategory, 1);
            handleDrawerClose();
          })
          .catch(err => console.log(err));
      }
    }
  };

  const [deleteImage, setDeleteImage] = useState({
    show: false,
    id: -1,
    pos: -1
  });

  const handleDeleteImage = async (id, pos) => {
    const api = await fetch(`${API}/package/image_thumbnail?id=${id}`, {
      method: 'DELETE'
    });

    const res = await api.json();

    if (res.response_code === '00') {
      uploadImage.splice(pos, 1);
      setDeleteImage({ show: false, id: -1, pos: -1 });
    }
  };

  const labelRef = React.createRef();
  const intervalTimeRef = React.createRef();
  const minimalReservationRef = React.createRef();
  const minimalRescheduleRef = React.createRef();
  const [disableWhenImageUpload, setDisableWhenImageUpload] = useState(false);

  useEffect(() => {
    // console.log(disableWhenImageUpload);
  }, [disableWhenImageUpload]);

  // Package City
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityPackage, setCityPackage] = useState([]);
  const [isManagePackageCity, setIsManagePackageCity] = useState(false);
  const [selectedCityDrawer, setSelectedCityDrawer] = useState(null);
  const [searchPackage, setSearchPackage] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [tempPackageByCity, setTempPackageByCity] = useState([]);
  const [isCreate, setIsCreate] = useState(true);

  const handleKeyDownCityPackage = e => {
    if (e.key === 'Enter') {
      // do search
      getAllPackages();
    }
  };

  const getAllPackages = async () => {
    // if (searchPackage && searchPackage.length > 0) {
    const api = await fetch(
      `${API}/packages?query=${searchPackage}&page=1&limit=0`
    );
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      res.data.map(v => {
        v.is_selected = false;
        v.is_disabled = false;
      });
      // setSearchResult(res.data);
      getTempPackageByCity(
        selectedCityDrawer ? selectedCityDrawer.id : cities[0].id,
        res.data
      );
    } else {
      handleShowSnackbar(true, res.message);
    }
    // } else {
    //   setSearchResult([]);
    // }
  };

  const getCities = async () => {
    const api = await fetch(`${API}/admin/cities`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      res.data.map(v => {
        v.label = v.name;
        v.value = v.id;
      });
      setCities(res.data);
      setSelectedCity(res.data[0]);
      setSelectedCityDrawer(res.data[0]);
      getPackageByCity(res.data[0].id);
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const getPackageByCity = async cityId => {
    const api = await fetch(`${API}/package/city?city=${cityId}`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      parsingCityPackages(res.data.packages);
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const parsingCityPackages = data => {
    if (data && data.length > 0) {
      const newData = data.map(v => {
        return {
          id: v.id,
          title: v.title,
          alias: v.alias,
          templates: v.templates,
          interval_time: v.interval_time + ' menit',
          price: `Rp ${v.price.base.toLocaleString(navigator.language, {
            minimumFractionDigits: 2
          })}`,
          included_description: v.included_description,
          additional_information: v.additional_information,
          online_reservation_description: v.online_reservation_description,
          buy_this_product_description: v.buy_this_product_description,
          tolerant: v.tolerant,
          enable_free_text: v.enable_free_text,
          free_text_limit: v.free_text_limit,
          price_text_per_char: v.price_text_per_char,
          description: v.description
        };
      });
      setCityPackage(newData);
    } else {
      setCityPackage([]);
    }
  };

  const onClickPackage = position => {
    if (searchResult && searchResult.length > 0) {
      let tempSearchResult = searchResult.slice(0, searchResult.length);
      tempSearchResult.map((v, i) => {
        // if (v.is_disabled === false) {
        if (i === position) {
          if (v.is_selected) {
            v.is_selected = false;
          } else {
            v.is_selected = true;
          }
        }
        // }
      });
      setSearchResult(tempSearchResult);
    }
  };

  const handleSelectedCity = city => {
    setSelectedCity(city);
    getPackageByCity(city.id);
  };

  const handleSelectedCityDrawer = city => {
    setIsCreate(true);
    // let tempSearchResult = searchResult.slice(0, searchResult.length);
    // if (tempSearchResult && tempSearchResult.length > 0) {
    searchResult.map(v => {
      v.is_selected = false;
      v.is_disabled = false;
    });
    //   setSearchResult(tempSearchResult);
    // }
    setSelectedCityDrawer(city);
    getTempPackageByCity(city.id, searchResult);
  };

  const handleChangePackage = e => {
    setSearchPackage(e.target.value);
  };

  const [cityPage, setCityPage] = useState(1);

  const handleOnNextPageCity = () => setCityPage(cityPage + 1);
  const handleOnBackPageCity = () => setCityPage(cityPage - 1);

  const getTempPackageByCity = async (cityId, arrSearch) => {
    const api = await fetch(`${API}/package/city?city=${cityId}`);
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      if (res.data.packages && res.data.packages.length > 0) {
        // let tempSearchResult = searchResult.slice(0, searchResult.length);
        res.data.packages.forEach(v => {
          arrSearch.forEach(x => {
            if (v.id === x.id) {
              x.is_selected = true;
              x.is_disabled = true;
              setIsCreate(false);
            }
          });
        });
        setSearchResult(arrSearch);
        // setTempPackageByCity(res.data.packages);
      } else {
        setSearchResult(arrSearch);
      }
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const handleDrawerOpenPackageCity = () => {
    getAllPackages();
    setIsManagePackageCity(true);
    setOpen(true);
  };

  const addOrUpdatePackageToCity = async () => {
    if (isCreate) {
      // handle add package to city
      let arrPackage = [];
      searchResult.map(v => {
        if (v.is_selected) {
          arrPackage.push(v.id);
        }
      });
      const body = {
        city: selectedCityDrawer.id,
        packages: arrPackage
      };
      const api = await fetch(`${API}/package/city`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!api.ok) {
        handleShowSnackbar(true, api.statusText);
        return;
      }
      const res = await api.json();
      if (res.response_code === '00') {
        setSearchResult([]);
        setTempPackageByCity([]);
        setSelectedCityDrawer(selectedCity);
        setOpen(false);
        getPackageByCity(selectedCity.id);
      } else {
        handleShowSnackbar(true, res.message);
      }
    } else {
      // handle update package in city
      let arrPackage = [];
      searchResult.map(v => {
        if (v.is_selected) {
          arrPackage.push(v.id);
        }
      });
      const body = {
        city: selectedCityDrawer.id,
        packages: arrPackage
      };
      const api = await fetch(`${API}/package/city`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!api.ok) {
        handleShowSnackbar(true, api.statusText);
        return;
      }
      const res = await api.json();
      if (res.response_code === '00') {
        setSearchResult([]);
        setTempPackageByCity([]);
        setSelectedCityDrawer(selectedCity);
        setOpen(false);
        getPackageByCity(selectedCity.id);
      } else {
        handleShowSnackbar(true, res.message);
      }
    }
  };

  const deletePackageFromCity = async id => {
    const body = {
      city: selectedCity.id,
      package: id
    };
    const api = await fetch(`${API}/package/city`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      getPackageByCity(selectedCity.id);
      handleShowSnackbar(true, 'Package berhasil di hapus');
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  // Margin Section
  const [marginPackage, setMarginPackage] = useState(null);
  const [selectedMarginCity, setSelectedMarginCity] = useState(null);
  const [showAddEditMargin, setShowAddEditMargin] = useState(false);
  const [showDeleteMargin, setShowDeleteMargin] = useState(false);
  const [formMarginCity, setFormMarginCity] = useState({
    id: null,
    city: cities && cities.length > 0 ? cities[0] : null,
    marginPrice: ''
  });

  const handleChangeMarginForm = e => {
    setFormMarginCity({ ...formMarginCity, marginPrice: e.target.value });
  };

  const handleChangeMarginCity = city => {
    setFormMarginCity({ ...formMarginCity, city });
  };

  const handleClickAddOrEditMargin = (isEdit, data = null) => e => {
    if (isEdit) {
      // handle edit margin
      let selectedCity = cities.filter(v => v.id === data.city.id)[0];
      setFormMarginCity({
        id: data.id,
        city: selectedCity,
        marginPrice: data.margin
      });
    }

    setShowAddEditMargin(true);
  };

  const handleDeleteMargin = async id => {
    setFormMarginCity({
      ...formMarginCity,
      id
    });
    setShowDeleteMargin(true);
  };

  const handleAddOrEditMargin = async e => {
    // validate margin request
    if (
      formMarginCity.marginPrice.length === 0 ||
      formMarginCity.city === null
    ) {
      handleShowSnackbar(true, 'Harap isi field yang diperlukan');
      return;
    }

    if (formMarginCity.id === null) {
      // handle add margin
      const bodyRequest = {
        package: form.id,
        city: formMarginCity.city.id,
        margin: parseFloat(formMarginCity.marginPrice)
      };
      const api = await fetch(`${API}/package/margin-city`, {
        method: 'POST',
        body: JSON.stringify(bodyRequest),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!api.ok) {
        handleShowSnackbar(true, api.statusText);
        return;
      }
      const res = await api.json();
      if (res.response_code === '00') {
        getMarginPackage(form.id);
        setShowAddEditMargin(false);
        handleShowSnackbar(true, 'Berhasil menambahkan margin');
      } else {
        handleShowSnackbar(true, res.message);
      }
    } else {
      //handle edit margin
      const bodyRequest = {
        id: formMarginCity.id,
        package: form.id,
        city: formMarginCity.city.id,
        margin: parseFloat(formMarginCity.marginPrice)
      };
      const api = await fetch(`${API}/package/margin-city`, {
        method: 'PUT',
        body: JSON.stringify(bodyRequest),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!api.ok) {
        handleShowSnackbar(true, api.statusText);
        return;
      }
      const res = await api.json();
      if (res.response_code === '00') {
        getMarginPackage(form.id);
        setShowAddEditMargin(false);
        handleShowSnackbar(true, 'Berhasil mengubah margin');
      } else {
        handleShowSnackbar(true, res.message);
      }
    }
  };

  const handleDeleteCurrentMargin = async e => {
    const bodyRequest = {
      id: formMarginCity.id
    };
    const api = await fetch(`${API}/package/margin-city`, {
      method: 'DELETE',
      body: JSON.stringify(bodyRequest),
      headers: { 'Content-Type': 'application/json' }
    });
    if (!api.ok) {
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      getMarginPackage(form.id);
      setShowDeleteMargin(false);
      handleShowSnackbar(true, 'Berhasil menghapus margin');
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const getMarginPackage = async packageId => {
    const api = await fetch(`${API}/package/margin-city?package=${packageId}`);
    if (!api.ok) {
      if (api.status === 401) {
        handleShowSnackbar(
          true,
          'Sesi anda telah berakhir, harap login kembali'
        );
        localStorage.clear();
        window.location = '/sign-in';
        return;
      }
      handleShowSnackbar(true, api.statusText);
      return;
    }
    const res = await api.json();
    if (res.response_code === '00') {
      setMarginPackage(res.data);
    } else {
      handleShowSnackbar(true, res.message);
    }
  };

  const addMargin = async packageIda => { };

  const formatNumber = x => {
    if (x == '' || typeof x === 'undefined') return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleShowHidePackage = async id => {
    // const pkg = data.filter(v => v.id === id)[0];
    // if (pkg) {
    axios({
      method: 'GET',
      url: `${API}/package?id=` + id,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          let selectPackage = response.data.data;

          // multiple categories, select one because for update we need category id
          let selectedCategoryFromResponse = [];
          selectPackage.category.map(v => {
            dataCategories.forEach(w => {
              if (v.id === w.id) {
                selectedCategoryFromResponse.push(w);
              }
            });
          });
          if (selectedCategoryFromResponse.length > 0) {
            const data = {
              id: selectPackage.id,
              title: selectPackage.title,
              alias: selectPackage.alias,
              templates: selectPackage.templates,
              interval_time: Number(selectPackage.interval_time),
              included_description: selectPackage.included_description,
              additional_information: selectPackage.additional_information,
              online_reservation_description:
                selectPackage.online_reservation_description,
              buy_this_product_description:
                selectPackage.buy_this_product_description,
              description: selectPackage.description,
              banner_image_url: selectPackage.banner_image_url,
              is_online_reservation:
                selectPackage.is_online_reservation || false,
              is_can_reschedule:
                selectPackage.is_can_reschedule || false,
              is_buy_this_product: selectPackage.is_buy_this_product || false,
              tolerant: selectPackage.tolerant,
              enable_free_text: selectPackage.enable_free_text,
              free_text_limit: Number(selectPackage.free_text_limit),
              price_text_per_char: Number(selectPackage.price_text_per_char),
              publish: !selectPackage.publish,
              subcategories: (selectedSubCategories || []).map(e => e.value),
            };
            axios({
              method: 'PUT',
              url: `${API}/package?category=${selectedCategoryFromResponse[0].id}`,
              data: data,
              headers: {
                'Content-Type': 'application/json'
              }
            })
              .then(response => {
                if (selectedCategory) {
                  getPackages(selectedCategory, page);
                }
              })
              .catch(error => {
                alert(error);
              });
          }
        } else {
          alert('ada kesalahan saat load data, harap hubungi developer.');
        }
      })
      .catch(error => {
        alert(error);
      });
    // }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>Package</h4>
            <hr style={{ marginBottom: '30px' }} />

            <Grid item xs={4}>
              <MySelect
                label="Filter Kategori"
                name="Kategori"
                onChange={handleSelectedCategory}
                options={dataCategories}
                placeholder="Filter Kategori"
                value={selectedCategory}
              />
            </Grid>

            <MyTable
              data={data}
              fieldData={fieldData}
              fields={fields}
              id="id"
              onAdd={e => handleDrawerOpen(true)}
              onBackPageClicked={handleOnBackPage}
              onDelete={deletePackage}
              onEdit={handleDrawerOpenForEdit}
              onNextPageClicked={handleOnNextPage}
              onUpdate={() => { }}
              onShowHide={handleShowHidePackage}
              pageCount={pageCount}
              isLoading={isLoading}
              page={page}
              onSub={setDrawerVariant}
              titleAdd="Tambah Package"
            />

            <h4 className="mt-3">Packages in City</h4>
            <hr style={{ marginBottom: '30px' }} />

            <Grid item xs={4}>
              <MySelect
                label="Filter Kota"
                name="Kota"
                onChange={handleSelectedCity}
                options={cities}
                placeholder="Filter Kota"
                value={selectedCity}
              />
            </Grid>

            <MyTable
              data={cityPackage}
              fieldData={fieldData}
              fields={fields}
              id="id"
              isEdit={false}
              onAdd={handleDrawerOpenPackageCity}
              onBackPageClicked={handleOnBackPageCity}
              onDelete={deletePackageFromCity}
              onEdit={handleDrawerOpenForEdit}
              onNextPageClicked={handleOnNextPageCity}
              onUpdate={() => { }}
              page={cityPage}
              isLoading={false}
              titleAdd="Tambah/Ubah Package ke Kota"
            />
          </Card>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        classes={{ paper: classes.drawerPaper }}
        className={classes.drawer}
        open={drawerVariant != null}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            getPackages(selectedCategory, 1);
            setDrawerVariant(null);
          }}>
            {theme.direction === 'rtl' ? (<ChevronLeftIcon />) : (<ChevronRightIcon />)}
          </IconButton>
          Varian Package
        </div>
        <Divider />
        <VarianTable product={drawerVariant} onClose={() => {
          getPackages(selectedCategory, 1);
          setDrawerVariant(null);
        }} />
      </Drawer>

      {isManagePackageCity ? (
        <Drawer
          anchor="right"
          classes={{
            paper: classes.drawerPaper
          }}
          className={classes.drawer}
          open={open}>
          <div className={classes.drawerHeader}>
            <IconButton
              onClick={() => {
                handleDrawerClose();
              }}>
              {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            City Package
            <Button
              className={classes.buttonAdd}
              color="primary"
              onClick={addOrUpdatePackageToCity}
              style={{ position: 'absolute', right: '20px', top: '-10px' }}
              variant="contained">
              {isCreate ? 'Tambah' : 'Ubah'}
            </Button>
          </div>
          <Divider />
          <Grid container style={{ padding: theme.spacing(3) }}>
            {/* <Grid item xs={12}> */}
            <Grid className="w-100" item style={{ paddingRight: '25px' }}>
              <h5>Kota</h5>
              <MySelect
                fullWidth
                name="Kota"
                onChange={handleSelectedCityDrawer}
                options={cities}
                placeholder="Pilih Kota"
                value={selectedCityDrawer}
              />
              {/* <Paper className="mt-3">
                  <InputBase
                    className={classes.input}
                    placeholder="Cari Package"
                    value={searchPackage}
                    onChange={handleChangePackage}
                    onKeyDown={handleKeyDownCityPackage}
                    inputProps={{ 'aria-label': 'cari package' }}
                  />
                  <IconButton className={classes.iconButton}
                    aria-label="search"
                    onClick={e => getAllPackages()}>
                    <SearchIcon />
                  </IconButton>
                </Paper> */}
              {searchResult && searchResult.length > 0 ? (
                <div
                  className="mt-3 pt-2 pb-3 d-flex flex-direction-row flex-wrap"
                  style={{
                    border: '1px solid #d8d8d8',
                    borderRadius: '8px'
                  }}>
                  {searchResult.map((v, i) => {
                    // if (tempPackageByCity && tempPackageByCity.length > 0) {
                    // tempPackageByCity.map((x) => {
                    //   if (x.id === v.id) {
                    //     v.is_selected = true;
                    //     // v.is_disabled = true;
                    //     setIsCreate(false);
                    //   }
                    // });
                    // }
                    return (
                      <div className="col-md-4" key={i}>
                        <Paper
                          className="mt-2 pr-1"
                          onClick={e => onClickPackage(i)}
                          style={{
                            cursor: 'pointer',
                            background: v.is_selected ? '#d8d8d8' : '#fff'
                            // pointerEvents: v.is_disabled ? 'none' : 'auto'
                          }}>
                          <div className="p-2">{v.title}</div>
                        </Paper>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Divider />
        </Drawer>
      ) : (
        <Drawer
          anchor="right"
          classes={{
            paper: classes.drawerPaper
          }}
          className={classes.drawer}
          open={open}>
          <div className={classes.drawerHeader}>
            <IconButton
              onClick={() => {
                handleDrawerClose();
              }}>
              {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
            {form.id == null ? 'Simpan' : 'Ubah'} Informasi Package
            <Button
              className={classes.buttonAdd}
              color="primary"
              onClick={uploadThumbs}
              style={{ position: 'absolute', right: '20px', top: '-10px' }}
              variant="contained"
            // disabled={
            //   dataPersonSetup.filter(v => v.value === 0).length !== 0 ||
            //   dataPersonSetup.filter(v => v.value === '0').length !== 0
            // }
            >
              {form.id == null ? 'Simpan' : 'Ubah'}
            </Button>
          </div>
          <Divider />
          <Grid container style={{ padding: theme.spacing(3) }}>
            {/* <Grid item xs={12}> */}
            <Grid item style={{ padding: 8, paddingRight: '25px' }} xs={4}>
              <h5>Informasi Package</h5>
              <MySelect
                fullWidth
                isMulti
                name="Kategori"
                onChange={handleSelectedCategoryForm}
                options={dataCategories}
                placeholder="Pilih Kategori"
                value={selectedCategoryForm}
              />
              <br />
              <MySelect
                fullWidth
                isMulti
                name="Sub Kategori"
                onChange={handleSelectedSubCategoryForm}
                options={subCategories.filter(f => {
                  const selectedCategory = selectedCategoryForm?.map(e => e.value) || [];
                  return selectedCategory.includes(f.parent);
                })}
                placeholder="Pilih Sub Kategori"
                value={selectedSubCategories}
              />
              <br />
              <MySelect
                fullWidth
                isMulti
                name="Template"
                onChange={handleSelectedTemplate}
                options={dataTemplate}
                placeholder="Pilih Template"
                value={selectedTemplate}
              />

              <TextField
                fullWidth
                id="title"
                label="Judul Package"
                margin="normal"
                onChange={handleChangeForm('title')}
                value={form.title}
                variant="outlined"
              />
              <TextField
                fullWidth
                id="alias"
                label="Alias Package"
                margin="normal"
                onChange={handleChangeForm('alias')}
                value={form.alias}
                variant="outlined"
              />
              <TextField
                fullWidth
                id="description"
                label="Included Description Package"
                margin="normal"
                multiline
                onChange={handleChangeForm('included_description')}
                rows="4"
                value={form.included_description}
                variant="outlined"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.is_online_reservation || false}
                    color="primary"
                    name="policy"
                    onChange={handleChangeForm('is_online_reservation')}
                  />
                }
                label="Customer Bisa Reservasi Online?"
              />
              <TextField
                fullWidth
                id="description"
                label="Online Reservation Description"
                margin="normal"
                multiline
                onChange={handleChangeForm('online_reservation_description')}
                rows="4"
                value={form.online_reservation_description}
                variant="outlined"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.is_buy_this_product || false}
                    color="primary"
                    name="policy"
                    onChange={handleChangeForm('is_buy_this_product')}
                  />
                }
                label="Customer Bisa Beli Produk?"
              />
              <TextField
                fullWidth
                id="informasi"
                label="Buy This Product Description"
                margin="normal"
                multiline
                onChange={handleChangeForm('buy_this_product_description')}
                rows="4"
                value={form.buy_this_product_description}
                variant="outlined"
              />
              <TextField
                fullWidth
                id="informasi"
                label="Additional Information"
                margin="normal"
                multiline
                onChange={handleChangeForm('additional_information')}
                rows="4"
                value={form.additional_information}
                variant="outlined"
              />
              <TextField
                fullWidth
                id="description"
                label="Description"
                margin="normal"
                multiline
                onChange={handleChangeForm('description')}
                rows="4"
                value={form.description}
                variant="outlined"
              />
              <TextField
                id="interval"
                label="Interval Time/Durasi foto (menit)"
                margin="normal"
                onChange={handleChangeForm('interval_time')}
                placeholder="20 (kelipatan 5)"
                ref={intervalTimeRef}
                style={{ width: '225px' }}
                type="number"
                value={form.interval_time}
                variant="outlined"
              />
              <br />
              <TextField
                id="reservation_time"
                label="Waktu Minimal Reservasi (Hari)"
                margin="normal"
                onChange={handleChangeForm('minimal_reservation')}
                placeholder="1"
                ref={minimalReservationRef}
                type="number"
                value={form.minimal_reservation}
                variant="outlined"
              />
              <br />
              <TextField
                id="reservation_clock"
                label="Waktu Minimal Reservasi (Jam)"
                margin="normal"
                placeholder='12:00 AM'
                style={{ width: '225px' }}
                onChange={handleChangeForm('minimal_reservation_time')}
                value={form.minimal_reservation_time}
                variant="outlined"
                type="time"
              />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.is_can_reschedule || false}
                    color="primary"
                    name="policy"
                    onChange={handleChangeForm('is_can_reschedule')}
                  />
                }
                label="Customer Bisa Reschedule?"
              />
              <br />
              <TextField
                id="reschedule_time"
                label="Waktu Minimal Reschedule (Hari)"
                margin="normal"
                onChange={handleChangeForm('minimal_reschedule')}
                placeholder="1"
                ref={minimalRescheduleRef}
                style={{ width: '225px' }}
                type="number"
                value={form.minimal_reschedule}
                variant="outlined"
              />
              <br />
              <TextField
                id="reschedule_clock"
                label="Waktu Minimal Reschedule (Jam)"
                margin="normal"
                placeholder='12:00 AM'
                style={{ width: '225px' }}
                onChange={handleChangeForm('minimal_reschedule_time')}
                value={form.minimal_reschedule_time}
                variant="outlined"
                type="time"
              />
              <br />
              {/* <TextField
                id="tolerang"
                label="Toleransi Waktu (Menit)"
                margin="normal"
                onChange={handleChangeForm('tolerant')}
                placeholder="Toleransi Waktu"
                style={{ width: '225px' }}
                type="number"
                value={form.tolerant}
                variant="outlined"
              /> */}
              {/* <TextField
            id="label"
            label="Label Thumbnail"
            value={uploadImage.label}
            onChange={handleChangeFormThumbnail('label')}
            margin="normal"
            fullWidth
            variant="outlined"
          /> */}
              <Grid item xs={12}>
                {/* <div className={classes.bungkusGambar}> */}
                <div className="d-flex flex-column" ref={labelRef}>
                  {/* {
                uploadImage.thumbnail !== '' ?
                  <div>
                    {uploadImage.load_on_upload === 1 ? <div className={classes.loaderBungkus}>
                      <CircularProgress color='secondary' style={{ top: '40%' }} className={classes.progress} />
                    </div> : uploadImage.load_on_upload === 2 ? <div className={classes.loaderBungkus}>
                      <CheckIcon color='primary' fontSize='large' className={classes.progress} />
                    </div> : ''}
                    < Avatar alt="Image" style={{ width: '100px' }} src={uploadImage.thumbnail} className={classes.bigAvatar} value={form.thumbnail_image_url} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatar} src={uploadImage.image_url}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
              } */}
                  {uploadImage && uploadImage.length > 0
                    ? uploadImage.map((v, i) => {
                      if (v && v.image_url) {
                        return (
                          <div
                            className="d-flex flex-row align-items-center mt-3"
                            key={i}>
                            <div className="position-relative mr-3">
                              <Avatar
                                alt="image"
                                className={classes.bigAvatar}
                                src={v.image_url}
                                value={v.image_url}
                              />
                              <div
                                className={
                                  classes.deleteIcon +
                                  ' position-absolute w-100 h-100'
                                }>
                                <div
                                  onClick={() =>
                                    setDeleteImage({
                                      show: true,
                                      id: v.id,
                                      pos: i
                                    })
                                  }
                                  style={
                                    disableWhenImageUpload
                                      ? { pointerEvents: 'none' }
                                      : {}
                                  }>
                                  <DeleteIcon style={{ fill: 'red' }} />
                                </div>
                              </div>
                            </div>
                            <TextField
                              className="mt-1"
                              disabled={disableWhenImageUpload === true}
                              error={v.error}
                              fullWidth
                              id="label1"
                              label="Label Thumbnail"
                              margin="normal"
                              onChange={handleLabelCurrentImageChange(i, v)}
                              value={v.label}
                              variant="outlined"
                            />
                          </div>
                        );
                      }
                    })
                    : null}
                  {photo &&
                    photo.map((v, i) => {
                      if (v && v.image_url) {
                        return (
                          <div
                            className="d-flex flex-row align-items-center mt-3"
                            key={i}>
                            <div className="position-relative mr-3">
                              <Avatar
                                alt="image"
                                className={classes.bigAvatar}
                                src={v.image_url}
                                value={v.image_url}
                              />
                              <div
                                className={
                                  classes.deleteIcon +
                                  ' position-absolute w-100 h-100'
                                }>
                                <div
                                  onClick={() => onDeleteImage(i)}
                                  style={
                                    disableWhenImageUpload
                                      ? { pointerEvents: 'none' }
                                      : {}
                                  }>
                                  <DeleteIcon style={{ fill: 'red' }} />
                                </div>
                                <div className="d-flex position-relative">
                                  <input
                                    className={classes.fileUploadhide + ' mt-2'}
                                    disabled={disableWhenImageUpload}
                                    onChange={onUpdateImage(i)}
                                    type="file"
                                  />
                                  <EditIcon style={{ fill: 'blue' }} />
                                </div>
                              </div>
                            </div>
                            <TextField
                              className="mt-1"
                              disabled={disableWhenImageUpload === true}
                              error={v.error}
                              fullWidth
                              id="label2"
                              label="Label Thumbnail"
                              margin="normal"
                              onChange={handleLabelChange(i, v)}
                              value={v.label}
                              variant="outlined"
                            />
                          </div>
                        );
                      }
                      // else {
                      //   return (
                      //     <div>
                      //       <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChooseImage(i)} />
                      //       <Avatar alt="Image" className={classes.bigAvatar} src={uploadImage.image_url}>
                      //         <InsertPhotoIcon fontSize='large' />
                      //       </Avatar>
                      //     </div>
                      //   )
                      // }
                    })}
                  <div className="d-flex flex-row align-items-center mt-3">
                    <Avatar
                      alt="Image"
                      className={`${classes.bigAvatar} opacity-100 position-relative`}>
                      <input
                        className={classes.fileUploadhide + ' mt-2'}
                        onChange={onChooseImage}
                        ref={fileRef}
                        type="file"
                      />
                      <InsertPhotoIcon fontSize="large" />
                    </Avatar>
                    <span className="ml-3">Tambah Foto</span>
                  </div>
                </div>
                {/* <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChangeGambar} />
            <Button variant="contained" color="secondary" className={classes.button} style={{ marginTop: '20px' }}>
              Ubah Foto
            </Button> */}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ padding: 8 }}>
              <h5 style={{ marginBottom: '-3px' }}>Price</h5>
              <TextField
                id="base"
                InputProps={{
                  inputComponent: NumberFormatCustom
                }}
                label="Base"
                margin="normal"
                onChange={handleChangeFormPrice('base')}
                style={{ width: '45%', paddingRight: '15px' }}
                // type="number"
                value={formPrice.base}
                variant="outlined"
              />
              <br />
              {/* <TextField
                        id="min"
                        label="Min"
                        value={formPrice.min}
                        onChange={handleChangeFormPrice('min')}
                        InputProps={{ inputProps: { min: 0 }}}
                        margin="normal"
                        variant="outlined"
                        // type="number"
                        style={{width:'45%'}}
                        InputProps={{ 
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                    <TextField
                        id="max"
                        label="Max"
                        value={formPrice.max}
                        onChange={handleChangeFormPrice('max')}
                        InputProps={{ inputProps: { min: 0 }}}
                        margin="normal"
                        variant="outlined"
                        // type="number"
                        style={{width:'45%',paddingRight:'15px'}}
                        InputProps={{ 
                            inputComponent: NumberFormatCustom,
                        }}
                    /> */}
              <TextField
                id="tax"
                InputProps={{ inputProps: { min: 0 } }}
                label="Tax"
                margin="normal"
                onChange={handleChangeFormPrice('tax')}
                style={{ width: '45%' }}
                type="number"
                value={formPrice.tax}
                variant="outlined"
              />
              <FormControl
                className={classes.formControl}
                style={{ margin: '15px 0' }}
                variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Tax Type
                </InputLabel>
                <Select
                  id="satuan"
                  label="demo-simple-select-label"
                  onChange={handleChangeFormPrice('tax_type')}
                  value={formPrice.tax_type}>
                  <MenuItem value="percent">Percent</MenuItem>
                  <MenuItem value="value">Value</MenuItem>
                </Select>
              </FormControl>
              <br />
              <TextField
                id="service"
                InputProps={{ inputProps: { min: 0 } }}
                label="Service"
                margin="normal"
                onChange={handleChangeFormPrice('service')}
                style={{ width: '45%' }}
                type="number"
                value={formPrice.service}
                variant="outlined"
              />
              <FormControl
                className={classes.formControl}
                style={{ margin: '15px 0' }}
                variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Service Type
                </InputLabel>
                <Select
                  id="service_type"
                  label="demo-simple-select-label"
                  onChange={handleChangeFormPrice('service_type')}
                  value={formPrice.service_type}>
                  <MenuItem value="percent">Percent</MenuItem>
                  <MenuItem value="value">Value</MenuItem>
                </Select>
              </FormControl>
              <h5>Margin</h5>
              {form.id === null ? (
                <label
                  style={{ color: 'red', fontSize: '12px', marginTop: '0' }}>
                  *Simpan data package terlebih dahulu
                </label>
              ) : (
                <div className="w-100 d-flex flex-column mb-3">
                  {marginPackage && marginPackage.length > 0 ? (
                    <Table style={{ marginBottom: '25px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Kota</TableCell>
                          <TableCell>Margin (Rp.)</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {marginPackage.map((v, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell>{v.city.name}</TableCell>
                              <TableCell>{v.margin}</TableCell>
                              <TableCell>
                                <a
                                  onClick={handleClickAddOrEditMargin(true, v)}>
                                  <EditIcon
                                    className="pointer mr-2 pointer"
                                    style={{ color: blue[500] }}
                                  />
                                </a>
                                <a onClick={e => handleDeleteMargin(v.id)}>
                                  <DeleteIcon
                                    className="pointer mr-2 pointer"
                                    style={{ color: red[500] }}
                                  />
                                </a>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : null}
                  <Button
                    color="secondary"
                    onClick={handleClickAddOrEditMargin(false)}
                    variant="contained"
                    style={{ width: 'max-content' }}>
                    Tambah Margin
                  </Button>
                </div>
              )}
              <h5>Person Setup</h5>
              {/* {dataPersonSetup.length != 0 ? ( */}
              <Table style={{ marginBottom: '25px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipe Setup</TableCell>
                    <TableCell style={{ width: '200px' }}>Value</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataPersonSetup &&
                    dataPersonSetup.map((v, index) => (
                      <TableRow key={v.id}>
                        <TableCell>{v.parameter}</TableCell>
                        <TableCell>
                          <TextField
                            className="mt-1"
                            fullWidth
                            id={'person' + index}
                            label="Value"
                            margin="dense"
                            value={v.value}
                            variant="outlined"
                            onChange={handleChangeFormPersonSetup(
                              'value',
                              index
                            )}
                            InputProps={{
                              inputComponent: ThousandFormatCustom,
                              endAdornment: (
                                <InputAdornment position="end">
                                  {v.parameter === 'fee_person'
                                    ? `IDR`
                                    : v.parameter === 'extra_time_person'
                                      ? `menit`
                                      : `orang`}
                                </InputAdornment>
                              )
                            }}
                          />
                          {/* {(v.value === 0 || v.value === '0') && (
                            <FormHelperText style={{ color: 'red' }}>
                              *value tidak boleh 0
                            </FormHelperText>
                          )} */}
                        </TableCell>
                        {/* <TableCell>
                            <a
                              onClick={() => {
                                handleEditPersonSetup(v.id);
                              }}
                            >
                              <EditIcon
                                className="pointer mr-2"
                                style={{ color: blue[500] }}
                              />
                            </a>
                            <a
                              onClick={() => {
                                setAlertDeletePersonSetup(true);
                                setSelectedIDPersonSetup(v.id);
                              }}
                            >
                              <DeleteIcon
                                className="pointer mr-2"
                                style={{ color: red[500] }}
                              />
                            </a>
                          </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <h5>Cloth Setup</h5>
              {/* {dataPersonSetup.length != 0 ? ( */}
              <Table style={{ marginBottom: '25px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipe Setup</TableCell>
                    <TableCell style={{ width: '200px' }}>Value</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataClothSetup &&
                    dataClothSetup.map((v, index) => (
                      <TableRow key={v.id}>
                        <TableCell>{v.parameter}</TableCell>
                        <TableCell>
                          <TextField
                            className="mt-1"
                            fullWidth
                            id={'cloth' + index}
                            label="Value"
                            margin="dense"
                            value={v.value}
                            variant="outlined"
                            onChange={handleChangeFormClothSetup(
                              'value',
                              index
                            )}
                            InputProps={{
                              inputComponent: ThousandFormatCustom,
                              endAdornment: (
                                <InputAdornment position="end">
                                  {v.parameter === 'fee_cloth' || v.parameter === 'extra_cloth_by_person_fee'
                                    ? `IDR`
                                    : v.parameter === 'extra_time_cloth' || v.parameter === 'extra_cloth_by_person_time'
                                      ? `menit`
                                      : v.parameter === 'extra_cloth_by_person_limit'
                                        ? `orang`
                                        : `kali`}
                                </InputAdornment>
                              )
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>


            </Grid>

            <Grid item xs={4} style={{ padding: 8 }}>
              <h5>Package Banner</h5>
              <div className="d-flex flex-row align-items-center my-3">
                {photoBanner ||
                  (form &&
                    form.banner_image_url &&
                    form.banner_image_url.length > 0) ? (
                  <Avatar
                    alt="Image"
                    className={`${classes.bigAvatar} opacity-100 position-relative w-100`}
                    style={{
                      backgroundImage: `url(${photoBanner && photoBanner.image_url
                        ? photoBanner.image_url
                        : form.banner_image_url
                          ? form.banner_image_url
                          : null
                        })`,
                      backgroundPosition: 'center',
                      backgroundSize: 'cover'
                    }}>
                    <input
                      className={classes.fileUploadhide + ' mt-2'}
                      onChange={onChooseImageBanner}
                      ref={bannerRef}
                      type="file"
                    />
                    <InsertPhotoIcon fontSize="large" />
                  </Avatar>
                ) : (
                  <Avatar
                    alt="Image"
                    className={`${classes.bigAvatar} opacity-100 position-relative w-100`}>
                    <input
                      className={classes.fileUploadhide + ' mt-2'}
                      onChange={onChooseImageBanner}
                      ref={bannerRef}
                      type="file"
                    />
                    <InsertPhotoIcon fontSize="large" />
                  </Avatar>
                )}
              </div>
              <h5>Included</h5>
              {dataIncluded.length != 0 ? (
                <Table style={{ marginBottom: '25px' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nama Produk</TableCell>
                      <TableCell>Label</TableCell>
                      <TableCell>Jumlah</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataIncluded.map(v => (
                      <TableRow key={v.id}>
                        <TableCell>{v.product.name}</TableCell>
                        <TableCell>{v.label}</TableCell>
                        <TableCell>{v.quantity}</TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              handleEditIncluded(v.id);
                            }}>
                            <EditIcon
                              className="pointer mr-2"
                              style={{ color: blue[500] }}
                            />
                          </a>
                          <a
                            onClick={() => {
                              setAlertDeleteIncluded(true);
                              setSelectedIDIncluded(v.id);
                            }}>
                            <DeleteIcon
                              className="pointer mr-2"
                              style={{ color: red[500] }}
                            />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : null}

              <h5>Pose Setup</h5>
              {/* {dataPersonSetup.length != 0 ? ( */}
              <Table style={{ marginBottom: '25px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipe Setup</TableCell>
                    <TableCell style={{ width: '200px' }}>Value</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataPoseSetup &&
                    dataPoseSetup.map((v, index) => (
                      <TableRow key={v.id}>
                        <TableCell>{v.parameter}</TableCell>
                        <TableCell>
                          <TextField
                            className="mt-1"
                            fullWidth
                            id={'pose' + index}
                            label="Value"
                            margin="dense"
                            value={v.value}
                            variant="outlined"
                            onChange={handleChangeFormPoseSetup(
                              'value',
                              index
                            )}
                            InputProps={{
                              inputComponent: ThousandFormatCustom,
                              endAdornment: (
                                <InputAdornment position="end">
                                  {v.parameter === 'fee_pose'
                                    ? `IDR`
                                    : v.parameter === 'extra_time_pose'
                                      ? `menit`
                                      : `kali`}
                                </InputAdornment>
                              )
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <h5>Free Text</h5>
              {/* {dataPersonSetup.length != 0 ? ( */}
              <Table style={{ marginBottom: '25px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Tipe Setup</TableCell>
                    <TableCell style={{ width: '200px' }}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Enable Text</TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.enable_free_text || false}
                            color="primary"
                            name="enable_free_text"
                            onChange={handleChangeForm('enable_free_text')}
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Free Text Limit</TableCell>
                    <TableCell>
                      <TextField
                        className="mt-1"
                        fullWidth
                        id={'free_text_limit'}
                        label="Value"
                        value={form.free_text_limit}
                        margin="dense"
                        variant="outlined"
                        onChange={handleChangeForm('free_text_limit')}
                        InputProps={{
                          inputComponent: ThousandFormatCustom,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Default Text per Char</TableCell>
                    <TableCell>
                      <TextField
                        className="mt-1"
                        fullWidth
                        id={'price_text_per_char'}
                        label="Value"
                        value={form.price_text_per_char}
                        margin="dense"
                        variant="outlined"
                        onChange={handleChangeForm('price_text_per_char')}
                        InputProps={{
                          inputComponent: ThousandFormatCustom,
                          endAdornment: (
                            <InputAdornment position="end">IDR</InputAdornment>
                          )
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              {form.id === null ? (
                <label
                  style={{ color: 'red', fontSize: '12px', marginTop: '0' }}>
                  *Simpan data package terlebih dahulu
                </label>
              ) : (
                <Button
                  color="secondary"
                  onClick={() => setOpenIncluded(true)}
                  variant="contained">
                  Tambah Included
                </Button>
              )}
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Divider />
        </Drawer>
      )}

      {/* DIALOG FOR PERSON SETUP */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handlePersonSetupClose}
        open={openPersonSetup}>
        <DialogTitle id="form-dialog-title">Person Setup</DialogTitle>
        <DialogContent>
          <Grid container>
            <FormControl
              className={classes.formControl}
              style={{ margin: '15px', marginLeft: '0' }}
              variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Tipe Setup
              </InputLabel>
              <Select
                id="personsetop"
                label="demo-simple-select-label"
                onChange={handleChangeFormPersonSetup('parameter')}
                value={formPersonSetup.parameter}>
                <MenuItem value="default_person">Default Person</MenuItem>
                <MenuItem value="min_person">Min Person</MenuItem>
                <MenuItem value="max_person">Max Person</MenuItem>
                <MenuItem value="fee_person">Fee Person</MenuItem>
                <MenuItem value="extra_time_person">Extra Time Person</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="value"
              InputProps={{ inputProps: { min: 0 } }}
              label="value"
              margin="normal"
              onChange={handleChangeFormPersonSetup('value')}
              type="number"
              value={formPersonSetup.value}
              variant="outlined"
            />
          </Grid>
          {/* </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handlePersonSetupClose}>
            Tidak
          </Button>
          <Button
            color="primary"
            onClick={
              formPersonSetup.id === null
                ? () => AddPersonSetup()
                : () => EditPersonSetup()
            }
            variant="contained">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR CLOTH SETUP */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleClothSetupClose}
        open={openClothSetup}>
        <DialogTitle id="form-dialog-title">Cloth Setup</DialogTitle>
        <DialogContent>
          <Grid container>
            <FormControl
              className={classes.formControl}
              style={{ margin: '15px', marginLeft: '0' }}
              variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Tipe Setup
              </InputLabel>
              <Select
                id="clothsetop"
                label="demo-simple-select-label"
                onChange={handleChangeFormClothSetup('parameter')}
                value={formClothSetup.parameter}>
                <MenuItem value="default_person">Default Cloth</MenuItem>
                <MenuItem value="min_cloth">Min Cloth</MenuItem>
                <MenuItem value="max_cloth">Max Cloth</MenuItem>
                <MenuItem value="fee_cloth">Fee Cloth</MenuItem>
                <MenuItem value="extra_time_cloth">Extra Time Cloth</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="value"
              InputProps={{ inputProps: { min: 0 } }}
              label="value"
              margin="normal"
              onChange={handleChangeFormClothSetup('value')}
              type="number"
              value={formClothSetup.value}
              variant="outlined"
            />
          </Grid>
          {/* </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClothSetupClose}>
            Tidak
          </Button>
          <Button
            color="primary"
            onClick={
              formPersonSetup.id === null
                ? () => AddClothSetup()
                : () => EditClothSetup()
            }
            variant="contained">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR POSE SETUP */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handlePoseSetupClose}
        open={openPoseSetup}>
        <DialogTitle id="form-dialog-title">Pose Setup</DialogTitle>
        <DialogContent>
          <Grid container>
            <FormControl
              className={classes.formControl}
              style={{ margin: '15px', marginLeft: '0' }}
              variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Tipe Setup
              </InputLabel>
              <Select
                id="posesetop"
                label="demo-simple-select-label"
                onChange={handleChangeFormPoseSetup('parameter')}
                value={formPoseSetup.parameter}>
                <MenuItem value="default_person">Default Pose</MenuItem>
                <MenuItem value="min_pose">Min Pose</MenuItem>
                <MenuItem value="max_pose">Max Pose</MenuItem>
                <MenuItem value="fee_pose">Fee Pose</MenuItem>
                <MenuItem value="extra_time_pose">Extra time Pose</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="value"
              InputProps={{ inputProps: { min: 0 } }}
              label="value"
              margin="normal"
              onChange={handleChangeFormPoseSetup('value')}
              type="number"
              value={formPoseSetup.value}
              variant="outlined"
            />
          </Grid>
          {/* </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handlePoseSetupClose}>
            Tidak
          </Button>
          <Button
            color="primary"
            onClick={
              formPersonSetup.id === null
                ? () => AddPoseSetup()
                : () => EditPoseSetup()
            }
            variant="contained">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR DELETE PERSON SETUP */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setAlertDeletePersonSetup(false);
        }}
        open={alertDeletePersonSetup}>
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Anda yakin ingin menghapus data person setup ini?
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setAlertDeletePersonSetup(false);
            }}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={() => {
              DeletePersonSetup(selectedIDPersonSetup);
            }}
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR DELETE CLOTH SETUP */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setAlertDeleteClothSetup(false);
        }}
        open={alertDeleteClothSetup}>
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Anda yakin ingin menghapus data cloth setup ini?
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setAlertDeleteClothSetup(false);
            }}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={() => {
              DeleteClothSetup(selectedIDClothSetup);
            }}
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR DELETE POSE SETUP */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setAlertDeletePoseSetup(false);
        }}
        open={alertDeletePoseSetup}>
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Anda yakin ingin menghapus data pose setup ini?
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setAlertDeletePoseSetup(false);
            }}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={() => {
              DeletePoseSetup(selectedIDPoseSetup);
            }}
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>


      {/* DIALOG FOR INCLUDED */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleIncludedClose}
        open={openIncluded}>
        <DialogTitle id="form-dialog-title">Included Produk</DialogTitle>
        <DialogContent>
          <Grid container>
            <FormControl
              className={classes.formControl}
              style={{ margin: '15px', marginLeft: '0', minWidth: '150px' }}
              variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Pilih Produk
              </InputLabel>
              <Select
                // YONGKI personsetop ???
                id="personsetop"
                label="demo-simple-select-label"
                onChange={handleChangeFormIncluded('product')}
                value={formIncluded.product}>
                {dataIncludedProduct.map(v => (
                  <MenuItem value={v.id} key={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid item xs={12}>
              <TextField
                id="label4"
                InputProps={{ inputProps: { min: 0 } }}
                label="Label"
                margin="normal"
                onChange={handleChangeFormIncluded('label')}
                style={{ marginRight: '20px' }}
                value={formIncluded.label}
                variant="outlined"
              />
              <TextField
                id="quantity"
                InputProps={{ inputProps: { min: 0 } }}
                label="Jumlah"
                margin="normal"
                onChange={handleChangeFormIncluded('quantity')}
                type="number"
                value={formIncluded.quantity}
                variant="outlined"
              />
            </Grid>
          </Grid>
          {/* </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleIncludedClose}>
            Tidak
          </Button>
          <Button
            color="primary"
            onClick={
              formIncluded.id === null
                ? () => AddIncluded()
                : () => EditIncluded()
            }
            variant="contained">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR DELETE INCLUDED */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setAlertDeleteIncluded(false);
        }}
        open={alertDeleteIncluded}>
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Anda yakin ingin menghapus produk ini dari package?
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setAlertDeleteIncluded(false);
            }}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={() => {
              DeleteIncluded(selectedIDIncluded);
            }}
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG DELETE IMAGE */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setDeleteImage({ show: false, id: -1, pos: -1 });
        }}
        open={deleteImage.show}>
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>Anda yakin ingin menghapus data ini?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setDeleteImage({ show: false, id: -1, pos: -1 });
            }}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleDeleteImage(deleteImage.id, deleteImage.pos);
            }}
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Margin */}
      <Dialog open={showAddEditMargin}>
        <DialogTitle>Margin Package</DialogTitle>
        <DialogContent>
          <div className="d-flex flex-column">
            <MySelect
              label="Margin Kota"
              name="Margin Kota"
              onChange={handleChangeMarginCity}
              options={cities}
              placeholder="Margin Kota"
              value={formMarginCity.city}
            />
            <FormControl
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
              variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Margin
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-margin"
                labelWidth={70}
                onChange={handleChangeMarginForm}
                value={formMarginCity.marginPrice}
              />
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={e => {
              setFormMarginCity({
                id: null,
                city: cities && cities.length > 0 ? cities[0] : null,
                marginPrice: ''
              });
              setShowAddEditMargin(false);
            }}>
            Tidak
          </Button>
          <Button
            color="primary"
            onClick={handleAddOrEditMargin}
            variant="contained">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Delete Margin */}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => {
          setFormMarginCity({
            id: null,
            city: cities && cities.length > 0 ? cities[0] : null,
            marginPrice: ''
          });
        }}
        open={showDeleteMargin}>
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>Anda yakin ingin menghapus data ini?</DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setFormMarginCity({
                id: null,
                city: cities && cities.length > 0 ? cities[0] : null,
                marginPrice: ''
              });
              setShowDeleteMargin(false);
            }}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={handleDeleteCurrentMargin}
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      {/* ALERT FOR ERROR */}
      <Snackbar
        autoHideDuration={3000}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={<span id="message-id">{messageSnackbar}</span>}
        onClose={() => setAlert(false)}
        open={alertt}
        TransitionComponent={Slide}
      />
    </div>
  );
};

export default Package;
