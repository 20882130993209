import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import axios from '../../../util/Service';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Input
} from '@material-ui/core';
import MyDialog from '../../../components/Dialog';
import InputLabel from '@material-ui/core/InputLabel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Select from '../../../components/Select';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { red, blue } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { ContactSupport, KeyboardArrowLeftOutlined, KeyboardArrowLeftRounded, KeyboardArrowRightOutlined, KeyboardArrowRightRounded } from '@material-ui/icons';

const drawerWidth = '80%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  buttonAdd: {
    margin: theme.spacing(3)
  },
})
);
const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

const fields = ['Nama', 'Deskripsi'];
const fieldData = ['name', 'description'];

const Studio = () => {
  const classes = useStyles();
  const theme = useTheme();

  // Role
  const currUser = JSON.parse(localStorage.getItem('currentUser'));
  const [accountData, setAccountData] = useState(null);

  //store
  const [dataStore, setDataStore] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  // studio
  const [data, setData] = useState([]);
  const [selectedStoreForm, setSelectedStoreForm] = useState(null);
  const [form, setForm] = useState({
    id: null,
    name: '',
    description: '',
    image_url: '',
    capacity: null,
  });

  // package
  const [packages, setPackages] = useState([]);
  const [studioPackages, setStudioPackages] = useState([]);
  const [searchAvailable, setSearchAvailable] = useState("")
  const [searchSelected, setSearchSelected] = useState("")

  // setup
  const [openSetup, setOpenSetup] = React.useState(false);
  const [dataSetup, setDataSetup] = React.useState([]);
  const dataTypeSetup = [
    { label: 'Weekday', value: 'WEEKDAY' },
    { label: 'Weekend', value: 'WEEKEND' },
    { label: 'Custom Tanggal', value: 'CUSTOM' },
    { label: 'Custom Range Tanggal', value: 'CUSTOM RANGE' },
  ];
  const [selectedTypeSetup, setSelectedTypeSetup] = React.useState(dataTypeSetup[0]);
  const [isTypeCustom, SetIsTypeCustom] = React.useState(false);

  const dataStatus = [
    { label: 'Buka', value: 'Buka' },
    { label: 'Tutup', value: 'Tutup' },
  ];
  const [selectedStatus, setSelectedStatus] = React.useState(dataStatus[0]);
  const [isStatus, SetIsStatus] = React.useState(true);
  const [alertDelete, setAlertDelete] = React.useState(false);
  const [selectedIDSetup, setSelectedIDSetup] = React.useState(null);

  const [formSetup, setFormSetup] = React.useState({
    id: null,
    parameter: 'WEEKDAY',
    status: '',
    session: '',
    tanggal: Date(),
    tanggalMulai: Date(),
    tanggalAkhir: Date(),
    open: '',
    close: '',
  });

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (currUser) {
      const { token } = currUser;
      if (token) {
        setAccountData(token);
      }
    }
  }, []);

  useEffect(() => {
    if (accountData) {
      if (accountData.role === 2) {
        getStore(accountData.store);
      } else {
        getStores();
      }
    }
  }, [accountData]);

  // API
  // ROLE = 0 || ROLE = 1
  const getStores = () => {
    axios({
      method: 'GET',
      url: `stores`,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) { parsingStore(response.data.data); }
      else { parsingStore([]); }
    }).catch(error => { alert(error); })
  };

  // ROLE = 2
  const getStore = (store) => {
    axios({
      method: 'GET',
      url: `store?id=` + store,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) {
        setSelectedStore(response.data.data);
        setSelectedStoreForm(response.data.data);
        getStudios(response.data.data);
      }
      else { setSelectedStore([]) }
    }).catch(error => { alert(error); })
  };

  const getStudios = (store) => {
    axios({
      method: 'GET',
      url: `store/studios?store=` + store.id,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) { setData(response.data.data); }
    }).catch(error => { alert(error); })
  };

  const AddStudio = async () => {
    let data_studio = {
      store: selectedStoreForm.id,
      studios: [
        {
          name: form.name,
          description: form.description,
          capacity: form.capacity == null || form.capacity == "" ? null : Number(form.capacity),
        }
      ],
    };

    const studio = await axios({
      method: 'POST',
      url: `store/studiod`,
      headers: { "Content-Type": "application/json" },
      data: data_studio
    })

    studio.data.data.studios.forEach(async (item) => {
      await axios({
        method: 'POST',
        url: `package/studio`,
        headers: { "Content-Type": "application/json" },
        data: {
          studio: item.id,
          packages: studioPackages.map(p => p.id)
        }
      })
    })

    getStudios(selectedStore);
    handleDrawerClose();
  };

  const UpdateStudio = async () => {
    let data_studio = {
      store: selectedStoreForm.id,
      studios: [
        {
          id: form.id,
          name: form.name,
          description: form.description,
          capacity: form.capacity == null || form.capacity == "" ? null : Number(form.capacity),
        }
      ]
    };

    await axios({
      method: 'PUT',
      url: `store/studiod`,
      data: data_studio,
      headers: { "Content-Type": "application/json" },
    })

    data_studio.studios.forEach(async (item) => {
      await axios({
        method: 'PUT',
        url: `package/studio`,
        headers: { "Content-Type": "application/json" },
        data: {
          studio: item.id,
          packages: studioPackages.map(p => p.id)
        }
      })
    })

    getStudios(selectedStore);
    handleDrawerClose();
  };

  const deleteStudio = id => {
    axios({
      method: 'DELETE',
      url: `store/studiod`,
      headers: { "Content-Type": "application/json" },
      params: { id: id }
    }).then(() => {
      getStudios(selectedStore);
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  const getSetups = (studio) => {
    axios({
      method: 'GET',
      url: `store/studio/setups?studio=` + studio,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) {
        setDataSetup(response.data.data);
      } else {
        dataSetup([]);
      }
    }).catch(error => {
      alert(error);
    })
  };

  const getPackages = async (store) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `package/store?store=` + store,
        headers: { "Content-Type": "application/json" },
      })

      if (response.status === 200 && response.data != null) {
        const packages = response.data.data.packages
          .map(item => ({ id: item.id, title: item.title, price: item.price.base }))
          .sort((a, b) => a.price > b.price ? -1 : 1);
        setPackages(packages);
        return packages;
      }
    } catch (e) {
      alert(e);
      return [];
    }
  }

  const getStudioPackages = async (studio, available) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `package/studio?studio=` + studio,
        headers: { "Content-Type": "application/json" },
      })

      if (response.status === 200 && response.data.data != null) {
        const studioPackages = response.data.data
          .map(item => ({ id: item.package, title: item.package_data.title, price: item.package_data.price.base }))
          .sort((a, b) => a.price > b.price ? -1 : 1)
        setStudioPackages(studioPackages);
        setPackages(available.filter(item => studioPackages.find(i => i.id == item.id) == undefined));
      } else {
        setStudioPackages([])
      }
    } catch (e) {
      alert(e);
    }
  };

  const AddSetup = () => {
    let data_setup = {};
    if (formSetup.parameter == 'CUSTOM RANGE') {
      let dates = [];
      var start = moment(formSetup.tanggalMulai);
      var end = moment(formSetup.tanggalAkhir);
      for (var m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
        const param = {
          parameter: m.format('YYYY-MM-DD'),
          is_open: isStatus,
          session: formSetup.session,
          open: formSetup.open,
          close: formSetup.close
        }
        dates.push(param);
      }
      data_setup = {
        studio: form.id,
        setups: dates,
        custom: true,
      };
    } else {
      let parameter = formSetup.parameter == 'CUSTOM' ? moment(formSetup.tanggal).format('YYYY-MM-DD') : formSetup.parameter;
      data_setup = {
        studio: form.id,
        setups: [{
          parameter: parameter,
          is_open: isStatus,
          session: formSetup.session,
          open: formSetup.open,
          close: formSetup.close
        }],
        custom: formSetup.parameter == 'CUSTOM',
      };
    }

    axios({
      method: 'POST',
      url: `store/studio/setup`,
      headers: { "Content-Type": "application/json" },
      data: data_setup
    }).then(() => {
      getSetups(form.id);
      handleSetupClose(true);
    }).catch(error => {
      alert(error);
    })
  };

  const EditSetup = () => {
    let parameter = formSetup.parameter === 'CUSTOM' ? moment(formSetup.tanggal).format('YYYY-MM-DD') : formSetup.parameter;

    let data_setup = {
      id: formSetup.id,
      parameter: parameter,
      is_open: isStatus,
      session: formSetup.session,
      open: formSetup.open,
      close: formSetup.close
    };

    axios({
      method: 'PUT',
      url: `store/studio/setup?studio=` + form.id,
      data: data_setup,
      headers: { "Content-Type": "application/json" },
    }).then(() => {
      getSetups(form.id);
      handleSetupClose();
    }).catch(error => {
      alert(error);
    })
  };

  const DeleteSetup = id => {
    axios({
      method: 'DELETE',
      url: `store/studio/setup`,
      headers: { "Content-Type": "application/json" },
      params: { id: id }
    }).then(() => {
      getSetups(form.id);
      setAlertDelete(false);
    }).catch(error => {
      alert(error);
    })
  };
  //

  // parsing
  const parsingStore = data => {
    const newData = data.map(v => {
      return {
        ...v,
        label: v.name,
        value: v.id
      }
    });

    setDataStore(newData);
    setSelectedStore(newData[0]);
    setSelectedStoreForm(newData[0]);

    getStudios(newData[0]);
  };
  // 

  // handle
  const handleDrawerOpen = async () => {
    setOpen(true);
    return await getPackages(selectedStore.id);
  };

  const handleDrawerOpenForEdit = async id => {
    const selectStudio = data.find(v => v.id === id);

    setForm({
      ...form,
      id: selectStudio.id,
      name: selectStudio.name,
      description: selectStudio.description,
      capacity: selectStudio.capacity,
    });

    setSelectedStoreForm(dataStore.find(store => store.id === selectedStore.id));

    getSetups(id);

    const available = await handleDrawerOpen();
    getStudioPackages(selectStudio.id, available);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      name: '',
      description: '',
      capacity: null,
    });
    setDataSetup([]);
    setStudioPackages([]);
    setSearchAvailable("");
    setSearchSelected("");
  };

  const handleChangeForm = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleSelectedStore = store => {
    setSelectedStore(store);
    getStudios(store);
  };

  const handleSelectedStoreForm = store => {
    setSelectedStoreForm(store);
  };

  const handleSelectedTypeSetup = setup => {
    setSelectedTypeSetup(setup);

    if (setup.value === 'CUSTOM' || setup.value === 'CUSTOM RANGE') {
      SetIsTypeCustom(true);
    } else {
      SetIsTypeCustom(false);
    }

    setFormSetup({
      ...formSetup,
      parameter: setup.value
    });
  };

  const handleSelectedStatus = status => {
    setSelectedStatus(status);

    if (status.value === 'Buka') {
      SetIsStatus(true);
    } else {
      SetIsStatus(false);
      setFormSetup({
        ...formSetup,
        open: '',
        close: ''
      });
    }
  };

  const handleChangeFormSetup = (name) => e => {
    if (name === 'tanggal' || name === 'tanggalMulai' || name === 'tanggalAkhir') {
      setFormSetup({
        ...formSetup,
        [name]: e
      });
    }
    else {
      setFormSetup({
        ...formSetup,
        [name]: e.target.value
      });
    }
  };

  const handleEditSetup = id => {
    const selectSetup = dataSetup.find(v => v.id === id);

    let tgl = new Date();
    let param = selectSetup.parameter;

    if (selectSetup.parameter != 'WEEKEND' && selectSetup.parameter != 'WEEKDAY') {
      SetIsTypeCustom(true);
      tgl = selectSetup.parameter;
      param = "CUSTOM";
    }

    selectSetup.parameter === 'WEEKDAY' ? setSelectedTypeSetup(dataTypeSetup[0]) : selectSetup.parameter === 'WEEKEND' ? setSelectedTypeSetup(dataTypeSetup[1]) : setSelectedTypeSetup(dataTypeSetup[2]);

    if (selectSetup.is_open === false) { SetIsStatus(false); }

    setFormSetup({
      ...formSetup,
      id: selectSetup.id,
      parameter: param,
      session: selectSetup.session,
      tanggal: tgl,
      status: '',
      open: selectSetup.open,
      close: selectSetup.close
    });

    setOpenSetup(true);
  }

  const handleSetupClose = (dontRemovePackage = false) => {
    setOpenSetup(false);

    setFormSetup({
      id: null,
      parameter: '',
      session: '',
      status: '',
      tanggal: Date(),
      tanggalMulai: Date(),
      tanggalAkhir: Date(),
      open: '',
      close: ''
    });
    setSelectedTypeSetup(dataTypeSetup[0]);
    SetIsStatus(true);
    SetIsTypeCustom(false);
    if (dontRemovePackage == false) {
      setPackages([]);
      setStudioPackages([]);
      setSearchAvailable("");
      setSearchSelected("");
    }
  };
  //


  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  const renderFilter = () => {
    if (accountData) {
      if (accountData.role === 2) {
        return (
          <h5>Store : <b>{selectedStore && selectedStore.name}</b></h5>
        )
      } else {
        return (
          <Grid item xs={4} style={{ marginTop: '15px', marginLeft: '-10px' }}>
            <Select
              name="Store"
              placeholder="Filter Store"
              options={dataStore}
              value={selectedStore}
              onChange={handleSelectedStore}
            />
          </Grid>
        )
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <NavLink to='/store' style={{ color: '#3c3835', marginLeft: '-10px' }}>
              <ArrowBackIcon />
            </NavLink>

            <h4>Studio</h4>
            <hr style={{ marginBottom: '30px' }} />

            {renderFilter()}

            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deleteStudio}
              onEdit={handleDrawerOpenForEdit}
              onUpdate={() => { }}
              titleAdd="Tambah Studio"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage} />
          </Card>
        </Grid>
      </Grid>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose();
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {form.id == null ? "Simpan" : "Ubah"} Informasi Studio
          <Button variant="contained" color="primary" style={{ position: 'absolute', right: '20px', top: '-10px' }} className={classes.buttonAdd} onClick={form.id === null ? () => AddStudio() : () => UpdateStudio()}>
            {form.id == null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={5}>
            <h5>Informasi Studio</h5>

            {accountData && (accountData.role === 0 || accountData.role === 1)
              ?
              <Grid item xs={11}>
                <Select
                  name="Store"
                  placeholder="Pilih Store"
                  options={dataStore}
                  value={selectedStoreForm}
                  onChange={handleSelectedStoreForm}
                  fullWidth
                />
              </Grid>
              :
              <h5>Store : {selectedStore && selectedStore.name}</h5>}

            <Grid item xs={11}>
              <TextField
                id="name"
                label="Nama Studio"
                value={form.name}
                onChange={handleChangeForm('name')}
                margin="normal"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                id="capacity"
                label="Kapasitas"
                value={form.capacity}
                onChange={handleChangeForm('capacity')}
                margin="normal"
                type='number'
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                id="description"
                label="Deskripsi Studio"
                value={form.description}
                onChange={handleChangeForm('description')}
                margin="normal"
                fullWidth
                multiline
                rows='4'
                variant="outlined"
              />
            </Grid>
          </Grid>
          {
            form.id != null && <Grid item xs={7}>
              <h5>Jadwal kerja studio</h5>
              <Grid item xs={12} style={{ marginBottom: '25px' }}>
                {
                  dataSetup.length != 0
                    ?
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Tipe Setup</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Sesi</TableCell>
                          <TableCell>Jam Buka</TableCell>
                          <TableCell>Jam Tutup</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          dataSetup.map(v => (
                            <TableRow key={v.id}>
                              <TableCell>{v.parameter == 'WEEKEND' || v.parameter === 'WEEKDAY' ? v.parameter : moment(v.parameter).format("DD-MM-YYYY")}</TableCell>
                              <TableCell>{v.is_open ? 'Buka' : 'Tutup'}</TableCell>
                              <TableCell>{v.session}</TableCell>
                              <TableCell>{v.open == "" ? "-" : v.open}</TableCell>
                              <TableCell>{v.close == "" ? "-" : v.close}</TableCell>
                              <TableCell>
                                <a onClick={() => { handleEditSetup(v.id); }}>
                                  <EditIcon style={{ color: blue[500] }} className="pointer mr-2" />
                                </a>
                                <a onClick={() => {
                                  setAlertDelete(true);
                                  setSelectedIDSetup(v.id);
                                }}>
                                  <DeleteIcon style={{ color: red[500] }} className="pointer mr-2" />
                                </a>
                              </TableCell>
                            </TableRow >
                          ))
                        }
                      </TableBody>
                    </Table>
                    :
                    null
                }
              </Grid>
              <Button onClick={() => setOpenSetup(true)} variant="contained" color="secondary">
                Tambah Setup
              </Button>
            </Grid>
          }
          {form.id == null && <Grid item xs={7}></Grid>}
          <Grid items xs={6} style={{ marginTop: '25px' }}>
            <Table>
              <TableHead style={{ backgroundColor: '#3C3835' }}>
                <TableRow>
                  <TableCell style={{ color: '#fff' }} width="90%" align='center'>Paket Store</TableCell>
                  <TableCell style={{ color: '#fff' }} width="10%"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={9}>
                        <TextField
                          id="search-available"
                          label="Cari Paket Store"
                          value={searchAvailable}
                          onChange={e => setSearchAvailable(e.target.value)}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={3} style={{ textAlign: "right" }}>
                        <Button onClick={() => {
                          const newData = packages;
                          setPackages([]);
                          setStudioPackages(
                            [...studioPackages, ...newData]
                              .sort((a, b) => a.price > b.price ? -1 : 1)
                          )
                        }} variant="contained">
                          Pilih Semua
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                {
                  packages.filter(item => item.title.toLowerCase().includes(searchAvailable.toLowerCase()))
                    .map(item => (
                      <StyledTableRow>
                        <TableCell width="90%">{item.title} (Rp{item.price.toLocaleString(navigator.language, { minimumFractionDigits: 2 })})</TableCell>
                        <TableCell width="10%">
                          <a style={{ cursor: "pointer" }} onClick={() => {
                            const newData = packages;
                            newData.splice(newData.indexOf(item), 1);
                            setPackages(newData);
                            setStudioPackages(
                              [...studioPackages, item]
                                .sort((a, b) => a.price > b.price ? -1 : 1)
                            )
                          }}>
                            <KeyboardArrowRightOutlined />
                          </a>
                        </TableCell>
                      </StyledTableRow >
                    ))
                }
              </TableBody>
            </Table>
          </Grid>
          <Grid items xs={6} style={{ marginTop: '25px' }}>
            <Table>
              <TableHead style={{ backgroundColor: '#3C3835' }}>
                <TableRow>
                  <TableCell style={{ color: '#fff' }} width="10%"></TableCell>
                  <TableCell style={{ color: '#fff' }} width="90%" align='center'>Paket Studio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Grid container
                      direction="row"
                      justifyContent="center"
                      alignItems="center">
                      <Grid item xs={3}>
                        <Button onClick={() => {
                          const newData = studioPackages;
                          setStudioPackages([]);
                          setPackages(
                            [...packages, ...newData]
                              .sort((a, b) => a.price > b.price ? -1 : 1));
                        }} variant="contained">
                          Hapus Semua
                        </Button>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          id="search-selected"
                          label="Cari Paket Studio"
                          value={searchSelected}
                          onChange={e => setSearchSelected(e.target.value)}
                          margin="normal"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
                {
                  studioPackages.filter(item => item.title.toLowerCase().includes(searchSelected.toLowerCase()))
                    .map((item, index) => (
                      <StyledTableRow>
                        <TableCell width="10%">
                          <a style={{ cursor: "pointer" }} onClick={() => {
                            const newData = studioPackages;
                            newData.splice(newData.indexOf(item), 1);
                            setStudioPackages(newData);
                            setPackages(
                              [...packages, item]
                                .sort((a, b) => a.price > b.price ? -1 : 1)
                            )
                          }}>
                            <KeyboardArrowLeftOutlined />
                          </a>
                        </TableCell>
                        <TableCell width="90%">{item.title} (Rp{item.price.toLocaleString(navigator.language, { minimumFractionDigits: 2 })})</TableCell>
                      </StyledTableRow>
                    ))
                }
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <Divider />
      </Drawer>

      {/* DIALOG FOR SETUP */}
      <Dialog open={openSetup} onClose={handleSetupClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Jadwal kerja studio</DialogTitle>
        <DialogContent>
          <Grid container style={{ width: '530px' }}>
            <Grid item xs={5} style={{ marginRight: '20px' }}>
              <InputLabel htmlFor="Setup">Jenis Setup</InputLabel>
              <Select
                name="Setup"
                placeholder="Jenis Setup"
                options={dataTypeSetup.filter(f => {
                  if (formSetup.id !== null)
                    return f.value != "CUSTOM RANGE";
                  return true;
                })}
                value={selectedTypeSetup}
                onChange={handleSelectedTypeSetup}
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: '10px' }}>
              {
                isTypeCustom === true
                  ? (
                    selectedTypeSetup.value == 'CUSTOM RANGE' ?
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className="m-0 p-0"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          id="tanggal"
                          name="tanggal"
                          label="Tanggal Mulai"
                          value={formSetup.tanggalMulai}
                          onChange={handleChangeFormSetup('tanggalMulai')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        <br />
                        <br />
                        <KeyboardDatePicker
                          className="m-0 p-0"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          id="tanggal"
                          name="tanggal"
                          label="Tanggal Akhir"
                          value={formSetup.tanggalAkhir}
                          onChange={handleChangeFormSetup('tanggalAkhir')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      :
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className="m-0 p-0"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          id="tanggal"
                          name="tanggal"
                          label="Tanggal"
                          value={formSetup.tanggal}
                          onChange={handleChangeFormSetup('tanggal')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                  )
                  :
                  null}
            </Grid>
            <Grid item xs={5} style={{ marginTop: '15px' }}>
              <InputLabel htmlFor="Status">Status</InputLabel>
              <Select
                name="Status"
                placeholder="Status"
                options={dataStatus}
                value={selectedStatus}
                onChange={handleSelectedStatus}
              />
            </Grid>

            {
              isStatus === true
                ?
                <Grid item xs={6} style={{ marginLeft: '20px', marginTop: '5px' }}>
                  <TextField
                    id="open_at"
                    name="open"
                    label="Jam Buka"
                    value={formSetup.open}
                    onChange={handleChangeFormSetup('open')}
                    margin="normal"
                    variant="outlined"
                    type="time"
                  />
                  <TextField
                    id="close"
                    name="close"
                    label="Jam Tutup"
                    value={formSetup.close}
                    onChange={handleChangeFormSetup('close')}
                    margin="normal"
                    variant="outlined"
                    type="time"
                    style={{ marginLeft: '20px' }}
                  />
                </Grid>
                :
                <></>
            }
            <TextField
              id="session"
              label="Sesi"
              value={formSetup.session}
              onChange={handleChangeFormSetup('session')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSetupClose} color="primary">
            Tidak
          </Button>
          <Button onClick={formSetup.id === null ? () => AddSetup() : () => EditSetup()} variant="contained" color="primary">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR DELETE SETUP */}

      <Dialog open={alertDelete} onClose={() => { setAlertDelete(false) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>
          Anda yakin ingin menghapus data ini?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setAlertDelete(false) }} color="primary">
            Tutup
          </Button>
          <Button onClick={() => {
            DeleteSetup(selectedIDSetup);
          }} color="primary" variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};

export default Studio;