import { Avatar, Button, Dialog, DialogActions, DialogContent, Grid, Modal, Slide, Snackbar, Switch, Table, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';
import axios from 'axios';
import { API } from '../../constants';
import React, { useEffect } from 'react';
import { useState } from 'react';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

const useStyles = makeStyles(theme => ({
    container: {
        padding: 20,
    },
    table: {
        "& .MuiTableCell-root": {
            border: '1px solid #ebebeb'
        }
    },
    bigAvatar: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        zIndex: 1,
        '&:hover': {
            opacity: '.5'
        }
    },
    fileUploadhide: {
        left: '0',
        float: 'left',
        opacity: 0,
        zIndex: '999',
        position: 'absolute',
        width: '100%',
        height: '100%',
        marginTop: '0'
    },
}));

const VarianTable = ({ product, onClose }) => {

    const styles = useStyles();
    const fileRef = React.createRef();

    const [errorMessage, setErrorMessage] = useState(null);

    const [willRemoveModal, setWillRemoveModal] = useState(null);
    const [willUpdateModal, setWillUpdateModal] = useState(false);
    const [imageToUpload, setImageToUpload] = useState(null);

    const [varians, setVarians] = useState([]);
    const [variantStatus, setVariantStatus] = useState(product?.variant_status || false)

    useEffect(() => {
        getPackageVarian(product.id);
    }, []);

    const getPackageVarian = async (packageId) => {
        try {
            const response = await axios({ url: `${API}/package/variant?package=${packageId}` });
            setVarians(response.data.data);
        } catch (e) {
            setErrorMessage(e.message);
        }
    }

    const onSubmit = async (newData) => {
        setWillUpdateModal(null);
        try {
            if (imageToUpload != null) {
                const url = `${API}/upload`;
                let bodyData = new FormData();
                bodyData.append('category', 'thumbnail');
                bodyData.append('file', imageToUpload.img);
                bodyData.append('type', imageToUpload.img['type']);

                const uploadResponse = await axios.post(url, bodyData);
                newData.image = uploadResponse.data.data;
            }

            await axios({
                method: newData.id == null ? 'POST' : 'PUT',
                url: `${API}/package/variant`,
                data: JSON.stringify({
                    ...newData,
                    base_price: Number(newData.base_price)
                }),
                headers: { 'Content-Type': 'application/json' }
            });

            setImageToUpload(null);
            getPackageVarian(newData.package);
        } catch (e) {
            setErrorMessage(e.message);
        }
    }

    const onClickRemove = async (varianId) => {
        setWillRemoveModal(null);
        try {
            await axios({
                method: 'DELETE',
                url: `${API}/package/variant?id=${varianId}`,
                headers: { 'Content-Type': 'application/json' }
            })
            getPackageVarian(product?.id);
        } catch (e) {
            setErrorMessage(e.message);
        }
    }

    const handleChangeFormValue = name => e => {
        setWillUpdateModal({
            ...willUpdateModal,
            [name]: name == 'image' ? e : e.target.value
        });
    };


    const onChooseImage = value => {
        const img = value.target.files;
        if (img.length !== 0) {
            const imgObj = {
                image_url: URL.createObjectURL(img[0]),
                label: '',
                img: img[0],
                error: false
            };
            setImageToUpload(imgObj)
            handleChangeFormValue('image')(imgObj.image_url);
        }
    };

    const updateStatusVariant = async (status) => {
        setVariantStatus(status);
        try {
            await axios({
                method: 'PUT',
                url: `${API}/package/variant/status`,
                data: JSON.stringify({
                    id: product?.id,
                    variant_status: status,
                }),
                headers: { 'Content-Type': 'application/json' }
            })
            getPackageVarian(product?.id);
        } catch (e) {
            setErrorMessage(e.message);
        }
    }

    return (
        <>
            <div className={styles.container}>
                Varian Package Status <Switch checked={variantStatus} onChange={(e) => updateStatusVariant(e.target.checked)} />
                <p>Jika varian package aktif, maka customer harus memilih salah satu varian ketika melakukan reservasi.</p>

                <Grid container>
                    <Grid item xs={12} alignItems='flex-end'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => setWillUpdateModal({
                                id: null,
                                package: product?.id,
                                name: "",
                                description: "",
                                base_price: Number(product?.price.replace(".00", "").replace(/\D/g, "") || 0),
                                image: "",
                            })}
                        >Tambah Varian</Button>
                    </Grid>
                </Grid>

                <br />

                <Table className={styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>No</TableCell>
                            <TableCell>Kode</TableCell>
                            <TableCell>Nama</TableCell>
                            <TableCell>Deskripsi</TableCell>
                            <TableCell>Base Harga</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        varians.map((varian, index) => (<>
                            <TableRow>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{varian.code}</TableCell>
                                <TableCell>{varian.name}</TableCell>
                                <TableCell>{varian.description}</TableCell>
                                <TableCell>Rp{Intl.NumberFormat().format(varian.base_price).replace(/\,/g, ".")}</TableCell>
                                <TableCell>
                                    <img src={varian.image} style={{
                                        width: 200,
                                        objectFit: "contain"
                                    }} />
                                </TableCell>
                                <TableCell>
                                    <Edit
                                        color='primary'
                                        onClick={() => setWillUpdateModal(varian)}
                                    />
                                    <Delete
                                        color='error'
                                        onClick={() => setWillRemoveModal(varian.id)}
                                    />
                                </TableCell>
                            </TableRow>
                        </>))
                    }
                </Table>
            </div >

            <Dialog
                open={willRemoveModal != null}
                onClose={() => setWillRemoveModal(null)}
            >
                <DialogContent>
                    Apakah anda yakin akan menghapus data?
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setWillRemoveModal(null)}>Batal</Button>
                    <Button variant='contained' color='primary' onClick={() => onClickRemove(willRemoveModal)}>Hapus Varian?</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={willUpdateModal}
                onClose={() => setWillUpdateModal(false)}
            >
                <DialogContent>
                    <h6>{willUpdateModal?.id == null ? "Tambah" : "Ubah"} Varian</h6>

                    <Grid container>

                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Kode"
                                margin="normal"
                                onChange={handleChangeFormValue('code')}
                                value={willUpdateModal?.code}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                label="Nama"
                                margin="normal"
                                onChange={handleChangeFormValue('name')}
                                value={willUpdateModal?.name}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Deskripsi"
                                margin="normal"
                                multiline
                                rows="4"
                                onChange={handleChangeFormValue('description')}
                                value={willUpdateModal?.description}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                style={{ width: "100%" }}
                                InputProps={{ inputProps: { min: 0 } }}
                                label="Base Price"
                                margin="normal"
                                type='number'
                                onChange={handleChangeFormValue('base_price')}
                                value={willUpdateModal?.base_price}
                                variant="outlined"
                            />
                        </Grid>

                        <br />
                        <br />

                        <Grid item xs={12}>
                            <Avatar
                                alt="Image"
                                className={`${styles.bigAvatar} opacity-100 position-relative`}
                                src={willUpdateModal?.image || ""}
                                value={willUpdateModal?.image || ""}>
                                <input
                                    className={styles.fileUploadhide + ' mt-2'}
                                    onChange={onChooseImage}
                                    ref={fileRef}
                                    type="file"
                                    accept="image/*"
                                />
                                <InsertPhotoIcon fontSize="large" />
                            </Avatar>
                            {
                                willUpdateModal?.image != "" && <>
                                    <Button
                                        variant='outlined'
                                        style={{ marginTop: 12 }}
                                        onClick={() => {
                                            handleChangeFormValue('image')('');
                                            setImageToUpload(null);
                                        }}>
                                        Hapus Gambar
                                    </Button>
                                </>
                            }
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setWillUpdateModal(false)}>Batal</Button>
                    <Button variant='contained' color='primary' onClick={() => onSubmit(willUpdateModal)}>Simpan</Button>
                </DialogActions>
            </Dialog >

            <Snackbar
                autoHideDuration={3000}
                ContentProps={{ 'aria-describedby': 'message-id' }}
                message={<span id="message-id">{errorMessage}</span>}
                onClose={() => setErrorMessage(null)}
                open={errorMessage != null}
                TransitionComponent={Slide}
            />
        </>
    );
}

export default VarianTable;