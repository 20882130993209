import React, { useEffect, useState } from 'react';
import MyTable from "../../components/MyTable";
import axios from '../../util/Service';
import { API } from '../../constants';
import fetch from 'isomorphic-unfetch';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StoreMenu from './StoreMenu'
import Select from '../../components/Select';
import moment from 'moment';

const drawerWidth = '40%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  buttonAdd: {
    margin: theme.spacing(3)
  },
  //upload image
  bungkusGambar: {
    // margin: 'auto',
    // width: 'auto',
    // display: 'table'
  },
  loaderBungkus: {
    margin: 10,
    width: 100,
    height: 100,
    background: 'rgba(0,0,0,0.4)',
    position: 'absolute',
    top: '0',
    borderRadius: '50%',
    zIndex: '99'
  },
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)'
  },
  fileUploadhide: {
    left: '25px',
    float: 'left',
    opacity: '0',
    zIndex: '999',
    position: 'absolute',
    width: '21%',
    height: '11%',
    marginTop: '20px'
  },
  bigAvatar: {
    width: '100px',
    height: '100px',
    borderRadius: '0',
    backgroundColor: '#f7f7f7',
    marginTop: '25px'
  }

})
);

const fields = ['Nama Store', 'Kota', 'Alamat', 'Jam Buka', 'Jam Tutup'];
const fieldData = ['name', 'city', 'address', 'open_at', 'closed_at'];

const Store = () => {
  const classes = useStyles();
  const theme = useTheme();

  //state
  const [data, setData] = useState([]);
  const [dataCities, setDataCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [dataPackages, setDataPackages] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState(null);
  const [accountData, setAccountData] = useState(null);

  //form
  const [selectedCityForm, setSelectedCityForm] = useState(null);
  const [form, setForm] = useState({
    id: null,
    name: '',
    description: '',
    open_at: '',
    closed_at: '',
    address: '',
    thumbnail_image_url: '',
    publish: false,
  })
  const [uploadImage, setUploadImage] = useState({
    load_on_upload: 0,
    thumbnail: '',
    image: '',
  })

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getCities();
    getPackages();

    const currUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currUser) {
      const { token } = currUser;
      if (token) {
        setAccountData(token);
      }
    }
  }, []);

  useEffect(() => {
    getStoreDetail();
  }, [accountData]);

  // API
  const getCities = () => {
    axios({
      method: 'GET',
      url: `admin/cities`,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.data.response_code === '00' && response.data.data != null) {
        parsingCities(response.data.data);
      } else {
        parsingCities([]);
      }
    }).catch(error => {
      alert(error);
    })
  }

  const getStores = (city) => {
    axios({
      method: 'GET',
      url: `admin/stores?city=` + city.id,
      headers: {
        "Content-Type": "application/json"
      },
    }).then(response => {
      if (response.data.response_code === '00' && response.data.data != null) {
        parsingStores(response.data.data)
      } else {
        parsingStores([])
      }
    }).catch(error => {
      alert(error);
    })
  }

  const getPackages = (category) => {
    axios({
      method: 'GET',
      url: `${API}/packages?page=1&limit=1000000`,
      headers: {
        "Content-Type": "application/json"
      },
    }).then(response => {
      response.data.response_code === '00' && setDataPackages(response.data.data.map(v => { return { ...v, label: v.title, value: v.id } }));
    }).catch(error => {
      alert(error);
    })
  }

  async function AddPhoto() {
    if (uploadImage.thumbnail !== '') {
      const url = `upload`
      let bodyData = new FormData();
      bodyData.append("category", "thumbnail");
      bodyData.append("file", uploadImage.image);
      bodyData.append("type", uploadImage.image['type']);
      const result = await axios.post(
        url, bodyData
      );

      let gambar = result.data.data;
      form.id === null ? AddStore(gambar) : UpdateStore(gambar);
    } else {
      form.id === null ? AddStore(form.thumbnail_image_url) : UpdateStore(form.thumbnail_image_url);
    }
  }


  async function AddStore(url_gambar) {

    const dataStore = {
      city: selectedCityForm.id,
      name: form.name,
      description: form.description,
      address: form.address,
      open_at: form.open_at + ":00",
      closed_at: form.closed_at + ":00",
      thumbnail_image_url: url_gambar,
      publish: form.publish,
      latitude: 0,
      longitude: 0,
    };

    axios({
      method: 'POST',
      url: `store`,
      headers: { "Content-Type": "application/json" },
      data: dataStore
    }).then(response => {
      getStores(selectedCity);

      if (selectedPackages.length != 0) {
        const dataPackages = {
          store: response.data.data.id,
          packages: selectedPackages.map(v => { return { id: v.id } })
        };
        fetch(`${API}/package/store`, {
          method: 'POST',
          body: JSON.stringify(dataPackages),
          headers: { 'Content-Type': 'application/json' }
        });
      }
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  async function UpdateStore(url_gambar) {

    const dataStore = {
      city: selectedCityForm.id,
      id: form.id,
      name: form.name,
      description: form.description,
      address: form.address,
      open_at: form.open_at,
      closed_at: form.closed_at,
      thumbnail_image_url: url_gambar,
      publish: form.publish,
      latitude: 0,
      longitude: 0,
    };

    axios({
      method: 'PUT',
      url: `store`,
      data: dataStore,
      headers: {
        "Content-Type": "application/json"
      },
    }).then(() => {
      getStores(selectedCity);
      getStoreDetail();

      if (selectedPackages.length != 0) {
        const dataPackages = {
          store: form.id,
          packages: selectedPackages.map(v => { return { id: v.id } })
        };
        fetch(`${API}/package/store`, {
          method: 'PUT',
          body: JSON.stringify(dataPackages),
          headers: { 'Content-Type': 'application/json' }
        });
      }
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  const deleteStore = id => {
    axios({
      method: 'DELETE',
      url: `store`,
      headers: { "Content-Type": "application/json" },
      params: {
        id: id
      }
    }).then(() => {
      getStores(selectedCity);
      handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };
  //

  // parsing
  const parsingStores = data => {
    const newData = data.map(v => {
      return {
        id: v.id,
        name: v.name,
        city: v.city.name,
        address: v.address,
        description: v.description,
        thumbnail_image_url: v.thumbnail_image_url,
        publish: v.publish ? v.publish : false,
        open_at: v.open_at,
        closed_at: v.closed_at,
        latitude: v.latitude,
        longitude: v.longitude
      }
    });
    setData(newData);
  };

  const parsingCities = data => {
    const newData = data.map(v => {
      return {
        ...v,
        label: v.name,
        value: v.id
      }
    });

    setDataCities(newData);
    setSelectedCity(newData[0]);
    setSelectedCityForm(newData[0]);

    getStores(newData[0]);
  };

  const getSelectedPackages = async (id) => {
    // get package by store role 0 & 1
    const api = await fetch(`${API}/package/store?store=${id}`, {
      method: 'GET'
    });
    const response = await api.json();
    response.response_code === '00' && setSelectedPackages(response.data.packages.map(v => { return { ...v, label: v.title, value: v.id } }));
  };
  //

  // handle
  const handleDrawerOpen = () => { setOpen(true); };

  const handleDrawerOpenForEdit = id => {

    const selectStore = data.find(v => v.id === id)
    setForm({
      ...form,
      id: selectStore.id,
      name: selectStore.name,
      description: selectStore.description,
      open_at: selectStore.open_at,
      closed_at: selectStore.closed_at,
      address: selectStore.address,
      thumbnail_image_url: selectStore.thumbnail_image_url,
      publish: selectStore.publish
    })

    setSelectedCityForm(dataCities.find(city => city.id === selectedCity.id));
    getSelectedPackages(selectStore.id);
    handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      name: '',
      description: '',
      open_at: '',
      closed_at: '',
      address: '',
      thumbnail_image_url: '',
      image: '',
      publish: false,
    })
    setSelectedPackages(null);
  };

  const handleChangeForm = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleSelectedCityForm = city => {
    setSelectedCityForm(city);
  };

  const handleSelectedCity = city => {
    setSelectedCity(city);
    getStores(city);
  };

  const onChangeGambar = (value) => {
    let gambar = value.target.files;
    if (gambar.length !== 0) {
      setUploadImage({
        thumbnail: URL.createObjectURL(gambar[0]),
        image: gambar[0]
      });
    }
  };

  const [store, setStore] = useState(null);

  const getStoreDetail = async () => {
    if (accountData && accountData.role && accountData.role === 2 && accountData.store) {
      const api = await fetch(`${API}/store?id=${accountData.store}`, {
        method: 'GET'
      });
      const res = await api.json();

      if (res.response_code === '00') {
        setStore(res.data);

        // get package by store role admin store
        getSelectedPackages(accountData.store);
      }
    }
  };

  const editCurrentStore = async () => {

    setForm({
      ...form,
      id: store.id,
      name: store.name,
      description: store.description,
      open_at: store.open_at,
      closed_at: store.closed_at,
      address: store.address,
      thumbnail_image_url: store.thumbnail_image_url,
      publish: store.publish
    });

    setSelectedCityForm(dataCities.find(city => city.id === store.city.id));

    getSelectedPackages(store.id);

    handleDrawerOpen();
  };

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  const handleShowHideStore = (id) => {
    const selectedStore = data.find(v => v.id === id);
    const selectedCity = dataCities.find(city => city.name === selectedStore.city);

    const dataStore = {
      city: selectedCity.id,
      id: selectedStore.id,
      name: selectedStore.name,
      description: selectedStore.description,
      address: selectedStore.address,
      open_at: selectedStore.open_at,
      closed_at: selectedStore.closed_at,
      thumbnail_image_url: selectedStore.thumbnail_image_url,
      publish: !selectedStore.publish,
      latitude: 0,
      longitude: 0,
    };

    axios({
      method: 'PUT',
      url: `store`,
      data: dataStore,
      headers: {
        "Content-Type": "application/json"
      },
    }).then(() => {
      getStores(selectedCity);
      // getStoreDetail()

      // if (selectedPackages.length != 0) {
      //   const dataPackages = {
      //     store: form.id,
      //     packages: selectedPackages.map(v => { return { id: v.id } })
      //   }
      //   fetch(`${API}/package/store`, {
      //     method: 'PUT',
      //     body: JSON.stringify(dataPackages),
      //     headers: {
      //       'Content-Type': 'application/json'
      //     }
      //   });
      // }
      // handleDrawerClose();
    }).catch(error => {
      alert(error);
    })
  };

  const handleChangePublish = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.checked
    });
  };

  const renderPerRole = () => {
    if (accountData) {
      if (accountData.role === 0 || accountData.role === 1) {
        return (
          <div>
            <Grid item xs={4}>
              <Select
                name="Kota"
                placeholder="Filter Kota"
                options={dataCities}
                value={selectedCity}
                onChange={handleSelectedCity}
              />
            </Grid>

            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onEdit={handleDrawerOpenForEdit}
              onShowHide={handleShowHideStore}
              onUpdate={() => { }}
              titleAdd="Tambah Store"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage} />
          </div>
        )
      } else {
        if (store) {
          return (
            <div>
              <div className="d-flex justify-content-end">
                <Button variant="contained" color="secondary" className="mb-3" onClick={editCurrentStore}>
                  Edit Store
                </Button>
              </div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="font-weight-bold">Store Name</TableCell>
                    <TableCell>{store.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-weight-bold">Store Address</TableCell>
                    <TableCell>{store.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-weight-bold">Description</TableCell>
                    <TableCell>{store.description}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-weight-bold">Open Hour</TableCell>
                    <TableCell>{`${moment(store.open_at, "HH:mm:ss").format("HH:mm")} - ${moment(store.closed_at, "HH:mm:ss").format("HH:mm")}`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-weight-bold">Store Photo</TableCell>
                    <TableCell><img src={store.description} alt='' width="200px" /></TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell className="font-weight-bold">Latitude</TableCell>
                    <TableCell>{store.latitude}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-weight-bold">Longitude</TableCell>
                    <TableCell>{store.longitude}</TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </div>
          )
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <StoreMenu />
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">

            <h4>Store</h4>
            <hr style={{ marginBottom: '30px' }} />

            {
              renderPerRole()
            }

            {/* {
              accountData != null && accountData.role && accountData.role === 0 || accountData.role === 1 ?
                <div>
                  <Grid item xs={4}>
                    <Select
                      name="Kota"
                      placeholder="Filter Kota"
                      options={dataCities}
                      value={selectedCity}
                      onChange={handleSelectedCity}
                    />
                  </Grid>

                  <MyTable
                    id="id"
                    data={data}
                    fields={fields}
                    fieldData={fieldData}
                    onAdd={handleDrawerOpen}
                    onDelete={deleteStore}
                    onEdit={handleDrawerOpenForEdit}
                    onUpdate={() => { }}
                    titleAdd="Tambah Store"
                    page={page}
                    onNextPageClicked={handleOnNextPage}
                    onBackPageClicked={handleOnBackPage} />
                </div> : null
            }
            {
              accountData != null && accountData.role && accountData.role === 2 || accountData.role === 3 && store ?
                <div>
                  <div className="d-flex justify-content-end">
                    <Button variant="contained" color="secondary" className="mb-3" onClick={editCurrentStore}>
                      Edit Store
                    </Button>
                  </div>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell className="font-weight-bold">Store Name</TableCell>
                        <TableCell>{store.name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="font-weight-bold">Store Address</TableCell>
                        <TableCell>{store.address}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="font-weight-bold">Description</TableCell>
                        <TableCell>{store.description}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="font-weight-bold">Open Hour</TableCell>
                        <TableCell>{`${moment(store.open_at, "HH:mm:ss").format("HH:mm")} - ${moment(store.closed_at, "HH:mm:ss").format("HH:mm")}`}</TableCell>
                      </TableRow>
                      <TableRow>
                          <TableCell className="font-weight-bold">Latitude</TableCell>
                          <TableCell>{store.latitude}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="font-weight-bold">Longitude</TableCell>
                          <TableCell>{store.longitude}</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </div> : <span>Toko tidak ditemukan</span>
            } */}
          </Card>
        </Grid >
      </Grid >

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => {
            handleDrawerClose()
          }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {form.id === null ? "Simpan" : "Ubah"} Informasi Store
          <Button variant="contained" color="primary" style={{ position: 'absolute', right: '20px', top: '-10px' }} className={classes.buttonAdd} onClick={() => AddPhoto()}>
            {form.id === null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            <div className="d-flex justify-content-end">
              <FormControlLabel
                control={
                  <Switch
                    checked={form.publish}
                    onChange={handleChangePublish('publish')}
                    name="publish store"
                    color="primary"
                  />
                }
                label="Publish Store"
              />
            </div>
            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              <Select
                name="Kota"
                placeholder="Pilih Kota"
                options={dataCities}
                value={selectedCityForm}
                onChange={handleSelectedCityForm}
                fullWidth
              />
            </Grid>
            <Select
              isMulti
              name="Packages"
              placeholder="Pilih Packages"
              options={dataPackages}
              value={selectedPackages}
              onChange={e => { setSelectedPackages(e) }}
              fullWidth
            />
            <TextField
              id="name"
              label="Nama Store"
              value={form.name}
              onChange={handleChangeForm('name')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
            <TextField
              id="description"
              label="Deskripsi Store"
              value={form.description}
              onChange={handleChangeForm('description')}
              margin="normal"
              fullWidth
              multiline
              rows='4'
              variant="outlined"
            />
            <TextField
              id="open_at"
              label="Jam Buka"
              value={form.open_at}
              onChange={handleChangeForm('open_at')}
              margin="normal"
              variant="outlined"
              type="time"
              style={{ width: "125px" }}
            />
            <TextField
              id="closed_at"
              label="Jam Tutup"
              value={form.closed_at}
              onChange={handleChangeForm('closed_at')}
              margin="normal"
              variant="outlined"
              type="time"
              style={{ marginLeft: '20px', width: "125px" }}
            />
            <TextField
              id="address"
              label="Alamat Store"
              value={form.address}
              onChange={handleChangeForm('address')}
              margin="normal"
              fullWidth
              multiline
              rows='4'
              variant="outlined"
            />
            <Grid item xs={6}>
              <div className={classes.bungkusGambar}>
                {uploadImage.thumbnail !== '' ?
                  <div>
                    {uploadImage.load_on_upload === 1 ? <div className={classes.loaderBungkus}>
                      <CircularProgress color='secondary' style={{ top: '40%' }} className={classes.progress} />
                    </div> : uploadImage.load_on_upload === 2 ? <div className={classes.loaderBungkus}>
                      <CheckIcon color='primary' fontSize='large' className={classes.progress} />
                    </div> : ''}
                    < Avatar alt="Image" style={{ width: '100px' }} src={uploadImage.thumbnail} className={classes.bigAvatar} value={form.thumbnail_image_url} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatar} src={form.thumbnail_image_url}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
                }
              </div>
              <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChangeGambar} />
              <Button variant="contained" color="secondary" className={classes.button} style={{ marginTop: '20px' }}>
                Ubah Foto
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </Drawer>
    </div >
  );
};

export default Store;
