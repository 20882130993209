import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, Grid, IconButton, InputLabel, Slide, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import MyTable from "../../components/MyTable";
import { connect } from "react-redux";
import { useEffect } from 'react';
import { useState } from 'react';
import { API } from "../../constants";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Select from '../../components/Select';
import moment from 'moment';
import { blue, red } from '@material-ui/core/colors';
import axios from "../../util/Service";

const drawerWidth = '80%';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    typography: {
        fontFamily: [
            'Roboto',
        ].join(','),
    },
    content: {
        flexShrink: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: '999',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    buttonAdd: {
        margin: theme.spacing(3),
    },
    //upload image
    fileUploadThumbnail: {
        left: '25px',
    },
    fileUploadhide: {
        float: 'left',
        opacity: '0',
        zIndex: '999',
        position: 'absolute',
        width: '21%',
        height: '11%',
        marginTop: '20px',
    },
    bigAvatar: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    bigAvatarBanner: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    disableClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    enableClick: {
        pointerEvents: 'auto',
        cursor: 'default',
    }
}));

const fields = ['Nama Template'];
const fieldData = ['name'];

const Template = props => {
    const classes = useStyles();
    const theme = useTheme();

    const getTemplates = async () => {
        const api = await fetch(`${API}/template/templates`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setData(res.data);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getDetailTemplate = async (id) => {
        const api = await fetch(`${API}/template/template?id=${id}`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setForm({
                id: res.data.id,
                name: res.data.name,
                pics: res.data.pics,
            });
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const sessionExpired = () => {
        handleShowSnackbar('Sesi anda sudah berakhir. Harap login kembali');
        localStorage.clear();
        window.location.href = "/sign-in";
    };

    const addTemplate = async (form) => {
        const api = await fetch(`${API}/template/template`, {
            body: JSON.stringify(form),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            console.log(res.data.id)
            handleDrawerClose(res.data.id);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const updateTemplate = async (form) => {
        const api = await fetch(`${API}/template/template`, {
            body: JSON.stringify(form),
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleDrawerClose(form.id);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const deleteTemplate = async (id) => {
        const body = {
            id: id
        };
        const api = await fetch(`${API}/template/template`, {
            method: 'DELETE',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!api.ok) {
            handleShowSnackbar(true, api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleShowSnackbar(true, 'Template berhasil di hapus');
            getTemplates();
        } else {
            handleShowSnackbar(true, res.message);
        }
    }

    useEffect(() => {
        const currUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currUser) {
            const { token } = currUser;
            if (token) {
                setAccountData(token);
                getTemplates();
            } else {
                sessionExpired();
            }
        } else {
            sessionExpired();
        }
    }, []);

    async function UploadImage(image) {
        const url = `upload`
        let bodyData = new FormData();
        bodyData.append("category", "thumbnail");
        bodyData.append("file", image);
        bodyData.append("type", image["type"]);
        const result = await axios.post(
            url, bodyData
        );

        setForm({
            ...form,
            pics: `${form.pics},${result.data.data}`
        })
    };

    const [accountData, setAccountData] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [data, setData] = useState([]);
    const [disableDuringFetch, setDisableDuringFetch] = useState(false);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState({
        id: null,
        name: null,
        pics: null,
    })

    const handleDrawerOpen = () => {
        setForm({
            id: null,
            name: null,
            pics: null,
        });
        setOpen(true);
    }

    const handleDrawerOpenForEdit = (id) => {
        getDetailTemplate(id);
        setOpen(true);
    }

    const handleShowSnackbar = message => {
        setShowSnackbar(true);
        setMessageSnackbar(message);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        getTemplates();
    }

    const handleSubmit = () => {
        const body = {
            name: form.name,
            pics: form.pics,
        }
        if (form.id == null) {
            addTemplate(body);
        } else {
            updateTemplate({
                ...body,
                id: form.id,
            })
        }
    }

    const handleDeleteTemplate = async id => {
        deleteTemplate(id);
    };


    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Card className={classes.root} variant="outlined">

                        <h4>Template</h4>
                        <hr style={{ marginBottom: '30px' }} />

                        <MyTable
                            id="id"
                            data={data}
                            fields={fields}
                            fieldData={fieldData}
                            onAdd={handleDrawerOpen}
                            onEdit={handleDrawerOpenForEdit}
                            onDelete={handleDeleteTemplate}
                            onUpdate={() => { }}
                            titleAdd="Tambah Template" />
                    </Card>
                </Grid>
            </Grid>

            <Drawer
                className={`${classes.drawer} ${disableDuringFetch ? classes.disableClick : classes.enableClick}`}
                anchor="right"
                open={open}
                classes={{ paper: classes.drawerPaper, }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => {
                        handleDrawerClose()
                    }}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Typography variant="h5">{form.id == null ? "Simpan" : "Ubah"} Informasi Template</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ position: 'absolute', right: '20px', top: '-10px' }}
                        className={classes.buttonAdd} onClick={() => handleSubmit()}>
                        {form.id == null ? "Simpan" : "Ubah"}
                    </Button>
                </div>
                <Divider />
                <Grid container style={{ padding: theme.spacing(3) }}>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            name="name"
                            label="Nama Template"
                            style={{ width: '100%' }}
                            value={form.name}
                            onChange={(e) => {
                                setForm({
                                    ...form,
                                    name: e.target.value,
                                })
                            }}
                            margin="normal"
                            variant="outlined"
                        />

                        <br />

                        <label for="file-upload" style={{
                            background: "#3C3835",
                            color: "white",
                            padding: "8px 12px",
                            borderRadius: "8px",
                        }}>
                            Upload Gambar
                        </label>
                        <input id="file-upload" accept="image/*" type="file" style={{ display: "none" }} onChange={(e) => UploadImage(e.target.files[0])} />

                        <br />

                        <Grid container>
                            {
                                (form.pics || "").split(",").filter(f => f != "" && f != "null").map(item => {
                                    return (
                                        <Grid item xs={3} style={{ padding: 4 }}>
                                            <img src={item} style={{
                                                width: "100%",
                                                objectFit: "contain"
                                            }} />
                                            <br />
                                            <br />
                                            <Button
                                                variant='outlined'
                                                style={{ width: "100%" }}
                                                size='small'
                                                onClick={() => {
                                                    setForm({
                                                        ...form,
                                                        pics: form.pics.replace(item, "")
                                                    })
                                                }}
                                            >Hapus</Button>
                                        </Grid >
                                    );
                                })
                            }
                        </Grid >

                    </Grid>
                </Grid>
            </Drawer>

            <Snackbar
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                TransitionComponent={Slide}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                autoHideDuration={3000}
                message={<span id="message-id">{messageSnackbar}</span>}
            />
        </div >
    )
}

export default connect()(Template);
