import React, { useEffect, useState } from 'react';
import MyTable from '../../components/MyTablePagination';
import axios from '../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { red, blue } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NumberFormatCustom from '../../components/NumberFormatCustom';

const drawerWidth = '80%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: '999'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  buttonAdd: {
    margin: theme.spacing(3)
  }
}));

const fields = ['Nama', 'Deskripsi', 'Weight', 'Height', 'Harga'];
const fieldData = ['name', 'description', 'weight', 'height', 'price'];

const Product = () => {
  const classes = useStyles();
  const theme = useTheme();

  // product
  const [data, setData] = useState([]);
  const [form, setForm] = useState({
    id: null,
    name: '',
    description: '',
    weight: 0,
    height: 0,
    price: 0
  });

  // component
  const [openComponent, setOpenComponent] = React.useState(false);
  const [dataComponent, setDataComponent] = React.useState([]);
  const [alertDelete, setAlertDelete] = React.useState(false);
  const [selectedIDComponent, setSelectedIDComponent] = React.useState(null);

  const [formComponent, setFormComponent] = React.useState({
    id: null,
    name: ''
  });

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    getProducts();
  }, [page]);

  // API
  const getProducts = () => {
    axios({
      method: 'GET',
      url: `package/products?page=${page}&limit=20`,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          setPageCount(response.data.pagination);
          parsingData(response.data.data);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const AddProduct = () => {
    let data_product = {
      name: form.name,
      description: form.description,
      weight: Number(form.weight),
      height: Number(form.height),
      price: Number(form.price)
    };

    axios({
      method: 'POST',
      url: `package/product`,
      headers: { 'Content-Type': 'application/json' },
      data: data_product
    })
      .then(() => {
        setPage(1);
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const UpdateProduct = () => {
    let data_product = {
      id: form.id,
      name: form.name,
      description: form.description,
      weight: Number(form.weight),
      height: Number(form.height),
      price: Number(form.price)
    };

    axios({
      method: 'PUT',
      url: `package/product`,
      data: data_product,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(() => {
        setPage(1);
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const deleteProduct = id => {
    axios({
      method: 'DELETE',
      url: `package/product`,
      headers: { 'Content-Type': 'application/json' },
      params: { id: id }
    })
      .then(() => {
        setPage(1);
        handleDrawerClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const getComponents = productID => {
    axios({
      method: 'GET',
      url: `package/product/components?product=` + productID,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        if (response.status === 200 && response.data.data != null) {
          setDataComponent(response.data.data);
        } else {
          dataComponent([]);
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  const AddComponent = () => {
    let data_component = {
      product: form.id,
      components: [
        {
          name: formComponent.name
        }
      ]
    };

    axios({
      method: 'POST',
      url: `package/product/component`,
      headers: { 'Content-Type': 'application/json' },
      data: data_component
    })
      .then(() => {
        getComponents(form.id);
        handleComponentClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const EditComponent = () => {
    let data_component = {
      product: form.id,
      components: [
        {
          id: formComponent.id,
          name: formComponent.name
        }
      ]
    };

    axios({
      method: 'PUT',
      url: `package/product/component`,
      data: data_component,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(() => {
        getComponents(form.id);
        handleComponentClose();
      })
      .catch(error => {
        alert(error);
      });
  };

  const DeleteComponent = id => {
    axios({
      method: 'DELETE',
      url: `package/product/component`,
      headers: { 'Content-Type': 'application/json' },
      params: { id: id }
    })
      .then(() => {
        getComponents(form.id);
        setAlertDelete(false);
      })
      .catch(error => {
        alert(error);
      });
  };
  //

  // parsing
  const parsingData = data => {
    if (data != null) {
      const newData = data.map(v => {
        return {
          ...v,
          name: v.name,
          description: v.description,
          weight: `${v.weight} cm`,
          height: `${v.height} cm`,
          price: `Rp ${v.price.toLocaleString(navigator.language, {
            minimumFractionDigits: 2
          })}`,
          // for validate on MyTable component
          publish: v.status === 'published'
        };
      });
      setData(newData);
    }
  };
  //////////

  // handle
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerOpenForEdit = id => {
    axios({
      method: 'GET',
      url: `package/product?id=` + id,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        const selectProduct = response.data.data;

        setForm({
          ...form,
          id: selectProduct.id,
          name: selectProduct.name,
          description: selectProduct.description,
          weight: selectProduct.weight,
          height: selectProduct.height,
          price: selectProduct.price
        });
        getComponents(id);

        handleDrawerOpen();
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      name: '',
      description: '',
      weight: 0,
      height: 0,
      price: 0
    });
    setDataComponent([]);
  };

  const handleChangeForm = name => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleChangeFormComponent = name => e => {
    setFormComponent({
      ...formComponent,
      [name]: e.target.value
    });
  };

  const handleEditComponent = id => {
    const selectComponent = dataComponent.find(v => v.id === id);

    setFormComponent({
      ...formComponent,
      id: selectComponent.id,
      name: selectComponent.name
    });

    setOpenComponent(true);
  };

  const handleComponentClose = () => {
    setOpenComponent(false);

    setFormComponent({
      id: null,
      name: ''
    });
  };
  //

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  const handleShowHideProduct = async id => {
    const product = data.filter(v => v.id === id)[0];

    if (product) {
      let data_product = {
        id,
        name: product.name,
        description: product.description,
        weight: Number(product.weight),
        height: Number(product.height),
        price: Number(product.price),
        status: product.publish ? '' : 'published'
      };

      axios({
        method: 'PUT',
        url: `package/product`,
        data: data_product,
        headers: { 'Content-Type': 'application/json' }
      })
        .then(() => {
          // await setPage(1);
          // handleDrawerClose();
          getProducts();
        })
        .catch(error => {
          alert(error);
        });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <h4>Produk</h4>
            <hr style={{ marginBottom: '30px' }} />

            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deleteProduct}
              onEdit={handleDrawerOpenForEdit}
              onShowHide={handleShowHideProduct}
              onUpdate={() => {}}
              titleAdd="Tambah Product"
              page={page}
              pageCount={pageCount}
              isLoading={tableLoading}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage}
            />
          </Card>
        </Grid>
      </Grid>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}>
        <div className={classes.drawerHeader}>
          <IconButton
            onClick={() => {
              handleDrawerClose();
            }}>
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          {form.id === null ? 'Simpan' : 'Ubah'} Informasi Product
          <Button
            variant="contained"
            color="primary"
            style={{ position: 'absolute', right: '20px', top: '-10px' }}
            className={classes.buttonAdd}
            onClick={
              form.id === null ? () => AddProduct() : () => UpdateProduct()
            }>
            {form.id === null ? 'Simpan' : 'Ubah'}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={6}>
            <h5>Informasi Product</h5>
            <Grid item xs={10}>
              <TextField
                id="name"
                label="Nama Produk"
                value={form.name}
                onChange={handleChangeForm('name')}
                margin="normal"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="description"
                label="Deskripsi Produk"
                value={form.description}
                onChange={handleChangeForm('description')}
                margin="normal"
                fullWidth
                multiline
                rows="4"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={10}>
              <Grid item xs={6} style={{ float: 'left' }}>
                <TextField
                  id="weight"
                  label="Weight"
                  value={form.weight}
                  onChange={handleChangeForm('weight')}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} style={{ float: 'right' }}>
                <TextField
                  id="height"
                  label="Height"
                  value={form.height}
                  onChange={handleChangeForm('height')}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="price"
                label="Harga"
                value={form.price}
                onChange={handleChangeForm('price')}
                margin="normal"
                variant="outlined"
                InputProps={{ inputComponent: NumberFormatCustom }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <h5>Komponen Produk</h5>
            <Grid item xs={12} style={{ marginBottom: '25px' }}>
              {dataComponent.length !== 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Komponen Produk</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataComponent.map(v => (
                      <TableRow key={v.id}>
                        <TableCell>{v.name}</TableCell>
                        <TableCell>
                          <a
                            onClick={() => {
                              handleEditComponent(v.id);
                            }}>
                            <EditIcon
                              style={{ color: blue[500] }}
                              className="pointer mr-2"
                            />
                          </a>
                          <a
                            onClick={() => {
                              setAlertDelete(true);
                              setSelectedIDComponent(v.id);
                            }}>
                            <DeleteIcon
                              style={{ color: red[500] }}
                              className="pointer mr-2"
                            />
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : null}
            </Grid>
            <Button
              onClick={() => setOpenComponent(true)}
              variant="contained"
              color="secondary">
              Tambah Component
            </Button>
          </Grid>
        </Grid>
        <Divider />
      </Drawer>

      {/* DIALOG FOR Component */}
      <Dialog
        open={openComponent}
        onClose={handleComponentClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Komponen Produk</DialogTitle>
        <DialogContent>
          <Grid container style={{ width: '450px' }}>
            <TextField
              id="name"
              label="Komponen"
              value={formComponent.name}
              onChange={handleChangeFormComponent('name')}
              margin="normal"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleComponentClose} color="primary">
            Tidak
          </Button>
          <Button
            onClick={
              formComponent.id === null
                ? () => AddComponent()
                : () => EditComponent()
            }
            variant="contained"
            color="primary">
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG FOR DELETE Component */}
      <Dialog
        open={alertDelete}
        onClose={() => {
          setAlertDelete(false);
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
        <DialogContent>Anda yakin ingin menghapus data ini?</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertDelete(false);
            }}
            color="primary">
            Tutup
          </Button>
          <Button
            onClick={() => {
              DeleteComponent(selectedIDComponent);
            }}
            color="primary"
            variant="contained">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Product;
