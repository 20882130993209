import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {red} from '@material-ui/core/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const shouldRenderPositive = (props) => {
  if (props.positive === '') {
    return null;
  } else {
    return (
      <Button variant="contained" onClick={props.onPositive} style={{ color:'white', backgroundColor: red[500] }}>
        {props.positive}
      </Button>
    )
  }
}

AlertDialogSlide.defaultProps = {
  positive: '',
}

export default function AlertDialogSlide(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { shouldRenderPositive(props) }
          <Button onClick={props.onNegative} color="primary">
            {props.negative}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}