import React from 'react'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
export default function ButtonExportPdf({
    data,
}) {
    return (
    <Link to={`/receipt?ids=${data.join(",")}`}>
        <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: '25px', marginLeft: '15px' }}
        >Pdf</Button></Link>
    )
}