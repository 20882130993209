import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, Grid, IconButton, InputLabel, Slide, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import MyTable from "../../components/MyTable";
import { connect } from "react-redux";
import { useEffect } from 'react';
import { useState } from 'react';
import { API } from "../../constants";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Select from '../../components/Select';
import moment from 'moment';
import { blue, red } from '@material-ui/core/colors';

const drawerWidth = '80%';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    typography: {
        fontFamily: [
            'Roboto',
        ].join(','),
    },
    content: {
        flexShrink: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: '999',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    buttonAdd: {
        margin: theme.spacing(3),
    },
    //upload image
    fileUploadThumbnail: {
        left: '25px',
    },
    fileUploadhide: {
        float: 'left',
        opacity: '0',
        zIndex: '999',
        position: 'absolute',
        width: '21%',
        height: '11%',
        marginTop: '20px',
    },
    bigAvatar: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    bigAvatarBanner: {
        width: '100px',
        height: '100px',
        borderRadius: '0',
        backgroundColor: '#f7f7f7',
        marginTop: '25px',
    },
    disableClick: {
        pointerEvents: 'none',
        cursor: 'not-allowed',
    },
    enableClick: {
        pointerEvents: 'auto',
        cursor: 'default',
    }
}));

const fields = ['Nama Package'];
const fieldData = ['package_name'];

const Ranged = props => {
    const classes = useStyles();
    const theme = useTheme();

    const getTimebox = async () => {
        const api = await fetch(`${API}/timebox/timebox`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setData(res.data.map(i => ({
                id: i.package_id,
                ...i
            })));
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getDetailTimebox = async (id) => {
        const api = await fetch(`${API}/timebox/detail?package=${id}`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setDataDetail(res.data);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getPackages = async () => {
        const api = await fetch(`${API}/packages?page=1&limit=1000000`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setDataPackages(res.data.map((v) => ({ label: v.title, value: v.id, time: v.interval_time })));
            setSelectedPackages([]);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getDetailPackage = async (id) => {
        const api = await fetch(`${API}/package?id=${id}`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            setDetailPackage(res.data);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const getStudios = async () => {
        const api = await fetch(`${API}/timebox/studios`);
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            function compare(a, b) {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            }

            setDataStudios(res.data.map((v) => ({ label: `${v.data_store.name} - ${v.name}`, value: v.id })).sort(compare));
            setSelectedStudios([]);
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const sessionExpired = () => {
        handleShowSnackbar('Sesi anda sudah berakhir. Harap login kembali');
        localStorage.clear();
        window.location.href = "/sign-in";
    };

    const addTimeBox = async (form) => {
        const api = await fetch(`${API}/timebox/timebox`, {
            body: JSON.stringify(form),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleCloseDialog();
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const updateTimeBox = async (form) => {
        const api = await fetch(`${API}/timebox/timebox`, {
            body: JSON.stringify(form),
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleCloseDialog();
        } else {
            handleShowSnackbar(res.message);
        }
    }

    const deleteTimeBox = async (form) => {
        const api = await fetch(`${API}/timebox/timebox?id=${form.id}`, { method: 'DELETE' });
        if (!api.ok) {
            handleShowSnackbar(api.statusText);
            return;
        }
        const res = await api.json();
        if (res.response_code === '00') {
            handleCloseDialog();
        } else {
            handleShowSnackbar(res.message);
        }
    }

    useEffect(() => {
        const currUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currUser) {
            const { token } = currUser;
            if (token) {
                setAccountData(token);
                getTimebox();
                getStudios();
                getPackages();
            } else {
                sessionExpired();
            }
        } else {
            sessionExpired();
        }
    }, []);

    const [accountData, setAccountData] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [messageSnackbar, setMessageSnackbar] = useState('');
    const [data, setData] = useState([]);
    const [dataDetail, setDataDetail] = useState([]);
    const [disableDuringFetch, setDisableDuringFetch] = useState(false);
    const [open, setOpen] = useState(false);
    const [detailPackage, setDetailPackage] = useState(null);
    const [dataStudios, setDataStudios] = useState([]);
    const [selectedStudios, setSelectedStudios] = useState(null);
    const [dataPackages, setDataPackages] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState(null);
    const [modalTimeBox, setModalTimeBox] = useState(null);
    const [alertDelete, setAlertDelete] = useState(null);
    const [form, setForm] = useState({
        id: null,
        basePackageId: null,
        packageId: null,
        studioId: null,
        startTime: null,
    })

    const handleDrawerOpen = () => {
        setForm({
            id: null,
            basePackageId: null,
            packageId: null,
            studioId: null,
            startTime: null,
        });
        setSelectedStudios(null);
        setDetailPackage(null);
        setDataDetail([]);
        setOpen(true);
    }

    const handleDrawerOpenForEdit = (packageId) => {
        setForm({
            id: null,
            basePackageId: packageId,
            packageId: null,
            studioId: null,
            startTime: null,
        });
        setSelectedPackages(dataPackages.find(i => i.value == packageId));
        getDetailTimebox(packageId);
        getDetailPackage(packageId);
        setSelectedStudios(null);
        setAlertDelete(null);
        setOpen(true);
    }

    const handleShowSnackbar = message => {
        setShowSnackbar(true);
        setMessageSnackbar(message);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        getTimebox();
    }

    const handleCloseDialog = () => {
        setModalTimeBox(null);
        handleDrawerOpenForEdit(
            (form.basePackageId == null) ? selectedPackages.value : form.basePackageId
        );
    }

    const handleSubmit = () => {
        const body = {
            package: selectedPackages.value,
            studio: selectedStudios.value,
            start_time: form.startTime,
            duration: detailPackage?.interval_time,
        }
        if (form.id == null) {
            addTimeBox(body);
        } else {
            updateTimeBox({
                ...body,
                id: form.id,
            })
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                    <Card className={classes.root} variant="outlined">

                        <h4>Timebox Package</h4>
                        <hr style={{ marginBottom: '30px' }} />

                        <MyTable
                            id="id"
                            data={data}
                            fields={fields}
                            fieldData={fieldData}
                            onAdd={handleDrawerOpen}
                            onEdit={handleDrawerOpenForEdit}
                            onUpdate={() => { }}
                            titleAdd="Tambah Time Box" />
                    </Card>
                </Grid>
            </Grid>

            <Drawer
                className={`${classes.drawer} ${disableDuringFetch ? classes.disableClick : classes.enableClick}`}
                anchor="right"
                open={open}
                classes={{ paper: classes.drawerPaper, }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => {
                        handleDrawerClose()
                    }}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <Typography variant="h5">{form.id == null ? "Simpan" : "Ubah"} Informasi Timebox</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ position: 'absolute', right: '20px', top: '-10px' }}
                        className={classes.buttonAdd} onClick={() => handleSubmit()}>
                        {form.id == null ? "Simpan" : "Ubah"}
                    </Button>
                </div>
                <Divider />
                <Grid container style={{ padding: theme.spacing(3) }}>
                    <Grid item xs={12}>
                        <Select
                            name="package"
                            placeholder="Pilih Package"
                            options={dataPackages}
                            value={selectedPackages}
                            onChange={e => { setSelectedPackages(e) }}
                            fullWidth
                            isDisabled={form.basePackageId != null}
                        />
                        {
                            detailPackage && <>
                                Informasi Durasi Package <strong>{detailPackage.interval_time}</strong> Menit<br />
                                Catatan: Durasi belum termasuk waktu tambahan yang diminta oleh pengguna.
                            </>
                        }
                        <br />
                        {
                            form.basePackageId == null && <Select
                                name="studio"
                                placeholder="Pilih Studio"
                                options={dataStudios}
                                value={selectedStudios}
                                onChange={e => { setSelectedStudios(e) }}
                                fullWidth
                            />
                        }
                        {
                            form.basePackageId == null && <TextField
                                id="open"
                                name="open"
                                label="Jam Buka"
                                style={{ width: '100%' }}
                                value={form.startTime}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        startTime: e.target.value,
                                    })
                                }}
                                margin="normal"
                                variant="outlined"
                                type="time"
                            />
                        }
                        {
                            form.basePackageId != null && <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    float: 'right',
                                }}
                                size='small'
                                className={classes.buttonAdd} onClick={() => {
                                    setModalTimeBox({});
                                }}>
                                Tambah Jadwal
                            </Button>
                        }
                        {
                            form.basePackageId != null && <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Store</TableCell>
                                        <TableCell>Studio</TableCell>
                                        <TableCell>Jam Mulai</TableCell>
                                        <TableCell>Jam Berakhir</TableCell>
                                        <TableCell>Aksi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataDetail.map(item => {
                                            let endTime = "";
                                            if (detailPackage) {
                                                const startTime = moment(item.start_time, "HH:mm");
                                                endTime = startTime.add(detailPackage.interval_time, "minutes");
                                                endTime = endTime.format("HH:mm");
                                            }
                                            return (
                                                <TableRow key={item.id}>
                                                    <TableCell>{item.data_studio.data_store.name}</TableCell>
                                                    <TableCell>{item.data_studio.name}</TableCell>
                                                    <TableCell>{item.start_time}</TableCell>
                                                    <TableCell>{endTime}</TableCell>
                                                    <TableCell>
                                                        <a onClick={() => {
                                                            setForm({
                                                                ...form,
                                                                id: item.id,
                                                                studioId: item.studio,
                                                                startTime: item.start_time
                                                            })
                                                            setModalTimeBox(form);
                                                            setSelectedStudios(dataStudios.find(f => f.value == item.studio))
                                                        }}>
                                                            <EditIcon style={{ color: blue[500] }} className="pointer mr-2" />
                                                        </a>
                                                        <a onClick={() => {
                                                            setAlertDelete({ id: item.id });
                                                        }}>
                                                            <DeleteIcon style={{ color: red[500] }} className="pointer mr-2" />
                                                        </a>
                                                    </TableCell>
                                                </TableRow >
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        }
                    </Grid>
                </Grid>
            </Drawer>

            <Dialog open={modalTimeBox} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Time Box</DialogTitle>
                <DialogContent>
                    <Grid container style={{ width: '530px' }}>
                        <Grid item xs={12} style={{ marginRight: '20px' }}>
                            <Select
                                name="modal_studio"
                                placeholder="Pilih Studio"
                                options={dataStudios}
                                value={selectedStudios}
                                onChange={e => { setSelectedStudios(e) }}
                                fullWidth
                            />
                            <TextField
                                id="open"
                                name="modal_open"
                                label="Jam Mulai"
                                style={{ width: '100%' }}
                                value={form.startTime}
                                onChange={(e) => {
                                    setForm({
                                        ...form,
                                        startTime: e.target.value,
                                    })
                                }}
                                margin="normal"
                                variant="outlined"
                                type="time"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    float: 'right',
                                }}
                                size='small'
                                className={classes.buttonAdd} onClick={handleSubmit}>
                                Tambah Jadwal
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog open={alertDelete != null} onClose={() => { setAlertDelete(null) }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Peringatan!</DialogTitle>
                <DialogContent>
                    Anda yakin ingin menghapus data ini?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setAlertDelete(null) }} color="primary">
                        Tutup
                    </Button>
                    <Button onClick={() => {
                        deleteTimeBox(alertDelete);
                    }} color="primary" variant="contained">
                        Hapus
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                TransitionComponent={Slide}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                autoHideDuration={3000}
                message={<span id="message-id">{messageSnackbar}</span>}
            />
        </div >
    )
}

export default connect()(Ranged);
