import { API } from '../constants';

export const selectLocalImage = (returnFunction) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*')
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
        const file = input.files[0];
        // file type is only image.
        if (/^image\//.test(file.type)) {
            saveImageToServer(file, returnFunction);
        } else {
            console.warn('You could only upload images.');
        }
    };
}

export const saveImageToServer = async (file, returnFunction) => {
    const body = new FormData();
    body.append("category", "thumbnail");
    body.append("file", file);
    body.append("type", file['type']);
    const upload = await fetch(`${API}/upload`, {
        method: 'POST',
        body,
    });
    if (upload.ok) {
        const res = await upload.json();
        if (res.response_code == "00") {
            returnFunction(res.data);
        } else {
            alert("Failed upload image");
        }
    } else {
        alert("Failed upload image");
    }
}
