import axios from 'axios';

const API ='http://ec2-18-138-82-207.ap-southeast-1.compute.amazonaws.com:6969/api/v1';
const API_NEW = process.env.REACT_APP_BASE_URL;

// const token = localStorage.getItem('token')

const currUser = JSON.parse(localStorage.getItem('currentUser'));
const { token } = currUser ? currUser : {};

const getToken = () => (token && token.token ? token.token : '');

let service = axios.create({
  baseURL: API_NEW,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + getToken()
  }
});

service.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Return any error which is not due to authentication back to the calling service

    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    } else {
      if (localStorage.getItem('token')) {
        alert('Sesi anda sudah berakhir, harap login kembali!');
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        window.location.href = '/sign-in';
      }
    }
  }
);

export default service;
