import Quill from "quill";

let BlockEmbed = Quill.import("blots/block/embed");

class ImageBlot extends BlockEmbed {
    static create(value) {
        let node = super.create();
        node.setAttribute("alt", value.alt);
        node.setAttribute("src", value.url);
        node.setAttribute("width", value.width);
        node.setAttribute("height", value.height);
        node.setAttribute("style", value.style);
        return node;
    }

    static value(node) {
        return {
            alt: node.getAttribute("alt"),
            url: node.getAttribute("src"),
            width: node.getAttribute("width"),
            height: node.getAttribute("height"),
            style: node.getAttribute("style")
        };
    }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
export default ImageBlot;