import authTypes from './auth.types'

const INITIAL_STATE = {
  token: ''
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case authTypes.SET_TOKEN:
      console.log('huwiw', state)
      console.log('huwiw', action.payload)
      console.log('huwiw', action.type)
      return {
        ...state, token: action.payload
      }
    default:
      return { ...state }
  }
}

export default authReducer