import React from 'react'
import Button from '@material-ui/core/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function ButtonExportExcel ({csvData, fileName, typeData}) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);

        if (typeData === 'transaksi') {
            alert('Hanya mengunduh 10 data yang muncul di tabel');
        }
    }

    return (
        <Button variant="contained" color="secondary" onClick={(e) => {exportToCSV(csvData,fileName)}} style={{marginTop:'25px'}}>Export Excel</Button>
    )
}