import { makeStyles } from '@material-ui/core/styles';

/**
 * Separating Styles for Improving Readability
 */
const drawerWidth = '50%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  buttonAdd: {
    margin: theme.spacing(3),
    position: 'absolute',
    right: '20px',
    top: '-10px'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  detail: {
    fontWeight: '600',
    textAlign: 'right'
  }
}));

export default useStyles;