import React, { useEffect,useState } from 'react';
import { NavLink } from "react-router-dom";
import MyTable from "../../../components/MyTable";
import axios from '../../../util/Service';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  Grid, 
  Card, 
  Drawer,
  Divider,
  Button,
  IconButton,
  TextField,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Select from '../../../components/Select';

const drawerWidth = '40%';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    flexShrink: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: '999',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  buttonAdd: {
    margin: theme.spacing(3),
  },
  //upload image
  loaderBungkus: {
    margin: 10,
    width: 100,
    height: 100,
    background: 'rgba(0,0,0,0.4)',
    position: 'absolute',
    top: '0',
    borderRadius: '50%',
    zIndex: '99',
  },
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
  },
  fileUploadhide: {
    left: '25px',
    float: 'left',
    opacity: '0',
    zIndex: '999',
    position: 'absolute',
    width: '21%',
    height: '11%',
    marginTop:'20px',
  },
  bigAvatar: {
    width: '500px',
    height: 'auto',
    borderRadius: '0',
    backgroundColor: '#f7f7f7',
    marginTop: '25px',
  }
  })
);

const fields = ['Judul'];
const fieldData = ['title'];

const BannerCategory = () => {
  const classes = useStyles();
  const theme = useTheme();

  // Role
  const currUser = JSON.parse(localStorage.getItem('currentUser'));
  const [accountData, setAccountData] = useState(null);

  //state
  const [data, setData] = useState([]);
  const [dataBannerCategory, setDataBannerCategory] = useState([]);

  // form
  const [selectedBannerCategory, setSelectedBannerCategory] = useState(null);
  const [form,setForm] = useState({
    id: null,
    title: '',
    image_url:'',
  });
  const [uploadImage, setUploadImage] = useState({
    load_on_upload : 0,
    thumbnail:'',
    image:'',
  });

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (currUser) {
      const { token } = currUser;
      if (token) {
        setAccountData(token);
      }
    }
  }, []);

  useEffect(() => {
    getCategories();
    getBannerCategory();
  },[]);

  // API
  const getCategories = () => {
    axios({
      method: 'GET',
      url: `categories`,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      if (response.status === 200 && response.data.data != null) { parsingCategory(response.data.data); }
      else { parsingCategory([]); }
    }).catch(error => { alert(error); })
  };

  const getBannerCategory = () => {
    axios({
      method: 'GET',
      url: `category/banners`,
      headers: { "Content-Type": "application/json" },
    }).then(response => { if (response.status === 200&& response.data.data != null) { setData(response.data.data); }
    }).catch(error => { alert(error); })
  };

  async function AddPhoto(){
    if (uploadImage.thumbnail  !== '') {
      const url = `upload`;
      let bodyData = new FormData();
        bodyData.append("category", "thumbnail");
        bodyData.append("file", uploadImage.image);
        bodyData.append("type", uploadImage.image['type']);
      const result = await axios.post(
        url, bodyData
      );
      
      let gambar = result.data.data;
      form.id === null ? AddBannerCategory(gambar) : UpdateBannerCategory(gambar);
    } else {
      form.id === null ? AddBannerCategory(form.image_url) : UpdateBannerCategory(form.image_url);
    }
  };

  async function AddBannerCategory(url_gambar) {

    const id_category = [];
    selectedBannerCategory && selectedBannerCategory.map(v => { id_category.push(v.id); })
    
    let data_banner_category = {
      banner: {
        title: form.title,
        image_url: url_gambar
      },
      category:{ id: id_category }
    };

    axios({
      method: 'POST',
      url: `category/banner`,
      headers: { "Content-Type": "application/json" },
      data: data_banner_category
    }).then(response => {
      getBannerCategory();
      handleDrawerClose();
    }).catch(error => { alert(error); })
  };

  async function UpdateBannerCategory(url_gambar){

    const id_category = [];
    selectedBannerCategory && selectedBannerCategory.map(v => { id_category.push(v.id); });
    
    let data_banner_category = {
      banner: {
        id: form.id,
        title: form.title,
        image_url: url_gambar
      },
      category:{ id: id_category }
    };

    axios({
      method: 'PUT',
      url: `category/banner`,
      data: data_banner_category,
      headers: { "Content-Type": "application/json" },
    }).then(response => {
      getBannerCategory();
      handleDrawerClose();
    }).catch(error => { alert(error); })
  };

  const deleteBannerCategory = id => {
    axios({
      method: 'DELETE',
      url: `category/banner`,
      headers: { "Content-Type": "application/json" },
      params: { id: id }
    }).then(response => {
      getBannerCategory();
      handleDrawerClose();
    }).catch(error => { alert(error); })
  };
  //

  // parsing
  const parsingCategory = data => {
    const newData = data.map(v => {
      return{
        ...v,
        label: v.name,
        value: v.id
      }
    });
    setDataBannerCategory(newData);
    setSelectedBannerCategory(newData[0]);
  };
  // 

  // handle
  const handleDrawerOpen = () => { setOpen(true); };

  const handleDrawerOpenForEdit = id => { 
    axios({
      method: 'GET',
      url: `category/banner?id=`+id,
      headers: { "Content-Type": "application/json" },
    }).then(response => { 
      if (response.status === 200&& response.data.data != null) { 
        const selectBannerCategory = response.data.data;
    
        setForm({
          ...form,
          id: selectBannerCategory.banner.id,
          title: selectBannerCategory.banner.title,
          image_url:selectBannerCategory.banner.image_url,
        });
  
        const newData = selectBannerCategory.category && selectBannerCategory.category.map(v => {
          return{
            ...v,
            label: v.name,
            value: v.id
          }
        });
        setSelectedBannerCategory(newData);
        handleDrawerOpen();
      }
    }).catch(error => { alert(error); })
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setForm({
      id: null,
      title: '',
      image_url:'',
    });
    setSelectedBannerCategory([]);
  };

  const handleChangeForm = (name) => e => {
    setForm({
      ...form,
      [name]: e.target.value
    });
  };

  const handleSelectedBannerCategory = category => {
    setSelectedBannerCategory(category);
  };

  const onChangeGambar = (value) => {
    let gambar = value.target.files;
    if (gambar.length !== 0) {
      setUploadImage({
        thumbnail: URL.createObjectURL(gambar[0]),
        image: gambar[0],
      })
    }
  };
  //

  const handleOnNextPage = () => setPage(page + 1);
  const handleOnBackPage = () => setPage(page - 1);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Card className={classes.root} variant="outlined">
            <NavLink to='/categories' style={{ color:'#3c3835',marginLeft:'-10px' }}>
              <ArrowBackIcon />
            </NavLink>

            <h4>Banner Kategori</h4>
            <hr style={{ marginBottom:'30px' }}/>
            
            <MyTable
              id="id"
              data={data}
              fields={fields}
              fieldData={fieldData}
              onAdd={handleDrawerOpen}
              onDelete={deleteBannerCategory}
              onEdit={handleDrawerOpenForEdit}
              onUpdate={() => {}}
              titleAdd="Tambah Banner Kategori"
              page={page}
              onNextPageClicked={handleOnNextPage}
              onBackPageClicked={handleOnBackPage}/>
          </Card>
        </Grid>
      </Grid>
      
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper, }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => { handleDrawerClose() }}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
            {form.id == null ? "Simpan" : "Ubah"} Informasi Banner Kategori
          <Button variant="contained" color="primary" style={{ position:'absolute',right:'20px',top:'-10px' }} className={classes.buttonAdd} onClick={() => AddPhoto()}>
            {form.id == null ? "Simpan" : "Ubah"}
          </Button>
        </div>
        <Divider />
        <Grid container style={{ padding: theme.spacing(3) }}>
          <Grid item xs={12}>
            <Select
              name="Kategori"
              placeholder="Pilih Kategori"
              options={dataBannerCategory}
              value={selectedBannerCategory}
              onChange={handleSelectedBannerCategory}
              fullWidth
              isMulti
            />
            <TextField
                id="title"
                title="title Banner Kategori"
                value={form.title}
                onChange={handleChangeForm('title')}
                margin="normal"
                fullWidth
                variant="outlined"
            />
            <Grid item xs={6}>
              <div className={classes.bungkusGambar}>
                {uploadImage.thumbnail !== '' ?
                  <div>
                    {uploadImage.load_on_upload === 1 ? <div className={classes.loaderBungkus}>
                      <CircularProgress color='secondary' style={{ top: '40%' }} className={classes.progress} />
                    </div> : uploadImage.load_on_upload === 2 ? <div className={classes.loaderBungkus}>
                      <CheckIcon color='primary' fontSize='large' className={classes.progress} />
                    </div> : ''}
                    < Avatar alt="Image" src={uploadImage.thumbnail} className={classes.bigAvatar} value={form.image_url} />
                  </div>
                  :
                  <Avatar alt="Image" className={classes.bigAvatar} src={form.image_url}>
                    <InsertPhotoIcon fontSize='large' />
                  </Avatar>
                }
              </div>
              <input className={classes.fileUploadhide + ' mt-2'} type='file' onChange={onChangeGambar} />
              <Button variant="contained" color="secondary" className={classes.button} style={{ marginTop:'20px' }}>
                {form.image_url === "" ? 'Tambah' : 'Ubah'} Foto
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </Drawer> 
    </div>
  );
};

export default BannerCategory;
