import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Icon } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import StoreIcon from '@material-ui/icons/Store';
import CategoryIcon from '@material-ui/icons/Category';
import SubCategoryIcon from '@material-ui/icons/CategoryOutlined';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import FilterFramesIcon from '@material-ui/icons/FilterFrames';
import BallotIcon from '@material-ui/icons/Ballot';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import PersonIcon from '@material-ui/icons/Person';
import TermsAndCondition from '@material-ui/icons/MenuBook';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import TimerIcon from '@material-ui/icons/Timer';
import RepeatIcon from '@material-ui/icons/Repeat';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';

import { Profile, SidebarNav, UpgradePlan } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const superAdminPages = [
  // {
  //   title: 'Dashboard',
  //   href: '/dashboard',
  //   icon: <DashboardIcon />
  // },
  {
    title: 'Banner',
    href: '/banner',
    icon: <ViewCarouselIcon />
  },
  {
    title: 'Kota',
    href: '/cities',
    icon: <LocationCityIcon />
  },
  {
    title: 'Kategori',
    href: '/categories',
    icon: <CategoryIcon />
  },
  {
    title: 'Sub Kategori',
    href: '/sub-categories',
    icon: <SubCategoryIcon />
  },
  {
    title: 'Store',
    href: '/store',
    icon: <StoreIcon />
  },
  {
    title: 'Produk',
    href: '/product',
    icon: <FilterFramesIcon />
  },
  {
    title: 'Package',
    href: '/package',
    icon: <BallotIcon />
  },
  {
    title: 'Time Box Package',
    href: '/ranged',
    icon: <TimerIcon />
  },
  {
    title: 'Package Quota',
    href: '/quota',
    icon: <RepeatIcon />
  },
  {
    title: 'Reservasi',
    href: '/reservation',
    icon: <EventSeatIcon />
  },
  {
    title: 'Voucher Discount',
    href: '/voucher-discount',
    icon: <ConfirmationNumberIcon />
  },
  {
    title: 'Tiered Discount',
    href: '/staircase-voucher',
    icon: <ConfirmationNumberIcon />
  },
  {
    title: 'Transaksi',
    href: '/transactions',
    icon: <ReceiptIcon />
  },
  {
    title: 'Konfirmasi Pembayaran',
    href: '/confirm-payment',
    icon: <ReceiptIcon />
  },
  {
    title: 'Setting',
    href: '/setting',
    icon: <SettingsIcon />
  },
  {
    title: 'Template',
    href: '/template',
    icon: <ImageAspectRatioIcon />
  },
  {
    title: 'Terms and Condition',
    href: '/tnc',
    icon: <TermsAndCondition />
  },
  {
    title: 'Users',
    href: '/users',
    icon: <PeopleIcon />
  },
  {
    title: 'Profile',
    href: '/profile',
    icon: <PersonIcon />
  }
];

const storeAdminPages = [
  {
    title: 'Store',
    href: '/store',
    icon: <StoreIcon />
  },
  {
    title: 'Reservasi',
    href: '/reservation',
    icon: <EventSeatIcon />
  },
  {
    title: 'Voucher Discount',
    href: '/voucher-discount',
    icon: <ConfirmationNumberIcon />
  },
  {
    title: 'Staircase Voucher',
    href: '/staircase-voucher',
    icon: <ConfirmationNumberIcon />
  },
  {
    title: 'Transaksi',
    href: '/transactions',
    icon: <ReceiptIcon />
  },
  {
    title: 'Users',
    href: '/users',
    icon: <PeopleIcon />
  },
  {
    title: 'Profile',
    href: '/profile',
    icon: <PersonIcon />
  }
];

const studioAdminPages = [
  {
    title: 'Studio',
    href: '/schedule-studio',
    icon: <StoreIcon />
  },
  {
    title: 'Profile',
    href: '/profile',
    icon: <PersonIcon />
  }
];

const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
  const [account, setAccount] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const account = localStorage.getItem('currentUser');
    setAccount(JSON.parse(account));
  }, []);

  const determinePageByRoles = () => {
    if (account.token) {
      const { token } = account;

      switch (token.role) {
        case 0:
        case 1:
          return superAdminPages;
        case 2:
          return storeAdminPages;
        case 3:
          return studioAdminPages;
        default:
          return [];
      }
    }

    return [];
  };

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        {account && (
          <SidebarNav className={classes.nav} pages={determinePageByRoles()} />
        )}
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
